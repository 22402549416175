import React, { useState, useEffect } from "react";

import {
  Breadcrumbs,
  Link,
  Paper,
  Typography,
  makeStyles,
  Button,
  CircularProgress,
  useMediaQuery,
  colors,
  TextField,
  Box,
  InputAdornment,
  ListItem,
  List,
  SwipeableDrawer,
  IconButton
} from "@material-ui/core";
import {
  ChevronRight,
  ChevronLeft,
  Check,
  Facebook,
  Twitter,
  LinkedIn,
  Instagram,
  YouTube,
  WhatsApp,
  RadioButtonUnchecked
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { getSocialMedia, updateSocialMedia } from "../../redux";
import clsx from "clsx";
import { useHistory } from 'react-router-dom'

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const SocialMedia = () => {
  const history = useHistory()
  const classes = useStyles()
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("socialmedia");
  const state = useSelector((state) => state);
  const id = state?.branch?.activeBranch?._id
  const dispatch = useDispatch();
  const [formValue, setformValue] = useState({
    status: false,
    _id: "",
    social_media_facebook_url: "",
    social_media_twitter_url: "",
    social_media_google_plus_url: "",
    social_media_linked_in_url: "",
    social_media_instagram_url: "",
    social_media_youtube_url: "",
    social_media_whatsapp_number: "",
  });
  const [validate, setvalidate] = useState(false);

  useEffect(() => {
    dispatch(getSocialMedia());
  }, [dispatch]);


  const onSubmit = () => {
    if (formValue.social_media_facebook_url && !formValue.social_media_facebook_url.includes("https")) {
      setvalidate(false)
    } else {
      if (formValue.social_media_twitter_url && !formValue.social_media_twitter_url.includes("https")) {
        setvalidate(false)
      } else {
        if (formValue.social_media_google_plus_url && !formValue.social_media_google_plus_url.includes("https")) {
          setvalidate(false)
        } else {
          if (formValue.social_media_linked_in_url && !formValue.social_media_linked_in_url.includes("https")) {
            setvalidate(false)
          } else {
            if (formValue.social_media_instagram_url && !formValue.social_media_instagram_url.includes("https")) {
              setvalidate(false)
            } else {
              if (formValue.social_media_youtube_url && !formValue.social_media_youtube_url.includes("https")) {
                setvalidate(false)
              } else {
                if (formValue.social_media_whatsapp_number && formValue.social_media_whatsapp_number.length !== 10) {
                  setvalidate(false)
                } else {
                  dispatch(updateSocialMedia({ ...formValue, social_media_whatsapp_number: `https://wa.me/${state?.branch?.activeBranch?.branch_phone_code}${formValue.social_media_whatsapp_number}` }))
                }
              }
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    state?.socialmedia?.data && setformValue({
      ...state?.socialmedia?.data,
      social_media_whatsapp_number: (state?.socialmedia?.data?.social_media_whatsapp_number?.split("/")
      [state?.socialmedia?.data?.social_media_whatsapp_number?.split("/").length - 1]).substr(state?.branch?.activeBranch?.branch_phone_code?.length)
    });
  }, [state?.socialmedia]);

  useEffect(() => {
    setselectState(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
}, [window.location.pathname])

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", left: "30%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            CMS & SOCIAL
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "cms" ? "var(--orange)" : "#ffffff",
              }}
              onClick={() => history.push(`/${id}/cms`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Create CMS page</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "social-media" ? "var(--orange)" : "#ffffff",
              }}
              onClick={() => history.push(`/${id}/cms/social-media`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Social setting</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div
        style={{ padding: "0 20px" }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            Social Media Page Urls
          </Typography>
        </Breadcrumbs>

        {state.socialmedia.loader ? (
          <div className="loaderBg">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <Paper style={{ padding: "2rem", margin: "15px auto", width: "60%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                paddingBottom: "10px",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h6">Social Media Page Urls</Typography>
            </div>

            <TextField
              label="Facebook Url"
              variant="outlined"
              size="small"
              value={formValue.social_media_facebook_url}
              name="social_media_facebook_urlsocial_media_facebook_url"
              error={
                formValue.social_media_facebook_url &&
                !formValue.social_media_facebook_url.includes("https")
              }
              helperText={
                formValue.social_media_facebook_url &&
                !formValue.social_media_facebook_url.includes("https") &&
                "https is necessary"
              }
              onChange={(e) => {
                formValue.social_media_facebook_url &&
                  !formValue.social_media_facebook_url.includes("https") &&
                  setvalidate(false);
                setformValue({
                  ...formValue,
                  social_media_facebook_url: e.target.value,
                });
              }}
              style={{ width: "100%", marginBottom: "1.5rem" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Facebook color="secondary" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Twitter Url"
              variant="outlined"
              size="small"
              value={formValue.social_media_twitter_url}
              name="social_media_twitter_url"
              error={
                formValue.social_media_twitter_url &&
                !formValue.social_media_twitter_url.includes("https")
              }
              helperText={
                formValue.social_media_twitter_url &&
                !formValue.social_media_twitter_url.includes("https") &&
                "https is necessary"
              }
              onChange={(e) =>
                setformValue({
                  ...formValue,
                  social_media_twitter_url: e.target.value,
                })
              }
              style={{ width: "100%", marginBottom: "1.5rem" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Twitter color="secondary" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Google Plus Url"
              variant="outlined"
              size="small"
              value={formValue.social_media_google_plus_url}
              name="social_media_google_plus_url"
              error={
                formValue.social_media_google_plus_url &&
                !formValue.social_media_google_plus_url.includes("https")
              }
              helperText={
                formValue.social_media_google_plus_url &&
                !formValue.social_media_google_plus_url.includes("https") &&
                "https is necessary"
              }
              onChange={(e) =>
                setformValue({
                  ...formValue,
                  social_media_google_plus_url: e.target.value,
                })
              }
              style={{ width: "100%", marginBottom: "1.5rem" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Facebook color="secondary" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="LinkedIn Url"
              variant="outlined"
              size="small"
              value={formValue.social_media_linked_in_url}
              name="social_media_linked_in_url"
              error={
                formValue.social_media_linked_in_url &&
                !formValue.social_media_linked_in_url.includes("https")
              }
              helperText={
                formValue.social_media_linked_in_url &&
                !formValue.social_media_linked_in_url.includes("https") &&
                "https is necessary"
              }
              onChange={(e) =>
                setformValue({
                  ...formValue,
                  social_media_linked_in_url: e.target.value,
                })
              }
              style={{ width: "100%", marginBottom: "1.5rem" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkedIn color="secondary" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Instagram Url"
              variant="outlined"
              size="small"
              value={formValue.social_media_instagram_url}
              name="social_media_instagram_url"
              error={
                formValue.social_media_instagram_url &&
                !formValue.social_media_instagram_url.includes("https")
              }
              helperText={
                formValue.social_media_instagram_url &&
                !formValue.social_media_instagram_url.includes("https") &&
                "https is necessary"
              }
              onChange={(e) =>
                setformValue({
                  ...formValue,
                  social_media_instagram_url: e.target.value,
                })
              }
              style={{ width: "100%", marginBottom: "1.5rem" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Instagram color="secondary" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="YouTube Url"
              variant="outlined"
              size="small"
              value={formValue.social_media_youtube_url}
              name="social_media_youtube_url"
              error={
                formValue.social_media_youtube_url &&
                !formValue.social_media_youtube_url.includes("https")
              }
              helperText={
                formValue.social_media_youtube_url &&
                !formValue.social_media_youtube_url.includes("https") &&
                "https is necessary"
              }
              onChange={(e) =>
                setformValue({
                  ...formValue,
                  social_media_youtube_url: e.target.value,
                })
              }
              style={{ width: "100%", marginBottom: "1.5rem" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <YouTube color="secondary" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="WhatsApp No" type="number"
              variant="outlined"
              size="small"
              value={formValue.social_media_whatsapp_number}
              name="social_media_whatsapp_number"
              error={
                formValue.social_media_whatsapp_number &&
                formValue.social_media_whatsapp_number.length !== 10
              }
              helperText={
                formValue.social_media_whatsapp_number &&
                formValue.social_media_whatsapp_number.length !== 10 &&
                "Number should be 10 digits"
              }
              onChange={(e) =>
                setformValue({
                  ...formValue,
                  social_media_whatsapp_number: parseFloat(e.target.value) === NaN ? "" : e.target.value,
                })
              }
              style={{ width: "100%", marginBottom: "1.5rem" }}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <WhatsApp color="secondary" />
                    </InputAdornment>
                    <Typography>{`https://wa.me/${state?.branch?.activeBranch?.branch_phone_code}`}</Typography>
                  </>
                ),
              }}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width={200}
              margin="auto"
            >
              <Button
                startIcon={<ChevronLeft />}
                className="button"
                onClick={() => dispatch(getSocialMedia())}
              >
                back
              </Button>
              <Button
                className="button"
                startIcon={<Check />}
                onClick={() =>
                  // {
                  onSubmit()
                  // dispatch(updateSocialMedia({...formValue, social_media_whatsapp_number: `https://wa.me/${state?.branch?.activeBranch?.branch_phone_code}${formValue.social_media_whatsapp_number}`}))
                  // }
                }
              >
                save
              </Button>
            </Box>
          </Paper>
        )}
      </div>
    </div>
  );
};
