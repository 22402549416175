import {
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  Typography,
  makeStyles,
  colors,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useDispatch,useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import { getAllCatagory, getItemDataAll } from "../../redux";

//Components

//pages
import CategoryPage from "../CategoryPage/CategoryPage";
// import { ItemSetupPage } from "../ItemSetuppage/ItemSetupPage";
// import  Ingredient from "../Ingredient/Ingredient";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const MenuSetup = ({ location, match }) => {
  const history = useHistory()
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("category");
  const id = match.params.id
  console.log(location)
  useEffect(() => {
    location && location.state && setselectState(location.state)
  }, [location])

  useEffect(() => {
    dispatch((getAllCatagory()))
    dispatch((getItemDataAll()))
  }, [dispatch])
  return (
    <>
      {
        !state?.branch?.loader &&
        <div
          style={{
            // paddingTop: "8%",
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Menu Setup
          </Typography>
            </div>
            <List>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: selectState === "category" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => setselectState("category")}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Category</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "itemsetup" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(`/${id}/menu-setup/item-setup`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Items Setup</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "ingredient" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(`/${id}/menu-setup/ingredient`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Ingredient</Typography>
                </div>
              </ListItem>
            </List>
          </SwipeableDrawer>
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            <CategoryPage id={id} />
            {/* {selectState === "itemsetup" && <ItemSetupPage />}
        {selectState === "ingredient" && <Ingredient />} */}
          </div>
        </div>
      }</>);
};
