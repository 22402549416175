import {
    Box, Grid, Divider, Typography, makeStyles, colors, withStyles, Table, TableRow,
    TableContainer, TableCell, TableHead, TableBody, CircularProgress
} from '@material-ui/core'
import React from 'react'
import moment from "moment";
import { useCurrency } from "../../../hooks/useCurrency";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({

    button: {
        marginRight: theme.spacing(1)
    },
    order_details: {
        background: "#e8fff8",
        padding: "10px",
        height: "100%",
        overflowY: "scroll"
    },
    containerPadding: {
        margin: "20px 0",
    },
    display: {
        display: 'flex',
        justifyContent: "space-between",
    },
    relative: {
        position: "relative"
    },
    table: {
        // borderBottom: `1px solid ${colors.grey[300]}`,
        background: "#e8fff8",
        height: "100%"
    }
}))

const StyledTableCell = withStyles((theme) => ({
    head: {
        border: `1px solid ${colors.grey[300]}`
    },
    body: {
        fontSize: 14,
        border: `1px solid ${colors.grey[300]}`,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



export const CustomerAddress = ({ order }) => {
    const classes = useStyles()
    const currency = useCurrency()
    const state = useSelector(state => state)
    return (
        <>

            <div>
                <Typography variant="h5" gutterBottom>
                    Customer Details
                </Typography>
                <Divider />
                <Grid container>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            Full Name :
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="subtitle2" gutterBottom>
                            {order.first_name} {order.last_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            Mobile :
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="subtitle2" gutterBottom>
                            {order.phone_number}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            Addressess :
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer className={classes.containerPadding}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Sl.No</StyledTableCell>
                                        <StyledTableCell>Full Name</StyledTableCell>
                                        <StyledTableCell>Address</StyledTableCell>
                                        <StyledTableCell>Address Type</StyledTableCell>
                                        <StyledTableCell>Create At</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order && order?.address?.map((row, i) =>
                                        row._id ? <StyledTableRow key={row._id}>
                                            <StyledTableCell component="th" scope="row">
                                                {i + 1}
                                            </StyledTableCell>
                                            <StyledTableCell>{row.full_name}</StyledTableCell>
                                            <StyledTableCell>{row.address_details}</StyledTableCell>
                                            <StyledTableCell>{row.address_type}</StyledTableCell>
                                            <StyledTableCell>{moment(row.createdAt).format('L')} {moment(row.createdAt).format('LT')}</StyledTableCell>
                                        </StyledTableRow> :
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={5}>This Item Are Not Avaliable Now In This App</StyledTableCell>
                                            </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {
                    !state?.user?.loading ? state?.user?.activeUser && state?.user?.activeUser?.map(allOrder =>
                        <Grid container className={classes.containerPadding}>
                            <Grid item xs={4}>
                                <Box className={classes.order_details}>
                                    <Typography variant="h5" gutterBottom>
                                        Order Details
                                    </Typography>
                                    <Divider />
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Order ID :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                {allOrder?.order_id}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Order Date :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                {moment(allOrder.createdAt).format('L')} {moment(allOrder.createdAt).format('LT')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Payment Option :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                {allOrder.order_payment_provider}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Payment Status :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                {allOrder?.order_payment_status}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={8}>
                                <TableContainer  className={classes.table}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Sl.No</StyledTableCell>
                                                <StyledTableCell>Item Image</StyledTableCell>
                                                <StyledTableCell>Item Name</StyledTableCell>
                                                <StyledTableCell>Quantity</StyledTableCell>
                                                <StyledTableCell>Variety - Price (LKR)</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allOrder && allOrder?.order_items?.map((row, i) =>
                                                <StyledTableRow key={row._id}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {i + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <img src={row.item_cover_image} alt="coverimage" height="40px" width="50px"/>
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.item_name}</StyledTableCell>
                                                    <StyledTableCell>{row.item_quantity}</StyledTableCell>
                                                    <StyledTableCell>{row.item_price}</StyledTableCell>
                                                </StyledTableRow> 
                                            )}
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={4}><b>Delivary Address</b> : {allOrder?.order_delivery_address_details}</StyledTableCell>
                                                <StyledTableCell>
                                                    <div className={classes.display}>
                                                        <Typography variant="subtitle2">SubTotal</Typography>
                                                        <Typography variant="subtitle1">{currency} {allOrder.order_grand_total}</Typography>
                                                    </div>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Grid>
                        </Grid>
                    ) : <CircularProgress />
                }
            </div>
        </>
    )
}
