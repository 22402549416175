import {
  Breadcrumbs,
  Link,
  Paper,
  Typography,
  Divider,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormGroup,
  FormControlLabel,
  Tooltip,
  withStyles,
  Snackbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  colors,
  CircularProgress,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  AddCircle,
  ChevronLeft,
  ChevronRight,
  Delete,
  Edit,
  Help,
  RadioButtonUnchecked,
  Search,
  // Search,
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IOSSwitch } from "../../components/Buttons/IOSSwitch.component";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";

//Components
import { BootstrapSelectInput } from "../../components/Input/SelectInput.component";
import { Image } from "../../components/CustomImage/Image.component";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getInGrientDataAll,
  updateIngridient,
  deleteIngridient,
  searchIngredientByWord,
  ingredientByStatus,
} from "../../redux";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Ingredient = ({ ingridient, fetchApi, match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const state = useSelector((state) => state);
  const [activity, setActivity] = useState("all");
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("ingredient");
  const [del, setDel] = useState("");
  const id = match.params.id;

  useEffect(() => {
    fetchApi(getInGrientDataAll(`get_all_ingredient?page=1&limit=10`));
    // return () => {
    //   cleanup
    // }
  }, [fetchApi]);

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            // paddingTop: "8%",
            // paddingLeft: "12%",
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Menu Setup
              </Typography>
            </div>
            <List>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "category" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/menu-setup`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Category</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "itemsetup" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/menu-setup/item-setup`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Items Setup</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "ingredient"
                        ? "var(--orange)"
                        : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/menu-setup/ingredient`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Ingredient</Typography>
                </div>
              </ListItem>
            </List>
          </SwipeableDrawer>
          <div
            style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: "5%" }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
              </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Ingredients
              </Typography>
            </Breadcrumbs>
            <Paper style={{ padding: "2rem", marginTop: "5%" }}>
              <Typography variant="h6">Ingredients</Typography>
              <Divider style={{ margin: "20px 0 20px 0" }} />
              <Typography
                variant="body2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 15,
                }}
                color="primary"
              >
                <Help fontSize="small" /> Create ingredient's like Sauce,
                Cheese, Garlic, Chilli etc...
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Button
                    startIcon={<AddCircle />}
                    variant="contained"
                    color="primary"
                    className="button"
                    onClick={() =>
                      history.push(`/${id}/menu-setup/create-ingredient`)
                    }
                  >
                    Add Ingredient
                  </Button>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControl className={classes.margin}>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={activity}
                      onChange={(e) => {
                        setActivity(e.target.value);
                        if (e.target.value === "active") {
                          dispatch(ingredientByStatus(true));
                        } else if (e.target.value === "inactive") {
                          dispatch(ingredientByStatus(false));
                        } else {
                          dispatch(
                            getInGrientDataAll(
                              `get_all_ingredient?page=1&limit=10`
                            )
                          );
                        }
                      }}
                      input={<BootstrapSelectInput />}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">InActive</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    color="primary"
                    label="Search..."
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        dispatch(searchIngredientByWord(e.target.value));
                      } else {
                        dispatch(
                          getInGrientDataAll(
                            `get_all_ingredient?page=1&limit=10`
                          )
                        );
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search color="primary" />
                        </InputAdornment>
                      ),
                      inputProps: { style: { padding: 10 } },
                    }}
                  />
                </Box>
              </Box>
              <Divider />
              {ingridient.loading ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : ingridient.loading ? (
                <CircularProgress />
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Ingredient Image</TableCell>
                      <TableCell>Ingredient Name</TableCell>
                      <TableCell>Ingridient Description</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ingridient.data &&
                      ingridient.data.map((res, i) => (
                        <TableRow>
                          <TableCell>
                            {(ingridient.currentPage - 1) * 10 + i + 1}
                          </TableCell>
                          <TableCell>
                            {res.ingredient_image && (
                              <Image src={res.ingredient_image} />
                            )}
                          </TableCell>
                          <TableCell>{res.ingredient_name}</TableCell>
                          <TableCell>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: res.ingredient_description,
                                    }}
                                  ></div>
                                </React.Fragment>
                              }
                            >
                              <div style={{ width: "100%",display:"flex",justifyContent:"flex-start" }}>
                                <span
                                  style={{ maxWidth: "300px", overflow: "hidden" }}
                                  dangerouslySetInnerHTML={{
                                    __html: res.ingredient_description,
                                  }}
                                ></span>
                                <span style={{marginTop: "auto"}}>...</span>
                              </div>
                            </HtmlTooltip>
                          </TableCell>
                          <TableCell>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    checked={res.status}
                                    onChange={() =>
                                      fetchApi(
                                        updateIngridient({
                                          _id: res._id,
                                          status: !res.status,
                                        })
                                      )
                                    }
                                  />
                                }
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Tooltip title="Edit">
                                <Edit
                                  className="edit"
                                  style={{
                                    borderRadius: "50%",
                                    border: "1px solid grey",
                                  }}
                                  onClick={() => {
                                    history.push({
                                      pathname: `/${id}/menu-setup/create-ingredient`,
                                      state: res,
                                    });
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title="Delete">
                                <Delete
                                  onClick={() => setDel(res._id)}
                                  className="delete"
                                  style={{
                                    borderRadius: "50%",
                                    border: "1px solid lightcoral",
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
              {ingridient?.totalPage > 1 && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  margin="auto"
                >
                  <Pagination
                    count={ingridient.totalPage}
                    page={ingridient?.currentPage}
                    color="primary"
                    onChange={(e, value) =>
                      fetchApi(
                        getInGrientDataAll(
                          `get_all_ingredient?page=${value.toString()}&limit=10`
                        )
                      )
                    }
                  />
                </Box>
              )}
            </Paper>
            <Snackbar open={ingridient.status} onClose={!ingridient.status}>
              <Alert onClose={!ingridient.status} severity={ingridient.status}>
                {ingridient.message}
              </Alert>
            </Snackbar>
          </div>
          <Dialog
            open={del}
            onClose={() => setDel("")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                DO YOU REALLY WANT TO DELETE
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDel("")} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setDel("");
                  fetchApi(deleteIngridient({ _id: del }));
                }}
                color="primary"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    ingridient: store.ingridient,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (dispatchAction) => dispatch(dispatchAction),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ingredient);
