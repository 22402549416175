import { Box, Breadcrumbs, Link, Typography, Paper, makeStyles, IconButton, SwipeableDrawer,colors } from '@material-ui/core'
import { ChevronLeft, ChevronRight, } from '@material-ui/icons'
import React, { useState } from 'react'
import clsx from "clsx";
import { useSelector } from "react-redux";

//pages
// import { Cupons } from '../Cupons/Cupons'
// import { Offers } from '../Offers/Offers'
import { Discount } from './Discount'
import { SwipeDrawer } from '../swipedrawer';

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const CuponsAndOffers = ({ match }) => {
  const classes = useStyles()
  const state = useSelector(state => state)
  const [drawerOpen, setdrawerOpen] = useState(true);
  const id = match.params.id

  return (
    <>
      {
        !state?.branch?.loader &&
        <div style={{
          width: "100%",
          height: "100%",
          background: colors.grey[200],
          position: "relative",
        }} className={classes.root} >
          <div
            style={{ position: "fixed", top: "0%", right: "63%", zIndex: 1100 }}
            className={classes.drawerHeader}
          >
            <IconButton  onClick={() => setdrawerOpen(!drawerOpen)} >
              {drawerOpen ? (
                <ChevronLeft />
              ) : (
                <ChevronRight />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
              PROMOTION
          </Typography>
            </div>
            <SwipeDrawer />
          </SwipeableDrawer>
          <Box position="relative" style={{ paddingLeft: 20, paddingRight: 20, }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })} >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
                  </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Cuponse / Offers
                  </Typography>
            </Breadcrumbs>
            <Paper style={{ padding: "2rem", margin: "3% auto 0 auto" }} >
              {/* <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                    showLabels
                    style={{ position: 'relative', left: 0, width: 300 }}
                  //   className={classes.root}
                  >
                  <BottomNavigationAction label="Offers" icon={<LocalOffer />} />
                  <BottomNavigationAction label="Cupons" icon={<Restore />} /> */}
              {/* </BottomNavigation> */}
              {/* {value === 0 && <Offers />} */}
              <Discount drawerOpen={drawerOpen} setdrawerOpen={setdrawerOpen} id={id} />
            </Paper>
          </Box>
        </div>
      }
    </>)
}
