import { getAllPaymentgetway, updatePaymentgetway, fetchTwilioSmsconfigure, fetchTextlocalSmsConfigure, updateSmsconfigure, fetchGDPR, updateGDPR, fetchEmailConfigure, updateEmailConfigure, fetchPreDeliveryDay,
fetchAnalytics, updateAnalytics, fetchPromotional, updatePromotional, offAllPaymentGetway, fetchGoogleMapApiKey,
updateGoogleMapApikey, fetchSocialMediaLogin, updateSocialMedialogin } from '../../Api/api'
import { FETCH_ALL_PAYMENT_GETWAY, UPDATE_PAYMENT_GETWAY, FETCH_TEXTLOCAL_SMS_CONFIGURE, FETCH_TWILIO_SMS_CONFIGURE, UPDATE_SMS_CONFIGURE, FETCH_GDPR, FETCH_EMAIL_CONFIGURE, UPDATE_EMAIL_CONFIGURE, FETCH_PRE_DELIVERY_DAY, UPDATE_GDPR, FETCH_ANALYTICS, UPDATE_ANALYTICS, FETCH_PROMOTIONAL_PAGE, UPDATE_PROMOTIONAL_PAGE, OFF_ALL_PAYMENT_GETWAY, FETCH_GOOGLE_MAP_API_KEY, UPDATE_GOOGLE_MAP_API_KEY, FETCH_SOCIAL_MEDIA_LOGIN, UPDATE_SOCIAL_MEDIA_LOGIN } from './AdvanceSetting.type'

export const fetchAllPaymentgetway = () => async dispatch => {
    try {
        const { data } = await getAllPaymentgetway()

        dispatch({ type: FETCH_ALL_PAYMENT_GETWAY, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const updatePaymentGetway = (body, id) => async dispatch => {
    try {
        const variables = {
            _id: id,
            ...body
        }

        const { data } = await updatePaymentgetway(variables)

        dispatch({ type: UPDATE_PAYMENT_GETWAY, payload: data })
        dispatch(fetchAllPaymentgetway())
    } catch (error) {
        console.log(error)
    }
}

export const offAllPaymentGetWay = () => async dispatch => {
    try {
        const variables = {}

        const { data } = await offAllPaymentGetway(variables)

        dispatch({ type: OFF_ALL_PAYMENT_GETWAY, payload: data })
        dispatch(fetchAllPaymentgetway())
    } catch (error) {
        console.log(error)
    }
}

export const getTwilioSmsConfigure = () => async dispatch => {
    try {
        const { data } = await fetchTwilioSmsconfigure()

        dispatch({ type: FETCH_TWILIO_SMS_CONFIGURE, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const getTextlocalSmsConfigure = () => async dispatch => {
    try {
        const { data } = await fetchTextlocalSmsConfigure()

        dispatch({ type: FETCH_TEXTLOCAL_SMS_CONFIGURE, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const updateSmsConfigure = (body,id) => async dispatch => {
    try {
        const variables ={
            _id: id,
            ...body
        }
        const { data } = await updateSmsconfigure(variables)
        
        dispatch({ type: UPDATE_SMS_CONFIGURE, payload: data })
        dispatch(getTwilioSmsConfigure())
        dispatch(getTextlocalSmsConfigure())
    } catch (error) {
        console.log(error)
    }
}

export const getGDPR = () => async dispatch => {
    try {
        const { data } = await fetchGDPR()

        dispatch({ type: FETCH_GDPR, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const updateGDPRDetails = (body, id) => async dispatch => {
    try {
        const variables ={
            _id:id,
            ...body
        }

        const { data } = await updateGDPR(variables)

        dispatch({ type: UPDATE_GDPR, payload: data })
        dispatch(getGDPR())
    } catch (error) {
        console.log(error)
    }
}

export const getEmailConfigure = () => async dispatch => {
    try {
        const { data } = await fetchEmailConfigure()

        dispatch({ type: FETCH_EMAIL_CONFIGURE, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const updateEmailConfigures = (body, id) => async dispatch => {
    try {
        const variables = {
            _id: id,
            ...body
        }

        const { data } = await updateEmailConfigure(variables)

        dispatch({ type: UPDATE_EMAIL_CONFIGURE, payload: data })
        dispatch(getEmailConfigure())
    } catch (error) {
        console.log(error)
    }
}

export const getPreDeliveryDay = () => async dispatch => {
    try {
        const { data } = await fetchPreDeliveryDay()

        dispatch({ type: FETCH_PRE_DELIVERY_DAY, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const getAnalytics = () => async dispatch => {
    try {
        const { data } = await fetchAnalytics()

        dispatch({ type: FETCH_ANALYTICS, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const updateAnaliticsDetails = (body, id) => async dispatch => {
    try {
        const variables = {
            _id: id,
            ...body
        }
        const { data } = await updateAnalytics(variables)

        dispatch({ type: UPDATE_ANALYTICS, payload: data })
        dispatch(getAnalytics())
    } catch (error) {
        console.log(error)
    }
}

export const getPromotionalPage = () => async dispatch => {
    try {
        const { data } = await fetchPromotional()

        dispatch({ type: FETCH_PROMOTIONAL_PAGE, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const updatePromotionalPage = (body, id) => async dispatch => {
    try {
        const variables = {
            _id: id,
            ...body
        }

        const { data } = await updatePromotional(variables)

        dispatch({ type: UPDATE_PROMOTIONAL_PAGE, payload: data })
        dispatch(getPromotionalPage())
    } catch (error) {
        console.log(error)
    }
}

export const getGoogleMapApiKey = () => async dispatch => {
    try {
        const { data } = await fetchGoogleMapApiKey()

        dispatch({ type: FETCH_GOOGLE_MAP_API_KEY, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const updateGoogleMapApiKey = (body, id) => async dispatch => {
    try {
        const variables = {
            _id: id,
            ...body
        }

        const { data } = await updateGoogleMapApikey(variables)

        dispatch({ type: UPDATE_GOOGLE_MAP_API_KEY, payload: data })
        dispatch(getGoogleMapApiKey())
    } catch (error) {
        console.log(error)
    }
}

export const getSocialMediaLogin = () => async dispatch => {
    try {
        const { data } = await fetchSocialMediaLogin()

        dispatch({ type: FETCH_SOCIAL_MEDIA_LOGIN, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const updateSocialMediaLogin = (body, id) => async dispatch => {
    try {
        const variables = {
            _id: id,
            ...body
        }

        const { data } = await updateSocialMedialogin(variables)

        dispatch({ type: UPDATE_SOCIAL_MEDIA_LOGIN, payload: data })
        dispatch(getSocialMediaLogin())
    } catch (error) {
        console.log(error)
    }
}