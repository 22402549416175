import "./index.css";
import { Switch, Route, BrowserRouter as Router,Redirect } from "react-router-dom";
import { ThemeProvider, createMuiTheme, colors, CircularProgress } from "@material-ui/core";
import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux';
// import { useHistory } from "react-router-dom";
import {auth, getAllCatagory, getConfigaration} from './redux'
import { getCurrency } from './redux/currency/currency.action'

//Pages
// import { DashBoard } from "./pages/DashBoard/DashBoard";
// import { OrderPage } from "./pages/OrderPage/OrderPage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
// import { RestaurantBesicPage } from "./pages/RestaurantBesicPage/RestaurantBesicPage";
// import { UsersPage } from "./pages/UsersPage/UsersPage.jsx";
// import  CreateBranchPage from "./pages/CreateBranchPage/CreateBranchPage";
// import { MenuSetup } from "./pages/MenuSetup/MenuSetup";
// import  CreateCategory  from "./pages/CreateCategory/CreateCategory";
// import { CreateItem } from "./pages/CreateItem/CreateItem";
// import { DelivaryBoy } from "./pages/DelivaryBoy/DelivaryBoy.jsx";
// import { Frontend } from "./pages/Frontend/Frontend";
// import  AddIngredient from "./pages/AddIngredient/AddIngredient";
// import {Settings} from "./pages/Settings/Settings"
// import { CmsPage } from "./pages/Cms/CmsPage";
// import { AdvanceSettings } from "./pages/Advance-Settings/AdvanceSettings";
// import {CreateDelivaryBoy} from './pages/DelivaryBoy/CreateDelivaryBoy'
// import { Preferences } from './pages/Preferences/Preferences'
// import { Report } from './pages/Report/Report'
// import { AccessControl } from './pages/AccessControl/AccessControl'
// import { Profile } from './pages/Profile/Profile'
// import { ChangePassword } from './pages/Profile/ChangePassword'
// import { AccountUpdate } from './pages/Profile/AccountUpdate'
// import { CreateAdministrator } from "./pages/AccessControl/CreateAdministrator";
import {AdminPage} from "./pages/AdminPage/AdminPage";
// import AdminPerAdmin from "./pages/AdminPerAdmin/AdminPerAdmin";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.orange[500],
    },
    secondary: {
      main: colors.grey[600],
    },
    danger : {
      main: colors.red[600],
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
    },
  },
});

function App() {
  // const history = useHistory();
   const state = useSelector(state => state)
   const dispatch = useDispatch()

  //  useEffect(() => {
  //   state?.login?.data?.adminstrator_branch_id &&  dispatch(getoperationalhours(state?.login?.data?.adminstrator_branch_id))
  // }, [state?.login?.data])
  
  useEffect(() => {
    dispatch(auth(localStorage.getItem("auth-token")))
  }, [localStorage.getItem("auth-token")])

  useEffect(() => {
    dispatch(getAllCatagory())
    dispatch(getCurrency())
    dispatch(getConfigaration())
  }, [dispatch])

  useEffect(() => {
    state?.administrator?.status === 'success' && dispatch(auth(localStorage.getItem("auth-token")))
  }, [state?.administrator?.status])

  return (
    <ThemeProvider theme={theme}>
         <Router>
          <Switch> 
            <Route path="/login" component={LoginPage} exact />
            { state.login.loading ? <div className="loaderBg"><CircularProgress color="secondary" /></div> : 
              state.login.token ? <AdminPage /> : <Redirect to={{pathname: '/login'}} />
             }
          </Switch>
        </Router> 
    </ThemeProvider>
  );
}

export default App;
