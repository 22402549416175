import React, { useState, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment'

import { Box, Grid, TextField, Typography, DialogActions, Button, FormControl, Select, MenuItem } from '@material-ui/core'
import { BootstrapSelectInput } from "../../../components/Input/SelectInput.component";
import { updateOrderStatus, getConfigaration } from "../../../redux";
import {postData} from '../../../Api/api'
import { useSelector, useDispatch } from "react-redux";

export const EmailSms = ({ order, modelClose, modelCondition }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [formValue, setformValue] = useState({
        from_email:"",
        subject:"",
        text:"",
        _id: order.user_id,
    })
    const state = useSelector((state) => state)
    const dispatch = useDispatch()
    useEffect(() => {
        console.log(order)
        return () => {
            console.log(order)
        }
    }, [])

    useEffect(() => {
        dispatch(getConfigaration())
        // return () => {
        //     cleanup
        // }
    }, [dispatch])

    useEffect(() => {
        setformValue({...formValue, from_email: state?.configarationSettings?.data?.configuration_app_email[0].email})
    }, [state?.configarationSettings?.data])

    useEffect(() => {
        state?.order?.status === 'success' && modelClose() && setformValue({
            from_email:"",
            subject:"",
            text:"",
            _id: order.user_id,
        })
    }, [state?.order?.status])

    const sendMail = () => {
            postData(`send_mail`, formValue).then(
                response => {
                    console.log(response.data)
                    response.data.status === 'success' && dispatch(updateOrderStatus(order))
                }
            ).catch(
                error => console.log(error)
                // error => dispatch(fetchBranchFaliour(error.message))
            )
        }

    return (
        <div>
            <Typography variant='h6' >Send Mail And Sms</Typography>

            <div className="flexDisplay">
            <FormControl>
                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={formValue.from_email}
                    onChange={(e) => setformValue({...formValue, from_email: e.target.value})
                    }

                    // }
                    input={<BootstrapSelectInput />}
                >
                    {
                        state?.configarationSettings?.data?.configuration_app_email?.map(res =>
                            <MenuItem value={res.email}>{res.email}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>

            <FormControl>
                <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    // value={res.order_status_delivered.status ? 'delivered' :
                    //     res.order_status_on_the_way.status ? 'on_the_way' :
                    //         res.order_status_accepted.status ? 'accepted' :
                    //             res.order_status_rejected.status ? 'rejected' :
                    //                 res.order_status_pending.status && 'pending'
                    // }
                    // onChange={(e) => {
                    //     setorder({ ...res, order_status_name: e.target.value })
                    //     handleClickOpen()
                    //     setmodelOpen('Send Order And Sms')
                    //     console.log(e.target.value)
                    //     // dispatch(updateOrderStatus({ _id: res._id, order_status_name: e.target.value }))
                    // }

                    // }
                    input={<BootstrapSelectInput />}
                >
                    {
                        state?.configarationSettings?.data?.configuration_app_contact_number?.map(res =>
                            <MenuItem value={res.contact_number}>{res.contact_number}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>

            </div>

            <TextField label="Email Subject" variant="outlined" size="small" fullWidth style={{marginBottom: "1rem"}}
                value={formValue.subject} 
               onChange={(e) => setformValue({...formValue, subject: e.target.value})} />

            <div style={{
                border: '1px solid rgb(224, 224, 224)', maxHeight: '24em', overflow: "scroll",
                minHeight: '12em', padding: '10px', borderRadius: '5px'
            }}>

                <Editor
                    editorState={editorState}
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    toolbar={{ position: "sticky", top: "0", zIndex: "2" }}
                    onEditorStateChange={(e) => {
                        setEditorState(e)
                        setformValue({ ...formValue, text: draftToHtml(convertToRaw(editorState.getCurrentContent())) })
                    }}
                />
            </div>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => modelClose()}>
                    Back
                </Button>
                <Button variant="contained" color="secondary" onClick={() => dispatch(updateOrderStatus(order))}>
                    {order.order_status_name === 'on_the_way' ? 'On the way' : order.order_status_name}
                </Button>
                <Button variant="contained" color="secondary" onClick={() => sendMail()}>
                    {order.order_status_name === 'on_the_way' ? 'On the way' : order.order_status_name} & Send Mail
                </Button>
                <Button variant="contained" color="secondary" onClick={() => modelClose()}>
                    {order.order_status_name === 'on_the_way' ? 'On the way' : order.order_status_name} & Send Mail & Send Sms
                </Button>
            </DialogActions>
            {/* <Grid container justify='space-between' >
                <Grid item sm={5} >
                    <Box display='flex' alignItems='center' justifyContent='space-between' margin='1rem 0' >
                        <Typography>Delivery Date:</Typography>
                        <Typography>{moment(order?.createdAt).format('DD/MM/YYYY')}</Typography>
                    </Box>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        fullWidth
                        label="Schedule Date:"
                        format="MM/dd/yyyy"
                        // value={selectedDate}
                        // onChange={handleDateChange}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                        />
                    </MuiPickersUtilsProvider>
                    <Typography variant="h6" style={{ margin: '1.5rem 0' }} >Order Info:</Typography>
                    <Grid container >
                        <Grid item sm={6} >
                            <Typography>Order Number:</Typography>
                        </Grid>
                        <Grid item sm={6} >
                            <Typography>{order?._id}</Typography>
                        </Grid>
                        <Box height='2rem' />
                        <Grid item sm={6} >
                            <Typography>Order Date:</Typography>
                        </Grid>
                        <Grid item sm={6} >
                            <Typography>{moment(order?.createdAt).format('DD/MM/YYYY')}</Typography>
                        </Grid>
                        <Box height='2rem' />
                        <Grid item sm={6} >
                            <Typography>First Name</Typography>
                        </Grid>
                        <Grid item sm={6} >
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={6} >
                    <Box display='flex' alignItems='center' justifyContent='space-between' margin='1rem 0' >
                        <Typography>Delivery Time:</Typography>
                        <Typography>{moment(order?.createdAt).format('hh:mm A')}</Typography>
                    </Box>
                        <Box height='5rem' />
                        <Grid container >
                            <Grid item sm={6} >
                                <Typography>Order Total:</Typography>
                            </Grid>
                            <Grid item sm={6} >
                                <Typography>{order?.order_total_amount}</Typography>
                            </Grid>
                            <Box height='2rem' />
                            <Grid item sm={6} >
                                <Typography>Order Time:</Typography>
                            </Grid>
                            <Grid item sm={6} >
                                <Typography>{moment(order?.createdAt).format('hh:mm A')}</Typography>
                            </Grid>
                            <Box height='2rem' />
                            <Grid item sm={6} >
                                <Typography>Last Name</Typography>
                            </Grid>
                            <Grid item sm={6} >
                            </Grid>
                        </Grid>
                </Grid>
            </Grid> */}
        </div>
    )
}