import React, { useState, useEffect } from 'react'
import ReactExport from "react-export-excel";
import { Button, withStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { useCurrency } from "../../hooks/useCurrency";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ColorButton = withStyles((theme) => ({
    root: {
        color: "#ffffff",
        backgroundColor: "#000000",
        '&:hover': {
            backgroundColor: "#000000",
        },
    },
}))(Button);

export default function ExportToExacl() {
    const state = useSelector(state => state)
    const [list, setlist] = useState([])
    const currency = useCurrency()

    useEffect(() => {
        let array = []
        state?.order?.data?.map(res => res.order_items.map(item => array.push({ ...res, item })))
        console.log(array)
        setlist(array)
    }, [state?.order?.data])

    // render() {
    return (
        <ExcelFile element={<ColorButton variant="contained" color="primary" onClick={() => <ExportToExacl />} >Export</ColorButton>}>
            <ExcelSheet data={list} name="Orders">
                <ExcelColumn label="Order Number" value="order_id" />
                <ExcelColumn label="Customer Name" value="order_delivery_full_name" />
                {/* <ExcelColumn label="Branch Name" value="branch_id.branch_name" /> */}
                <ExcelColumn label="Order Date" value={(col) => moment(col.createdAt).tz("America/New_York").format('L')} />
                <ExcelColumn label="Mobile Number" value="order_delivery_phone_number" />
                <ExcelColumn label="Payment" value="order_payment_option" />
                <ExcelColumn label="Payment Status" value={(col) => col?.order_payment_status} />
                <ExcelColumn label="Order Status" value={(res) => res.order_status_delivered.status ? 'delivered' :
                    res.order_status_on_the_way.status ? 'on_the_way' :
                        res.order_status_accepted.status ? 'accepted' :
                            res.order_status_rejected.status ? 'rejected' :
                                res.order_status_pending.status && 'pending'
                } />
                <ExcelColumn label="Booking Mode" value="order_booking_type" />
                <ExcelColumn label="Delivary Date" value={(col) => col.order_status_delivered.order_status_date_time &&
                    moment(col.order_status_delivered.order_status_date_time).tz("America/New_York").format('L')
                } />
                <ExcelColumn label="Rescedule Date" value={(col) => col.order_delivery_reschedule_date_and_time &&
                    moment(col.order_delivery_reschedule_date_and_time).tz("America/New_York").format('L')
                } />
                <ExcelColumn label="Item Name" value={col => col.item.item_id ? col.item.item_id.item_name : "This Item Are Not Avaliable Now In This App"} />
                <ExcelColumn label="Item Price" value={col => col.item.item_id ? col.item.item_id.item_price : "This Item Are Not Avaliable Now In This App"} />
                <ExcelColumn label="Quantity" value={col => col.item.item_quantity} />
                <ExcelColumn label="Order Total" value={col => `${currency} ${col.order_grand_total}`}  />
                <ExcelColumn label="Address" value={col => col.order_delivery_address_details} />
            </ExcelSheet>
        </ExcelFile>
    );
    // }
}
