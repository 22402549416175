import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Cupons } from "../Cupons/Cupons";

import {
    List,
    ListItem,
    Typography,
} from "@material-ui/core";
import { RadioButtonUnchecked } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const drawerWidth = 200;


export const SwipeDrawer = () => {
    const history = useHistory();
    const state = useSelector((state) => state);
    const [selectState, setselectState] = useState("restaurant-basic");
    const id = state?.branch?.activeBranch?._id;

    useEffect(() => {
        setselectState(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
    }, [window.location.pathname])
    // console.log(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
    return (
        <>
            {!state?.branch?.loader && (
                <List>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "restaurant-basic" ? "var(--orange)" : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/restaurant-basic`)
                            }
                            >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Branch</Typography>
                        </div>
                    </ListItem>
                    {/* <ListItem>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color:
                        selectState === "pickup" ? "var(--orange)" : "#ffffff",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      history.push(`/${id}/restaurant-basic/pickup-delivary`)
                    }
                  >
                    <RadioButtonUnchecked
                      style={{ fontSize: "1rem", marginRight: 20 }}
                    />
                    <Typography>Pickup/Delivery</Typography>
                  </div>
                </ListItem> */}
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "deliveryzone"
                                        ? "var(--orange)"
                                        : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/restaurant-basic/deliveryzone`)
                            }
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Delivery Zones</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "operationalhours"
                                        ? "var(--orange)"
                                        : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/restaurant-basic/operationalhours`)
                            }
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Operational Hours</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "menu-time"
                                        ? "var(--orange)"
                                        : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/restaurant-basic/menu-time`)
                            }
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Menu Time</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: selectState === "payement-getways" ? "var(--orange)" : "#ffffff",
                                cursor: "pointer"
                            }}
                            onClick={() => history.push(`/${id}/restaurant-basic/payement-getways`)}
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Payment Gateway</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: selectState === "preferences" ? "var(--orange)" : "#ffffff",
                                cursor: "pointer"
                            }}
                            onClick={() => history.push(`/${id}/restaurant-basic/preferences`)}
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Prefernces</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: selectState === "administrator" || selectState === "create-administrator" ? "var(--orange)" : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/restaurant-basic/administrator`)
                            }
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Access Control</Typography>
                        </div>
                    </ListItem>
                </List>
            )}
        </>
    );
};
