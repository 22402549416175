import React, { useEffect } from 'react'
import { Table, TableHead, TableRow, TableCell, TableContainer, TableBody, Checkbox, CircularProgress } from "@material-ui/core";
import { getDelivaryBoy } from "../../../redux";
import { useSelector, useDispatch } from "react-redux";

export default function AssignDelivaryBoy({ order }) {
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getDelivaryBoy(`get_delivery_boy_by_status/true`))
    }, [dispatch])
    return (
        <TableContainer>
            {
                state?.delivaryBoy?.loader ? <CircularProgress /> :
                    state?.delivaryBoy?.data?.length > 0 ?
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Gender</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state?.delivaryBoy?.data?.map((row) => (
                                    <TableRow key={row._id}>
                                        <TableCell>
                                            <Checkbox checked={order.order_delivery_boy === row._id}
                                                onChange={() => order.order_delivery_boy = row._id} />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.delivery_boy_name}
                                        </TableCell>
                                        <TableCell>{row.delivery_boy_email}</TableCell>
                                        <TableCell>{row.delivery_boy_phone_number}</TableCell>
                                        <TableCell>{row.delivery_boy_gender}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table> :
                        <div className="nodleiveryboy">
                            <p>No Delivery boy Found</p>
                        </div>
            }
        </TableContainer>
    )
}
