import { FETCH_HANDLE_DELIVARY_BOY_FALIOUR,FETCH_HANDLE_DELIVARY_BOY_REQUEST,FETCH_HANDLE_DELIVARY_BOY_DATA,
    FETCH_HANDLE_DELIVARY_BOY_SUCCESS } from "./delivaryboyTypes";
import {postData,fetchData,updateData,deleteData, searchingDeliveryBoyByWord} from '../../Api/api'

export const fetchdelivaryBoyAccess = () => {
    return {
        type: FETCH_HANDLE_DELIVARY_BOY_REQUEST
    }
}

export const fetchdelivaryBoySuccess = (data) => {
    return {
        type: FETCH_HANDLE_DELIVARY_BOY_SUCCESS,
        payload: data
    }
}

export const fetchdelivaryBoyFaliour = (data) => {
    return {
        type: FETCH_HANDLE_DELIVARY_BOY_FALIOUR,
        payload : data
    }
}

export const fetchdelivaryFormData = (data) => {
    return {
        type: FETCH_HANDLE_DELIVARY_BOY_DATA,
        payload: data
    }
}

export const getDelivaryBoy = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchdelivaryBoyAccess())
        fetchData(endpoint).then(
            response => dispatch(fetchdelivaryBoySuccess(response.data.response))
        ).catch(
            error => dispatch(fetchdelivaryBoyFaliour(error.message))
        )
    }
}

export const postDelivaryBoy = (body) => {
    return (dispatch) => {
        dispatch(fetchdelivaryBoyAccess())
        postData(`add_delivery_boy`,body).then(
            response => {
                dispatch(fetchdelivaryFormData(response.data))
                response.data.status === 'success' && dispatch(getDelivaryBoy(`get_delivery_boy/${body.delivery_boy_branch_id}`))
            }
        ).catch(
            error => dispatch(fetchdelivaryBoyFaliour(error.message))
        )
    }
}

export const updateDelivaryBoy = (body) => {
    return (dispatch) => {
        dispatch(fetchdelivaryBoyAccess())
        updateData(`update_delivery_boy`,body).then(
            response => {
                dispatch(fetchdelivaryFormData(response.data))
                response.data.status === 'success' && dispatch(getDelivaryBoy(`get_delivery_boy/${body.delivery_boy_branch_id}`))
            }
        ).catch(
            error => dispatch(fetchdelivaryBoyFaliour(error.message))
        )
    }
}

export const deleteDelivaryBoy = (body) => {
    return (dispatch) => {
        deleteData(`delete_delivery_boy`,{_id : body._id}).then(
            response => {
                console.log(body.delivery_boy_branch_id)
                dispatch(fetchdelivaryFormData(response.data))
                response.data.status === 'success' && dispatch(getDelivaryBoy(`get_delivery_boy/${body.delivery_boy_branch_id}`))
            }
        ).catch(
            error => dispatch(fetchdelivaryBoyFaliour(error.message))
        )
    }
}

export const searchDeliveryBoyByWord = (value) => dispatch => {
    try {
        dispatch(fetchdelivaryBoyAccess())
        searchingDeliveryBoyByWord(value).then(res => {
            dispatch(fetchdelivaryBoySuccess(res.data.response))
        }).catch(er => {
            dispatch(fetchdelivaryBoyFaliour(er.message))
        })
    } catch (error) {
        dispatch(fetchdelivaryBoyFaliour(error.message))
    }
}