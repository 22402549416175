import { Box, Breadcrumbs, Button, FormControlLabel, FormGroup, Link, Paper, Typography, makeStyles, ListItem, List, SwipeableDrawer, IconButton, colors } from '@material-ui/core'
import { ChevronLeft, ChevronRight, RadioButtonUnchecked } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import clsx from "clsx";
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

//actions
import { getGDPR, updateGDPRDetails } from '../../redux/AdvanceSetting/AdvanceSetting.action'

import { IOSSwitch } from '../../components/Buttons/IOSSwitch.component'
import { Input } from '../../components/Input/Input.component'

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const GDPR = ({ match }) => {
    const classes = useStyles()
    const history = useHistory()
    const [drawerOpen, setdrawerOpen] = useState(true);
    const [selectState, setselectState] = useState("GDPR");
    const id = match.params.id
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const gdpr = useSelector(state => state?.advanceSetting?.gdpr?.response)
    const dispatch = useDispatch()
    const [value, setValue] = useState({
        gdpr_title: '',
        gdpr_content: '',
        status: true
    })

    useEffect(() => {
        dispatch(getGDPR())
    }, [dispatch])

    useEffect(() => {
        gdpr?.map(item => {
            setValue(item)
            const contentBlock = htmlToDraft(item?.gdpr_content);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
            }
        })
    }, [gdpr])

    const handleSubmit = (e) => {
        e.preventDefault()
        try {
            dispatch(updateGDPRDetails(value, gdpr[0]?._id))
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{
            paddingTop: "10px",
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}>
            <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Advance Settings
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "payment-gateways" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Payment Gateways</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "configureSms" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/configure-sms`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Configure SMS</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Congifure Email" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/configure-email`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Congifure Email</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Google Map Api Key" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/google-map-api-key`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Google Map Api Key</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Social Media Login" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/social-media-login`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Social Media Login</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Promotional Page Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/promotional`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Promotional Page Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Analytics Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/analytics-settings`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Analytics Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "GDPR" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/gdpr`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>GDPR</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Homepage Banner" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("Homepage Banner")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Home Page Popup Banner</Typography>
            </div>
          </ListItem> */}
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Pre Order Delivery" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/pre-order-delivary`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Pre Order Delivery Days</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div
      style={{ paddingLeft: 20, paddingRight: 20 }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
                <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                    Home
                </Link>
                <Typography
                    color="textPrimary"
                    style={{ fontSize: 15, color: "var(--orange)" }}
                >
                    GDPR
                </Typography>
            </Breadcrumbs>
            <Box display='flex' alignItems='center' justifyContent="center" width='100%' >
            <Paper style={{ padding: "2rem", margin: "15px 0 5rem 0", width: "70%" }}>
                <Typography variant="h6" >GDPR Status</Typography>
                <Typography color="textSecondary" variant="body1" >Do you want to ENABLE the GDPR popup?</Typography>
                <form onSubmit={handleSubmit} >
                <FormGroup>
                        <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={value.status}
                                onChange={(e) => {
                                    const variables = {
                                        status: e.target.checked
                                    }
                                    setValue({ ...value, status: e.target.checked })
                                    dispatch(updateGDPRDetails(variables, gdpr[0]?._id))
                                }}
                              />
                            }
                        />
                </FormGroup>
                {value.status && 
                <>
                <Input style={{ width: '100%', marginTop: '2rem' }} label='Title' value={value.gdpr_title} onChange={(e) => setValue({ ...value, gdpr_title: e.target.value })} />
                <Box marginTop='2rem' >
                    <label style={{ marginBottom: 5 }} >Content</label>
                    <Editor
                        editorStyle={{ height: '400px' }}
                        wrapperStyle={{ border: '1px solid rgba(0, 0, 0, 0.23)',borderRadius:"5px"}}
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(e) => {
                            setEditorState(e)
                            setValue({ ...value, gdpr_content: draftToHtml(convertToRaw(editorState.getCurrentContent())) })
                        }}
                    />
                </Box>
                <Box display='flex' alignItems='center' justifyContent="flex-end" marginTop='2rem' >
                    <Button className="button" style={{ marginRight: '2rem' }} type='submit' >submit</Button>
                    <Button className="button">cancel</Button>
                </Box>
                </>
                }
                </form>
            </Paper>
            </Box>
      </div>
        </div>
    )
}
