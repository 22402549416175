import {FETCH_HANDLE_PICKUP_DELIVARY_FALIOUR,FETCH_HANDLE_PICKUP_DELIVARY_REVERVATION,FETCH_HANDLE_PICKUP_DELIVARY_REQUEST,FETCH_HANDLE_PICKUP_DELIVARY_SUCCESS} from "./pickupDelivaryType";

const initialState = {
    loader: false,
    data: {},
    status: '',
    message: ''
}

export const pickupDelivaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HANDLE_PICKUP_DELIVARY_REQUEST:
            return {
                ...state, loader: true
            }

        case FETCH_HANDLE_PICKUP_DELIVARY_SUCCESS:
            return {
                ...state,
                loader: false,
                data: action.payload,
                status: '',
                message: ''
            }

        case FETCH_HANDLE_PICKUP_DELIVARY_FALIOUR:
            return {
                ...state, 
                loader: false,
                data : {},
                status: 'error',
                message: 'Network Error'
            }

        case FETCH_HANDLE_PICKUP_DELIVARY_REVERVATION:
            return {
                ...state,
                loader: false,
                status: action.payload.status==='success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}