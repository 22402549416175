import React, { useState, useEffect } from "react";
import {
    Breadcrumbs,
    colors,
    Typography,
    Link,
    Box,
    Button,
    Paper,
    Divider,
    FormControl,
    // makeStyles,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    TextField,
    Container, Snackbar
} from "@material-ui/core";
import {
    Check,
    ChevronLeft,
    ChevronRight,
} from "@material-ui/icons";
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { updateDelivaryBoy, postDelivaryBoy } from '../../redux'

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

// const useStyles = makeStyles((theme) => ({
//     margin: {
//         margin: theme.spacing(1),
//     },
//     editorPrent: {
//         position: "relative",
//         border: '1px solid rgb(224, 224, 224)',
//         padding: '10px',
//         borderRadius: '5px',
//         '& > .makeStyles-editorPrent-45': {
//             borderColor: "blue !important"
//         },
//     },
//     label: {
//         position: "absolute",
//         top: -12,
//         padding: "0 5px",
//         backgroundColor: "white",
//         fontSize: "13px",
//         color: "var(--orange)"
//     },
//     input: {
//         display: "none",
//     },
// }));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CreateDelivaryBoy = ({ location, match }) => {
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const history = useHistory();
    const id = match.params.id

    const [delivaryBoyData, setdelivaryBoyData] = useState({
        delivery_boy_branch_id: "",
        delivery_boy_name: "", delivery_boy_branch_name: "", delivery_boy_email: "",
        delivery_boy_phone_number: "", delivery_boy_address: "", delivery_boy_city: "",
        delivery_boy_country: "", delivery_boy_dob: null, delivery_boy_gender: "",
        delivery_boy_identity_number: "", delivery_boy_licence_number: "",
        delivery_boy_licence_expiry_date: null
    })

    useEffect(() => {
        setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_branch_id: state.branch.activeBranch._id })
    }, [state.branch.activeBranch._id])
    useEffect(() => {
        if (location && location.state) {
            setdelivaryBoyData(location.state)
        }
    }, [location])
    useEffect(() => {
        if (state.delivaryBoy.status === 'success') {
            setdelivaryBoyData(
                {
                    delivery_boy_name: "", delivery_boy_branch_name: "", delivery_boy_email: "",
                    delivery_boy_phone_number: "", delivery_boy_address: "", delivery_boy_city: "",
                    delivery_boy_country: "", delivery_boy_dob: null, delivery_boy_gender: "",
                    delivery_boy_identity_number: "", delivery_boy_licence_number: "",
                    delivery_boy_licence_expiry_date: null
                }
            )
            setTimeout(() => {
                location && location.state &&
                    history.push({
                        pathname: "delivary-boy",
                    })
            }, 1000);
        }
    }, [state.delivaryBoy])

    return (<>
        {
            !state?.branch?.loader &&
            <div
                style={{
                    paddingLeft: 120,
                    paddingRight: 15,
                    paddingTop: 10,
                    width: "100%",
                    height: "100%",
                    background: colors.grey[200],
                    position: "editorPrent",
                }}
            >
                {/* <Header /> */}
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<ChevronRight style={{ fontSize: 15 }} />}
                >
                    <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                        Home
        </Link>
                    <Link color="inherit" href={`/${id}/delivary-boy`} style={{ fontSize: 15 }}>
                        Delivary boy
        </Link>
                    <Typography
                        color="textPrimary"
                        style={{ fontSize: 15, color: "var(--orange)" }}
                    >
                        Create
        </Typography>
                </Breadcrumbs>
                <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Container maxWidth="sm">
                        <form noValidate autoComplete="off">
                            <Paper style={{ padding: "2rem", marginTop: "5%" }}>
                                <Typography variant="h6">Create</Typography>
                                <Divider style={{ margin: "20px 0 20px 0" }} />
                                <TextField label="Delivary Boy Name" variant="outlined" size="small" value={delivaryBoyData.delivery_boy_name}
                                    style={{ width: '100%', marginBottom: '1.5rem' }}
                                    onChange={(e) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_name: e.target.value })} />
                                <TextField label="Address" multiline rows={4} variant="outlined" value={delivaryBoyData.delivery_boy_address}
                                    style={{ width: '100%', marginBottom: '1.5rem' }}
                                    onChange={(e) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_address: e.target.value })} />
                                <TextField label="City" variant="outlined" size="small" value={delivaryBoyData.delivery_boy_city}
                                    style={{ width: '100%', marginBottom: '1.5rem' }}
                                    onChange={(e) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_city: e.target.value })} />
                                <TextField label="Country" variant="outlined" size="small" value={delivaryBoyData.delivery_boy_country}
                                    style={{ width: '100%', marginBottom: '1.5rem' }}
                                    onChange={(e) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_country: e.target.value })} />
                                <TextField label="Email" variant="outlined" size="small" value={delivaryBoyData.delivery_boy_email}
                                    style={{ width: '100%', marginBottom: '1.5rem' }}
                                    onChange={(e) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_email: e.target.value })} />
                                <TextField label="Phone Number" variant="outlined" size="small" value={delivaryBoyData.delivery_boy_phone_number}
                                    style={{ width: '100%', marginBottom: '1.5rem' }}
                                    onChange={(e) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_phone_number: e.target.value })} />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar style={{ width: '100%', marginBottom: '1.5rem' }}
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="BirthDay"
                                        value={delivaryBoyData.delivery_boy_dob}
                                        onChange={(date) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_dob: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <FormControl fullWidth style={{ margin: "3% 0" }}>
                                    <FormLabel component="legend">Gender</FormLabel>
                                    <RadioGroup row aria-label="quiz" style={{ "display": "flex" }} name="quiz"
                                        value={delivaryBoyData.delivery_boy_gender}
                                        onChange={(e) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_gender: e.target.value })}>
                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                    </RadioGroup>
                                </FormControl>
                                {/* <TextField label="Password" variant="outlined" size="small" defaultValue=""
                            style={{ width: '100%', marginBottom: '1.5rem' }} />
                          <TextField label="Confirm Password" variant="outlined" size="small" defaultValue=""
                            style={{ width: '100%', marginBottom: '1.5rem' }} /> */}
                                <TextField label="Identify Number" variant="outlined" size="small" value={delivaryBoyData.delivery_boy_identity_number}
                                    style={{ width: '100%', marginBottom: '1.5rem' }}
                                    onChange={(e) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_identity_number: e.target.value })} />
                                <TextField label="Licence Number" variant="outlined" size="small" value={delivaryBoyData.delivery_boy_licence_number}
                                    style={{ width: '100%', marginBottom: '1.5rem' }}
                                    onChange={(e) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_licence_number: e.target.value })} />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar style={{ width: '100%', marginBottom: '1.5rem' }}
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Licence Expiry Date"
                                        value={delivaryBoyData.delivery_boy_licence_expiry_date}
                                        onChange={(date) => setdelivaryBoyData({ ...delivaryBoyData, delivery_boy_licence_expiry_date: date })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>

                            </Paper>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                width={200}
                                margin="auto"
                                marginTop="5%"
                            >
                                <Button startIcon={<ChevronLeft />} className="button">
                                    back
                            </Button>
                                {
                                    location && location.state ? <Button className="button" startIcon={<Check />}
                                        onClick={() => dispatch(updateDelivaryBoy(delivaryBoyData))}>Update
                                </Button> :
                                        <Button className="button" startIcon={<Check />} onClick={() => dispatch(postDelivaryBoy(delivaryBoyData))}>
                                            save
                                </Button>
                                }
                            </Box>
                        </form>

                    </Container>
                </Box>
                <Snackbar open={state.delivaryBoy.status}
                    onClose={!state.delivaryBoy.status}>
                    <Alert onClose={!state.delivaryBoy.status} severity={state.delivaryBoy.status}>
                        {state.delivaryBoy.message}
                    </Alert>
                </Snackbar>
            </div>
        }</>);
};

