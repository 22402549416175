import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import clsx from "clsx";

import {
  Breadcrumbs,
  Typography,
  Link,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  FormGroup,
  colors,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Box,
  Button,
  IconButton,
  Snackbar,
  SwipeableDrawer,
  makeStyles,
  CircularProgress,
  Tooltip
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MuiAlert from '@material-ui/lab/Alert';
import { ChevronRight, Remove, Check, Add, ChevronLeft, RadioButtonUnchecked } from "@material-ui/icons";
import { IOSSwitch } from "../../components/Buttons/IOSSwitch.component";
import { updateoperationalhours, getoperationalhours } from "../../redux";
import moment from "moment";
import { SwipeDrawer } from "./swipedrawer/swipeDrawer";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const Operationalhours = () => {
  const classes = useStyles()
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const [value, setValue] = React.useState('one');
  const [drawerOpen, setdrawerOpen] = useState(true);

  useEffect(() => {
    state?.login?.data?.adminstrator_branch_id && dispatch(getoperationalhours(state?.login?.data?.adminstrator_branch_id))
  }, [state?.login?.data])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [delivaryTime, setdelivaryTime] = React.useState(null)

  useEffect(() => {
    state?.operationalhours?.data && setdelivaryTime(state?.operationalhours?.data)
  }, [state?.operationalhours?.data])

  const submitPickUp = (e) => {
    const body = {
      _id: delivaryTime._id,
      pickup_operational_week_day_monday: delivaryTime.pickup_operational_week_day_monday,
      pickup_operational_week_day_tuesday: delivaryTime.pickup_operational_week_day_tuesday,
      pickup_operational_week_day_wednesday: delivaryTime.pickup_operational_week_day_wednesday,
      pickup_operational_week_day_thursday: delivaryTime.pickup_operational_week_day_thursday,
      pickup_operational_week_day_fryday: delivaryTime.pickup_operational_week_day_fryday,
      pickup_operational_week_day_saturday: delivaryTime.pickup_operational_week_day_saturday,
      pickup_operational_week_day_sunday: delivaryTime.pickup_operational_week_day_sunday
    }
    dispatch(updateoperationalhours(body))
    e.preventDefault()
  }
  const submitDelivary = (e) => {
    const body = {
      _id: delivaryTime._id,
      delivery_operational_week_day_monday: delivaryTime.delivery_operational_week_day_monday,
      delivery_operational_week_day_tuesday: delivaryTime.delivery_operational_week_day_tuesday,
      delivery_operational_week_day_wednesday: delivaryTime.delivery_operational_week_day_wednesday,
      delivery_operational_week_day_thursday: delivaryTime.delivery_operational_week_day_thursday,
      delivery_operational_week_day_fryday: delivaryTime.delivery_operational_week_day_fryday,
      delivery_operational_week_day_saturday: delivaryTime.delivery_operational_week_day_saturday,
      delivery_operational_week_day_sunday: delivaryTime.delivery_operational_week_day_sunday
    }
    dispatch(updateoperationalhours(body))
    e.preventDefault()
  }

  const submitReservation = (e) => {
    const body = {
      _id: delivaryTime._id,
      reservation_operational_week_day_monday: delivaryTime.reservation_operational_week_day_monday,
      reservation_operational_week_day_tuesday: delivaryTime.reservation_operational_week_day_tuesday,
      reservation_operational_week_day_wednesday: delivaryTime.reservation_operational_week_day_wednesday,
      reservation_operational_week_day_thursday: delivaryTime.reservation_operational_week_day_thursday,
      reservation_operational_week_day_fryday: delivaryTime.reservation_operational_week_day_fryday,
      reservation_operational_week_day_saturday: delivaryTime.reservation_operational_week_day_saturday,
      reservation_operational_week_day_sunday: delivaryTime.reservation_operational_week_day_sunday
    }
    dispatch(updateoperationalhours(body))
    e.preventDefault()
  }

  return (
    <>
      {
        !state?.branch?.loader &&
        <div style={{
          width: "100%",
          height: "100vh",
          background: colors.grey[200],
          position: "relative",
        }} className={classes.root} >
          <div
            style={{ position: "fixed", top: "0%", right: "63%", zIndex: 1100 }}
            className={classes.drawerHeader}
          >
            <IconButton onClick={() => setdrawerOpen(!drawerOpen)}>
              {drawerOpen ? (
                <ChevronLeft />
              ) : (
                <ChevronRight />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            style={{ marginTop: "10px" }} open={drawerOpen}
            o style={{ marginTop: "10px" }} nClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Restaurant Basics
              </Typography>
            </div>
            <SwipeDrawer />
          </SwipeableDrawer>
          {
            delivaryTime ?
              <div className={clsx(classes.content, {
                [classes.contentShift]: drawerOpen,
              })} style={{ paddingLeft: 20, paddingRight: 20 }} >
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRight style={{ fontSize: 15 }} />}
                >
                  <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                    Home
                  </Link>
                  <Typography
                    color="textPrimary"
                    style={{ fontSize: 15, color: "var(--orange)" }}
                  >
                    Operational Hours
                  </Typography>
                </Breadcrumbs>
                <Paper style={{ marginTop: '15px' }}>
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                      <Tab
                        value="one"
                        label="Pickup Time Slot"
                        wrapped
                        {...a11yProps('one')}
                      />
                      <Tab value="two" label="Delivery Time Slot" {...a11yProps('two')} />
                      <Tab value="three" label="Reservation Time Slot" {...a11yProps('three')} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index="one">
                    <form onSubmit={submitPickUp}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell rowSpan={2} width="400px">DAYS</TableCell>
                            <TableCell colSpan={4} align="center">
                              DELIVERY SERVICE
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>Start</Grid>
                                <Grid item xs={6}>End</Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Sunday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_sunday: {
                                                    ...delivaryTime?.pickup_operational_week_day_sunday,
                                                    pickup_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_sunday: {
                                                    ...delivaryTime?.pickup_operational_week_day_sunday,
                                                    pickup_operational_hour_time:
                                                      [...delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time,
                                                      {
                                                        pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) ||
                                            i > 0 && moment(res.pickup_operational_hour_starts_from).isBefore(delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time[i - 1].pickup_operational_hour_ends_at)
                                          }
                                          helperText={res.pickup_operational_hour_starts_from ? i > 0 &&
                                            moment(res.pickup_operational_hour_starts_from).isBefore(delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time[i - 1].pickup_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          margin="normal"
                                          label="Pickup Hours Starts From"
                                          value={res.pickup_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_starts_from = date
                                            // res.pickup_operational_hour_starts_from = moment(date).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) ?
                                            //   date : null
                                            // res.on = date
                                            // res.pickup_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_sunday: {
                                                ...delivaryTime?.pickup_operational_week_day_sunday,
                                                pickup_operational_hour_time: [...delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          label="PickUp Hours Ends at"
                                          value={res.pickup_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.pickup_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_sunday: {
                                                ...delivaryTime?.pickup_operational_week_day_sunday,
                                                pickup_operational_hour_time: [...delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_sunday: {
                                              ...delivaryTime?.pickup_operational_week_day_sunday,
                                              pickup_operational_hour_time: [...delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.pickup_operational_hour_starts_from || !res.pickup_operational_hour_ends_at
                                        || (moment(res.pickup_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.pickup_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() => {
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_sunday: {
                                              ...delivaryTime?.pickup_operational_week_day_sunday,
                                              pickup_operational_hour_time:
                                                [...delivaryTime?.pickup_operational_week_day_sunday.pickup_operational_hour_time,
                                                {
                                                  pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })
                                        }}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Monday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime?.pickup_operational_week_day_monday.pickup_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime?.pickup_operational_week_day_monday.pickup_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_monday: {
                                                    ...delivaryTime?.pickup_operational_week_day_monday,
                                                    pickup_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_monday: {
                                                    ...delivaryTime?.pickup_operational_week_day_monday,
                                                    pickup_operational_hour_time:
                                                      [...delivaryTime?.pickup_operational_week_day_monday.pickup_operational_hour_time,
                                                      {
                                                        pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime?.pickup_operational_week_day_monday.pickup_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? i > 0 &&
                                            moment(res.pickup_operational_hour_starts_from).isBefore(delivaryTime?.pickup_operational_week_day_monday.pickup_operational_hour_time[i - 1].pickup_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          margin="normal"
                                          label="Pickup Hours Starts From"
                                          value={res.pickup_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.pickup_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_monday: {
                                                ...delivaryTime.pickup_operational_week_day_monday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_monday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          label="PickUp Hours Ends at"
                                          value={res.pickup_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.pickup_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_monday: {
                                                ...delivaryTime.pickup_operational_week_day_monday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_monday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.pickup_operational_week_day_monday.pickup_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_monday: {
                                              ...delivaryTime.pickup_operational_week_day_monday,
                                              pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_monday.pickup_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.pickup_operational_hour_starts_from || !res.pickup_operational_hour_ends_at
                                        || (moment(res.pickup_operational_hour_ends_at).format('LT') === "11:59 PM"
                                          && moment(res.pickup_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_monday: {
                                              ...delivaryTime.pickup_operational_week_day_monday,
                                              pickup_operational_hour_time:
                                                [...delivaryTime.pickup_operational_week_day_monday.pickup_operational_hour_time,
                                                {
                                                  pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>TuesDay</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.pickup_operational_week_day_tuesday.pickup_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.pickup_operational_week_day_tuesday.pickup_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_tuesday: {
                                                    ...delivaryTime.pickup_operational_week_day_tuesday,
                                                    pickup_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_tuesday: {
                                                    ...delivaryTime.pickup_operational_week_day_tuesday,
                                                    pickup_operational_hour_time:
                                                      [...delivaryTime.pickup_operational_week_day_tuesday.pickup_operational_hour_time,
                                                      {
                                                        pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.pickup_operational_week_day_tuesday.pickup_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? i > 0 &&
                                            moment(res.pickup_operational_hour_starts_from).isBefore(delivaryTime?.pickup_operational_week_day_tuesday.pickup_operational_hour_time[i - 1].pickup_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          margin="normal"
                                          label="Pickup Hours Starts From"
                                          value={res.pickup_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.pickup_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_tuesday: {
                                                ...delivaryTime.pickup_operational_week_day_tuesday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_tuesday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          label="PickUp Hours Ends at"
                                          value={res.pickup_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.pickup_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_tuesday: {
                                                ...delivaryTime.pickup_operational_week_day_tuesday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_tuesday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.pickup_operational_week_day_tuesday.pickup_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_tuesday: {
                                              ...delivaryTime.pickup_operational_week_day_tuesday,
                                              pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_tuesday.pickup_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.pickup_operational_hour_starts_from || !res.pickup_operational_hour_ends_at
                                        || (moment(res.pickup_operational_hour_ends_at).format('LT') === "11:59 PM"
                                          && moment(res.pickup_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_tuesday: {
                                              ...delivaryTime.pickup_operational_week_day_tuesday,
                                              pickup_operational_hour_time:
                                                [...delivaryTime.pickup_operational_week_day_tuesday.pickup_operational_hour_time,
                                                {
                                                  pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Wednesday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.pickup_operational_week_day_wednesday.pickup_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.pickup_operational_week_day_wednesday.pickup_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_wednesday: {
                                                    ...delivaryTime.pickup_operational_week_day_wednesday,
                                                    pickup_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_wednesday: {
                                                    ...delivaryTime.pickup_operational_week_day_wednesday,
                                                    pickup_operational_hour_time:
                                                      [...delivaryTime.pickup_operational_week_day_wednesday.pickup_operational_hour_time,
                                                      {
                                                        pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.pickup_operational_week_day_wednesday.pickup_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? i > 0 &&
                                            moment(res.pickup_operational_hour_starts_from).isBefore(delivaryTime?.pickup_operational_week_day_wednesday.pickup_operational_hour_time[i - 1].pickup_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          margin="normal"
                                          label="Pickup Hours Starts From"
                                          value={res.pickup_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            // res.on = date
                                            res.pickup_operational_hour_starts_from = date
                                            // res.pickup_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_wednesday: {
                                                ...delivaryTime.pickup_operational_week_day_wednesday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_wednesday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          label="PickUp Hours Ends at"
                                          value={res.pickup_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.pickup_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_wednesday: {
                                                ...delivaryTime.pickup_operational_week_day_wednesday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_wednesday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.pickup_operational_week_day_wednesday.pickup_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_wednesday: {
                                              ...delivaryTime.pickup_operational_week_day_wednesday,
                                              pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_wednesday.pickup_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.pickup_operational_hour_starts_from || !res.pickup_operational_hour_ends_at
                                        || (moment(res.pickup_operational_hour_ends_at).format('LT') === "11:59 PM"
                                          && moment(res.pickup_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_wednesday: {
                                              ...delivaryTime.pickup_operational_week_day_wednesday,
                                              pickup_operational_hour_time:
                                                [...delivaryTime.pickup_operational_week_day_wednesday.pickup_operational_hour_time,
                                                {
                                                  pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>ThursDay</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.pickup_operational_week_day_thursday.pickup_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.pickup_operational_week_day_thursday.pickup_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_thursday: {
                                                    ...delivaryTime.pickup_operational_week_day_thursday,
                                                    pickup_operational_hour_time:
                                                      []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_thursday: {
                                                    ...delivaryTime.pickup_operational_week_day_thursday,
                                                    pickup_operational_hour_time:
                                                      [...delivaryTime.pickup_operational_week_day_thursday.pickup_operational_hour_time,
                                                      {
                                                        pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.pickup_operational_week_day_thursday.pickup_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? i > 0 &&
                                            moment(res.pickup_operational_hour_starts_from).isBefore(delivaryTime?.pickup_operational_week_day_thursday.pickup_operational_hour_time[i - 1].pickup_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          margin="normal"
                                          label="Pickup Hours Starts From"
                                          value={res.pickup_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.pickup_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_thursday: {
                                                ...delivaryTime.pickup_operational_week_day_thursday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_thursday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Time picker"
                                          // value={res.off}
                                          value={res.pickup_operational_hour_ends_at}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.pickup_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_thursday: {
                                                ...delivaryTime.pickup_operational_week_day_thursday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_thursday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.pickup_operational_week_day_thursday.pickup_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_thursday: {
                                              ...delivaryTime.pickup_operational_week_day_thursday,
                                              pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_thursday.pickup_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.pickup_operational_hour_starts_from || !res.pickup_operational_hour_ends_at
                                        || (moment(res.pickup_operational_hour_ends_at).format('LT') === "11:59 PM"
                                          && moment(res.pickup_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_thursday: {
                                              ...delivaryTime.pickup_operational_week_day_thursday,
                                              pickup_operational_hour_time:
                                                [...delivaryTime.pickup_operational_week_day_thursday.pickup_operational_hour_time,
                                                {
                                                  pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Friday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.pickup_operational_week_day_fryday.pickup_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.pickup_operational_week_day_fryday.pickup_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_fryday: {
                                                    ...delivaryTime.pickup_operational_week_day_fryday,
                                                    pickup_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_fryday: {
                                                    ...delivaryTime.pickup_operational_week_day_fryday,
                                                    pickup_operational_hour_time:
                                                      [...delivaryTime.pickup_operational_week_day_fryday.pickup_operational_hour_time,
                                                      {
                                                        pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.pickup_operational_week_day_fryday.pickup_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? i > 0 &&
                                            moment(res.pickup_operational_hour_starts_from).isBefore(delivaryTime?.pickup_operational_week_day_fryday.pickup_operational_hour_time[i - 1].pickup_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          margin="normal"
                                          label="Pickup Hours Starts From"
                                          value={res.pickup_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.pickup_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_fryday: {
                                                ...delivaryTime.pickup_operational_week_day_fryday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_fryday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          label="PickUp Hours Ends at"
                                          value={res.pickup_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.pickup_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_fryday: {
                                                ...delivaryTime.pickup_operational_week_day_fryday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_fryday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.pickup_operational_week_day_fryday.pickup_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_fryday: {
                                              ...delivaryTime.pickup_operational_week_day_fryday,
                                              pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_fryday.pickup_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.pickup_operational_hour_starts_from || !res.pickup_operational_hour_ends_at
                                        || (moment(res.pickup_operational_hour_ends_at).format('LT') === "11:59 PM"
                                          && moment(res.pickup_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_fryday: {
                                              ...delivaryTime.pickup_operational_week_day_fryday,
                                              pickup_operational_hour_time:
                                                [...delivaryTime.pickup_operational_week_day_fryday.pickup_operational_hour_time,
                                                {
                                                  pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Saturday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.pickup_operational_week_day_saturday.pickup_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.pickup_operational_week_day_saturday.pickup_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_saturday: {
                                                    ...delivaryTime.pickup_operational_week_day_saturday,
                                                    pickup_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  pickup_operational_week_day_saturday: {
                                                    ...delivaryTime.pickup_operational_week_day_saturday,
                                                    pickup_operational_hour_time:
                                                      [...delivaryTime.pickup_operational_week_day_saturday.pickup_operational_hour_time,
                                                      {
                                                        pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.pickup_operational_week_day_saturday.pickup_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? i > 0 &&
                                            moment(res.pickup_operational_hour_starts_from).isBefore(delivaryTime?.pickup_operational_week_day_saturday.pickup_operational_hour_time[i - 1].pickup_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          margin="normal"
                                          label="Pickup Hours Starts From"
                                          value={res.pickup_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.pickup_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_saturday: {
                                                ...delivaryTime.pickup_operational_week_day_saturday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_saturday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at)}
                                          helperText={res.pickup_operational_hour_starts_from ? !moment(res.pickup_operational_hour_starts_from).startOf('hour').isBefore(res.pickup_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          label="PickUp Hours Ends at"
                                          value={res.pickup_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.pickup_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.pickup_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              pickup_operational_week_day_saturday: {
                                                ...delivaryTime.pickup_operational_week_day_saturday,
                                                pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_saturday.pickup_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.pickup_operational_week_day_saturday.pickup_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_saturday: {
                                              ...delivaryTime.pickup_operational_week_day_saturday,
                                              pickup_operational_hour_time: [...delivaryTime.pickup_operational_week_day_saturday.pickup_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.pickup_operational_hour_starts_from || !res.pickup_operational_hour_ends_at
                                        || (moment(res.pickup_operational_hour_ends_at).format('LT') === "11:59 PM"
                                          && moment(res.pickup_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            pickup_operational_week_day_saturday: {
                                              ...delivaryTime.pickup_operational_week_day_saturday,
                                              pickup_operational_hour_time:
                                                [...delivaryTime.pickup_operational_week_day_saturday.pickup_operational_hour_time,
                                                {
                                                  pickup_operational_hour_starts_from: null, pickup_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <div style={{ padding: "15px", display: "flex", justifyContent: "center" }}>
                        <Button variant="contained" style={{ "color": "#fff" }} color="primary" startIcon={<Check />} type="submit">
                          Save
                        </Button>
                      </div>
                    </form>
                  </TabPanel>
                  <TabPanel value={value} index="two">
                    <form onSubmit={submitDelivary}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell rowSpan={2} rowSpan={2} width="400px">DAYS</TableCell>
                            <TableCell colSpan={4} align="center">
                              DELIVERY SERVICE
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>Start</Grid>
                                <Grid item xs={6}>End</Grid>
                              </Grid>
                            </TableCell>
                            {/* <TableCell>ACTION</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Sunday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.delivery_operational_week_day_sunday.delivery_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.delivery_operational_week_day_sunday.delivery_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_sunday: {
                                                    ...delivaryTime.delivery_operational_week_day_sunday,
                                                    delivery_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_sunday: {
                                                    ...delivaryTime.delivery_operational_week_day_sunday,
                                                    delivery_operational_hour_time:
                                                      [...delivaryTime.delivery_operational_week_day_sunday.delivery_operational_hour_time,
                                                      {
                                                        delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.delivery_operational_week_day_sunday.delivery_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? i > 0 &&
                                            moment(res.delivery_operational_hour_starts_from).isBefore(delivaryTime?.delivery_operational_week_day_sunday.delivery_operational_hour_time[i - 1].delivery_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          label="Delivery Hours Starts From"
                                          margin="normal"
                                          value={res.delivery_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.delivery_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_sunday: {
                                                ...delivaryTime.delivery_operational_week_day_sunday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_sunday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Delivery Hours Ends at"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          // value={res.off}
                                          value={res.delivery_operational_hour_ends_at}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.delivery_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_sunday: {
                                                ...delivaryTime.delivery_operational_week_day_sunday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_sunday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                          onClick={() => {
                                          delivaryTime.delivery_operational_week_day_sunday.delivery_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_sunday: {
                                              ...delivaryTime.delivery_operational_week_day_sunday,
                                              delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_sunday.delivery_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton  disabled={!res.delivery_operational_hour_starts_from || !res.delivery_operational_hour_ends_at
                                        || (moment(res.delivery_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.delivery_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_sunday: {
                                              ...delivaryTime.delivery_operational_week_day_sunday,
                                              delivery_operational_hour_time:
                                                [...delivaryTime.delivery_operational_week_day_sunday.delivery_operational_hour_time,
                                                {
                                                  delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Monday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.delivery_operational_week_day_monday.delivery_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.delivery_operational_week_day_monday.delivery_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_monday: {
                                                    ...delivaryTime.delivery_operational_week_day_monday,
                                                    delivery_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_monday: {
                                                    ...delivaryTime.delivery_operational_week_day_monday,
                                                    delivery_operational_hour_time:
                                                      [...delivaryTime.delivery_operational_week_day_monday.delivery_operational_hour_time,
                                                      {
                                                        delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.delivery_operational_week_day_monday.delivery_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? i > 0 &&
                                            moment(res.delivery_operational_hour_starts_from).isBefore(delivaryTime?.delivery_operational_week_day_monday.delivery_operational_hour_time[i - 1].delivery_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          label="Delivery Hours Starts From"
                                          value={res.delivery_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.delivery_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_monday: {
                                                ...delivaryTime.delivery_operational_week_day_monday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_monday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Delivery Hours Ends at"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.delivery_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.delivery_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_monday: {
                                                ...delivaryTime.delivery_operational_week_day_monday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_monday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.delivery_operational_week_day_monday.delivery_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_monday: {
                                              ...delivaryTime.delivery_operational_week_day_monday,
                                              delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_monday.delivery_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton  disabled={!res.delivery_operational_hour_starts_from || !res.delivery_operational_hour_ends_at
                                        || (moment(res.delivery_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.delivery_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_monday: {
                                              ...delivaryTime.delivery_operational_week_day_monday,
                                              delivery_operational_hour_time:
                                                [...delivaryTime.delivery_operational_week_day_monday.delivery_operational_hour_time,
                                                {
                                                  delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>TuesDay</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.delivery_operational_week_day_tuesday.delivery_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.delivery_operational_week_day_tuesday.delivery_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_tuesday: {
                                                    ...delivaryTime.delivery_operational_week_day_tuesday,
                                                    delivery_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_tuesday: {
                                                    ...delivaryTime.delivery_operational_week_day_tuesday,
                                                    delivery_operational_hour_time:
                                                      [...delivaryTime.delivery_operational_week_day_tuesday.delivery_operational_hour_time,
                                                      {
                                                        delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.delivery_operational_week_day_tuesday.delivery_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? i > 0 &&
                                            moment(res.delivery_operational_hour_starts_from).isBefore(delivaryTime?.delivery_operational_week_day_tuesday.delivery_operational_hour_time[i - 1].delivery_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          label="Delivery Hours Starts From"
                                          value={res.delivery_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.delivery_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_tuesday: {
                                                ...delivaryTime.delivery_operational_week_day_tuesday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_tuesday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Delivery Hours Ends at"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.delivery_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.delivery_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_tuesday: {
                                                ...delivaryTime.delivery_operational_week_day_tuesday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_tuesday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.delivery_operational_week_day_tuesday.delivery_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_tuesday: {
                                              ...delivaryTime.delivery_operational_week_day_tuesday,
                                              delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_tuesday.delivery_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton  disabled={!res.delivery_operational_hour_starts_from || !res.delivery_operational_hour_ends_at
                                        || (moment(res.delivery_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.delivery_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_tuesday: {
                                              ...delivaryTime.delivery_operational_week_day_tuesday,
                                              delivery_operational_hour_time:
                                                [...delivaryTime.delivery_operational_week_day_tuesday.delivery_operational_hour_time,
                                                {
                                                  delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Wednesday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.delivery_operational_week_day_wednesday.delivery_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.delivery_operational_week_day_wednesday.delivery_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_wednesday: {
                                                    ...delivaryTime.delivery_operational_week_day_wednesday,
                                                    delivery_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_wednesday: {
                                                    ...delivaryTime.delivery_operational_week_day_wednesday,
                                                    delivery_operational_hour_time:
                                                      [...delivaryTime.delivery_operational_week_day_wednesday.delivery_operational_hour_time,
                                                      {
                                                        delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.delivery_operational_week_day_wednesday.delivery_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? i > 0 &&
                                            moment(res.delivery_operational_hour_starts_from).isBefore(delivaryTime?.delivery_operational_week_day_wednesday.delivery_operational_hour_time[i - 1].delivery_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          label="Delivery Hours Starts From"
                                          value={res.delivery_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            // res.on = date
                                            res.delivery_operational_hour_starts_from = date
                                            // res.delivery_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_wednesday: {
                                                ...delivaryTime.delivery_operational_week_day_wednesday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_wednesday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Delivery Hours Ends at"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.delivery_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.delivery_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_wednesday: {
                                                ...delivaryTime.delivery_operational_week_day_wednesday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_wednesday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.delivery_operational_week_day_wednesday.delivery_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_wednesday: {
                                              ...delivaryTime.delivery_operational_week_day_wednesday,
                                              delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_wednesday.delivery_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton  disabled={!res.delivery_operational_hour_starts_from || !res.delivery_operational_hour_ends_at
                                        || (moment(res.delivery_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.delivery_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_wednesday: {
                                              ...delivaryTime.delivery_operational_week_day_wednesday,
                                              delivery_operational_hour_time:
                                                [...delivaryTime.delivery_operational_week_day_wednesday.delivery_operational_hour_time,
                                                {
                                                  delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>ThursDay</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.delivery_operational_week_day_thursday.delivery_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.delivery_operational_week_day_thursday.delivery_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_thursday: {
                                                    ...delivaryTime.delivery_operational_week_day_thursday,
                                                    delivery_operational_hour_time:
                                                      []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_thursday: {
                                                    ...delivaryTime.delivery_operational_week_day_thursday,
                                                    delivery_operational_hour_time:
                                                      [...delivaryTime.delivery_operational_week_day_thursday.delivery_operational_hour_time,
                                                      {
                                                        delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.delivery_operational_week_day_thursday.delivery_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? i > 0 &&
                                            moment(res.delivery_operational_hour_starts_from).isBefore(delivaryTime?.delivery_operational_week_day_thursday.delivery_operational_hour_time[i - 1].delivery_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          label="Delivery Hours Starts From"
                                          value={res.delivery_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.delivery_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_thursday: {
                                                ...delivaryTime.delivery_operational_week_day_thursday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_thursday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Time picker"
                                          // value={res.off}
                                          value={res.delivery_operational_hour_ends_at}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.delivery_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_thursday: {
                                                ...delivaryTime.delivery_operational_week_day_thursday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_thursday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.delivery_operational_week_day_thursday.delivery_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_thursday: {
                                              ...delivaryTime.delivery_operational_week_day_thursday,
                                              delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_thursday.delivery_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton  disabled={!res.delivery_operational_hour_starts_from || !res.delivery_operational_hour_ends_at
                                        || (moment(res.delivery_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.delivery_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_thursday: {
                                              ...delivaryTime.delivery_operational_week_day_thursday,
                                              delivery_operational_hour_time:
                                                [...delivaryTime.delivery_operational_week_day_thursday.delivery_operational_hour_time,
                                                {
                                                  delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Friday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.delivery_operational_week_day_fryday.delivery_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.delivery_operational_week_day_fryday.delivery_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_fryday: {
                                                    ...delivaryTime.delivery_operational_week_day_fryday,
                                                    delivery_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_fryday: {
                                                    ...delivaryTime.delivery_operational_week_day_fryday,
                                                    delivery_operational_hour_time:
                                                      [...delivaryTime.delivery_operational_week_day_fryday.delivery_operational_hour_time,
                                                      {
                                                        delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.delivery_operational_week_day_fryday.delivery_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? i > 0 &&
                                            moment(res.delivery_operational_hour_starts_from).isBefore(delivaryTime?.delivery_operational_week_day_fryday.delivery_operational_hour_time[i - 1].delivery_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          label="Delivery Hours Starts From"
                                          value={res.delivery_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.delivery_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_fryday: {
                                                ...delivaryTime.delivery_operational_week_day_fryday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_fryday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Delivery Hours Ends at"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.delivery_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.delivery_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_fryday: {
                                                ...delivaryTime.delivery_operational_week_day_fryday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_fryday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.delivery_operational_week_day_fryday.delivery_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_fryday: {
                                              ...delivaryTime.delivery_operational_week_day_fryday,
                                              delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_fryday.delivery_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton  disabled={!res.delivery_operational_hour_starts_from || !res.delivery_operational_hour_ends_at
                                        || (moment(res.delivery_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.delivery_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_fryday: {
                                              ...delivaryTime.delivery_operational_week_day_fryday,
                                              delivery_operational_hour_time:
                                                [...delivaryTime.delivery_operational_week_day_fryday.delivery_operational_hour_time,
                                                {
                                                  delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Saturday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.delivery_operational_week_day_saturday.delivery_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.delivery_operational_week_day_saturday.delivery_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_saturday: {
                                                    ...delivaryTime.delivery_operational_week_day_saturday,
                                                    delivery_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  delivery_operational_week_day_saturday: {
                                                    ...delivaryTime.delivery_operational_week_day_saturday,
                                                    delivery_operational_hour_time:
                                                      [...delivaryTime.delivery_operational_week_day_saturday.delivery_operational_hour_time,
                                                      {
                                                        delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.delivery_operational_week_day_saturday.delivery_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? i > 0 &&
                                            moment(res.delivery_operational_hour_starts_from).isBefore(delivaryTime?.delivery_operational_week_day_saturday.delivery_operational_hour_time[i - 1].delivery_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          label="Delivery Hours Starts From"
                                          value={res.delivery_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.delivery_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_saturday: {
                                                ...delivaryTime.delivery_operational_week_day_saturday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_saturday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Delivery Hours Ends at"
                                          error={!moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at)}
                                          helperText={res.delivery_operational_hour_starts_from ? !moment(res.delivery_operational_hour_starts_from).startOf('hour').isBefore(res.delivery_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.delivery_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.delivery_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.delivery_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              delivery_operational_week_day_saturday: {
                                                ...delivaryTime.delivery_operational_week_day_saturday,
                                                delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_saturday.delivery_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.delivery_operational_week_day_saturday.delivery_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_saturday: {
                                              ...delivaryTime.delivery_operational_week_day_saturday,
                                              delivery_operational_hour_time: [...delivaryTime.delivery_operational_week_day_saturday.delivery_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.delivery_operational_hour_starts_from || !res.delivery_operational_hour_ends_at
                                        || (moment(res.delivery_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.delivery_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            delivery_operational_week_day_saturday: {
                                              ...delivaryTime.delivery_operational_week_day_saturday,
                                              delivery_operational_hour_time:
                                                [...delivaryTime.delivery_operational_week_day_saturday.delivery_operational_hour_time,
                                                {
                                                  delivery_operational_hour_starts_from: null, delivery_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <div style={{ padding: "15px", display: "flex", justifyContent: "center" }}>
                        <Button variant="contained" style={{ "color": "#fff" }} color="primary" startIcon={<Check />} type="submit">
                          Save
                        </Button>
                      </div>
                    </form>
                  </TabPanel>
                  <TabPanel value={value} index="three">
                    <form onSubmit={submitReservation}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell rowSpan={2} rowSpan={2} width="400px">DAYS</TableCell>
                            <TableCell colSpan={4} align="center">
                              DELIVERY SERVICE
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Grid container spacing={3}>
                                <Grid item xs={6}>Start</Grid>
                                <Grid item xs={6}>End</Grid>
                              </Grid>
                            </TableCell>
                            {/* <TableCell>ACTION</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Sunday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.reservation_operational_week_day_sunday.reservation_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.reservation_operational_week_day_sunday.reservation_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_sunday: {
                                                    ...delivaryTime.reservation_operational_week_day_sunday,
                                                    reservation_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_sunday: {
                                                    ...delivaryTime.reservation_operational_week_day_sunday,
                                                    reservation_operational_hour_time:
                                                      [...delivaryTime.reservation_operational_week_day_sunday.reservation_operational_hour_time,
                                                      {
                                                        reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.reservation_operational_week_day_sunday.reservation_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Starts From"
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? i > 0 &&
                                            moment(res.reservation_operational_hour_starts_from).isBefore(delivaryTime?.reservation_operational_week_day_sunday.reservation_operational_hour_time[i - 1].reservation_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          value={res.reservation_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.reservation_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_sunday: {
                                                ...delivaryTime.reservation_operational_week_day_sunday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_sunday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Ends at"
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                            value={res.reservation_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.reservation_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_sunday: {
                                                ...delivaryTime.reservation_operational_week_day_sunday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_sunday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.reservation_operational_week_day_sunday.reservation_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_sunday: {
                                              ...delivaryTime.reservation_operational_week_day_sunday,
                                              reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_sunday.reservation_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.reservation_operational_hour_starts_from || !res.reservation_operational_hour_ends_at
                                        || (moment(res.reservation_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.reservation_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_sunday: {
                                              ...delivaryTime.reservation_operational_week_day_sunday,
                                              reservation_operational_hour_time:
                                                [...delivaryTime.reservation_operational_week_day_sunday.reservation_operational_hour_time,
                                                {
                                                  reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Monday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.reservation_operational_week_day_monday.reservation_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.reservation_operational_week_day_monday.reservation_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_monday: {
                                                    ...delivaryTime.reservation_operational_week_day_monday,
                                                    reservation_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_monday: {
                                                    ...delivaryTime.reservation_operational_week_day_monday,
                                                    reservation_operational_hour_time:
                                                      [...delivaryTime.reservation_operational_week_day_monday.reservation_operational_hour_time,
                                                      {
                                                        reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.reservation_operational_week_day_monday.reservation_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Starts From" 
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? i > 0 &&
                                            moment(res.reservation_operational_hour_starts_from).isBefore(delivaryTime?.reservation_operational_week_day_monday.reservation_operational_hour_time[i - 1].reservation_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          value={res.reservation_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.reservation_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_monday: {
                                                ...delivaryTime.reservation_operational_week_day_monday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_monday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Ends at"
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.reservation_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.reservation_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_monday: {
                                                ...delivaryTime.reservation_operational_week_day_monday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_monday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.reservation_operational_week_day_monday.reservation_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_monday: {
                                              ...delivaryTime.reservation_operational_week_day_monday,
                                              reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_monday.reservation_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.reservation_operational_hour_starts_from || !res.reservation_operational_hour_ends_at
                                        || (moment(res.reservation_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.reservation_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_monday: {
                                              ...delivaryTime.reservation_operational_week_day_monday,
                                              reservation_operational_hour_time:
                                                [...delivaryTime.reservation_operational_week_day_monday.reservation_operational_hour_time,
                                                {
                                                  reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>TuesDay</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.reservation_operational_week_day_tuesday.reservation_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.reservation_operational_week_day_tuesday.reservation_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_tuesday: {
                                                    ...delivaryTime.reservation_operational_week_day_tuesday,
                                                    reservation_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_tuesday: {
                                                    ...delivaryTime.reservation_operational_week_day_tuesday,
                                                    reservation_operational_hour_time:
                                                      [...delivaryTime.reservation_operational_week_day_tuesday.reservation_operational_hour_time,
                                                      {
                                                        reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.reservation_operational_week_day_tuesday.reservation_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Starts From" 
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? i > 0 &&
                                            moment(res.reservation_operational_hour_starts_from).isBefore(delivaryTime?.reservation_operational_week_day_tuesday.reservation_operational_hour_time[i - 1].reservation_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          value={res.reservation_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.reservation_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_tuesday: {
                                                ...delivaryTime.reservation_operational_week_day_tuesday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_tuesday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Ends at"
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.reservation_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.reservation_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_tuesday: {
                                                ...delivaryTime.reservation_operational_week_day_tuesday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_tuesday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.reservation_operational_week_day_tuesday.reservation_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_tuesday: {
                                              ...delivaryTime.reservation_operational_week_day_tuesday,
                                              reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_tuesday.reservation_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.reservation_operational_hour_starts_from || !res.reservation_operational_hour_ends_at
                                        || (moment(res.reservation_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.reservation_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_tuesday: {
                                              ...delivaryTime.reservation_operational_week_day_tuesday,
                                              reservation_operational_hour_time:
                                                [...delivaryTime.reservation_operational_week_day_tuesday.reservation_operational_hour_time,
                                                {
                                                  reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Wednesday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.reservation_operational_week_day_wednesday.reservation_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.reservation_operational_week_day_wednesday.reservation_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_wednesday: {
                                                    ...delivaryTime.reservation_operational_week_day_wednesday,
                                                    reservation_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_wednesday: {
                                                    ...delivaryTime.reservation_operational_week_day_wednesday,
                                                    reservation_operational_hour_time:
                                                      [...delivaryTime.reservation_operational_week_day_wednesday.reservation_operational_hour_time,
                                                      {
                                                        reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.reservation_operational_week_day_wednesday.reservation_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Starts From" 
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? i > 0 &&
                                            moment(res.reservation_operational_hour_starts_from).isBefore(delivaryTime?.reservation_operational_week_day_wednesday.reservation_operational_hour_time[i - 1].reservation_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          value={res.reservation_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            // res.on = date
                                            res.reservation_operational_hour_starts_from = date
                                            // res.reservation_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_wednesday: {
                                                ...delivaryTime.reservation_operational_week_day_wednesday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_wednesday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Ends at"
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.reservation_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.reservation_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_wednesday: {
                                                ...delivaryTime.reservation_operational_week_day_wednesday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_wednesday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.reservation_operational_week_day_wednesday.reservation_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_wednesday: {
                                              ...delivaryTime.reservation_operational_week_day_wednesday,
                                              reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_wednesday.reservation_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.reservation_operational_hour_starts_from || !res.reservation_operational_hour_ends_at
                                        || (moment(res.reservation_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.reservation_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_wednesday: {
                                              ...delivaryTime.reservation_operational_week_day_wednesday,
                                              reservation_operational_hour_time:
                                                [...delivaryTime.reservation_operational_week_day_wednesday.reservation_operational_hour_time,
                                                {
                                                  reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>ThursDay</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.reservation_operational_week_day_thursday.reservation_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.reservation_operational_week_day_thursday.reservation_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_thursday: {
                                                    ...delivaryTime.reservation_operational_week_day_thursday,
                                                    reservation_operational_hour_time:
                                                      []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_thursday: {
                                                    ...delivaryTime.reservation_operational_week_day_thursday,
                                                    reservation_operational_hour_time:
                                                      [...delivaryTime.reservation_operational_week_day_thursday.reservation_operational_hour_time,
                                                      {
                                                        reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.reservation_operational_week_day_thursday.reservation_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Starts From" 
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? i > 0 &&
                                            moment(res.reservation_operational_hour_starts_from).isBefore(delivaryTime?.reservation_operational_week_day_thursday.reservation_operational_hour_time[i - 1].reservation_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          value={res.reservation_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.reservation_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_thursday: {
                                                ...delivaryTime.reservation_operational_week_day_thursday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_thursday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Time picker"
                                          // value={res.off}
                                          value={res.reservation_operational_hour_ends_at}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.reservation_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_thursday: {
                                                ...delivaryTime.reservation_operational_week_day_thursday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_thursday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.reservation_operational_week_day_thursday.reservation_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_thursday: {
                                              ...delivaryTime.reservation_operational_week_day_thursday,
                                              reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_thursday.reservation_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.reservation_operational_hour_starts_from || !res.reservation_operational_hour_ends_at
                                        || (moment(res.reservation_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.reservation_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_thursday: {
                                              ...delivaryTime.reservation_operational_week_day_thursday,
                                              reservation_operational_hour_time:
                                                [...delivaryTime.reservation_operational_week_day_thursday.reservation_operational_hour_time,
                                                {
                                                  reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Friday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.reservation_operational_week_day_fryday.reservation_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.reservation_operational_week_day_fryday.reservation_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_fryday: {
                                                    ...delivaryTime.reservation_operational_week_day_fryday,
                                                    reservation_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_fryday: {
                                                    ...delivaryTime.reservation_operational_week_day_fryday,
                                                    reservation_operational_hour_time:
                                                      [...delivaryTime.reservation_operational_week_day_fryday.reservation_operational_hour_time,
                                                      {
                                                        reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.reservation_operational_week_day_fryday.reservation_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Starts From" 
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? i > 0 &&
                                            moment(res.reservation_operational_hour_starts_from).isBefore(delivaryTime?.reservation_operational_week_day_fryday.reservation_operational_hour_time[i - 1].reservation_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          value={res.reservation_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.reservation_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_fryday: {
                                                ...delivaryTime.reservation_operational_week_day_fryday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_fryday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Ends at"
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.reservation_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.reservation_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_fryday: {
                                                ...delivaryTime.reservation_operational_week_day_fryday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_fryday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.reservation_operational_week_day_fryday.reservation_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_fryday: {
                                              ...delivaryTime.reservation_operational_week_day_fryday,
                                              reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_fryday.reservation_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.reservation_operational_hour_starts_from || !res.reservation_operational_hour_ends_at
                                        || (moment(res.reservation_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.reservation_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_fryday: {
                                              ...delivaryTime.reservation_operational_week_day_fryday,
                                              reservation_operational_hour_time:
                                                [...delivaryTime.reservation_operational_week_day_fryday.reservation_operational_hour_time,
                                                {
                                                  reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                                <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Saturday</Typography>
                                <Grid container spacing={1}>
                                  <Grid item xs style={{ marginTop: "10px" }}>Close</Grid>
                                  <Grid item xs>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            checked={delivaryTime.reservation_operational_week_day_saturday.reservation_operational_hour_time.length}
                                            onChange={() => {
                                              if (delivaryTime.reservation_operational_week_day_saturday.reservation_operational_hour_time.length) {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_saturday: {
                                                    ...delivaryTime.reservation_operational_week_day_saturday,
                                                    reservation_operational_hour_time: []
                                                  }
                                                })
                                              } else {
                                                setdelivaryTime({
                                                  ...delivaryTime,
                                                  reservation_operational_week_day_saturday: {
                                                    ...delivaryTime.reservation_operational_week_day_saturday,
                                                    reservation_operational_hour_time:
                                                      [...delivaryTime.reservation_operational_week_day_saturday.reservation_operational_hour_time,
                                                      {
                                                        reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                        // on: null, off: null
                                                      }
                                                      ]
                                                  }
                                                })
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid item xs style={{ marginTop: "10px" }}>Open</Grid>
                                </Grid>
                              </div>
                            </TableCell>
                            <TableCell>
                              {
                                delivaryTime.reservation_operational_week_day_saturday.reservation_operational_hour_time.map((res, i) =>
                                  <Grid container spacing={3}>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Starts From" 
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? i > 0 &&
                                            moment(res.reservation_operational_hour_starts_from).isBefore(delivaryTime?.reservation_operational_week_day_saturday.reservation_operational_hour_time[i - 1].reservation_operational_hour_ends_at) &&
                                            "StartTime Should be greater than previous End Time" : !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) ?
                                            "StartTime Cann't be Greater than EndTime" : "StartTime is Necessary"}
                                          value={res.reservation_operational_hour_starts_from}
                                          // value={res.on}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_starts_from = date
                                            // res.on = date
                                            // res.reservation_operational_hour_starts_from = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_saturday: {
                                                ...delivaryTime.reservation_operational_week_day_saturday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_saturday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs>
                                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker required
                                          margin="normal"
                                          label="Reservation Hours Ends at"
                                          error={!moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at)}
                                          helperText={res.reservation_operational_hour_starts_from ? !moment(res.reservation_operational_hour_starts_from).startOf('hour').isBefore(res.reservation_operational_hour_ends_at) &&
                                            "EndtTime Cann't be Greater than StartTime" : "EndTime is necessary"}
                                          value={res.reservation_operational_hour_ends_at}
                                          // value={res.off}
                                          onChange={(date) => {
                                            res.reservation_operational_hour_ends_at = date
                                            // res.off = date
                                            // res.reservation_operational_hour_ends_at = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
                                            setdelivaryTime({
                                              ...delivaryTime,
                                              reservation_operational_week_day_saturday: {
                                                ...delivaryTime.reservation_operational_week_day_saturday,
                                                reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_saturday.reservation_operational_hour_time]
                                              }
                                            })
                                          }}
                                          KeyboardButtonProps={{
                                            "aria-label": "change time",
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton
                                        onClick={() => {
                                          delivaryTime.reservation_operational_week_day_saturday.reservation_operational_hour_time.splice(i, 1)
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_saturday: {
                                              ...delivaryTime.reservation_operational_week_day_saturday,
                                              reservation_operational_hour_time: [...delivaryTime.reservation_operational_week_day_saturday.reservation_operational_hour_time]
                                            }
                                          })
                                        }}>
                                        <Remove
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={1} style={{ margin: "auto" }}>
                                      <IconButton disabled={!res.reservation_operational_hour_starts_from || !res.reservation_operational_hour_ends_at
                                        || (moment(res.reservation_operational_hour_ends_at).format('LT') === "11:59 PM" && moment(res.reservation_operational_hour_starts_from).format('LT') === "12:00 AM")}
                                        onClick={() =>
                                          setdelivaryTime({
                                            ...delivaryTime,
                                            reservation_operational_week_day_saturday: {
                                              ...delivaryTime.reservation_operational_week_day_saturday,
                                              reservation_operational_hour_time:
                                                [...delivaryTime.reservation_operational_week_day_saturday.reservation_operational_hour_time,
                                                {
                                                  reservation_operational_hour_starts_from: null, reservation_operational_hour_ends_at: null,
                                                  // on: null, off: null
                                                }
                                                ]
                                            }
                                          })}>
                                        <Add
                                          style={{ background: colors.red[400], borderRadius: "50%" }}
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <div style={{ padding: "15px", display: "flex", justifyContent: "center" }}>
                        <Button variant="contained" style={{ "color": "#fff" }} color="primary" startIcon={<Check />} type="submit">
                          Save
                        </Button>
                      </div>
                    </form>
                  </TabPanel>

                </Paper>
                <Snackbar open={state.operationalhours.status}
                  onClose={state.operationalhours.status}>
                  <Alert onClose={!state.operationalhours.status} severity={state.operationalhours.status}>
                    {state.operationalhours.message}
                  </Alert>
                </Snackbar>
              </div> : <div className="loaderBg">
                <CircularProgress color="secondary" /></div>

          }
        </div >
      }
    </>);
};
