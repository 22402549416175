import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  colors,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
  Breadcrumbs,
  Link,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Delete, FilterList, ChevronRight } from "@material-ui/icons";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import { getUserDataAll } from "../../redux/user/userActions";
import {
  addUserToCustomerGroup,
  removeUserFromCustomerGroup,
} from "../../redux/CustomerGroup/CustomerGroup.action";

export const CustomerList = ({ location, match }) => {
  const dispatch = useDispatch();
  const userList = useSelector((state) => state?.user?.userList);
  const addRemove = useSelector((state) => state?.customerGroup);
  const [selectUsers, setselectUsers] = useState([]);
  const [openSnakbar, setOpenSnakbar] = useState(false);
  const id = match.params.id;

  console.log(addRemove);

  const result =
    location?.state?.type === "add"
      ? userList?.filter((el) => {
        return location?.state?.customerGroup?.indexOf(el?._id) === -1;
      })
      : userList?.filter((el) => {
        return location?.state?.customerGroup?.indexOf(el?._id) !== -1;
      });

  useEffect(() => {
    dispatch(getUserDataAll());
    // if(addRemove?.status === 'success'){
    //     setOpenSnakbar(true)
    // }else if(addRemove?.status === 'fail'){
    //     setOpenSnakbar(true)
    // }
  }, [dispatch, addRemove]);

  const handleAdd = () => {
    try {
      selectUsers.map((item) =>
        dispatch(addUserToCustomerGroup({
          customer_group_id: location?.state?.groupId,
          user_id: item,
        }))
      );
      setOpenSnakbar(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemove = () => {
    try {
      selectUsers.map((item) =>
        dispatch(removeUserFromCustomerGroup({
          customer_group_id: location?.state?.groupId,
          user_id: item,
        }))
      );
      setOpenSnakbar(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
        paddingLeft: 115,
        paddingRight: 15,
        paddingTop: 15,
      }}
    >
      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={openSnakbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnakbar(false)}
      >
        <Alert
          onClose={() => setOpenSnakbar(false)}
          severity={addRemove?.status === "success" ? "success" : "error"}
        >
          {addRemove?.message}
        </Alert>
      </Snackbar>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRight style={{ fontSize: 15 }} />}
      >
        <Link color="inherit" href="/" style={{ fontSize: 15 }}>
          Home
        </Link>
        <Link
          color="inherit"
          href={`/${id}/users-basic/customer-group`}
          style={{ fontSize: 15 }}
        >
          Customer Group
        </Link>
        <Typography
          color="textPrimary"
          style={{ fontSize: 15, color: "var(--orange)" }}
        >
          Customer List
        </Typography>
      </Breadcrumbs>
      <Paper style={{ padding: "2rem", marginTop: "2rem" }}>
        <Typography>View Customer</Typography>
        <Divider style={{ margin: "1rem 0" }} />
        {/* <ButtonGroup variant='outlined' color='primary'>
                    <Button variant={ allCustomer ? 'contained' : 'outlined' } onClick={() => setAllCustomer(true)} >All Customers</Button>
                    <Button variant={ allCustomer ? 'outlined' : 'contained' } onClick={() => setAllCustomer(false)}>Custom Views</Button>
                </ButtonGroup> */}
        <Box display="flex" alignItems="center" marginTop="2rem">
          {location?.state?.type === "add" ? (
            <Button
              className="button"
              style={{ marginRight: "2rem" }}
              disabled={
                selectUsers.length > 0 && location?.state?.type === "add"
                  ? false
                  : true
              }
              onClick={() => {
                handleAdd();
              }}
            >
              Add
            </Button>
          ) : (
            location?.state?.type === "remove" && (
              <Button
                className="button"
                style={{ marginRight: "2rem" }}
                disabled={
                  selectUsers.length > 0 && location?.state?.type === "remove"
                    ? false
                    : true
                }
                onClick={() => {
                  handleRemove();
                }}
              >
                <Delete />
              </Button>
            )
          )}
          <Button className="button" style={{ marginRight: "2rem" }}>
            Export All Customers
          </Button>
          <TextField variant="outlined" size="small" />
          <Button className="button" style={{ marginRight: "2rem" }}>
            <FilterList />
          </Button>
          <Button className="button">Search</Button>
        </Box>
        <Table style={{ marginTop: "2rem" }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setselectUsers([]);
                      result?.map((item) =>
                        setselectUsers([...selectUsers, item._id])
                      );
                    } else if (!e.target.checked) {
                      setselectUsers([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone(Optional)</TableCell>
              <TableCell>Store Credit</TableCell>
              <TableCell>Joined</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result?.map((item) => (
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={selectUsers.includes(item._id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setselectUsers([...selectUsers, item._id]);
                      } else if (!e.target.checked) {
                        setselectUsers(
                          selectUsers.filter((i) => i !== item._id)
                        );
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  {item.first_name} {item.last_name}
                </TableCell>
                <TableCell>{item.email !== null && item.email}</TableCell>
                <TableCell>{item.phone_number}</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ marginRight: "1rem" }}
                    />
                    <Button className="button">save</Button>
                  </Box>
                </TableCell>
                <TableCell>
                  {moment(item.createdAt).format("DD MM YYYY hh:mm A")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};
