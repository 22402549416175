import {GET_ADMINISTATOR_DATA_BY_ID, HANDLE_ADMINISTATOR_FALIOUR,HANDLE_ADMINISTATOR_FORM_DATA, 
    HANDLE_ADMINISTATOR_REQUEST, HANDLE_ADMINISTATOR_SUCCESS} from './administratorTypes'
import { deleteData, fetchData, postData, updateData } from "../../Api/api";

export const fetchAdministratorRequest = () => {
    return {
        type: HANDLE_ADMINISTATOR_REQUEST
    }
}

export const fetchAdministratorSucess = (data) => {
    return {
        type: HANDLE_ADMINISTATOR_SUCCESS,
        payload: data
    }
}

export const fetchAdministratorSucessById = (data) => {
    return {
        type: GET_ADMINISTATOR_DATA_BY_ID,
        payload: data
    }
}

export const fetchAdministratorFaliour = (data) => {
    return {
        type: HANDLE_ADMINISTATOR_FALIOUR,
        payload: data
    }
}

export const handleAdministratorData = (data) => {
    return {
        type: HANDLE_ADMINISTATOR_FORM_DATA,
        payload: data
    }
}

export const getAdministrator = (endpoint = `get_all_administrator?page=1&limit=10`) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        fetchData(endpoint).then(
            response => dispatch(fetchAdministratorSucess(response.data.response))
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}

export const getAdministratorById = (id) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        fetchData(`get_Administrator/${id}`).then(
            response => dispatch(fetchAdministratorSucessById(response.data.response))
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}

export const postAdministrator = (body) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        postData(`add_administrator`, body).then(
            response => {
                dispatch(handleAdministratorData(response.data))
                response.data.status === 'success' && dispatch(getAdministrator(`get_all_adminstrator?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}

export const updateAdministrator = (body) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        updateData(`update_administrator`, body).then(
            response => {
                dispatch(handleAdministratorData(response.data))
                response.data.status === 'success' && dispatch(getAdministrator(`get_all_Administrator`))
            }
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}

export const deleteAdministrator = (body) => {
    return (dispatch) => {
        dispatch(fetchAdministratorRequest())
        deleteData(`delete_administrator`, {_id: body._id}).then(
            response => {
                dispatch(handleAdministratorData(response.data))
                response.data.status === 'success' && dispatch(getAdministrator(`get_all_Administrator`))
            }
        ).catch(
            error => dispatch(fetchAdministratorFaliour(error.message))
        )
    }
}