import { FETCH_CURRENCY } from './currency.type'

export const currencyReducer = (state = [], action) => {
   switch(action.type){
        case FETCH_CURRENCY:
            return{
                ...state,
                data: action.payload
            }
        default:
           return state
   }
}