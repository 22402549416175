import React,{ useState,useEffect } from 'react'

import { Box, Breadcrumbs, Button, colors, Divider, Link, Paper, Table, TableBody, SwipeableDrawer, List, ListItem,
     TableCell, TableContainer, TableHead, TableRow, TextField, Typography,  makeStyles, IconButton
    } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ChevronRight, Autorenew, Search,ChevronLeft,RadioButtonUnchecked } from '@material-ui/icons'

import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { useHistory } from "react-router-dom";
import clsx from "clsx";


  const drawerWidth = 220;
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      marginLeft: 100,
    },
    drawerPaper: {
      width: drawerWidth,
      marginLeft: 100,
      background: "var(--light-black)",
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
  }));


export const Report = ({match}) => {
    const [autoCompleteOptions,
        //  setautoCompleteOptions
    ] = useState(['some',
    'SomeOne',
   ])

   const [value, setValue] = useState('')
   const history = useHistory();
    const [drawerOpen, setdrawerOpen] = useState(true);
    const [selectState, setselectState] = useState("Promotional Page Setting");
     const classes = useStyles();
     const id = match.params.id;
     useEffect(() => {
        setselectState(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
    }, [window.location.pathname])
   return (
    <div
      style={{
        paddingTop: "10px",
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            SEO & ANALYTICS
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "report"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/report`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Report</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "seo"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/report/seo`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>SEO</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div
        style={{ paddingLeft: 20, paddingRight: 20 }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            Social Media Login
          </Typography>
        </Breadcrumbs>
        <Paper style={{ padding: "2rem", margin: "15px auto 5rem auto", width: "100%" }}>
                  <Box padding='1rem' border='1px solid grey' borderRadius='5px'  >
                      <Box display='flex' width='100%'  >
                      <Autocomplete
                          value={value}
                          onChange={(event, newValue) => {
                              setValue(newValue)
                          }}
                          options={autoCompleteOptions}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          renderOption={(option) => option}
                          style={{ width: '50%', marginBottom: '1.5rem', marginRight: '2rem' }}
                          freeSolo
                          renderInput={(params) => (
                              <TextField {...params} label="Select a Report" variant="outlined" size="small"
                                   />
                          )}
                      />
                      <Autocomplete
                          value={value}
                          onChange={(event, newValue) => {
                              setValue(newValue)
                          }}
                          options={autoCompleteOptions}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          renderOption={(option) => option}
                          style={{ width: '50%', marginBottom: '1.5rem' }}
                          freeSolo
                          renderInput={(params) => (
                              <TextField {...params} label="Select a Branch" variant="outlined" size="small"
                                   />
                          )}
                      />
                      </Box>
                      <Box display='flex' width='100%' marginTop='2rem' >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label="Start Date"
                              required
                              style={{ width: '50%', marginRight: '2rem' }}
                              // value={res.starts_from}
                              // onChange={date => {
                              // res.starts_from = date
                              // setcreateItem({ ...createItem, item_start_and_end_time: [...createItem.item_start_and_end_time] })
                              // }}
                              KeyboardButtonProps={{
                              "aria-label": "change time",
                              }}
                          />
                          <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label="End Date"
                              required
                              style={{ width: '50%' }}
                              // value={res.starts_from}
                              // onChange={date => {
                              // res.starts_from = date
                              // setcreateItem({ ...createItem, item_start_and_end_time: [...createItem.item_start_and_end_time] })
                              // }}
                              KeyboardButtonProps={{
                              "aria-label": "change time",
                              }}
                          />
                      </MuiPickersUtilsProvider>
                      </Box>
                      <Box display='flex' alignItems='center' justifyContent='space-between' marginTop='2rem' >
                          <Button className='button' startIcon={<Autorenew />} >reset</Button>
                          <Button className='button' startIcon={<Search />}>filter</Button>
                      </Box>
                  </Box>
                  <Box padding='1rem' border='1px solid grey' borderRadius='5px' marginTop='2rem' >
                      <Typography variant='h6' >Orders per Hour</Typography>
                      <Divider style={{ margin: '5px 0' }} />
                      <Box display='flex' >
                          <Button className="button" style={{ marginRight: '2rem' }} >download to jpg</Button>
                          <Button className="button" style={{ marginRight: '2rem' }}>download to png</Button>
                          <Button className="button" >export to excel</Button>
                      </Box>
                          <TableContainer component={Paper} style={{ marginTop: '2rem' }} >
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell align='center'>Hour</TableCell>
                                          <TableCell align='center'>Total Orders</TableCell>
                                          <TableCell align='center'>Total Sales</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      <TableRow>
                                          <TableCell align='center' >00:00</TableCell>
                                          <TableCell align='center'>2</TableCell>
                                          <TableCell align='center'>RD$ 288.15</TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell align='center'>00:00</TableCell>
                                          <TableCell align='center'>2</TableCell>
                                          <TableCell align='center'>RD$ 288.15</TableCell>
                                      </TableRow>
                                  </TableBody>
                              </Table>
                          </TableContainer>
                  </Box>
                  <Box padding='1rem' border='1px solid grey' borderRadius='5px' marginTop='2rem' >
                      <Typography variant='h6' >Orders per Day</Typography>
                      <Divider style={{ margin: '5px 0' }} />
                      <Box display='flex' >
                          <Button className="button" style={{ marginRight: '2rem' }} >download to jpg</Button>
                          <Button className="button" style={{ marginRight: '2rem' }}>download to png</Button>
                          <Button className="button" >export to excel</Button>
                      </Box>
                          <TableContainer component={Paper} style={{ marginTop: '2rem' }} >
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell align='center'>Year</TableCell>
                                          <TableCell align='center'>Month</TableCell>
                                          <TableCell align='center'>Day</TableCell>
                                          <TableCell align='center'>Total Orders</TableCell>
                                          <TableCell align='center'>Total Sales</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      <TableRow>
                                          <TableCell align='center' >2020</TableCell>
                                          <TableCell align='center' >05</TableCell>
                                          <TableCell align='center' >15</TableCell>
                                          <TableCell align='center'>4</TableCell>
                                          <TableCell align='center'>RD$ 288.15</TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell align='center' >2020</TableCell>
                                          <TableCell align='center' >05</TableCell>
                                          <TableCell align='center' >15</TableCell>
                                          <TableCell align='center'>4</TableCell>
                                          <TableCell align='center'>RD$ 288.15</TableCell>
                                      </TableRow>
                                  </TableBody>
                              </Table>
                          </TableContainer>
                  </Box>
                  <Box padding='1rem' border='1px solid grey' borderRadius='5px' marginTop='2rem' >
                      <Typography variant='h6' >Orders per Month</Typography>
                      <Divider style={{ margin: '5px 0' }} />
                      <Box display='flex' >
                          <Button className="button" style={{ marginRight: '2rem' }} >download to jpg</Button>
                          <Button className="button" style={{ marginRight: '2rem' }}>download to png</Button>
                          <Button className="button" >export to excel</Button>
                      </Box>
                          <TableContainer component={Paper} style={{ marginTop: '2rem' }} >
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell align='center'>Year</TableCell>
                                          <TableCell align='center'>Month</TableCell>
                                          <TableCell align='center'>Total Orders</TableCell>
                                          <TableCell align='center'>Total Sales</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      <TableRow>
                                          <TableCell align='center' >2020</TableCell>
                                          <TableCell align='center' >05</TableCell>
                                          <TableCell align='center'>4</TableCell>
                                          <TableCell align='center'>RD$ 288.15</TableCell>
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align='center' >2020</TableCell>
                                          <TableCell align='center' >05</TableCell>
                                          <TableCell align='center'>4</TableCell>
                                          <TableCell align='center'>RD$ 288.15</TableCell>
                                      </TableRow>
                                  </TableBody>
                              </Table>
                          </TableContainer>
                  </Box>
                  <Box padding='1rem' border='1px solid grey' borderRadius='5px' marginTop='2rem' >
                      <Typography variant='h6' >Most Selling Branches</Typography>
                      <Divider style={{ margin: '5px 0' }} />
                      <Box display='flex' >
                          <Button className="button" style={{ marginRight: '2rem' }} >download to jpg</Button>
                          <Button className="button" style={{ marginRight: '2rem' }}>download to png</Button>
                          <Button className="button" >export to excel</Button>
                      </Box>
                          <TableContainer component={Paper} style={{ marginTop: '2rem' }} >
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell align='center'>Name</TableCell>
                                          <TableCell align='center'>Total Orders</TableCell>
                                          <TableCell align='center'>Total Sales</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      <TableRow>
                                          <TableCell align='center' >Ontabee</TableCell>
                                          <TableCell align='center'>2</TableCell>
                                          <TableCell align='center'>RD$ 288.15</TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell align='center'>test</TableCell>
                                          <TableCell align='center'>2</TableCell>
                                          <TableCell align='center'>RD$ 288.15</TableCell>
                                      </TableRow>
                                  </TableBody>
                              </Table>
                          </TableContainer>
                  </Box>
                  <Box padding='1rem' border='1px solid grey' borderRadius='5px' marginTop='2rem' >
                      <Typography variant='h6' >Delivery Time Based Report</Typography>
                      <Divider style={{ margin: '5px 0' }} />
                      <Box display='flex' >
                          <Button className="button" style={{ marginRight: '2rem' }} >download to jpg</Button>
                          <Button className="button" style={{ marginRight: '2rem' }}>download to png</Button>
                          <Button className="button" >export to excel</Button>
                      </Box>
                          <TableContainer component={Paper} style={{ marginTop: '2rem' }} >
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell align='center'>Branch</TableCell>
                                          <TableCell align='center'>Average of Delivery Time</TableCell>
                                          <TableCell align='center'>Sum of Order</TableCell>
                                          <TableCell align='center'>Sum of Order &#60; 30</TableCell>
                                          <TableCell align='center'>Sum of Order &#62; 45</TableCell>
                                          <TableCell align='center'>% &#60; 30 min</TableCell>
                                          <TableCell align='center'>% &#62; 45 min</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      <TableRow>
                                          <TableCell align='center' >Ontabee</TableCell>
                                          <TableCell align='center'>2</TableCell>
                                          <TableCell align='center'>87</TableCell>
                                          <TableCell align='center'>41</TableCell>
                                          <TableCell align='center'>43</TableCell>
                                          <TableCell align='center'>47.13%</TableCell>
                                          <TableCell align='center'>49.43%</TableCell>
                                      </TableRow>
                                      <TableRow>
                                      <TableCell align='center' >Ontabee</TableCell>
                                          <TableCell align='center'>2</TableCell>
                                          <TableCell align='center'>87</TableCell>
                                          <TableCell align='center'>41</TableCell>
                                          <TableCell align='center'>43</TableCell>
                                          <TableCell align='center'>47.13%</TableCell>
                                          <TableCell align='center'>49.43%</TableCell>
                                      </TableRow>
                                  </TableBody>
                              </Table>
                          </TableContainer>
                  </Box>
              </Paper>
          </div>
    </div>
  );
    
}
  