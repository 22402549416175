import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  colors,
  Typography,
  Link,
  Box,
  Button,
  Paper,
  Divider,
  FormControl,
  InputLabel,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Checkbox,
  FormGroup,
  TextField,
  Grid,
  IconButton,
  OutlinedInput,
  Container,
  Snackbar,
  Menu,
  MenuItem,
  ListItem,
  List,
  SwipeableDrawer,
} from "@material-ui/core";
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab";
import {
  AddCircle,
  Check,
  ChevronLeft,
  ChevronRight,
  CloudUpload,
  RemoveCircle,
  ExpandMore,
  Cancel,
  ArrowDropDown,
  RadioButtonUnchecked,
  OpenWith,
} from "@material-ui/icons";
import {
  postItemData,
  addItemImage,
  deleteItemImage,
  updateItem,
  getAllCatagory,
  getInGrientDataAll,
} from "../../redux";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import clsx from "clsx";

//Components
import { useSelector, useDispatch } from "react-redux";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { useCurrency } from "../../hooks/useCurrency";
import { getmenutime } from "../../redux/menutime/menutimeActions";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  margin: {
    margin: theme.spacing(1),
  },
  editorPrent: {
    position: "relative",
    border: "1px solid rgb(224, 224, 224)",
    padding: "10px",
    borderRadius: "5px",
    "& > .makeStyles-editorPrent-45": {
      borderColor: "blue !important",
    },
  },
  label: {
    position: "absolute",
    top: -12,
    padding: "0 5px",
    backgroundColor: "white",
    fontSize: "13px",
    color: "var(--orange)",
  },
  input: {
    display: "none",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CreateItem = ({ location, match }) => {
  const currency = useCurrency();
  const state = useSelector((state) => state);
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("itemsetup");
  const id = match.params.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const [discountTypes, setdiscountTypes] = useState(null);
  const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const classes = useStyles();
  const [createItem, setcreateItem] = useState({
    branch_id: window.location.pathname.split("/")[1],
    main_category_id: "",
    sub_category_id: "",
    ingredient_id: [],
    ingredient_list: [],
    item_tax_code: "",
    item_name: "",
    item_price: 0,
    item_discount: 0,
    item_discount_type: "%",
    item_mrp: 0,
    item_short_description: "",
    item_long_description: "",
    item_available_in_week_days: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    item_start_and_end_time: [{ starts_from: null, ends_at: null }],
    breakfast: {
      from: null,
      to: null,
      status: false
    },
    brunch: {
      from: null,
      to: null,
      status: false
    },
    lunch: {
      from: null,
      to: null,
      status: false
    },
    supper: {
      from: null,
      to: null,
      status: false
    },
    dinner: {
      from: null,
      to: null,
      status: false
    },
    item_available_for_pre_order: false,
    item_available_start_date: null,
    item_available_end_date: null,
    item_total_calories: 0,
    item_image: [],
    stock_item_quantity: 0,
    item_stock_order_quantity_is_unlimited: false,
    item_stock_order_quantity: 0,
    item_low_stock_alert_quantity: 0,
    item_low_stock_alert: true,
    item_specification_non_veg: false,
    item_specification_vegitable: false,
    item_specification_breakfast: false,
    item_specification_lunch: false,
    item_specification_dinner: false,
    item_specification_indian: false,
    item_specification_chinese: false,
    item_specification_continental: false,
    item_specification_all: false,
    item_specification_starter: false,
    item_specification_main_course: false,
    item_specification_main_and_starter: false,
    time_to_ready_the_dish: 0,
    status: true,
    active: "true",
    item_cover_image: "",
  });
  const [specificationModal, setspecificationModal] = useState(false);
  const [opencatagory, setopencatagory] = useState(false);
  const [weekdayModel, setweekdayModel] = useState(false);

  useEffect(() => {
    dispatch(getmenutime())
  }, [dispatch])

  useEffect(() => {
    if (location.state) {
      setcreateItem({
        ...createItem,
        ...location.state,
        ingredient_list: location.state.ingredient_id
          ? location.state.ingredient_id
          : [],
        ingredient_id: [],
        active: location.state.status && location.state.status.toString(),
      });
      if (location.state.item_short_description) {
        const contentBlock1 = htmlToDraft(
          location.state.item_short_description
        );
        if (contentBlock1) {
          const contentState1 = ContentState.createFromBlockArray(
            contentBlock1.contentBlocks
          );
          const editorState1 = EditorState.createWithContent(contentState1);
          setEditorState1(editorState1);
        }
      }
      if (location.state.item_long_description) {
        const contentBlock2 = htmlToDraft(location.state.item_long_description);
        if (contentBlock2) {
          const contentState2 = ContentState.createFromBlockArray(
            contentBlock2.contentBlocks
          );
          const editorState2 = EditorState.createWithContent(contentState2);
          setEditorState2(editorState2);
        }
      }
    }
  }, [location]);
  useEffect(() => {
    if (state.item.status === "success") {
      setEditorState1(EditorState.createEmpty());
      setEditorState2(EditorState.createEmpty());
      setcreateItem({
        branch_id: window.location.pathname.split("/")[1],
        main_category_id: "",
        sub_category_id: "",
        ingredient_id: [],
        ingredient_list: [],
        item_tax_code: "",
        item_name: "",
        item_price: 0,
        item_discount: 0,
        item_discount_type: "%",
        item_mrp: 0,
        item_short_description: "",
        item_long_description: "",
        item_available_in_week_days: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
        item_start_and_end_time: [{ starts_from: null, ends_at: null }],
        breakfast: {
          from: null,
          to: null,
          status: false
        },
        brunch: {
          from: null,
          to: null,
          status: false
        },
        lunch: {
          from: null,
          to: null,
          status: false
        },
        supper: {
          from: null,
          to: null,
          status: false
        },
        dinner: {
          from: null,
          to: null,
          status: false
        },
        item_available_for_pre_order: false,
        item_available_start_date: null,
        item_available_end_date: null,
        item_total_calories: 0,
        item_image: [],
        stock_item_quantity: 0,
        item_stock_order_quantity_is_unlimited: false,
        item_stock_order_quantity: 0,
        item_low_stock_alert_quantity: 0,
        item_low_stock_alert: true,
        item_specification_non_veg: false,
        item_specification_vegitable: false,
        item_specification_breakfast: false,
        item_specification_lunch: false,
        item_specification_dinner: false,
        item_specification_indian: false,
        item_specification_chinese: false,
        item_specification_continental: false,
        item_specification_all: false,
        item_specification_starter: false,
        item_specification_main_course: false,
        item_specification_main_and_starter: false,
        time_to_ready_the_dish: 0,
        status: true,
        active: "true",
        item_cover_image: "",
      });
      location &&
        location.state &&
        setTimeout(() => {
          history.push(
            `/${state.login.data.adminstrator_branch_id}/menu-setup/item-setup`
          );
          // handleActive();
        }, 1000);
    } else if (state.item.image_link) {
      setcreateItem({
        ...createItem,
        item_image: [
          ...createItem.item_image,
          { image: state.item.image_link },
        ],
      });
    }
  }, [state?.item]);

  useEffect(() => {
    const array = []
    createItem.ingredient_list.map((res) => {
      array.push(res._id)
      setcreateItem({
        ...createItem,
        ingredient_id: array,
      })
    }
    );
  }, [createItem?.ingredient_list]);

  useEffect(() => {
    !createItem.item_cover_image &&
      createItem?.item_image?.length > 0 &&
      createItem?.item_image?.[0] &&
      setcreateItem({
        ...createItem,
        item_cover_image: createItem?.item_image[0].image,
      });
  }, [createItem?.item_image]);

  useEffect(() => {
    dispatch(getInGrientDataAll());
    dispatch(getAllCatagory());
  }, []);

  const catagoryTree = () => {
    return (
      <Box
        width="100%"
        height="60vh"
        bgcolor="#ffffff"
        position="absolute"
        top="20%"
        left="20%"
        padding="2rem"
        overflow="scroll"
        style={{ outline: "none" }}
      >
        <Typography variant="h6">Category</Typography>
        <Divider style={{ margin: "3% 0" }} />
        {state?.catagory?.data?.map((res) => (
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
          >
            {res.sub_category && res.sub_category.length > 0 ? (
              <TreeItem nodeId={res._id} label={res.category_name}>
                {res.sub_category.map((sub_res) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={res._id}
                          checked={sub_res._id === createItem.sub_category_id}
                          // disabled={
                          //   createItem.main_category_id &&
                          //   sub_res._id !== createItem.sub_category_id
                          // }
                          onChange={(e) =>
                            e.target.checked
                              ? setcreateItem({
                                ...createItem,
                                main_category_id: res._id,
                                sub_category_id: sub_res._id,
                              })
                              : setcreateItem({
                                ...createItem,
                                main_category_id: "",
                                sub_category_id: "",
                              })
                          }
                        />
                      }
                      label={sub_res.category_name}
                    />
                  </FormGroup>
                ))}
              </TreeItem>
            ) : (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={res._id}
                      checked={res._id === createItem.main_category_id}
                      // disabled={
                      //   createItem.main_category_id &&
                      //   res._id !== createItem.main_category_id
                      // }
                      onChange={(e) =>
                        e.target.checked
                          ? setcreateItem({
                            ...createItem,
                            main_category_id: res._id,
                            sub_category_id: "",
                          })
                          : setcreateItem({
                            ...createItem,
                            main_category_id: "",
                            sub_category_id: "",
                          })
                      }
                    />
                  }
                  label={res.category_name}
                />
              </FormGroup>
            )}
          </TreeView>
        ))}
      </Box>
    );
  };

  const itemSpecifications = () => {
    return (
      <Box
        width="60%"
        bgcolor="#ffffff"
        position="absolute"
        top="30%"
        left="20%"
        padding="2rem"
      >
        <Typography variant="h6">Item Specifications</Typography>
        <Divider style={{ margin: "3% 0" }} />
        <FormGroup row>
          <FormControlLabel
            label="Non Veg"
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_specification_non_veg}
                name="item_specification_non_veg"
                onChange={(e) =>
                  setcreateItem({
                    ...createItem,
                    item_specification_non_veg: e.target.checked,
                    item_specification_vegitable: false
                  })
                }
              />
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="item_specification_vegitable"
                checked={createItem.item_specification_vegitable}
                onChange={(e) =>
                  setcreateItem({
                    ...createItem,
                    item_specification_vegitable: e.target.checked,
                    item_specification_non_veg: false
                  })
                }
              />
            }
            label="Veg"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_specification_indian}
                name="item_specification_indian"
                onChange={(e) =>
                  setcreateItem({
                    ...createItem,
                    item_specification_indian: e.target.checked,
                  })
                }
              />
            }
            label="Indian"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_specification_chinese}
                name="item_specification_chinese"
                onChange={(e) =>
                  setcreateItem({
                    ...createItem,
                    item_specification_chinese: e.target.checked,
                  })
                }
              />
            }
            label="Chinese"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_specification_continental}
                name="item_specification_continental"
                onChange={(e) =>
                  setcreateItem({
                    ...createItem,
                    item_specification_continental: e.target.checked,
                  })
                }
              />
            }
            label="Continental"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_specification_all}
                name="item_specification_all"
                onChange={(e) =>
                  setcreateItem({
                    ...createItem,
                    item_specification_indian: e.target.checked,
                    item_specification_chinese: e.target.checked,
                    item_specification_continental: e.target.checked,
                    item_specification_all: e.target.checked,
                  })
                }
              />
            }
            label="All"
          />
        </FormGroup>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_specification_starter}
                name="item_specification_starter"
                onChange={(e) =>
                  setcreateItem({
                    ...createItem,
                    item_specification_starter: e.target.checked,
                  })
                }
              />
            }
            label="Starter"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_specification_main_course}
                name="item_specification_main_course"
                onChange={(e) =>
                  setcreateItem({
                    ...createItem,
                    item_specification_main_course: e.target.checked,
                  })
                }
              />
            }
            label="Main Course"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_specification_main_and_starter}
                name="item_specification_main_and_starter"
                onChange={(e) =>
                  setcreateItem({
                    ...createItem,
                    item_specification_starter: e.target.checked,
                    item_specification_main_course: e.target.checked,
                    item_specification_main_and_starter: e.target.checked,
                  })
                }
              />
            }
            label="Main & Starter"
          />
        </FormGroup>
      </Box>
    );
  };

  const avaliableDays = () => {
    return (
      <Box
        width="60%"
        bgcolor="#ffffff"
        position="absolute"
        top="30%"
        left="20%"
        padding="2rem"
      >
        <Typography variant="h6">Avaliable On WeekDays</Typography>
        <Divider style={{ margin: "3% 0" }} />
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_available_in_week_days.monday}
                name="monday"
              />
            }
            label="Monday"
            onChange={(e) =>
              setcreateItem({
                ...createItem,
                item_available_in_week_days: {
                  ...createItem.item_available_in_week_days,
                  monday: e.target.checked,
                },
              })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_available_in_week_days.tuesday}
                name="tuesday"
              />
            }
            label="Tuesday"
            onChange={(e) =>
              setcreateItem({
                ...createItem,
                item_available_in_week_days: {
                  ...createItem.item_available_in_week_days,
                  tuesday: e.target.checked,
                },
              })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_available_in_week_days.wednesday}
                name="wednesday"
              />
            }
            label="Wednesday"
            onChange={(e) =>
              setcreateItem({
                ...createItem,
                item_available_in_week_days: {
                  ...createItem.item_available_in_week_days,
                  wednesday: e.target.checked,
                },
              })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_available_in_week_days.thursday}
                name="thursday"
              />
            }
            label="Thursday"
            onChange={(e) =>
              setcreateItem({
                ...createItem,
                item_available_in_week_days: {
                  ...createItem.item_available_in_week_days,
                  thursday: e.target.checked,
                },
              })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_available_in_week_days.friday}
                name="friday"
              />
            }
            label="Friday"
            onChange={(e) =>
              setcreateItem({
                ...createItem,
                item_available_in_week_days: {
                  ...createItem.item_available_in_week_days,
                  friday: e.target.checked,
                },
              })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_available_in_week_days.saturday}
                name="saturday"
              />
            }
            label="Saturday"
            onChange={(e) =>
              setcreateItem({
                ...createItem,
                item_available_in_week_days: {
                  ...createItem.item_available_in_week_days,
                  saturday: e.target.checked,
                },
              })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={createItem.item_available_in_week_days.sunday}
                name="sunday"
              />
            }
            label="Sunday"
            onChange={(e) =>
              setcreateItem({
                ...createItem,
                item_available_in_week_days: {
                  ...createItem.item_available_in_week_days,
                  sunday: e.target.checked,
                },
              })
            }
          />
        </FormGroup>
      </Box>
    );
  };

  return (
    <div
      style={{
        // paddingTop: "8%",
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Menu Setup
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "category" ? "var(--orange)" : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/${id}/menu-setup`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Category</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "itemsetup" ? "var(--orange)" : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/${id}/menu-setup/item-setup`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Items Setup</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "ingredient" ? "var(--orange)" : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/${id}/menu-setup/ingredient`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Ingredient</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div
        style={{ paddingLeft: 15, paddingRight: 15 }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Modal open={opencatagory} onClose={() => setopencatagory(false)}>
          {catagoryTree()}
        </Modal>

        <Modal open={weekdayModel} onClose={() => setweekdayModel(false)}>
          {avaliableDays()}
        </Modal>

        <Modal
          open={specificationModal}
          onClose={() => setspecificationModal(false)}
        >
          {itemSpecifications()}
        </Modal>

        {/* <Header /> */}
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Link
            color="inherit"
            href={`/${id}/menu-setup/item-setup`}
            style={{ fontSize: 15 }}
          >
            Items
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            Create
          </Typography>
        </Breadcrumbs>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="center"
          flexDirection="column"
        >
          <form style={{ width: "65%" }}>
            <Paper style={{ padding: "2rem", marginTop: "5%" }}>
              <Typography variant="h6">Create</Typography>
              <Divider style={{ margin: "20px 0 20px 0" }} />
              <Button
                endIcon={<OpenWith />}
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={() => setopencatagory(true)}
                style={{
                  width: "100%",
                  marginBottom: "1.5rem",
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span
                  className={
                    (createItem.sub_category_id ||
                      createItem.main_category_id) &&
                    classes.label
                  }
                >
                  Add Category
                </span>
                {state?.catagory?.data?.map((res) => (
                  // <span>
                  res._id === createItem.main_category_id ?
                    res.category_name : ''
                  // </span>
                ))}
                {state?.catagory?.data?.map((res) =>
                  res.sub_category.map((sub_cat) => (
                    sub_cat._id === createItem.sub_category_id ?
                      ` >> ${sub_cat.category_name}` : ''
                  ))
                )}
              </Button>

              <TextField
                label="Item Name"
                style={{ marginBottom: "1.5rem" }}
                variant="outlined"
                size="small"
                fullWidth
                value={createItem.item_name}
                onChange={(e) =>
                  setcreateItem({ ...createItem, item_name: e.target.value })
                }
              />

              <TextField variant="outlined" label="Item Short Description"
                rows={2} multiline style={{ marginBottom: "1rem" }} fullWidth
                value={createItem.item_short_description}
                onChange={(e) => {
                  setcreateItem({
                    ...createItem,
                    item_short_description: e.target.value,
                  });
                }}></TextField>

              {/* <div
                className={classes.editorPrent}
                style={{
                  border: "1px solid rgb(224, 224, 224)",
                  minHeight: "5em",
                  padding: "10px",
                  borderRadius: "5px",
                  marginBottom: "1.5rem",
                }}
              >
                <label className={classes.label}>Item Short Description</label>
                <Editor
                  editorState={editorState1}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) => {
                    setEditorState1(e);
                    setcreateItem({
                      ...createItem,
                      item_short_description: draftToHtml(
                        convertToRaw(editorState1.getCurrentContent())
                      ),
                    });
                  }}
                />
              </div> */}

              <TextField variant="outlined" label="Item Long Description"
                rows={4} multiline style={{ marginBottom: "1rem" }} fullWidth
                value={createItem.item_long_description}
                onChange={(e) => {
                  setcreateItem({
                    ...createItem,
                    item_long_description: e.target.value,
                  });
                }}>

              </TextField>

              {/* <div
                className={classes.editorPrent}
                style={{
                  minHeight: "20em",
                  marginBottom: "1.5rem",
                }}
              >
                <label className={classes.label}>Item Long Description</label>
                <Editor
                  editorState={editorState2}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) => {
                    setEditorState2(e);
                    setcreateItem({
                      ...createItem,
                      item_long_description: draftToHtml(
                        convertToRaw(editorState2.getCurrentContent())
                      ),
                    });
                  }}
                />
              </div> */}

              {/* <TextField label="Tax Code" style={{ marginBottom: "1.5rem" }} variant="outlined" size="small" fullWidth /> */}
              {/* {createItem.items_packs.map((res, i) => ( */}
              <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                {/* <Grid item xs>
                  <TextField label="Pack Name" value={createItem} variant="outlined" fullWidth
                    onChange={e => {
                      res.pack_name = e.target.value
                      setcreateItem({ ...createItem, items_packs: [...createItem.items_packs] })
                    }} />
                </Grid> */}
                <Grid item xs>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Price
                    </InputLabel>
                    <OutlinedInput
                      style={{ padding: "0.2px !important" }}
                      type="number"
                      id="outlined-adornment-amount"
                      value={createItem.item_price}
                      onChange={(e) => {
                        createItem.item_price = e.target.value;
                        if (0 > createItem.item_price) {
                          createItem.item_price = "";
                          createItem.item_mrp = "";
                        } else {
                          //for India and Bangladesh only
                          // createItem.item_price.toExponential(2)
                          // createItem.item_price = parseFloat(createItem.item_price)
                          // console.log(createItem.item_price.length)
                          if (createItem.item_price.includes(".")) {
                            createItem.item_price =
                              createItem.item_price.length -
                                createItem.item_price.indexOf(".") >
                                3
                                ? parseFloat(createItem.item_price).toFixed(2)
                                : parseFloat(createItem.item_price);
                          }
                          // console.log(createItem.item_price.length > createItem.item_price.indexOf(".") >= 0 + 2)
                          // createItem.item_price =
                          // parseFloat(createItem.item_price) % 1 === 0 ? parseFloat(createItem.item_price) :
                          // parseFloat(createItem.item_price).toFixed(2)
                          //
                          if (createItem.item_discount) {
                            createItem.item_discount = parseFloat(
                              createItem.item_discount
                            );
                            let new_dis = createItem.item_discount / 100;
                            //for India and Bangladesh only
                            createItem.item_mrp = Math.round(
                              createItem.item_price / (1 - new_dis)
                            );
                            //for another countries
                            // createItem.item_mrp = (createItem.item_price / (1 - new_dis)).toFixed(2)
                          } else {
                            createItem.item_mrp = createItem.item_price
                              ? parseFloat(createItem.item_price)
                              : "";
                          }
                          if (isNaN(createItem.item_mrp)) {
                            createItem.item_price = "";
                            createItem.item_mrp = "";
                          }
                        }
                        setcreateItem({
                          ...createItem,
                          items_packs: parseInt(createItem.items_packs),
                        });
                      }}
                      // onChange={e => {
                      //   createItem.item_price = e.target.value
                      //   setcreateItem({ ...createItem, items_packs: [...createItem.items_packs] })
                      // }}
                      startAdornment={currency}
                      labelWidth={60}
                    />
                  </FormControl>
                  {/* <TextField label="Price" value={createItem.item_price} variant="outlined" size="small" fullWidth
                  onChange={e => {
                    createItem.item_price = e.target.value
                    setcreateItem({ ...createItem, items_packs: [...createItem.items_packs] })
                  }} /> */}
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Discount
                    </InputLabel>
                    <OutlinedInput
                      style={{ padding: "0.2px !important" }}
                      type="number"
                      value={createItem.item_discount}
                      onChange={(e) => {
                        if (createItem.item_discount_type === "%") {
                          setcreateItem({ ...createItem, item_discount: e.target.value })
                          let dis = e.target.value > 0 && e.target.value < 100 ? Math.floor(parseFloat(e.target.value)) : 0
                          setcreateItem({
                            ...createItem, item_discount: e.target.value.length < 3 ? parseFloat(e.target.value)
                              : parseFloat(e.target.value.slice(0, 2)),
                            item_price: e.target.value ? createItem.item_mrp ? (createItem.item_mrp - ((parseFloat(dis) *
                              createItem.item_mrp) / 100)).toFixed(2) : createItem.item_price : createItem.item_mrp
                          })
                        } else {
                          createItem.item_discount = e.target.value
                          if (createItem.item_discount.toString().includes(".")) {
                            createItem.item_discount =
                              createItem.item_discount.toString().length -
                                createItem.item_discount.toString().indexOf(".") >
                                3
                                ? parseFloat(createItem.item_discount).toFixed(2)
                                : parseFloat(createItem.item_discount);
                          }
                          createItem.item_mrp > 0 &&
                            setcreateItem({
                              ...createItem,
                              item_price: (
                                parseFloat(createItem.item_mrp) -
                                parseFloat(createItem.item_discount)).toFixed(2),
                            });
                          createItem.item_price > 0 &&
                            setcreateItem({
                              ...createItem,
                              item_mrp: (
                                parseFloat(createItem.item_price) +
                                parseFloat(
                                  createItem.item_discount
                                )).toFixed(2),
                            });
                        }
                      }}
                      endAdornment={
                        <>
                          <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) => {
                              setdiscountTypes(event.currentTarget);
                            }}
                          >
                            {createItem.item_discount_type}
                            <ArrowDropDown></ArrowDropDown>
                          </Button>
                          <Menu
                            id="simple-menu"
                            anchorEl={discountTypes}
                            keepMounted
                            open={Boolean(discountTypes)}
                            onClose={(event) => {
                              setdiscountTypes(null);
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setdiscountTypes(null);
                                setcreateItem({
                                  ...createItem, item_discount_type: "%",
                                  item_discount: createItem.item_discount.toString().length < 3 ? createItem.item_discount
                                    : parseFloat(createItem.item_discount.toString().slice(0, 2)),
                                  item_price: createItem.item_mrp ? (createItem.item_mrp - ((createItem.item_discount *
                                    createItem.item_mrp) / 100)).toFixed(2) : createItem.item_price
                                })
                              }}
                            >
                              %
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setdiscountTypes(null);
                                setcreateItem({ ...createItem, item_discount_type: "FLAT" })
                                if (createItem.item_discount.toString().includes(".")) {
                                  createItem.item_discount =
                                    createItem.item_discount.toString().length -
                                      createItem.item_discount.toString().indexOf(".") >
                                      3
                                      ? parseFloat(createItem.item_discount).toFixed(2)
                                      : parseFloat(createItem.item_discount);
                                }
                                createItem.item_mrp > 0 &&
                                  setcreateItem({
                                    ...createItem, item_discount_type: "FLAT",
                                    item_price: (
                                      parseFloat(createItem.item_mrp) -
                                      parseFloat(createItem.item_discount)).toFixed(2),
                                  });
                                createItem.item_price > 0 &&
                                  setcreateItem({
                                    ...createItem, item_discount_type: "FLAT",
                                    item_mrp: (
                                      parseFloat(createItem.item_price) +
                                      parseFloat(
                                        createItem.item_discount
                                      )).toFixed(2),
                                  });
                              }}
                            >
                              Flat Rate
                            </MenuItem>
                          </Menu>
                        </>
                      }
                      labelWidth={60}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      MRP
                    </InputLabel>
                    <OutlinedInput
                      style={{ padding: "0.2px !important" }}
                      type="number"
                      id="outlined-adornment-amount"
                      value={createItem.item_mrp}
                      onChange={(e) => {
                        createItem.item_mrp = e.target.value;
                        if (0 > createItem.item_mrp) {
                          createItem.item_mrp = "";
                          createItem.item_price = ""
                        } else {
                          if (createItem.item_mrp.includes(".")) {
                            createItem.item_mrp =
                              createItem.item_mrp.length -
                                createItem.item_mrp.indexOf(".") >
                                3
                                ? parseFloat(createItem.item_mrp).toFixed(2)
                                : parseFloat(createItem.item_mrp);
                          }
                          if (createItem.item_discount) {
                            // createItem.item_mrp = parseFloat(
                            //   createItem.item_mrp
                            // );
                            //for India and Bangladesh only
                            // createItem.item_mrp = Math.round(
                            //   createItem.item_mrp
                            // );
                            //
                            let discount_amount =
                              (createItem.item_discount * createItem.item_mrp) /
                              100;
                            //for India and Bangladesh only
                            // createItem.item_price = Math.round(
                            //   createItem.item_mrp - discount_amount
                            // );
                            //for another countries
                            createItem.item_price = (createItem.item_mrp - discount_amount).toFixed(2)
                          } else {
                            createItem.item_price = createItem.item_mrp ? parseFloat(createItem.item_mrp)
                              : "";
                            //for India and Bangladesh only
                            // createItem.item_mrp = Math.round(
                            //   createItem.item_mrp
                            // );
                          }
                          if (isNaN(createItem.item_mrp)) {
                            createItem.item_price = "";
                            createItem.item_mrp = "";
                          }
                        }
                        setcreateItem({
                          ...createItem,
                          items_packs: parseInt(createItem.items_packs),
                        });
                      }}
                      startAdornment={currency}
                      labelWidth={60}
                    />
                  </FormControl>
                  {/* <TextField label="MRP" value={createItem.item_mrp} variant="outlined" size="small" fullWidth
                  onChange={e => {
                    createItem.item_mrp = e.target.value
                    setcreateItem({ ...createItem, items_packs: [...createItem.items_packs] })
                  }} /> */}
                </Grid>
                {/* <Grid item xs={1}>
                  {i === createItem.items_packs.length - 1 ? (
                    <IconButton onClick={() =>
                      setcreateItem({
                        ...createItem, items_packs: [...createItem.items_packs,
                        { pack_name: '', pack_price: 0, pack_discount: 0, pack_mrp: 0 }]
                      })
                    }><AddCircle /> </IconButton>
                  ) : (
                    <IconButton onClick={() => {
                      createItem.items_packs.splice(i, 1)
                      setcreateItem({ ...createItem, items_packs: [...createItem.items_packs] })
                    }
                    }><RemoveCircle /> </IconButton>
                  )}
                </Grid> */}
              </Grid>
              {/* ))} */}
              {/* <Button
                endIcon={<OpenWith />}
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={() => setweekdayModel(true)}
                style={{
                  width: "100%",
                  marginBottom: "1.5rem",
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span
                  className={
                    (createItem.item_available_in_week_days.monday ||
                      createItem.item_available_in_week_days.tuesday ||
                      createItem.item_available_in_week_days.wednesday ||
                      createItem.item_available_in_week_days.thursday ||
                      createItem.item_available_in_week_days.friday ||
                      createItem.item_available_in_week_days.saturday ||
                      createItem.item_available_in_week_days.sunday) &&
                    classes.label
                  }
                >
                  Avaliable On WeeKDays
                </span>
                {createItem.item_available_in_week_days.monday && "Monday, "}
                {createItem.item_available_in_week_days.tuesday && "Tuesday, "}
                {createItem.item_available_in_week_days.wednesday &&
                  "Wednesday, "}
                {createItem.item_available_in_week_days.thursday &&
                  "Thursday, "}
                {createItem.item_available_in_week_days.friday && "Friday, "}
                {createItem.item_available_in_week_days.saturday &&
                  "Saturday, "}
                {createItem.item_available_in_week_days.sunday && "Sunday"}
              </Button> */}
              {/* {createItem.item_start_and_end_time.map((res, i) => (
                <Grid container spacing={3}>
                  <Grid item xs>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker style={{ marginTop: "0px" }}
                        margin="normal"
                        id="time-picker"
                        label="Avaliable From"
                        required
                        value={res.starts_from}
                        onChange={(date) => {
                          res.starts_from = date;
                          setcreateItem({
                            ...createItem,
                            item_start_and_end_time: [
                              ...createItem.item_start_and_end_time,
                            ],
                          });
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker style={{ marginTop: "0px" }}
                        margin="normal"
                        id="time-picker"
                        label="Avaliable To"
                        required
                        value={res.ends_at}
                        onChange={(date) => {
                          res.ends_at = date;
                          setcreateItem({
                            ...createItem,
                            item_start_and_end_time: [
                              ...createItem.item_start_and_end_time,
                            ],
                          });
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={1} style={{ margin: "auto" }}>
                    {i === createItem.item_start_and_end_time.length - 1 ? (
                      <IconButton
                        onClick={() =>
                          setcreateItem({
                            ...createItem,
                            item_start_and_end_time: [
                              ...createItem.item_start_and_end_time,
                              { starts_from: null, ends_at: null },
                            ],
                          })
                        }
                      >
                        <AddCircle />{" "}
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          createItem.item_start_and_end_time.splice(i, 1);
                          setcreateItem({
                            ...createItem,
                            item_start_and_end_time: [
                              ...createItem.item_start_and_end_time,
                            ],
                          });
                        }}
                      >
                        <RemoveCircle />{" "}
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              ))} */}
              {
                state?.menutime?.data?.breakfast?.status &&
                <Grid container spacing={5}>
                  <Grid item xs style={{ margin: "auto" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={createItem.breakfast.status}
                          onChange={(e) =>
                            setcreateItem({
                              ...createItem,
                              breakfast: {
                                ...createItem.breakfast, status: e.target.checked,
                                from: e.target.checked ? state?.menutime?.data?.breakfast?.from : null,
                                to: e.target.checked ? state?.menutime?.data?.breakfast?.to : null
                              }
                            })
                          }
                          name="breakfast"
                          color="primary"
                        />
                      }
                      label="Breakfast"
                    />
                  </Grid>
                  <Grid item xs>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker style={{ marginTop: "0px" }}
                        margin="normal"
                        id="time-picker"
                        label="Avaliable From"
                        disabled={!createItem.breakfast.status}
                        required={createItem.breakfast.status}
                        value={createItem.breakfast.from}
                        onChange={(date) =>
                          setcreateItem({
                            ...createItem,
                            breakfast: { ...createItem.breakfast, from: date }
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker style={{ marginTop: "0px" }}
                        margin="normal"
                        id="time-picker"
                        label="Avaliable To"
                        disabled={!createItem.breakfast.status}
                        required={createItem.breakfast.status}
                        value={createItem.breakfast.to}
                        onChange={(date) =>
                          setcreateItem({
                            ...createItem,
                            breakfast: { ...createItem.breakfast, to: date }
                          })
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              }
              <Grid container spacing={5}>
                <Grid item xs style={{ margin: "auto" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={createItem.brunch.status}
                        onChange={(e) =>
                          setcreateItem({
                            ...createItem,
                            brunch: {
                              ...createItem.brunch, status: e.target.checked,
                              from: e.target.checked ? state?.menutime?.data?.brunch?.from : null,
                              to: e.target.checked ? state?.menutime?.data?.brunch?.to : null
                            }
                          })
                        }
                        name="brunch"
                        color="primary"
                      />
                    }
                    label="Brunch"
                  />
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker style={{ marginTop: "0px" }}
                      margin="normal"
                      id="time-picker"
                      label="Avaliable From"
                      disabled={!createItem.brunch.status}
                      required={createItem.brunch.status}
                      value={createItem.brunch.from}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          brunch: { ...createItem.brunch, from: date }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker style={{ marginTop: "0px" }}
                      margin="normal"
                      id="time-picker"
                      label="Avaliable To"
                      disabled={!createItem.brunch.status}
                      required={createItem.brunch.status}
                      value={createItem.brunch.to}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          brunch: { ...createItem.brunch, to: date }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs style={{ margin: "auto" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={createItem.lunch.status}
                        onChange={(e) =>
                          setcreateItem({
                            ...createItem,
                            lunch: {
                              ...createItem.lunch, status: e.target.checked,
                              from: e.target.checked ? state?.menutime?.data?.lunch?.from : null,
                              to: e.target.checked ? state?.menutime?.data?.lunch?.to : null
                            }
                          })
                        }
                        name="lunch"
                        color="primary"
                      />
                    }
                    label="Lunch"
                  />
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker style={{ marginTop: "0px" }}
                      margin="normal"
                      id="time-picker"
                      label="Avaliable From"
                      disabled={!createItem.lunch.status}
                      required={createItem.lunch.status}
                      value={createItem.lunch.from}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          lunch: { ...createItem.lunch, from: date }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker style={{ marginTop: "0px" }}
                      margin="normal"
                      id="time-picker"
                      label="Avaliable To"
                      disabled={!createItem.lunch.status}
                      required={createItem.lunch.status}
                      value={createItem.lunch.to}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          lunch: { ...createItem.lunch, to: date }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs style={{ margin: "auto" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={createItem.supper.status}
                        onChange={(e) =>
                          setcreateItem({
                            ...createItem,
                            supper: {
                              ...createItem.supper, status: e.target.checked,
                              from: e.target.checked ? state?.menutime?.data?.supper?.from : null,
                              to: e.target.checked ? state?.menutime?.data?.supper?.to : null
                            }
                          })
                        }
                        name="supper"
                        color="primary"
                      />
                    }
                    label="Supper"
                  />
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker style={{ marginTop: "0px" }}
                      margin="normal"
                      id="time-picker"
                      label="Avaliable From"
                      disabled={!createItem.supper.status}
                      required={createItem.supper.status}
                      value={createItem.supper.from}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          supper: { ...createItem.supper, from: date }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker style={{ marginTop: "0px" }}
                      margin="normal"
                      id="time-picker"
                      label="Avaliable To"
                      disabled={!createItem.supper.status}
                      required={createItem.supper.status}
                      value={createItem.supper.to}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          supper: { ...createItem.supper, to: date }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs style={{ margin: "auto" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={createItem.dinner.status}
                        onChange={(e) =>
                          setcreateItem({
                            ...createItem,
                            dinner: {
                              ...createItem.dinner, status: e.target.checked,
                              from: e.target.checked ? state?.menutime?.data?.dinner?.from : null,
                              to: e.target.checked ? state?.menutime?.data?.dinner?.to : null
                            }
                          })
                        }
                        name="dinner"
                        color="primary"
                      />
                    }
                    label="dinner"
                  />
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker style={{ marginTop: "0px" }}
                      margin="normal"
                      id="time-picker"
                      label="Avaliable From"
                      disabled={!createItem.dinner.status}
                      required={createItem.dinner.status}
                      value={createItem.dinner.from}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          dinner: { ...createItem.dinner, from: date }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker style={{ marginTop: "0px" }}
                      margin="normal"
                      id="time-picker"
                      label="Avaliable To"
                      disabled={!createItem.dinner.status}
                      required={createItem.dinner.status}
                      value={createItem.dinner.to}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          dinner: { ...createItem.dinner, to: date }
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                  <Grid item xs>
                    <KeyboardDatePicker fullWidth
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Starts Date"
                      value={createItem.item_available_start_date}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          item_available_start_date: date,
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <KeyboardDatePicker fullWidth
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Ends Date"
                      value={createItem.item_available_end_date}
                      onChange={(date) =>
                        setcreateItem({
                          ...createItem,
                          item_available_end_date: date,
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
              <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                <Grid item xs>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Order Max Quantity
                    </InputLabel>
                    <OutlinedInput
                      style={{ padding: "0.2px !important" }}
                      type="number"
                      disabled={
                        createItem.item_stock_order_quantity_is_unlimited
                      }
                      id="outlined-adornment-amount"
                      value={createItem.item_stock_order_quantity_is_unlimited ? "" : createItem.item_stock_order_quantity}
                      onChange={(e) =>
                        setcreateItem({
                          ...createItem,
                          item_stock_order_quantity: parseInt(e.target.value),
                        })
                      }
                      endAdornment={
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="item_stock_order_quantity_is_unlimited"
                              checked={
                                createItem.item_stock_order_quantity_is_unlimited
                              }
                              onChange={(e) =>
                                setcreateItem({
                                  ...createItem,
                                  item_stock_order_quantity_is_unlimited:
                                    e.target.checked,
                                  item_stock_order_quantity: e.target.checked ? 1000000000000 : 0,
                                })
                              }
                            />
                          }
                          label="Unlimited"
                        />
                      }
                      labelWidth={60}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Low Stock Alert
                    </InputLabel>
                    <OutlinedInput
                      style={{ padding: "0.2px !important" }}
                      type="number"
                      disabled={!createItem.item_low_stock_alert}
                      id="outlined-adornment-amount"
                      value={createItem.item_low_stock_alert ? createItem.item_low_stock_alert_quantity : ""}
                      onChange={(e) =>
                        setcreateItem({
                          ...createItem,
                          item_low_stock_alert_quantity: parseInt(e.target.value) < 0 ? 0 : parseInt(
                            e.target.value
                          ),
                        })
                      }
                      endAdornment={
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="item_low_stock_alert"
                              checked={createItem.item_low_stock_alert}
                              onChange={(e) =>
                                setcreateItem({
                                  ...createItem,
                                  item_low_stock_alert: e.target.checked,
                                  item_low_stock_alert_quantity: 0,
                                })
                              }
                            />
                          }
                          label={createItem.item_low_stock_alert ? "Yes" : "No"}
                        />
                      }
                      labelWidth={60}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Button
                endIcon={<OpenWith />}
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="outlined"
                onClick={() => setspecificationModal(true)}
                style={{
                  width: "100%",
                  marginBottom: "1.5rem",
                  position: "relative",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span
                  className={
                    (createItem.item_specification_non_veg ||
                      createItem.item_specification_vegitable ||
                      createItem.item_specification_indian ||
                      createItem.item_specification_chinese ||
                      createItem.item_specification_continental ||
                      createItem.item_specification_all ||
                      createItem.item_specification_starter ||
                      createItem.item_specification_main_course ||
                      createItem.item_specification_main_and_starter) &&
                    classes.label
                  }
                >
                  Item Specification --
                </span>
                {createItem.item_specification_non_veg && "Non Veg, "}
                {createItem.item_specification_vegitable && "Veg, "}
                {createItem.item_specification_indian && "Indian, "}
                {createItem.item_specification_chinese && "Chinese, "}
                {createItem.item_specification_continental && "Contential, "}
                {createItem.item_specification_all && "All, "}
                {createItem.item_specification_starter && "Starter, "}
                {createItem.item_specification_main_course && "Main Course, "}
                {createItem.item_specification_main_and_starter &&
                  "Main & Starter "}
              </Button>
              <Autocomplete
                style={{ marginBottom: "1.5rem" }}
                size="small"
                multiple
                id="tags-standard"
                options={state.ingridient.data}
                getOptionLabel={(option) => option.ingredient_name}
                onChange={(e, newValue) =>
                  setcreateItem({ ...createItem, ingredient_list: newValue })
                }
                value={createItem.ingredient_list}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add Ingridient"
                    variant="outlined"
                  />
                )}
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="1.5rem"
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ width: "30%" }}
                >
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Total Calories
                  </InputLabel>
                  <OutlinedInput
                    style={{ padding: "0.2px !important" }}
                    type="number"
                    id="outlined-adornment-amount"
                    value={createItem.item_total_calories}
                    onChange={(e) =>
                      setcreateItem({
                        ...createItem,
                        item_total_calories: parseInt(e.target.value),
                      })
                    }
                    labelWidth={60}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ width: "30%" }}
                >
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Item In Stock
                  </InputLabel>
                  <OutlinedInput
                    style={{ padding: "0.2px !important" }}
                    type="number"
                    id="outlined-adornment-amount"
                    value={createItem.stock_item_quantity}
                    onChange={(e) =>
                      setcreateItem({
                        ...createItem,
                        stock_item_quantity: parseInt(e.target.value),
                      })
                    }
                    // endAdornment={<FormControlLabel control={<Checkbox
                    //     checked={parseInt(createItem.stock_item_quantity) === 1000000000000}
                    //     onChange={(e) => setcreateItem({
                    //       ...createItem,
                    //       stock_item_quantity: e.target.checked && 1000000000000
                    //     })}
                    //   />} label="Unlimited" />}
                    labelWidth={60}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="outlined"
                  style={{ width: "30%" }}
                >
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Time to ready the dish
                  </InputLabel>
                  <OutlinedInput
                    style={{ padding: "0.2px !important" }}
                    type="number"
                    id="outlined-adornment-amount"
                    value={createItem.time_to_ready_the_dish}
                    onChange={(e) =>
                      setcreateItem({
                        ...createItem,
                        time_to_ready_the_dish: parseInt(e.target.value),
                      })
                    }
                    endAdornment="Minutes"
                    labelWidth={100}
                  />
                </FormControl>
                {/* <TextField label="Total Calories" style={{ width: "45%" }} variant="outlined" size="small" value={createItem.item_total_calories}
              onChange={(e) => setcreateItem({ ...createItem, item_total_calories: e.target.value })} />
            <TextField label="Time to ready the dish" style={{ width: "45%" }} variant="outlined" size="small"
            /> */}
              </Box>
              <div
                className={classes.editorPrent}
                style={{
                  minHeight: "150px",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0px",
                }}
              >
                <Box
                  className={classes.editorPrent}
                  style={{ height: "175px", width: "200px", margin: "10px" }}
                >
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files;
                      var filesAmount = e.target.files.length;
                      for (let i = 0; i < filesAmount; i++) {
                        const formData = new FormData();
                        formData.append("item_image", file[i]);
                        dispatch(addItemImage(formData));
                      }
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="default"
                      className="button"
                      startIcon={<CloudUpload />}
                      component="span"
                    >
                      Upload Image
                    </Button>
                  </label>
                  <Typography
                    style={{ marginTop: "5%" }}
                    variant="body1"
                    color="textSecondary"
                  >
                    Size (600 px * 400 px)
                  </Typography>
                </Box>
                {createItem.item_image.map((res, i) => (
                  <div style={{ height: "250px" }}>
                    <Box
                      className={classes.editorPrent}
                      style={{
                        height: "70%",
                        width: "200px",
                        margin: "10px",
                        position: "relative",
                      }}
                    >
                      <IconButton
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => {
                          createItem.item_image.splice(i, 1);
                          dispatch(deleteItemImage(res.image));
                        }}
                      >
                        <Cancel />
                      </IconButton>
                      <img
                        src={res.image}
                        alt="item"
                        width="100%"
                        height="100%"
                      />
                    </Box>
                    <RadioGroup
                      aria-label="quiz"
                      name="quiz"
                      value={createItem.item_cover_image}
                      style={{ marginRight: "10px" }}
                      onChange={(e) =>
                        setcreateItem({
                          ...createItem,
                          item_cover_image: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value={res.image}
                        control={<Radio />}
                        label="Cover Photo"
                      />
                    </RadioGroup>
                  </div>
                ))}
              </div>
              <FormControl fullWidth style={{ margin: "3% 0" }}>
                <RadioGroup
                  row
                  name="item_status"
                  value={createItem.active}
                  onChange={(e) =>
                    setcreateItem({
                      ...createItem,
                      active: e.target.value,
                      status: e.target.value === "true" ? true : false,
                    })
                  }
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="InActive"
                  />
                </RadioGroup>
              </FormControl>
            </Paper>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width={200}
              margin="auto"
              marginTop="5%"
              paddingBottom="5%"
            >
              <Button
                startIcon={<ChevronLeft />}
                className="button"
                onClick={() => {
                  history.push(`/${state.login.data.adminstrator_branch_id}/menu-setup/item-setup`);
                  // handleActive();
                }}
              >
                back
              </Button>
              {location && location.state ? (
                <Button
                  className="button"
                  startIcon={<Check />}
                  onClick={() => dispatch(updateItem({
                    ...createItem, item_discount:
                      createItem.item_discount ? createItem.item_discount : 0
                  }))}
                >
                  Update
                </Button>
              ) : (
                <Button
                  className="button"
                  startIcon={<Check />}
                  onClick={() => dispatch(postItemData({
                    ...createItem, item_discount:
                      createItem.item_discount ? createItem.item_discount : 0
                  }))}
                >
                  save
                </Button>
              )}
            </Box>
          </form>
        </Box>
        <Snackbar open={state.item.status} onClose={!state.item.status}>
          <Alert onClose={!state.item.status} severity={state.item.status}>
            {state.item.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};
