import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Link,
  Paper,
  Typography,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  IconButton,
  Snackbar,
  SwipeableDrawer,
  colors,
  DialogActions,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {
  ChevronRight,
  ChevronLeft,
  Check,
  CloudUpload,
  Add,
  Edit,
  Delete,
} from "@material-ui/icons";
import {
  Pagination,
} from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import {
  getPushNotificationDataAll,
  addPushNotificationImage,
  deletePushNotification,
  postPushNotificationData,
  updatePushNotification,
  deletePushNotificationImage,
} from "../../redux";
import { SwipeDrawer } from "./swipedrawer";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  formControl: {
    marginBottom: "1rem",
  },
  imageBackGround: {
    width: "200px",
    height: "200px",
    margin: "auto",
    border: 1,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
  },
}));

export default function PushNotification({ match }) {
  const classes = useStyles();
  const state = useSelector((state) => state);
  const [drawerOpen, setdrawerOpen] = useState(true);
  const dispatch = useDispatch();
  const [formData, _formData] = useState({
    push_notification_type: "",
    push_notification_name: "",
    push_notification_title: "",
    push_notification_text: "",
    push_notification_image: null,
  });
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState("All");
  const [del, setDel] = useState("");

  useEffect(() => {
    dispatch(getPushNotificationDataAll());
  }, [dispatch]);

  useEffect(() => {
    _formData({
      ...formData,
      push_notification_image: state?.pushNotification?.image_link,
    });
  }, [state?.pushNotification?.image_link]);

  // useEffect(() => {
  //     setformValue({ ...formValue, logo: state?.iconlogo?.logo_image })
  // }, [state?.iconlogo?.logo_image])

  useEffect(() => {
    state?.pushNotification?.status === "success" && handleClose();
  }, [state?.pushNotification?.status]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const formDataChange = (e) => {
    _formData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setStage(event.target.value);
  };

  const handleOpen = (value) => {
    setOpen(true);
    _formData(value);
  };

  const handleClose = () => {
    setOpen(false);
    _formData({
      push_notification_type: "",
      push_notification_name: "",
      push_notification_title: "",
      push_notification_text: "",
      push_notification_image: null,
    });
  };

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                PROMOTION
              </Typography>
            </div>
            <SwipeDrawer />
          </SwipeableDrawer>
          <div
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
              </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Send Push Notification
              </Typography>
            </Breadcrumbs>

            <Paper style={{ padding: "2rem", margin: "15px auto" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  paddingBottom: "10px",
                }}
              >
                <Typography variant="h6">Add Push Notification</Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  padding: "20px 0",
                }}
              >
                <Button
                  variant="contained"
                  className="button"
                  startIcon={<Add />}
                  onClick={() => setOpen(true)}
                >
                  Add Push Notification
                </Button>
                <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {" "}
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      separator={<ChevronRight style={{ fontSize: 15 }} />}
                    >
                      <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                        Home
                      </Link>
                      <Typography
                        color="secondary"
                        style={{ fontSize: 15 }}
                        onClick={() => setOpen(false)}
                      >
                        Settings
                      </Typography>
                      <Typography
                        color="textPrimary"
                        style={{ fontSize: 15, color: "var(--orange)" }}
                      >
                        Add Push Notification
                      </Typography>
                    </Breadcrumbs>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <form
                        autoComplete="off"
                        onSubmit={(e) => {
                          formData._id
                            ? dispatch(updatePushNotification({
                              ...formData,
                              push_notification_image: formData.push_notification_image ? formData.push_notification_image : null
                            }))
                            : dispatch(postPushNotificationData({
                              ...formData,
                              push_notification_image: formData.push_notification_image ? formData.push_notification_image : null
                            }));
                          e.preventDefault();
                        }}
                      >
                        <TextField
                          label="Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          className={classes.formControl}
                          name="push_notification_name"
                          value={formData.push_notification_name}
                          onChange={(e) => formDataChange(e)}
                        />

                        <FormControl variant="outlined" fullWidth required>
                          <InputLabel id="demo-simple-select-outlined-label">
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            label="Type"
                            className={classes.formControl}
                            name="push_notification_type"
                            value={formData.push_notification_type}
                            onChange={(e) => formDataChange(e)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="IOS">IOS</MenuItem>
                            <MenuItem value="Android">Android</MenuItem>
                            <MenuItem value="All">All</MenuItem>
                          </Select>
                        </FormControl>

                        <TextField
                          label="Title"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          className={classes.formControl}
                          name="push_notification_title"
                          value={formData.push_notification_title}
                          onChange={(e) => formDataChange(e)}
                        />

                        <TextField
                          label="Message"
                          variant="outlined"
                          size="small"
                          fullWidth
                          multiline
                          rows={5}
                          required
                          className={classes.formControl}
                          name="push_notification_text"
                          value={formData.push_notification_text}
                          onChange={(e) => formDataChange(e)}
                        />

                        <Box style={{ width: "100%", marginBottom: "1.5rem" }}>
                          <Box
                            border={1}
                            borderColor="secondary"
                            className={classes.center}
                            style={{
                              width: "150px",
                              height: "150px",
                              margin: "auto",
                            }}
                          >
                            {formData.push_notification_image ? (
                              <img
                                src={formData.push_notification_image}
                                width="100%"
                                height="100%"
                                alt="icon"
                              ></img>
                            ) : (
                              <Typography variant="body1" color="textSecondary">
                                Size (400 px * 400 px)
                              </Typography>
                            )}
                          </Box>
                          <Box className={classes.center}>
                            <input
                              name="icon_image"
                              accept="image/*"
                              id="contained-button-file"
                              style={{ display: "none" }}
                              type="file"
                              onChange={(e) => {
                                if (formData.push_notification_image) {
                                  let public_id =
                                    formData.push_notification_image
                                      .split("/")[8]
                                      .split(".")[0];
                                  dispatch(
                                    deletePushNotificationImage({
                                      image_id: `push_notification_image/${public_id}`,
                                    })
                                  );
                                }
                                const file = e.target.files[0];
                                const formValue = new FormData();
                                formValue.append(
                                  "push_notification_image",
                                  file
                                );
                                dispatch(addPushNotificationImage(formValue));
                              }}
                            />
                            <label htmlFor="contained-button-file">
                              <Button
                                variant="contained"
                                color="default"
                                className="button"
                                startIcon={<CloudUpload />}
                                component="span"
                              >
                                Upload Image
                              </Button>
                            </label>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          width={200}
                          margin="auto"
                        >
                          <Button
                            startIcon={<ChevronLeft />}
                            className="button"
                            onClick={() => handleClose()}
                          >
                            back
                          </Button>
                          <Button
                            className="button"
                            startIcon={<Check />}
                            type="submit"
                          >
                            save
                          </Button>
                        </Box>
                      </form>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl variant="outlined" style={{ padding: "0 10px" }}>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      value={stage}
                      onChange={handleChange}
                      className={classes.selectEmpty}
                      size="small"
                    >
                      <MenuItem value="All">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value={"Active"}>Active</MenuItem>
                      <MenuItem value={"InActive"}>InActive</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Search"
                    variant="outlined"
                  />
                </div>
              </div>
              <Divider />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.No</TableCell>
                    <TableCell>Push Notification Name</TableCell>
                    <TableCell>Push Notification Image</TableCell>
                    <TableCell>Push Notification Title</TableCell>
                    <TableCell>Push Notification Text</TableCell>
                    <TableCell>Push Notification Send</TableCell>
                    <TableCell colSpan={2}>Action</TableCell>
                  </TableRow>
                </TableHead>
                {state?.pushNotification?.loading ? <CircularProgress /> :
                  state?.pushNotification?.data.map((res, i) => (
                    <TableBody>
                      <TableRow>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{res.push_notification_name}</TableCell>
                        <TableCell>
                          {res.push_notification_image && (
                            <img
                              src={res.push_notification_image}
                              width="100px"
                              height="100px"
                              alt="push_notification_image"
                            ></img>
                          )}
                        </TableCell>
                        <TableCell>{res.push_notification_title}</TableCell>
                        <TableCell>{res.push_notification_text}</TableCell>
                        <TableCell>
                          {/* {
                            res.status ?  */}
                          <Button onClick={() => dispatch(updatePushNotification({ _id: res._id, status: true }))}>Send</Button>
                          {/* : 
                          <Button onClick={() => dispatch(updatePushNotification({_id: res._id, status: false}))}>Not Send</Button> 
                        } */}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Edit">
                            <IconButton onClick={() => handleOpen(res)}>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              onClick={() => setDel(res._id)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
              {state?.pushNotification?.totalPage > 1 && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  margin="auto"
                >
                  <Pagination
                    count={state?.pushNotification?.totalPage}
                    page={state?.pushNotification?.currentPage}
                    color="primary"
                    onChange={(e, value) =>
                      dispatch(
                        getPushNotificationDataAll(
                          `get_all_push_notification?page=${value.toString()}&limit=10`
                        )
                      )
                    }
                  />
                </Box>
              )}
            </Paper>

            <Snackbar
              open={state?.pushNotification?.status}
              onClose={!state?.pushNotification?.status}
            >
              <Alert
                onClose={!state?.pushNotification?.status}
                severity={state?.pushNotification?.status}
              >
                {state?.pushNotification?.message}
              </Alert>
            </Snackbar>
            <Dialog
              open={del}
              onClose={() => setDel("")}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  DO YOU REALLY WANT TO DELETE
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDel("")} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setDel("");
                    dispatch(deletePushNotification({ _id: del }));
                  }}
                  color="primary"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
}
