import {
  Breadcrumbs,
  Typography,
  Link,
  Paper,
  Divider,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormGroup,
  FormControlLabel,
  Tooltip,
  Snackbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  colors,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
import {
  AddCircle,
  ChevronLeft,
  ChevronRight,
  Delete,
  Edit,
  RadioButtonUnchecked,
  Search,
} from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteItem,
  getItemDataAll,
  updateItem,
  itemByStatus,
} from "../../redux";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";

import { getItemDataByName } from "../../redux/item/itemActions";

//components
import { IOSSwitch } from "../../components/Buttons/IOSSwitch.component";
import { BootstrapSelectInput } from "../../components/Input/SelectInput.component";
import Addons from "./Addons";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export const ItemSetupPage = ({ match }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [activity, setActivity] = useState("all");
  const [openAddons, setopenAddons] = useState(null);
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("itemsetup");
  const [del, setDel] = useState("");
  const id = match.params.id;

  useEffect(() => {
    dispatch(getItemDataAll());
    // return () => {
    //   cleanup
    // }
  }, [dispatch]);

  const searchByWord = (value) => {
    try {
      dispatch(getItemDataByName(value));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            // paddingTop: "8%",
            // paddingLeft: "12%",
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <Dialog
            maxWidth="lg"
            open={openAddons}
            onClose={() => setopenAddons(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Addons item={openAddons} dialogClose={() => setopenAddons(null)} />
            {/* <AddFetureProduct feture={viewFeture} addItem={addItemToFeture}
                    removeItem={removeItemToFeture} dialogClose={closeAddItem} /> */}
          </Dialog>
          {/* <Modal open={openAddons} onClose={() => setopenAddons(false)}>
            <AddFetureProduct />
          </Modal> */}
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Menu Setup
              </Typography>
            </div>
            <List>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "category" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/menu-setup`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Category</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "itemsetup" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/menu-setup/item-setup`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Items Setup</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "ingredient"
                        ? "var(--orange)"
                        : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/menu-setup/ingredient`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Ingredient</Typography>
                </div>
              </ListItem>
            </List>
          </SwipeableDrawer>
          <div
            style={{ paddingLeft: 15, paddingRight: 15 }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
              </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Items
              </Typography>
            </Breadcrumbs>
            <Paper style={{ padding: "2rem", marginTop: "5%" }}>
              <Typography variant="h6">Items</Typography>
              <Divider style={{ margin: "20px 0 20px 0" }} />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  {/* <Button
                    style={{ marginRight: 10 }}
                    variant="contained"
                    color="primary"
                    className="button"
                  >
                    <FilterList />
                  </Button> */}
                  <Button
                    startIcon={<AddCircle />}
                    variant="contained"
                    color="primary"
                    className="button"
                    onClick={() =>
                      history.push(`/${id}/menu-setup/create-item`)
                    }
                  // onClick={() => history.push(`/${state.login.data.adminstrator_branch_id}/create-item`)}
                  >
                    Add Item
                  </Button>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormControl className={classes.margin}>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={activity}
                      onChange={(e) => {
                        setActivity(e.target.value);
                        if (e.target.value === "active") {
                          dispatch(
                            itemByStatus(true, state?.item?.currentPage)
                          );
                        } else if (e.target.value === "inactive") {
                          dispatch(
                            itemByStatus(false, state?.item?.currentPage)
                          );
                        } else {
                          dispatch(getItemDataAll());
                        }
                      }}
                      input={<BootstrapSelectInput />}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">InActive</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <Autocomplete
                  id="combo-box-demo"
                  options={state?.item?.data}
                  getOptionLabel={(option) => option.item_name}
                  onChange={(e, newValue) =>
                    newValue?._id ? dispatch(getItemDataAll()) : dispatch(getItemDataById(newValue._id))}
                  style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} label="Search" variant="outlined" size="small" />}
                /> */}
                  <TextField
                    variant="outlined"
                    color="primary"
                    label="Search..."
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        searchByWord(e.target.value);
                      } else {
                        dispatch(getItemDataAll());
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search color="primary" />
                        </InputAdornment>
                      ),
                      inputProps: { style: { padding: 10 } },
                    }}
                  />
                </Box>
              </Box>
              <Divider style={{ margin: "20px 0 20px 0" }} />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Item Image</TableCell>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Category Name</TableCell>
                    <TableCell>Start DateTime</TableCell>
                    <TableCell>End DateTime</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state?.item?.loading ? (
                    <TableRow
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </TableRow>
                  ) : state?.item?.data?.length > 0 ? (
                    state?.item?.data?.map((res, i) => (
                      <TableRow>
                        <TableCell>
                          {(state?.item?.currentPage - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          {res.item_image.length > 0 && (
                            <img
                              src={res.item_cover_image}
                              alt="product"
                              width={100}
                              height={100}
                              style={{
                                objectFit: "fill",
                                borderRadius: "50%",
                                border: "1px solid grey",
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography>{res.item_name}</Typography>
                        </TableCell>
                        <TableCell>
                          {state.catagory.data.map((cat) => 
                           cat._id === res.main_category_id && 
                              <Typography>{cat.category_name}</Typography>
                            // cat.sub_category.length > 0
                            //   && cat.sub_category.map(
                            //     (sub_cat) =>
                            //       sub_cat._id === res.sub_category_id && (
                            //         <Typography>
                            //           {`>> ${sub_cat.category_name}`}
                            //         </Typography>
                            //       )
                            //   )
                          )}
                          {state.catagory.data.map((cat) => 
                            cat.sub_category.length > 0
                              && cat.sub_category.map(
                                (sub_cat) =>
                                  sub_cat._id === res.sub_category_id && (
                                    <Typography>
                                      {`>> ${sub_cat.category_name}`}
                                    </Typography>
                                  )
                              )
                          )}
                        </TableCell>
                        <TableCell>
                          {res.item_available_start_date &&
                            `${String(
                              new Date(res.item_available_start_date).getDate()
                            ).padStart(2, "0")}/
                      ${String(
                              new Date(res.item_available_start_date).getMonth() + 1
                            ).padStart(2, "0")}/
                      ${new Date(res.item_available_start_date).getFullYear()}`}
                        </TableCell>
                        <TableCell>
                          {res.item_available_end_date &&
                            `${String(
                              new Date(res.item_available_end_date).getDate()
                            ).padStart(2, "0")}/
                      ${String(
                              new Date(res.item_available_end_date).getMonth() + 1
                            ).padStart(2, "0")}/
                      ${new Date(res.item_available_end_date).getFullYear()}`}
                        </TableCell>
                        <TableCell>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={res.status}
                                  onChange={() =>
                                    dispatch(
                                      updateItem({
                                        _id: res._id,
                                        status: !res.status,
                                      })
                                    )
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        </TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Tooltip
                              title="Edit"
                              onClick={() =>
                                history.push({
                                  pathname: `/${state.login.data.adminstrator_branch_id}/create-item`,
                                  state: res,
                                })
                              }
                            >
                              <Edit
                                className="edit"
                                style={{
                                  borderRadius: "50%",
                                  border: "1px solid grey",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  history.push({
                                    pathname: `/${id}/menu-setup/create-item`,
                                    state: res,
                                  })
                                }
                              />
                            </Tooltip>
                            <Tooltip
                              title="Delete"
                              onClick={() => setDel(res._id)}
                            >
                              <Delete
                                className="delete"
                                style={{
                                  borderRadius: "50%",
                                  border: "1px solid lightcoral",
                                  marginLeft: 5,
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Addons">
                              <AddCircle
                                onClick={() => setopenAddons(res)}
                                className="delete"
                                style={{
                                  borderRadius: "50%",
                                  border: "1px solid lightcoral",
                                  marginLeft: 5,
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    "No More Product"
                  )}
                </TableBody>
              </Table>
              {state?.item?.totalPage > 1 && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  margin="5% 0 3% 0"
                >
                  <Pagination
                    count={state?.item?.totalPage}
                    page={state?.item?.currentPage}
                    color="primary"
                    onChange={(e, value) =>
                      dispatch(
                        getItemDataAll(
                          `get_all_item?page=${value.toString()}&limit=10`
                        )
                      )
                    }
                  />
                </Box>
              )}
            </Paper>
            <Snackbar open={state.item.status} onClose={!state.item.status}>
              <Alert onClose={!state.item.status} severity={state.item.status}>
                {state.item.message}
              </Alert>
            </Snackbar>
          </div>
          <Dialog
            open={del}
            onClose={() => setDel("")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                DO YOU REALLY WANT TO DELETE
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDel("")} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setDel("");
                  dispatch(deleteItem({ _id: del }));
                }}
                color="primary"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};
