import React ,{useEffect} from "react";

import {
  Breadcrumbs,
  FormControl,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  FormControlLabel,
  Divider,
  FormGroup,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
// import { RiGitBranchLine } from "react-icons/all";

import { useHistory } from "react-router-dom";

//Components
import { IOSSwitch } from "../../components/Buttons/IOSSwitch.component";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import { getAllCountry, updateBranch } from "../../redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BranchPage = ({ branchInfo, fetchApi, id }) => {
  const history = useHistory();
  const classes = useStyles();
  const handleActive = () => {
    var header = document.getElementById("myDIV");
    var btns = header.getElementsByClassName("btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
  };

  useEffect(() => {
    fetchApi(getAllCountry())
    // return () => {
    //   cleanup
    // }
  }, [fetchApi])

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRight style={{ fontSize: 15 }} />}
      >
        <Link color="inherit" href="/" style={{ fontSize: 15 }}>
          Home
        </Link>
        <Typography
          color="textPrimary"
          style={{ fontSize: 15, color: "var(--orange)" }}
        >
          Branch/Locations
        </Typography>
      </Breadcrumbs>

      <Paper style={{ padding: "2rem", marginTop: "15px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "5%",
          }}
        >
          <Typography variant="h6">Branch/Locations</Typography>
          {/* <Button
            variant="text"
            onClick={() => history.push(`/${id}/restaurant-basic/create-branch`)}
            endIcon={
              <div
                style={{
                  padding: 5,
                  background: "var(--orange)",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RiGitBranchLine />
              </div>
            }
          >
            Create New Branch
          </Button> */}
        </div>
        <Divider />
        {branchInfo.branch?.loading ? (
          <h2>loading</h2>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Branch Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>City, State, Country</TableCell>
                <TableCell>Branch Availability Status</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            {branchInfo.branch?.data?.map((res, i) => (
              <TableBody>
                <TableRow>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{res.branch_name}</TableCell>
                  <TableCell>
                    {res.branch_address}
                  </TableCell>
                  <TableCell>
                    {res.branch_city && `${res.branch_city}, `}{" "}
                    {res.branch_state && `${res.branch_state}, `}{" "}
                    {res.branch_country}
                  </TableCell>
                  <TableCell>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        value={res.branch_availability_status}
                        onChange={(e) =>
                          fetchApi(
                            updateBranch({
                              _id: res._id,
                              branch_availability_status: e.target.value,
                            })
                          )
                        }
                      >
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="busy">Busy</MenuItem>
                        <MenuItem value="close">Close</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={res.status}
                            onChange={(e) =>
                              fetchApi(
                                updateBranch({
                                  _id: res._id,
                                  status: !res.status,
                                })
                              )
                            }
                          />
                        }
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <ChevronRight
                        style={{
                          background: "var(--orange)",
                          color: "var(--light-black)",
                          borderRadius: "50%",
                        }}
                        onClick={() =>
                          history.push({
                            pathname: `/${id}/restaurant-basic/create-branch`,
                            state: {...res, country: branchInfo?.countryCurrency?.allCountry?.find(country => country.name === res.branch_country) },
                          })
                        }
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        )}
      </Paper>
      <Snackbar open={branchInfo.branch.status} onClose={branchInfo.branch.status}>
        <Alert onClose={!branchInfo.branch.status} severity={branchInfo.branch.status}>
          {branchInfo.branch.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    branchInfo: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (dispatchAction) => dispatch(dispatchAction),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchPage);
