import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { Cupons } from "../Cupons/Cupons";

import {
    List,
    ListItem,
    Typography,
} from "@material-ui/core";
import { RadioButtonUnchecked } from "@material-ui/icons";
import { useHistory } from "react-router-dom";


export const SwipeDrawer = () => {
    const history = useHistory();
    const state = useSelector((state) => state);
    const [selectState, setselectState] = useState("restaurant-basic");
    const id = state?.branch?.activeBranch?._id;

    useEffect(() => {
        setselectState(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
    }, [window.location.pathname])
    // console.log(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
    return (
        <>
            {!state?.branch?.loader && (
                <List>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "frontend" ? "var(--orange)" : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/frontend`)
                            }
                            >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Featured Products</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "banner-image-slider"
                                        ? "var(--orange)"
                                        : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/frontend/banner-image-slider`)
                            }
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Banner setting</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "homepage-banner"
                                        ? "var(--orange)"
                                        : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/frontend/homepage-banner`)
                            }
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Home Page Banner</Typography>
                        </div>
                    </ListItem>
                </List>
            )}
        </>
    );
};
