import React, { useState, useEffect } from "react";

import {
  colors,
  Typography,
  Breadcrumbs,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Divider,
  Button,
  IconButton,
  TextField,
  Tooltip,
  // CircularProgress,
  withStyles,
  Snackbar,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { AddCircle, Edit, Delete, Search } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import { ChevronRight } from "@material-ui/icons";

import {
  getDelivaryBoy,
  deleteDelivaryBoy,
  searchDeliveryBoyByWord,
} from "../../redux";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const DelivaryBoy = () => {
  const classes = useStyles();
  const history = useHistory();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [del, setDel] = useState("");
  const [
    drawerOpen,
    // setdrawerOpen
  ] = useState(true);
  useEffect(() => {
    dispatch(
      getDelivaryBoy(`get_delivery_boy/${state.branch.activeBranch._id}`)
    );
    // dispatch(getDelivaryBoy(`get_delivery_boy/${window.location.pathname.split('/')[1]}`))
  }, [dispatch, state?.branch?.activeBranch?._id]);

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: "100px",
            width: "100%",
            height: "100vh",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          {/* <Header /> */}
          {
            // state.delivaryBoy && state.delivaryBoy.loader ? <CircularProgress color="inherit" /> :
            state.delivaryBoy && state.delivaryBoy.loader ? (
              <div className="loaderBg">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <div
                className={clsx(classes.content, {
                  [classes.contentShift]: drawerOpen,
                })}
              >
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<ChevronRight style={{ fontSize: 15 }} />}
                  >
                    <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                      Home
                    </Link>
                    <Typography
                      color="textPrimary"
                      style={{ fontSize: 15, color: "var(--orange)" }}
                    >
                      Delivary Boy
                    </Typography>
                  </Breadcrumbs>

                  <Paper style={{ padding: "2rem", margin: "15px auto" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography variant="h6">Delivary Boy</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        padding: "20px 0",
                      }}
                    >
                      <Button
                        variant="contained"
                        className="button"
                        startIcon={<AddCircle />}
                        onClick={() => history.push(`create-delivaryboy`)}
                      >
                        Add Delivary Boy
                      </Button>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          size="small"
                          label="search..."
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              dispatch(searchDeliveryBoyByWord(e.target.value));
                            } else {
                              dispatch(
                                getDelivaryBoy(
                                  `get_delivery_boy/${state.branch.activeBranch._id}`
                                )
                              );
                            }
                          }}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search color="primary" />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {/* <Autocomplete
                      id="combo-box-demo"
                      options={autoCompleteOptions}
                      getOptionLabel={(option) => option.title}
                      style={{ width: 300 }}
                      renderInput={(params) => <TextField {...params} label="Search" variant="outlined" size="small" />}
                    /> */}
                        {/* <Button variant="outlined" style={{height: "40px", paddingLeft: "0", paddingRight: "0"}}>
                  <DeleteIcon  color="primary"/>
                </Button> */}
                        {/* <Button
                            variant="contained"
                            style={{
                                padding: '15px 0',
                                background: "var(--orange)",
                                color: "#ffffff",
                                height: '100%'
                            }}
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                        >
                        </Button> */}
                      </div>
                    </div>
                    <Divider />
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>Gender</TableCell>
                          <TableCell>Identify Number</TableCell>
                          <TableCell>Licence Number</TableCell>
                          <TableCell>Address</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state?.loader ? (
                          <TableRow>
                            <TableCell colSpan={5} />
                            <TableCell colSpan={2}>
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        ) : state?.delivaryBoy?.data?.length > 0 ? (
                          state.delivaryBoy.data.map((res, i) => (
                            <TableRow>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{res.delivery_boy_name}</TableCell>
                              <TableCell>{res.delivery_boy_email}</TableCell>
                              <TableCell>
                                {res.delivery_boy_phone_number}
                              </TableCell>
                              <TableCell>{res.delivery_boy_gender}</TableCell>
                              <TableCell>
                                {res.delivery_boy_identity_number}
                              </TableCell>
                              <TableCell>
                                {res.delivery_boy_licence_number}
                              </TableCell>
                              <TableCell>
                                <HtmlTooltip
                                  title={
                                    <React.Fragment>
                                      {res.delivery_boy_address}
                                      {res.delivery_boy_country &&
                                        `, ${res.delivery_boy_country}`}
                                    </React.Fragment>
                                  }
                                >
                                  <Button style={{ width: "100%" }}>
                                    <span
                                      style={{
                                        width: "auto",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {res.delivery_boy_address}
                                    </span>
                                    <span>...</span>
                                  </Button>
                                </HtmlTooltip>
                              </TableCell>
                              <TableCell
                                style={{
                                  color: res.status ? "lawngreen" : "red",
                                }}
                              >
                                {res.status ? "Online" : "OffLine"}
                              </TableCell>
                              <TableCell>
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={() =>
                                      history.push({
                                        pathname: `create-delivaryboy`,
                                        state: res,
                                      })
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton
                                    style={{ color: "rgb(220, 0, 78)" }}
                                    onClick={() => setDel(res)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5} />
                            <TableCell colSpan={2}>no data found</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                </div>
              </div>
            )
          }
          <Snackbar
            open={state.delivaryBoy.status}
            onClose={!state.delivaryBoy.status}
          >
            <Alert
              onClose={!state.delivaryBoy.status}
              severity={state.delivaryBoy.status}
            >
              {state.delivaryBoy.message}
            </Alert>
          </Snackbar>
          <Dialog
            open={del}
            onClose={() => setDel("")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                DO YOU REALLY WANT TO DELETE
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDel("")} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setDel("");
                  dispatch(deleteDelivaryBoy(del));
                }}
                color="primary"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};
