import React, { useState, useEffect } from "react";

import {
  colors,
  Typography,
  Breadcrumbs,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Divider,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  useMediaQuery,
  useTheme,
  TextField,
  withStyles,
  Switch,
  CircularProgress,
  Snackbar,
  SwipeableDrawer,
  List,
  ListItem,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
// import EditorJs from 'react-editor-js';
import { AddCircle, Edit, Delete, RadioButtonUnchecked} from "@material-ui/icons";
//Components
import { ChevronRight,
  ChevronLeft, } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from "@material-ui/lab/Alert";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  postCmsPage,
  getCmsPage,
  getCmsPageById,
  updateCmsPage,
  deleteCmsPage,
} from "../../redux";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  label: {
    position: "absolute",
    top: -12,
    padding: "0 5px",
    backgroundColor: "white",
    fontSize: "13px",
    color: "var(--orange)",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const CmsPage = () => {
  const [del, setDel] = useState("");
  const classes = useStyles();
  const state = useSelector((state) => state);
  const [selectState, setselectState] = useState("socialmedia");
  const dispatch = useDispatch();
  const history = useHistory()
  const [
    drawerOpen,
    setdrawerOpen
  ] = useState(true);
  const [open, setOpen] = useState(false);
  const [autoCompleteOptions, setautoCompleteOptions] = useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editorState, seteditorState] = useState(EditorState.createEmpty());

  const [formValue, setformValue] = useState({
    cms_title: "",
    cms_content: "",
  });

  const handleClose = () => {
    setformValue({ cms_title: "", cms_content: "" });
    seteditorState(EditorState.createEmpty());
    setOpen(false);
  };

  useEffect(() => {
    setformValue({ cms_title: "", cms_content: "" });
    // seteditorState(EditorState.createEmpty());
    setOpen(false);
  }, [state?.cmsPage?.status === "success"]);

  useEffect(() => {
    dispatch(getCmsPage());
  }, [dispatch]);

  useEffect(() => {
    setselectState(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
}, [window.location.pathname])

  useEffect(() => {
    setautoCompleteOptions(state?.cmsPage?.data);
  }, [state?.cmsPage?.data]);

  const id = state?.branch?.activeBranch?._id

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", left: "30%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            CMS & SOCIAL
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "cms" ? "var(--orange)" : "#ffffff",
              }}
              onClick={() => history.push(`/${id}/cms`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Create CMS page</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "social-media" ? "var(--orange)" : "#ffffff",
              }}
              onClick={() => history.push(`/${id}/cms/social-media`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Social setting</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div
        style={{ padding: "0 20px" }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            Social Media Page Urls
          </Typography>
        </Breadcrumbs>

        {state.socialmedia.loader ? (
          <div className="loaderBg">
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <Paper style={{ padding: "2rem", margin: "15px auto" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography variant="h6">CMS Management</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      padding: "20px 0",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="button"
                      startIcon={<AddCircle />}
                      onClick={() => setOpen(true)}
                    >
                      Create
                    </Button>
                    <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <DialogTitle id="responsive-dialog-title">
                        {" "}
                        <Breadcrumbs
                          aria-label="breadcrumb"
                          separator={<ChevronRight style={{ fontSize: 15 }} />}
                        >
                          <Link
                            color="inherit"
                            href="/"
                            style={{ fontSize: 15 }}
                          >
                            Home
                          </Link>
                          <Typography color="secondary" onClick={handleClose}>
                            CMS Management
                          </Typography>
                          <Typography color="primary">Create</Typography>
                        </Breadcrumbs>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          <TextField
                            label="Page Name"
                            style={{ marginBottom: "1.5rem" }}
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={formValue.cms_title}
                            onChange={(e) =>
                              setformValue({
                                ...formValue,
                                cms_title: e.target.value,
                              })
                            }
                          />
                          <TextField value={formValue.cms_content} fullWidth variant="outlined" label="Content" multiline
                            onChange={(e) =>
                              setformValue({
                                ...formValue,
                                cms_content: e.target.value,
                              })
                            }/>
                          {/* <div
                            style={{
                              position: "relative",
                              width: "100%",
                              border: "1px solid rgb(224, 224, 224)",
                              padding: "10px",
                            }}
                          >
                            <label className={classes.label}>
                              Item Short Description
                            </label>
                            <Editor
                              editorStyle={{
                                maxheight: "400px",
                                width: "100%",
                              }}
                              editorState={editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(e) => {
                                seteditorState(e);
                                setformValue({
                                  ...formValue,
                                  cms_content: draftToHtml(
                                    convertToRaw(
                                      editorState.getCurrentContent()
                                    )
                                  ),
                                });
                              }}
                            />
                          </div> */}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                          Cancel
                        </Button>
                        {formValue._id ? (
                          <Button
                            type="submit"
                            color="primary"
                            onClick={() => dispatch(updateCmsPage(formValue))}
                          >
                            Update{" "}
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            color="primary"
                            onClick={() => dispatch(postCmsPage(formValue))}
                          >
                            Save
                          </Button>
                        )}
                      </DialogActions>
                    </Dialog>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Autocomplete
                        id="combo-box-demo"
                        options={autoCompleteOptions}
                        getOptionLabel={(option) => option.cms_title}
                        onChange={(e, newValue) =>
                          newValue?._id
                            ? dispatch(
                                getCmsPageById(
                                  `/get_cms_page_by_id/${newValue?._id}`
                                )
                              )
                            : dispatch(getCmsPage())
                        }
                        style={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search"
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl.No</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Content</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell colSpan={2}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    {state?.cmsPage?.data && state?.cmsPage?.data?.length > 0
                      ? state?.cmsPage?.data?.map((res, i) => (
                          <TableBody>
                            <TableRow key={res._id}>
                              <TableCell>{i + 1}</TableCell>
                              <TableCell>{res.cms_title}</TableCell>
                              <TableCell
                                dangerouslySetInnerHTML={{
                                  __html: res.cms_content,
                                }}
                              ></TableCell>
                              <TableCell style={{ color: "red" }}>
                                <IOSSwitch
                                  checked={res.status}
                                  onChange={(e) =>
                                    dispatch(
                                      updateCmsPage({
                                        _id: res._id,
                                        status: e.target.checked,
                                      })
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={() => {
                                      // const contentBlock = htmlToDraft(
                                      //   res.cms_content
                                      // );
                                      // console.log(res.cms_content);
                                      // if (contentBlock) {
                                      //   const contentState =
                                      //     ContentState.createFromBlockArray(
                                      //       contentBlock.contentBlocks
                                      //     );
                                      //   const editorState =
                                      //     EditorState.createWithContent(
                                      //       contentState
                                      //     );
                                      //   seteditorState(editorState);
                                      // }
                                      setformValue(res);
                                      setOpen(true);
                                    }}
                                  >
                                    <Edit />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                <Tooltip title="Delete">
                                  <IconButton onClick={() => setDel(res._id)}>
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      : "no Data Found"}
                  </Table>
                </Paper>
        )}
      <Snackbar open={state.cmsPage.status} onClose={!state.cmsPage.status}>
            <Alert
              onClose={!state.cmsPage.status}
              severity={state.cmsPage.status}
            >
              {state.cmsPage.message}
            </Alert>
          </Snackbar>
          <Dialog
            open={del}
            onClose={() => setDel("")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                DO YOU REALLY WANT TO DELETE
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDel("")} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setDel("");
                  dispatch(deleteCmsPage({ _id: del }));
                }}
                color="primary"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
    </div>
  );
};
