import { FETCH_ITEM_FALIOUR, FETCH_ITEM_REQUEST, FETCH_ITEM_SUCCESS, ITEM_DATA, ITEM_IMAGE, IMAGE_DELETE,
    FETCH_ITEM_ADDONS_SUCCESS } from "./itemTypes";

const initialState = {
    loading: false,
    data: [],
    addons:[],
    totalPage: 0,
    currentPage: 0,
    totalPageforAddons: 0,
    currentPageforAddons: 0,
    status: '',
    message: '',
    image_link: ''
}

export const ItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ITEM_REQUEST:
            return {
                ...state, loading: true,
            }
        case FETCH_ITEM_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.response,
                totalPage: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                status: '',
                message: '',
                image_link: ''
            }

        case FETCH_ITEM_ADDONS_SUCCESS:
            return {
                ...state,
                loading: false,
                addons: action.payload.response,
                totalPageforAddons: action.payload.totalPages,
                currentPageforAddons: action.payload.currentPage,
                status: '',
                message: '',
                image_link: ''
            }
        case FETCH_ITEM_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: action.payload
            }
        case ITEM_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }

        case ITEM_IMAGE:
            return {
                ...state,
                image_link: action.payload.image_url
            }

        case IMAGE_DELETE:
            return {
                ...state,
                image_link: ''
            }

        default:
            return state
    }
}