import React, { useState, useEffect } from "react";

import {
    Breadcrumbs,
    Link,
    Paper,
    Typography,
    makeStyles,
    withStyles,
    Switch,
    Button,
    IconButton,
    TextField,
    Grid,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    Snackbar
} from "@material-ui/core";
import { ChevronRight, ChevronLeft, Check } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle'
import TimezoneSelect from 'react-timezone-select'
import InputAdornment from '@material-ui/core/InputAdornment';
import InfoIcon from '@material-ui/icons/Info';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import { useSelector, useDispatch } from 'react-redux';
import { getConfigaration, updateConfigaration } from '../../redux'
import {Alert} from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '25ch',
        color: "var(--orange)"
    },
}));

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: "#52d869",
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: "#52d869",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export const Configaration = () => {
    const classes = useStyles();
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const [formValue, setformValue] = useState({
        _id: '',
        configuration_app_email: [{ email: '' }],
        configuration_app_contact_number: [{ contact_number: '', }],
        configuration_app_contact_address: [{ address: '' }],
        configuration_is_item_sku: false,
        configuration_time_zone: '',
        configuration_hour_format: '',
        configuration_distance_unit: '',
        configuration_app_name: '',
        configuration_app_longitude: '',
        configuration_app_latitude: '',
        configuration_app_radius: '',
        configuration_app_defaut_language: '',
    })

    useEffect(() => {
        dispatch(getConfigaration())
    }, [dispatch])

    useEffect(() => {
        state?.configarationSettings?.data && setformValue(state?.configarationSettings?.data)
    }, [state?.configarationSettings])

    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
                <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                    Home
        </Link>
                <Typography
                    color="textPrimary"
                    style={{ fontSize: 15, color: "var(--orange)" }}
                >
                    App Settings
        </Typography>
            </Breadcrumbs>
            {
                state?.configarationSettings && !state?.configarationSettings?.loader && state?.configarationSettings?.data ?

                    <Paper style={{ padding: "2rem", margin: "15px auto", width: "50%" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                                paddingBottom: "10px"
                            }}
                        >
                            <Typography variant="h6">App Settings</Typography>
                        </div>

                        <form onSubmit={(e) => {
                            e.preventDefault()
                            dispatch(updateConfigaration(formValue))
                        }}>

                            <div style={{ padding: "10px" }}>
                                <div style={{ width: '100%', marginBottom: '1.5rem' }} >
                                    <label>Time Zone</label>
                                    <TimezoneSelect
                                        value={formValue.configuration_time_zone}
                                        label="Outlined" variant="outlined"
                                        onChange={(e) => setformValue({ ...formValue, configuration_time_zone: e?.value })}
                                    />
                                </div>

                                <FormControl variant="outlined" style={{ width: "100%", marginBottom: "1.5rem" }}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Hour Format 12 24</InputLabel>
                                    <Select
                                        native
                                        value={formValue.configuration_hour_format}
                                        onChange={(e) => setformValue({ ...formValue, configuration_hour_format: e.target.value })}
                                        label="Age"
                                        inputProps={{
                                            name: 'age',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="12">12</option>
                                        <option value="24">24</option>
                                    </Select>
                                </FormControl>

                                <FormControl variant="outlined" style={{ width: "100%", marginBottom: "1.5rem" }}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Distance Unit</InputLabel>
                                    <Select
                                        native
                                        value={formValue?.configuration_distance_unit}
                                        onChange={(e) => setformValue({ ...formValue, configuration_distance_unit: e.target.value })}
                                        label="Age"
                                        inputProps={{
                                            name: 'age',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="Km">Km</option>
                                        <option value="Miles">Miles</option>
                                    </Select>
                                </FormControl>
                                <TextField label="App Name" variant="outlined" size="small" value={formValue.configuration_app_name}
                                    onChange={(e) => setformValue({ ...formValue, configuration_app_name: e.target.value })}
                                    style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <InfoIcon color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }} />
                                {
                                    formValue?.configuration_app_email?.map((res, index) => {
                                        return (
                                            <Grid container spacing={1}>
                                                <Grid item xs={10}>
                                                    <TextField label="App Contact Email" type="email" required
                                                        variant="outlined" size="small" value={res.email}
                                                        onChange={(e) => {
                                                            res.email = e.target.value
                                                            setformValue({ ...formValue, configuration_app_email: [...formValue.configuration_app_email] })
                                                        }}
                                                        style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <MailOutlineIcon color="secondary" />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>
                                                {index === formValue.configuration_app_email.length - 1 && <Grid item xs={1}>
                                                    <IconButton className={classes.margin} onClick={() => {
                                                        setformValue({ ...formValue, configuration_app_email: [...formValue.configuration_app_email, { email: '' }] })
                                                    }}>
                                                        <AddIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>}
                                                {formValue.configuration_app_email.length > 1 && <Grid item xs={1}>
                                                    <IconButton className={classes.margin} onClick={(i = index) => {
                                                        formValue.configuration_app_email.splice(index, 1)
                                                        setformValue({ ...formValue, configuration_app_email: [...formValue.configuration_app_email] })
                                                    }}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>}
                                            </Grid>
                                        )
                                    })
                                }
                                {
                                    formValue?.configuration_app_contact_number?.map((res, index) => {
                                        return (
                                            <Grid container spacing={1}>
                                                <Grid item xs={10}>
                                                    <TextField label="App Contact Number" required
                                                        variant="outlined" size="small" value={res.contact_number}
                                                        onChange={(e) => {
                                                            res.contact_number = e.target.value
                                                            setformValue({ ...formValue, configuration_app_contact_number: [...formValue.configuration_app_contact_number] })
                                                        }}
                                                        style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <PhoneInTalkIcon color="secondary" />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>
                                                {index === formValue.configuration_app_contact_number.length - 1 && <Grid item xs={1}>
                                                    <IconButton className={classes.margin} onClick={() => {
                                                        setformValue({ ...formValue, configuration_app_contact_number: [...formValue.configuration_app_contact_number, { contact_number: '' }] })
                                                    }}>
                                                        <AddIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>}
                                                {formValue.configuration_app_contact_number.length > 1 && <Grid item xs={1}>
                                                    <IconButton className={classes.margin} onClick={(i = index) => {
                                                        formValue.configuration_app_contact_number.splice(index, 1)
                                                        setformValue({ ...formValue })
                                                    }}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>}
                                            </Grid>
                                        )
                                    })
                                }
                                {
                                    formValue?.configuration_app_contact_address?.map((res, index) => {
                                        return (
                                            <Grid container spacing={1}>
                                                <Grid item xs={10}>
                                                    <TextField label="App Contact Address" variant="outlined" size="small" value={res.address}
                                                        onChange={(e) => {
                                                            res.address = e.target.value
                                                            setformValue({ ...formValue, configuration_app_contact_address: [...formValue.configuration_app_contact_address] })
                                                        }}
                                                        multiline
                                                        rows={4}
                                                        style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <RecentActorsIcon color="secondary" />
                                                                </InputAdornment>
                                                            ),
                                                        }} />
                                                </Grid>
                                                {index === formValue.configuration_app_contact_address.length - 1 && <Grid item xs={1}>
                                                    <IconButton className={classes.margin} onClick={() => {
                                                        setformValue({ ...formValue, configuration_app_contact_address: [...formValue.configuration_app_contact_address, { address: '' }] })
                                                    }}>
                                                        <AddIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>}
                                                {formValue.configuration_app_contact_address.length > 1 && <Grid item xs={1}>
                                                    <IconButton className={classes.margin} onClick={(i = index) => {
                                                        formValue.configuration_app_contact_address.splice(index, 1)
                                                        setformValue({ ...formValue, configuration_app_contact_address: [...formValue.configuration_app_contact_address] })
                                                    }}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>}
                                            </Grid>
                                        )
                                    })
                                }
                                <TextField label="App Latitude" variant="outlined" size="small" value={formValue.configuration_app_latitude}
                                    style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationOnIcon color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }} onChange={e => setformValue({ ...formValue, configuration_app_latitude: e.target.value })} />
                                <TextField label="App Longitude" variant="outlined" size="small" value={formValue.configuration_app_longitude}
                                    style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LocationOnIcon color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }} onChange={e => setformValue({ ...formValue, configuration_app_longitude: e.target.value })} />

                                <TextField label="Radius (Km)" variant="outlined" size="small" value={formValue.configuration_app_radius}
                                    style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FiberManualRecordIcon color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }} onChange={e => setformValue({ ...formValue, configuration_app_radius: e.target.value })} />

                                <Typography component="div" style={{ width: '100%', marginBottom: '1.5rem' }}>
                                    Is Sku Avaliabe
                        <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>Off</Grid>
                                        <Grid item>
                                            <IOSSwitch checked={formValue.configuration_is_item_sku} onChange={(e) =>
                                                setformValue({ ...formValue, configuration_is_item_sku: e.target.checked })
                                            } name="configuration_is_item_sku" />
                                        </Grid>
                                        <Grid item>On</Grid>
                                    </Grid>
                                </Typography>

                                <TextField label="Language" variant="outlined" size="small" value={formValue.configuration_app_defaut_language}
                                    style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <GTranslateIcon color="secondary" />
                                            </InputAdornment>
                                        ),
                                    }} onChange={e => setformValue({ ...formValue, configuration_app_defaut_language: e.target.value })} />

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width={200}
                                    margin="auto"
                                >
                                    <Button startIcon={<ChevronLeft />} className="button" onClick={() => dispatch(getConfigaration())}>
                                        back
                                </Button>
                                    <Button className="button" type="submit" startIcon={<Check />} >
                                        save
                        </Button>
                                </Box>
                                {/* <div className='code'>
                        <pre
                            style={{
                                margin: '0 20px',
                                fontWeight: 500,
                                fontFamily: 'monospace',
                            }}
                        >
                            {JSON.stringify(selectedTimezone, null, 2)}
                        </pre>
                    </div> */}
                            </div>

                        </form>


                    </Paper> : <div className="loaderBg">
                        <CircularProgress color="secondary" /></div>
            }
            <Snackbar open={state.configarationSettings.status}
                onClose={!state.configarationSettings.status}>
                <Alert onClose={!state.configarationSettings.status} severity={state.configarationSettings.status}>
                    {state.configarationSettings.message}{state.feture.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

