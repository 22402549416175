import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  Snackbar,
  makeStyles,
  SwipeableDrawer,
  colors,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import {
  ChevronRight,
  LocationSearching,
  AddCircle,
  ChevronLeft,
  RadioButtonUnchecked,
  VisibilityOff,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { Cancel, Visibility } from "@material-ui/icons";

import Map from "../../components/Map/Map.component";
import { useGeolocation } from "../../hooks/useGeoLocation";
import { useSelector, useDispatch } from "react-redux";
import {
  postDelivaryZones,
  getDelivaryZones,
  deleteDelivaryZones,
  getAllBranch,
} from "../../redux";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { SwipeDrawer } from "./swipedrawer/swipeDrawer";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const DelevaryZones = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  const state = useSelector((state) => state);
  const [drawerOpen, setdrawerOpen] = useState(true);
  const dispatch = useDispatch();
  const id = match.params.id;
  const [showSaveCancelButton, setshowSaveCancelButton] = useState(false);
  const [delivaryForm, setdelivaryForm] = useState({
    branch_id: window.location.pathname.split("/")[1],
    delivery_zone_name: "",
  });
  const [triangleCoords, setTriangleCoords] = useState([]);
  const [del, setDel] = useState("");
  const [centerPosition, setCenterPosition] = useState({ lat: "", lng: "" });
  const branch = useSelector((state) => state?.branch);
  const geoLocation = useGeolocation();

  console.log(branch);

  const handleClick = (t, map, c) => {
    try {
      const latlng = { lat: c.latLng.lat(), lng: c.latLng.lng() };
      setTriangleCoords([...triangleCoords, latlng]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    dispatch(
      getDelivaryZones(
        `/get_all_delivery_zone_by_branch_id/${delivaryForm.branch_id}`
      )
    );
    dispatch(getAllBranch());
  }, [dispatch, delivaryForm.branch_id]);

  useEffect(() => {
    if (state.delivaryZone.status === "success") {
      setdelivaryForm({ ...delivaryForm, delivery_zone_name: "" });
      setTriangleCoords([]);
      setshowSaveCancelButton(false);
    }
    setCenterPosition({
      lat: branch?.activeBranch?.branch_latitute,
      lng: branch?.activeBranch?.branch_longitute,
    });
  }, [state.delivaryZone, delivaryForm, branch]);

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0%", right: "63%", zIndex: 1100 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Restaurant Basics
              </Typography>
            </div>
            <SwipeDrawer />
          </SwipeableDrawer>
          <div
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
              </Link>
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Branch
              </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Branch Delevary Zones
              </Typography>
            </Breadcrumbs>
            {state.delivaryZone.loader ? (
              <div className="loaderBg">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Paper
                style={{ padding: "2rem", marginTop: "15px", width: "100%" }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6">Branch Delevary Zones</Typography>
                  {/* <Autocomplete style={{ width: 300 }}
                  id="combo-box-demo"
                  value= {state?.branch?.data?.find(element => element._id === delivaryForm.branch_id)}
                  options={state.branch.data}
                  onChange={(e, newValue) => setdelivaryForm({
                    ...delivaryForm, branch_id: newValue ?
                      newValue._id : state?.login?.data?.adminstrator_branch_id
                  })}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => <TextField {...params} label="Select Branch" variant="outlined" size="small"
                    style={{ width: '100%', marginBottom: '1rem' }} />}
                /> */}
                </div>
                <Divider style={{ margin: "3% 0" }} />
                <Grid container>
                  <Grid item sm={8} xs={12}>
                    {geoLocation?.loaded && (
                      <Map
                        handleClick={showSaveCancelButton && handleClick}
                        triangleCoords={triangleCoords}
                        height="550px"
                        lat={centerPosition.lat}
                        lng={centerPosition.lng}
                        position="relative"
                        width="100%"
                        defailtTriange={state.delivaryZone.data}
                        draggable={false}
                        branchLat={branch?.activeBranch?.branch_latitute}
                        branchLng={branch?.activeBranch?.branch_longitute}
                      />
                    )}
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Box marginLeft="10px">
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1">Delevary Zones</Typography>
                        <LocationSearching
                          style={{ marginLeft: 10, fontSize: "1rem" }}
                        />
                      </Box>
                      {
                        // state.delivaryZone.loader ? <CircularProgress /> :
                        state.delivaryZone.data.length > 0 ? (
                          <List>
                            {state.delivaryZone.data.map((res, i) => (
                              <ListItem key={res._id}>
                                <ListItemText
                                  primary={res.delivery_zone_name}
                                />
                                <ListItemSecondaryAction>
                                  {triangleCoords === res.delivery_zone_arr ? (
                                    <IconButton
                                      edge="end"
                                      aria-label="view"
                                      onClick={() => {
                                        setshowSaveCancelButton(false);
                                        setTriangleCoords([]);
                                      }}
                                    >
                                      <VisibilityOff />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      edge="end"
                                      aria-label="view"
                                      onClick={() => {
                                        setshowSaveCancelButton(false);
                                        setTriangleCoords(
                                          res.delivery_zone_arr
                                        );
                                        setCenterPosition({
                                          lat: res.delivery_zone_arr[0].lat,
                                          lng: res.delivery_zone_arr[0].lng,
                                        });
                                      }}
                                    >
                                      <Visibility />
                                    </IconButton>
                                  )}
                                  <Tooltip title="Delete">
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() => setDel(res._id)}
                                    >
                                      <Cancel />
                                    </IconButton>
                                  </Tooltip>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          <List>No Delivary Zone Found</List>
                        )
                      }
                      <Button
                        startIcon={<AddCircle />}
                        style={{ marginTop: 10 }}
                        onClick={() => {
                          setTriangleCoords([]);
                          setshowSaveCancelButton(true);
                        }}
                        className="button"
                      >
                        Add delevary zone
                      </Button>
                      {showSaveCancelButton && (
                        <Box marginTop="10px">
                          <Button
                            className="button"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                              setshowSaveCancelButton(false);
                              setTriangleCoords([]);
                            }}
                          >
                            cancel
                          </Button>
                          <Button
                            className="button"
                            onClick={() =>
                              dispatch(
                                postDelivaryZones({
                                  ...delivaryForm,
                                  delivery_zone_name: `Area ${
                                    state.delivaryZone.data.length + 1
                                  }`,
                                  delivery_zone_arr: triangleCoords,
                                })
                              )
                            }
                          >
                            save
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            )}
            <Dialog
              open={del}
              onClose={() => setDel("")}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  DO YOU REALLY WANT TO DELETE
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDel("")} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setDel("");
                    dispatch(deleteDelivaryZones({ _id: del }));
                  }}
                  color="primary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              open={state.delivaryZone.status}
              onClose={state.delivaryZone.status}
            >
              <Alert
                onClose={!state.delivaryZone.status}
                severity={state.delivaryZone.status}
              >
                {state.delivaryZone.message}
              </Alert>
            </Snackbar>
          </div>
        </div>
      )}
    </>
  );
};
