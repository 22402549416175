import {
    HANDLE_LOGIN_FALIOUR, HANDLE_LOGIN_SUCCESS, HANDLE_LOGIN_REQUEST, HANDLE_LOGIN_FORM_DATA,
    HANDLE_CHANGE_PASSWORD_FALIOUR, HANDLE_CHANGE_PASSWORD_REQUEST, HANDLE_CHANGE_PASSWORD_SUCCESS
} from './loginTypes'


const initialState = {
    loading: true,
    loader: false,
    data: {},
    token: '',
    status: '',
    message: ''
}

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_LOGIN_REQUEST:
            return {
                ...state, loading: true
            }
        case HANDLE_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                token: action.payload.token,
                data: action.payload.response,
                status: '',
                message: ''
            }
        // case GET_ADMINISTATOR_DATA_BY_ID:
        //     return {
        //         ...state,
        //         loading: false,
        //         activeBranch: action.payload
        //     }
        case HANDLE_LOGIN_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: 'Network Error'
            }
        case HANDLE_LOGIN_FORM_DATA:
            return {
                ...state,
                loading: false,
                token: action.payload.token,
                data: action.payload.response,
                status: action.payload.status === "success" ? 'green' : 'red',
                message: action.payload.message
            }
        case HANDLE_CHANGE_PASSWORD_REQUEST: {
            return {
                ...state, 
                loader: true,
                status: '',
                message: ''
            }
        }
        case HANDLE_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loader: false,
                status: action.payload.status === "success" ? action.payload.status : 'error',
                message: action.payload.message
            }
        case HANDLE_CHANGE_PASSWORD_FALIOUR:
            return {
                ...state,
                loader: false,
                status: 'error',
                message: 'Network Error'
            }
        default:
            return state
    }
}