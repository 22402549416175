import {FETCH_ICON_LOGO_REQUEST,FETCH_ICON_LOGO_SUCCESS, FETCH_ICON_LOGO_FALIOUR, ICON_LOGO_DATA, ICON_IMAGE, LOGO_IMAGE} from "./iconlogoTypes";

const initialState = {
    loading: false,
    data: [],
    status: '',
    message: '',
    icon_image: '',
    logo_image: ''
}

export const iconLogoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ICON_LOGO_REQUEST:
            return {
                ...state, loading: true,
            }
        case FETCH_ICON_LOGO_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                status: '',
                message: '',
                icon_image: '',
                logo_image: ''
            }
        case FETCH_ICON_LOGO_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: action.payload
            }
        case ICON_LOGO_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }

        case ICON_IMAGE:
            return {
                ...state,
                icon_image: action.payload
            }

        case LOGO_IMAGE:
            return {
                ...state,
                logo_image: action.payload
            }

        default:
            return state
    }
}