import React from "react";
import "./Input.css";
import { TextField, InputAdornment, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      color: "grey",
    },
  },
});

export const Input = ({
  name,
  label,
  value,
  error = null,
  onChange,
  icon,
  style,
  helperText,
  type,
  min,
  max,
  multiline = false,
  row,
  endIcon,
}) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      helperText={helperText}
      type={type}
      InputProps={{
        startAdornment: icon && (
          <InputAdornment className={classes.root} position="start">
            {icon}
          </InputAdornment>
        ),
        endAdornment: endIcon && (
          <InputAdornment className={classes.root} position="end">
            {endIcon}
          </InputAdornment>
        ),
        inputProps: { min: 0, max: 10, style: { padding: 10 } },
      }}
      style={style}
      InputLabelProps={{ style: { fontSize: 15 } }}
      {...(error && { error: true, helperText: error })}
      multiline={multiline}
      rows={row}
    />
  );
};
