import {FETCH_ITEM_FALIOUR, FETCH_ITEM_REQUEST,FETCH_ITEM_SUCCESS,ITEM_DATA, 
    ITEM_IMAGE ,IMAGE_DELETE, FETCH_ITEM_ADDONS_SUCCESS} from "./itemTypes";
import { fetchData,postData,updateData,deleteData,itembyStatus } from "../../Api/api";

export const fetchItemRequest = () => {
    return {
        type: FETCH_ITEM_REQUEST
    }
}

export const fetchItemSuccess = (data) => {
    return {
        type : FETCH_ITEM_SUCCESS,
        payload : data
    }
}

export const fetchItemAddonsSuccess = (data) => {
    return {
        type : FETCH_ITEM_ADDONS_SUCCESS,
        payload : data
    }
}

export const fetchItemFaliour = (data) => {
    return {
        type : FETCH_ITEM_FALIOUR, 
        payload : data
    }
}

export const itemData = (data) => {
    return {
        type : ITEM_DATA,
        payload : data
    }
}

export const itemImage = (data) => {
    return {
        type : ITEM_IMAGE,
        payload: data
    }
}

export const imageDelete = (data) => {
    return {
        type : IMAGE_DELETE,
        payload : data
    }
}

export const getItemDataAll = (endpoint = `get_all_item?page=1&limit=10`) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        fetchData(endpoint).then(
            response => dispatch(fetchItemSuccess(response.data))
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const getItemDataAllForAddons = (endpoint = `get_all_item?page=1&limit=10`) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        fetchData(endpoint).then(
            response => dispatch(fetchItemAddonsSuccess(response.data))
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const getItemDataById = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        fetchData(`/get_item_by_id/${endpoint}?page=1&limit=10`).then(
            response => dispatch(fetchItemSuccess(response.data))
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const getItemDataByName = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        fetchData(`/search_item_by_name/${endpoint}`).then(
            response => dispatch(fetchItemSuccess(response.data))
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const postItemData = (body) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        postData(`add_item`,body).then(
            response => {
                dispatch(itemData(response.data))
                response.data.status === 'success' && dispatch(getItemDataAll())
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const postAddonItem = (endpoint, body) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        postData(endpoint,body).then(
            response => {
                dispatch(itemData(response.data))
                response.data.status === 'success' && dispatch(getItemDataAll())
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const updateItem = (body) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        updateData(`update_item`,body).then(
            response => {
                dispatch(itemData(response.data))
                response.data.status === 'success' && dispatch(getItemDataAll())
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const deleteItem = (body) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        deleteData(`delete_item`,body).then(
            response => {
                dispatch(itemData(response.data))
                response.data.status === 'success' && dispatch(getItemDataAll())
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const addItemImage = (body) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        postData(`add_item_image`,body).then(
            response => {dispatch(itemImage(response.data))
                dispatch(itemImage(''))
            }
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const deleteItemImage = (body) => {
    return (dispatch) => {
        dispatch(fetchItemRequest())
        let public_id = body.split("/")[8].split(".")[0];
        postData(`remove_image`,{ "item_image": `add_item_image/${public_id}` }).then(
            response => dispatch(itemImage(''))
        ).catch(
            error => dispatch(fetchItemFaliour(error.message))
        )
    }
}

export const itemByStatus = (value, page) => dispatch => {
    try {
        dispatch(fetchItemRequest())
        itembyStatus(value, page).then(res => {
            dispatch(fetchItemSuccess(res.data))
        }).catch(error => dispatch(fetchItemFaliour(error.message)))
    } catch (error) {
        dispatch(fetchItemFaliour(error.message))
    }
}