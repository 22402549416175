import {
  Breadcrumbs,
  Divider,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Button,
  Tooltip,
} from "@material-ui/core";
import { ChevronRight, Edit, AddCircle } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import React from "react";

const useStyles = makeStyles({
  editButton: {
    border: "1px solid grey",
    borderRadius: "50%",
  },
});

export const Role = ({ setselectState, id }) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div style={{ paddingLeft: 20, paddingRight: 20, width: "100%" }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRight style={{ fontSize: 15 }} />}
      >
        <Link color="inherit" href="/" style={{ fontSize: 15 }}>
          Home
        </Link>
        <Typography
          color="textPrimary"
          style={{ fontSize: 15, color: "var(--orange)" }}
        >
          Role
        </Typography>
      </Breadcrumbs>
      <Paper
        style={{ padding: "2rem", margin: "15px auto 5rem auto", width: "70%" }}
      >
        <Typography variant="h6">Role</Typography>
        <Divider style={{ margin: "10px 0" }} />
        <Button
          className="button"
          startIcon={<AddCircle />}
          onClick={() => history.push(`/${id}/access-control/create-role`)}
        >
          Add role
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">1</TableCell>
              <TableCell align="center">Main Admin</TableCell>
              <TableCell align="center">
                <Tooltip title="Edit">
                  <Edit
                    className={`${classes.editButton} edit`}
                    onClick={() =>
                      history.push(`/${id}/access-control/create-role`)
                    }
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};
