import {
    Grid,Typography, makeStyles,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';

import React from 'react'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from "moment";
import {useCurrency} from '../../../hooks/useCurrency'


const useStyles = makeStyles((theme) => ({

    button: {
        marginRight: theme.spacing(1)
    },
    order_details: {
        background: "#e8fff8",
        padding: "10px"
    },
    containerPadding: {
        padding: "15px 0"
    },
    display: {
        display: 'flex',
        justifyContent: "space-between"
    }
}))

export const RescheduleOrder = ({ order }) => {
    const classes = useStyles()
    const currency = useCurrency()
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Order Reschedule:
            </Typography>
            <Grid container className={classes.containerPadding} spacing={3}>
                {
                    order.order_delivery_date_and_time && <>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Expected Delivary Date :
                    </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                {moment(order.order_delivery_date_and_time).tz("America/New_York").format("L")}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Expected Delivary Time :
                    </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" gutterBottom>
                                {moment(order.order_delivery_date_and_time).tz("America/New_York").format("LT")}
                            </Typography>
                        </Grid>
                    </>
                }
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={3} justify="flex-end">Reschedule Date : </Grid>
                    <Grid item xs={3}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            minDate={new Date()}
                            margin="normal"
                            id="date-picker-inline"
                            label="MM/dd/yyyy"
                            value={order.order_delivery_reschedule_date_and_time}
                            onChange={(date) => order.order_delivery_reschedule_date_and_time= (date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} justify="flex-end">Reschedule Time : </Grid>
                    <Grid item xs={3}>
                        <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="HH:MM"
                            value={order.order_delivery_reschedule_date_and_time ? moment(order.order_delivery_reschedule_date_and_time).tz("America/New_York") : null}
                            onChange={(date) => order.order_delivery_reschedule_date_and_time= date}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <Typography variant="h5" gutterBottom>
                Order Info:
            </Typography>
            <Grid container className={classes.containerPadding}>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Order Number:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                        {order.order_id}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Order Total:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                    {currency} {order?.order_grand_total}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Order Date:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                        {moment(order.createdAt).tz("America/New_York").format('L')}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Order Time:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                        {moment(order.createdAt).tz("America/New_York").format('LT')}
                    </Typography>
                </Grid>
            </Grid>

        </div>
    )
}
