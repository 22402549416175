import { FETCH_CURRENCY } from './currency.type'
import { fetchCurrency } from '../../Api/api'

export const getCurrency = () => async dispatch => {
    try {
        const { data } = await fetchCurrency()
        dispatch({ type: FETCH_CURRENCY, payload: data })
    } catch (error) {
        console.log(error)
    }
}