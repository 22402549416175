import React from "react";

export const Image = ({ width = 100, height = 100, src }) => {
  return (
    <img
      src={src}
      alt="product"
      width={width}
      height={height}
      style={{
        objectFit: "fill",
        borderRadius: "50%",
        border: "1px solid grey",
      }}
    />
  );
};
