import React, { useState } from "react";

import {
  colors,
  List,
  ListItem,
  SwipeableDrawer,
  makeStyles,
  IconButton,
  Typography,
} from "@material-ui/core";
import { RadioButtonUnchecked } from "@material-ui/icons";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

//Components
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

//pages
// import { NewsSub } from "./NewsSub";
// import { CustomerFeedback } from "./Feedback";
// import { CustomerTestimonial } from "./CustomerTestimonial";
// import { Rating } from "./Rating";
import UsersIndex from "./UsersIndex";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
    // marginTop: 65,
    // zIndex: 200
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
    // marginTop: 65,
    // zIndex: 200
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const UsersPage = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("user");
  const id = match.params.id;

  return (
    <div
      style={{
        paddingTop: "10px",
        width: "100%",
        height: "100vh",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1200 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Users
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "user" ? "var(--orange)" : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => setselectState("user")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Users</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Newsletter Subscribers" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/news-sub`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Newsletter Subscribers</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Customer feedback" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/customer-feedback`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Customer feedback</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Customer Testimonial" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/customer-testimonial`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Customer Testimonial</Typography>
            </div>
          </ListItem> 
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Rating" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/rating`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Rating</Typography>
            </div>
          </ListItem>*/}
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "customer-group"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/${id}/users-basic/customer-group`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Customer Group</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <UsersIndex />
        {/* {selectState === "Newsletter Subscribers" && <NewsSub />}
        {selectState === "Customer feedback" && <CustomerFeedback />}
        {selectState === "Customer Testimonial" && <CustomerTestimonial />}
        {selectState === "Rating" && <Rating />} */}
      </div>
    </div>
  );
};
