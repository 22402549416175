import {
  IconButton,
  List,
  ListItem,
  makeStyles,
  SwipeableDrawer,
  Typography,
  colors,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import React, { useState } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

//Pages
// import { Gallary } from './Gallary'
// import { BannerImageSlide } from "./BannerImageSlide";
import { Fetures } from "./Feture/Fetures";
// import { HomepageBanner } from './HomepageBanner'

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const Frontend = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("socialmedia");
  const id = match.params.id;

  return (
    // <div
    //   style={{
    //     width: "100%",
    //     height: "100%",
    //     background: colors.grey[200],
    //     position: "relative",
    //   }}
    //   className={classes.root}
    // >
    //   {/* <Header /> */}
    //   <div
    //     style={{ position: "fixed", top: "0", left: "30%", zIndex: 1500 }}
    //     className={classes.drawerHeader}
    //   >
    //     <IconButton>
    //       {drawerOpen ? (
    //         <ChevronLeft onClick={() => setdrawerOpen(false)} />
    //       ) : (
    //         <ChevronRight onClick={() => setdrawerOpen(true)} />
    //       )}
    //     </IconButton>
    //   </div>
    //   <SwipeableDrawer
    //     className={classes.drawer}
    //     anchor="left"
    //     variant="persistent"
    //     open={drawerOpen}
    //     onClose={() => setdrawerOpen(false)}
    //     classes={{
    //       paper: classes.drawerPaper,
    //     }}
    //   >
    //     <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
    //       <Typography align="center" variant="h6">
    //         Front End
    //       </Typography>
    //     </div>
    //     <List>
    //       {/* <ListItem>
    //         <div
    //           style={{
    //             cursor: "pointer",
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             color: selectState === "gallery" ? "var(--orange)" : "#ffffff",
    //           }}
    //           onClick={() => setselectState("gallery")}
    //         >
    //           <RadioButtonUnchecked
    //             style={{ fontSize: "1rem", marginRight: 20 }}
    //           />
    //           <Typography>Gallery</Typography>
    //         </div>
    //       </ListItem> */}
    //       <ListItem>
    //         <div
    //           style={{
    //             cursor: "pointer",
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             color:
    //               selectState === "socialmedia" ? "var(--orange)" : "#ffffff",
    //           }}
    //           onClick={() => history.push(`/${id}/frontend`)}
    //         >
    //           <RadioButtonUnchecked
    //             style={{ fontSize: "1rem", marginRight: 20 }}
    //           />
    //           <Typography>Social Media Page Urls</Typography>
    //         </div>
    //       </ListItem>
    //       <ListItem>
    //         <div
    //           style={{
    //             cursor: "pointer",
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             color: selectState === "banner" ? "var(--orange)" : "#ffffff",
    //           }}
    //           onClick={() =>
    //             history.push(`/${id}/frontend/banner-image-slider`)
    //           }
    //         >
    //           <RadioButtonUnchecked
    //             style={{ fontSize: "1rem", marginRight: 20 }}
    //           />
    //           <Typography>Banner - Image Slide</Typography>
    //         </div>
    //       </ListItem>
    //       <ListItem>
    //         <div
    //           style={{
    //             cursor: "pointer",
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             color:
    //               selectState === "Homepage Banner"
    //                 ? "var(--orange)"
    //                 : "#ffffff",
    //           }}
    //           onClick={() => history.push(`/${id}/frontend/homepage-banner`)}
    //         >
    //           <RadioButtonUnchecked
    //             style={{ fontSize: "1rem", marginRight: 20 }}
    //           />
    //           <Typography>Home Page Popup Banner</Typography>
    //         </div>
    //       </ListItem>
    //     </List>
    //   </SwipeableDrawer>
    //   <div
    //     className={clsx(classes.content, {
    //       [classes.contentShift]: drawerOpen,
    //     })}
    //   >
        <Fetures match={id} />
      // {/* </div> */}
    //   {/* {selectState === "banner" && <BannerImageSlide />}
    //   {selectState === "socialmedia" && <SocialMedia />}
    //   {selectState === 'Homepage Banner' && <HomepageBanner />} */}
    // // </div>
  );
};
