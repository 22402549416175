import { useState, useEffect } from "react";

export const useGeolocation = () => {
  const [geoLocation, setgeoLocation] = useState({
    loaded: false,
    coordinates: {
      lat: "",
      lng: "",
    },
  });

  const onSuccess = (location) => {
    setgeoLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });
  };

  const onError = (error) => {
    setgeoLocation({
      loaded: true,
      error,
    });
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({ code: 0, message: "Geolocation not supported" });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  return geoLocation;
};
