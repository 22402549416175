import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Link,
  Paper,
  Typography,
  makeStyles,
  ListItem,
  List,
  SwipeableDrawer,
  Snackbar,
  InputAdornment,
  IconButton,
  colors,
  FormGroup,
  FormControlLabel,
  Grid,
  FormControl,
  Radio,
  RadioGroup,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  CloudUpload,
  Image,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getfetureAll,
  searchCategoryByWord,
  getItemDataByName,
} from "../../redux";
import {
  getHomePagePopupBanner,
  addHomePageBannerImage,
  updateHomepageBanner,
} from "../../redux/homePagePopupbanner/HomePagePopup.action";
//Components
import { IOSSwitch } from "../../components/Buttons/IOSSwitch.component";
import MuiAlert from "@material-ui/lab/Alert";
import { SwipeDrawer } from "./swipeDrawer";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  marginRight: {
    marginRight: "10px",
  },
}));

export const HomepageBanner = ({ match }) => {
  const state = useSelector((state) => state);
  const imageUrl = state?.homepagePopup?.imageUrl;
  const homepageDetails = state?.homepagePopup?.data;
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("Homepage Banner");
  const [value, setValue] = useState({
    status: true,
    home_page_popup_banner_image: "",
    home_page_popup_banner_applied_to: "custom",
    home_page_banner_applied_to_in_app: {},
    applied_to_item_id: null,
    applied_to_main_category_id: null,
    applied_to_sub_category_id: null,
    applied_featured_item_id: null,
    applied_to_custom_link: null,
  });
  const id = match.params.id;

  useEffect(() => {
    dispatch(getHomePagePopupBanner());
  }, [dispatch]);

  const handleChange = (e, name = e.target.name) => {
    setValue({
      ...value, [name]: e.target.value, home_page_banner_applied_to_in_app: {},
      applied_to_item_id: null,
      applied_to_main_category_id: null,
      applied_to_sub_category_id: null,
      applied_featured_item_id: null,
    }
    );
  };

  useEffect(() => {
    value.home_page_popup_banner_applied_to === '' && setValue({
      ...value, applied_to_custom_link : null
    })
  }, [value.home_page_popup_banner_applied_to])

  const dispatchAction = (e) => {
    switch (value.home_page_popup_banner_applied_to) {
      case "category":
        dispatch(searchCategoryByWord(e.target.value));
        break;
      case "feture":
        dispatch(
          getfetureAll(
            `/search_featured_item_by_featured_item_name/${e.target.value}`
          )
        );
        break;
      case "item":
        dispatch(getItemDataByName(e.target.value));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log(homepageDetails);
    setValue({
      ...value, ...homepageDetails,
      home_page_popup_banner_applied_to: homepageDetails.applied_to_item_id ? "item" : homepageDetails.applied_to_main_category_id ? 'category' :
        homepageDetails.applied_featured_item_id ? 'feture' : homepageDetails.applied_to_custom_link ? 'custom' : '',
      applied_to_custom_link:
        homepageDetails.applied_to_custom_link
          ? homepageDetails.applied_to_custom_link
          : null,
      applied_to_item_id:
        homepageDetails.applied_to_item_id
          ? homepageDetails.applied_to_item_id._id : null,
      applied_to_main_category_id:
        homepageDetails.applied_to_main_category_id
          ? homepageDetails.applied_to_main_category_id._id : null,
      applied_featured_item_id:
        homepageDetails.applied_featured_item_id
          ? homepageDetails.applied_featured_item_id._id : null,
      home_page_banner_applied_to_in_app: homepageDetails.applied_to_item_id ? homepageDetails.applied_to_item_id :
        homepageDetails.applied_to_main_category_id ? homepageDetails.applied_to_main_category_id :
          homepageDetails.applied_featured_item_id ? homepageDetails.applied_featured_item_id : {},
    });
  }, [homepageDetails, imageUrl]);


  useEffect(() => {
    imageUrl && setValue({ ...value, home_page_popup_banner_image: imageUrl });
  }, [imageUrl])

  const handleImageUpload = async (image) => {
    try {
      const formData = new FormData();

      formData.append("home_page_popup_banner_image", image);

      dispatch(addHomePageBannerImage(formData));
      // setImage(null)
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    try {
      dispatch(
        updateHomepageBanner(homepageDetails?._id, {
          ...value,
          // applied_to_custom_link:
          //   value.home_page_popup_banner_applied_to === "custom"
          //     ? value.applied_to_custom_link
          //     : null,
          // applied_to_item_id:
          //   value.home_page_popup_banner_applied_to === "item"
          //     ? value.applied_to_item_id : null,
          // applied_to_main_category_id:
          //   value.home_page_popup_banner_applied_to === "category"
          //     ? value.applied_to_main_category_id : null,
          // applied_featured_item_id:
          //   value.home_page_popup_banner_applied_to === "feture"
          //     ? value.applied_featured_item_id : null,
          // hom: value.home_page_popup_banner_applied_to === "item" ? value.applied_to_item_id :
          //   value.home_page_popup_banner_applied_to === "category" ? value.applied_to_main_category_id : 
          //   value.home_page_popup_banner_applied_to === "feture" ? value.applied_featured_item_id._id : {},
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", left: "30%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
          Web And App Builder
          </Typography>
        </div>
        <SwipeDrawer />
      </SwipeableDrawer>
      <div
        style={{ padding: "0 20px" }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            Home Page Banner
          </Typography>
        </Breadcrumbs>
        <form
          onSubmit={(e) => {
            handleSubmit();
            e.preventDefault();
          }}
        >
          <Paper
            style={{
              padding: "2rem",
              margin: "15px auto 5rem auto",
              width: "70%",
            }}
          >
            <Typography variant="h6">Home Page Banner</Typography>
            <Divider style={{ margin: "10px 0" }} />
            <Typography variant="body2">
              Is Home Page Popup Banner Enabled
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={value.status}
                    onChange={(e) => {
                      setValue({ ...value, status: e.target.checked });
                      const variables = {
                        status: e.target.checked,
                        home_page_popup_banner_image:
                          homepageDetails?.home_page_popup_banner_image,
                        home_page_popup_banner_applied_to:
                          value.home_page_popup_banner_applied_to,
                      };
                      dispatch(
                        updateHomepageBanner(homepageDetails?._id, variables)
                      );
                    }}
                  />
                }
              />
            </FormGroup>
            <Typography style={{ marginTop: "2rem" }} variant="body2">
              Home Page Popup Banner Image (1600 px * 700 px)
            </Typography>
            <Box
              style={{
                width: 150,
                height: 100,
                border: "1px solid grey",
                marginTop: 10,
              }}
            >
              {value.home_page_popup_banner_image !== "" && (
                <img
                  src={value.home_page_popup_banner_image}
                  alt="homepage banner"
                  style={{ width: "100%", height: "100%" }}
                />
              )}
            </Box>
            <input
              // accept="image/*"
              style={{ display: "none" }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => {
                handleImageUpload(e.target.files[0]);
              }}
            />
            <label htmlFor="contained-button-file">
              <Button
                className="button"
                startIcon={<CloudUpload />}
                component="span"
                style={{ marginTop: "1rem" }}
                type="submit"
              >
                Upload Image
              </Button>
            </label>
            <Grid container alignItems="center">
              <Grid className={classes.marginRight}>
                <Typography color="secondary">Applied To:</Typography>
              </Grid>
              <Grid>
                <FormControl
                  value={value.home_page_popup_banner_applied_to}
                  onChange={(e) =>
                    handleChange(e, "home_page_popup_banner_applied_to")
                  }
                >
                  <RadioGroup
                    value={value.home_page_popup_banner_applied_to}
                    row
                    required
                  >
                    <FormControlLabel
                      value="custom"
                      control={<Radio color="primary" />}
                      label="Custom Link"
                    />
                    <FormControlLabel
                      value="category"
                      control={<Radio color="primary" />}
                      label="Category"
                    />
                    <FormControlLabel
                      value="feture"
                      control={<Radio color="primary" />}
                      label="Feture"
                    />
                    <FormControlLabel
                      value="item"
                      control={<Radio color="primary" />}
                      label="Item"
                    />
                    <FormControlLabel
                      value=""
                      control={<Radio color="primary" />}
                      label="No Link"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {value.home_page_popup_banner_applied_to ? value.home_page_popup_banner_applied_to !== "custom" ?
              <Autocomplete
                size="small"
                value={value.home_page_banner_applied_to_in_app}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    home_page_banner_applied_to_in_app: newValue,
                    applied_to_main_category_id: value?.home_page_popup_banner_applied_to === "category" ? newValue ? newValue._id : null : null,
                    applied_featured_item_id: value?.home_page_popup_banner_applied_to === "feture" ? newValue ? newValue._id : null : null,
                    applied_to_item_id: value?.home_page_popup_banner_applied_to === "item" ? newValue ? newValue._id : null : null
                  });
                }}
                getOptionLabel={(option) =>
                  value?.home_page_popup_banner_applied_to === "category"
                    ? option.category_name
                    : value?.home_page_popup_banner_applied_to === "feture"
                      ? option.featured_item_name
                      : value?.home_page_popup_banner_applied_to === "item" &&
                      option.item_name
                }
                options={
                  value?.home_page_popup_banner_applied_to === "category"
                    ? state?.catagory?.data
                    : value?.home_page_popup_banner_applied_to === "feture"
                      ? state?.feture?.data
                      : value?.home_page_popup_banner_applied_to === "item" &&
                      state?.item?.data
                }
                renderInput={(params) => (
                  <TextField
                    name="home_page_banner_applied_to_in_app"
                    required={
                      value.home_page_popup_banner_applied_to !== "custom"
                    }
                    // error={!formValue.coupon_applied_to_specific_collection_name &&
                    //   state?.coupon?.response === 'coupon_applied_to_specific_collection_name' &&
                    //   formValue.coupon_applied_to_specific_collection_name === null
                    // }
                    // helperText={!formValue.coupon_applied_to_specific_collection_name &&
                    //   state?.coupon?.response === 'coupon_applied_to_specific_collection_name' &&
                    //   formValue.coupon_applied_to_specific_collection_name === null &&
                    //   state?.coupon?.message
                    // }
                    {...params}
                    variant="outlined"
                    onChange={(event) => dispatchAction(event)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {state?.feture?.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              :
              <TextField
                label="Home Page Popup Banner Link"
                name="applied_to_custom_link"
                variant="outlined"
                size="small"
                required={value.home_page_popup_banner_applied_to === "custom"}
                value={value.applied_to_custom_link}
                onChange={(e) => handleChange(e)}
                style={{ width: "100%", marginTop: "2rem" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Image color="secondary" />
                    </InputAdornment>
                  ),
                }}
              /> : <></>
            }
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginTop="2rem"
            >
              <Button className="button" type="submit" startIcon={<Check />}>
                save
              </Button>
            </Box>
          </Paper>
        </form>
      </div>
      <Snackbar
        open={state.homepagePopup.status}
        onClose={!state.homepagePopup.status}
      >
        <Alert
          onClose={!state.homepagePopup.status}
          severity={state.homepagePopup.status}
        >
          {state.homepagePopup.message}
          {state.feture.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
