import React, { useState } from "react";

import {
  colors,
  List,
  ListItem,
  SwipeableDrawer,
  makeStyles,
  IconButton,
  Typography,
} from "@material-ui/core";
import { RadioButtonUnchecked } from "@material-ui/icons";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

//Components
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { PaymentGateWay } from "../RestaurantBesicPage/PaymentGateWay";
// import { ConfigureSms } from "./ConfigureSms";
// import { ConfigureEmail } from './ConfigureEmail'
// import { GoogleMapApiKey } from './GoogleMapApiKey'
// import { SocialMediaLogin } from './SocialMediaLogin'
// import { PromotionalPage } from './promotionalPage'
// import { AnalyticsSetting } from './AnalyticsSetting'
// import { GDPR } from './GDPR'
// import { HomepageBanner } from './HomepageBanner'
// import { PreOrderDalivery } from './PreOrderDalivery'

//pages

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const AdvanceSettings = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("payment-gateways");
  const id = match.params.id;
  return (
    <div
      style={{
        paddingTop: "10px",
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Advance Settings
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "payment-gateways"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => setselectState("payment-gateways")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Payment Gateways</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "configureSms" ? "var(--orange)" : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/advance-settings/configure-sms`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Configure SMS</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Congifure Email"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/advance-settings/configure-email`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Congifure Email</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Google Map Api Key" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/google-map-api-key`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Google Map Api Key</Typography>
            </div>
          </ListItem> */}
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Social Media Login" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/social-media-login`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Social Media Login</Typography>
            </div>
          </ListItem> */}
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Promotional Page Setting"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/advance-settings/promotional`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Promotional Page Setting</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Analytics Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/analytics-settings`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Analytics Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "GDPR" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/gdpr`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>GDPR</Typography>
            </div>
          </ListItem> */}
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Homepage Banner" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("Homepage Banner")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Home Page Popup Banner</Typography>
            </div>
          </ListItem> */}
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Pre Order Delivery" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/pre-order-delivary`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Pre Order Delivery Days</Typography>
            </div>
          </ListItem> */}
        </List>
      </SwipeableDrawer>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <PaymentGateWay />
        {/* {selectState ==="configureSms" && <ConfigureSms />}
        {selectState === 'Congifure Email' && <ConfigureEmail />}
        {selectState === 'Google Map Api Key' && <GoogleMapApiKey />}
        {selectState === 'Social Media Login' && <SocialMediaLogin />}
        {selectState === 'Promotional Page Setting' && <PromotionalPage />}
        {selectState === 'Analytics Setting' && <AnalyticsSetting />}
        {selectState === 'GDPR' && <GDPR />} */}
        {/* {selectState === 'Homepage Banner' && <HomepageBanner />} */}
        {/* {selectState === 'Pre Order Delivery' && <PreOrderDalivery />} */}
      </div>
    </div>
  );
};
