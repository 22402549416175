import {
  Breadcrumbs,
  Link,
  Typography,
  Paper,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Select,
  Button,
  FormGroup,
  FormControlLabel,
  makeStyles,
  ListItem,
  List,
  SwipeableDrawer,
  IconButton,
  colors,
} from "@material-ui/core";
import {
  ChevronRight,
  Storage,
  Lock,
  Person,
  Check,
  RadioButtonUnchecked,
  ChevronLeft,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import {
  getEmailConfigure,
  updateEmailConfigures,
} from "../../redux/AdvanceSetting/AdvanceSetting.action";

//Components
import { IOSSwitch } from "../../components/Buttons/IOSSwitch.component";
import { Input } from "../../components/Input/Input.component";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const ConfigureEmail = ({ match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("Congifure Email");
  const id = match.params.id;
  const [value, setValue] = useState({
    smpt_host: "",
    smpt_encryption: "",
    smpt_port: "",
    smpt_username: "",
    smpt_password: "",
    status: true,
  });
  const emailConfigure = useSelector(
    (state) => state?.advanceSetting?.emailConfigure?.response
  );

  console.log(value);

  useEffect(() => {
    dispatch(getEmailConfigure());
  }, [dispatch]);

  useEffect(() => {
    emailConfigure?.map((item) => {
      setValue(item);
    });
  }, [emailConfigure]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(updateEmailConfigures(value, emailConfigure[0]?._id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        paddingTop: "10px",
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Advance Settings
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "payment-gateways"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/${id}/advance-settings`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Payment Gateways</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "configureSms" ? "var(--orange)" : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/advance-settings/configure-sms`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Configure SMS</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Congifure Email"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/advance-settings/configure-email`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Congifure Email</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Google Map Api Key" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/google-map-api-key`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Google Map Api Key</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Social Media Login" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/social-media-login`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Social Media Login</Typography>
            </div>
          </ListItem> */}
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Promotional Page Setting"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/advance-settings/promotional`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Promotional Page Setting</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Analytics Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/analytics-settings`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Analytics Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "GDPR" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/gdpr`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>GDPR</Typography>
            </div>
          </ListItem> */}
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Homepage Banner" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("Homepage Banner")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Home Page Popup Banner</Typography>
            </div>
          </ListItem> */}
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Pre Order Delivery" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/pre-order-delivary`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Pre Order Delivery Days</Typography>
            </div>
          </ListItem> */}
        </List>
      </SwipeableDrawer>
      <div
        style={{ paddingLeft: 20, paddingRight: 20 }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            Email Settings
          </Typography>
        </Breadcrumbs>
        <Paper style={{ padding: "2rem", margin: "15px auto", width: "70%" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h6" style={{ marginRight: "5px" }}>
                Email Settings
              </Typography>
              <Link>Test eMail Settings</Link>
            </Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={value.status}
                    onChange={(e) => {
                      const variables = {
                        status: e.target.checked,
                      };
                      setValue({ ...value, status: e.target.checked });
                      dispatch(
                        updateEmailConfigures(variables, emailConfigure[0]?._id)
                      );
                    }}
                  />
                }
              />
            </FormGroup>
          </Box>
          <Divider style={{ margin: "10px 0" }} />
          {value.status && (
            <form onSubmit={handleSubmit}>
              <Input
                style={{ width: "100%", marginTop: "2rem" }}
                value={value.smpt_host}
                onChange={(e) =>
                  setValue({ ...value, smpt_host: e.target.value })
                }
                label="Smtp Host"
                icon={<Storage />}
              />
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: "2rem" }}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Smtp Encryption
                </InputLabel>
                <Select
                  native
                  value={value.smpt_encryption}
                  onChange={(e) =>
                    setValue({ ...value, smpt_encryption: e.target.value })
                  }
                  label="Smtp Encryption"
                  inputProps={{
                    style: { padding: 10 },
                  }}
                >
                  <option value="ssl">SSL</option>
                  <option value="tls">TLS</option>
                </Select>
              </FormControl>
              <Input
                style={{ width: "100%", marginTop: "2rem" }}
                value={value.smpt_port}
                onChange={(e) =>
                  setValue({ ...value, smpt_port: e.target.value })
                }
                label="Smtp Port"
                icon={<Storage />}
              />
              <Input
                style={{ width: "100%", marginTop: "2rem" }}
                value={value.smpt_username}
                onChange={(e) =>
                  setValue({ ...value, smpt_username: e.target.value })
                }
                label="Smtp Username"
                icon={<Person />}
              />
              <Input
                type="password"
                style={{ width: "100%", marginTop: "2rem" }}
                label="Smtp Password"
                value={value.smpt_password}
                onChange={(e) =>
                  setValue({ ...value, smpt_password: e.target.value })
                }
                icon={<Lock />}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop="2rem"
              >
                <Button
                  className="button"
                  style={{ marginRight: "2rem" }}
                  type="submit"
                  startIcon={<Check />}
                >
                  save
                </Button>
                <Link>Test eMail Settings</Link>
              </Box>
            </form>
          )}
        </Paper>
      </div>
    </div>
  );
};
