import { FETCH_CATAGORY_ACCESS,HANDLE_CATAGORY_DATA,FETCH_CATAGORY_SUCCESS,REQUEST_FALIOUR,HANDLE_IMAGE_UPLOAD } from "./catagoryActionTypes";
import {fetchData,postData,updateData,deleteData, searchingCategoryByWord, categorybyStatus} from '../../Api/api'

export const fetchCatagory = () => {
    return {
        type: FETCH_CATAGORY_ACCESS
    }
}

export const fetchCatagorySuccess = (data) => {
    return {
        type: FETCH_CATAGORY_SUCCESS, 
        payload : data
    }
}

export const requestFail = (error) => {
    return {
        type: REQUEST_FALIOUR,
        payload : error
    }
}

export const handelSubmit = (data) => {
    return {
        type : HANDLE_CATAGORY_DATA, 
        payload : data
    }
}

export const uploadImage = (data) => {
    return {
        type : HANDLE_IMAGE_UPLOAD, 
        payload : data
    }
}

export const getAllCatagory = (endpoint = `populate_all_category`) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        fetchData(endpoint).then(
            response => {
                dispatch(fetchCatagorySuccess(response.data))
            }
        ).catch(
            error => {
               dispatch(requestFail(error.message))
            }
        )

    }
}

export const getMainCatagory = () => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        fetchData(`get_all_main_category`).then(
            response => {
                dispatch(fetchCatagorySuccess(response.data))
            }
        ).catch(
            error => {
               dispatch(requestFail(error.message))
            }
        )

    }
}

export const postSubmit = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        postData(`add_main_category`,body).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === 'success' && dispatch(getMainCatagory())
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const postSubSubmit = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        postData(`add_sub_category`,body).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === 'success' && dispatch(getMainCatagory())
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const editMainCatagoryStatus = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        updateData(`update_main_category`,body).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === 'success' && dispatch(getAllCatagory())
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const editSubCatagoryStatus = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        updateData(`update_sub_category`,body).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === 'success' && dispatch(getAllCatagory())
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const editMainCatagory = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        updateData(`update_main_category`,body).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === 'success' && dispatch(getMainCatagory())
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const editSubCatagory = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        updateData(`update_sub_category`,body).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === 'success' && dispatch(getMainCatagory())
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const deleteMainCatagory = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        deleteData(`delete_main_category`,body).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === 'success' && dispatch(getAllCatagory())
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const deleteSubCatagory = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        deleteData(`delete_sub_category`,body).then(
            response => {
                dispatch(handelSubmit(response.data))
                response.data.status === 'success' && dispatch(getAllCatagory())
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const imageUpload = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        postData(`add_category_image`,body).then(
            response => {
                dispatch(uploadImage(response.data))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const imageDelete = (body) => {
    return (dispatch) => {
        dispatch(fetchCatagory())
        postData(`remove_image`,body).then(
            response => {
                dispatch(uploadImage(response.data))
            }
        ).catch(
            error => dispatch(requestFail(error.message))
        )
    }
}

export const searchCategoryByWord = (value) => dispatch => {
    try {
        dispatch(fetchCatagory())
        searchingCategoryByWord(value).then(res => {
            dispatch(fetchCatagorySuccess(res.data))
        }).catch(er => {
            dispatch(requestFail(er.message))
        })
    } catch (error) {
        dispatch(requestFail(error.message))
    }
}

export const categoryByStatus = (value) => dispatch => {
    try {
        dispatch(fetchCatagory())
        categorybyStatus(value).then(res => {
            dispatch(fetchCatagorySuccess(res.data))
        }).catch(er => dispatch(requestFail(er.message)))
    } catch (error) {
        dispatch(requestFail(error.message))
    }
}