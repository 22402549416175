import React, { useState, useEffect } from "react";

import {
  Breadcrumbs,
  colors,
  Typography,
  Link,
  Paper,
  Grid,
  FormControl,
  FormControlLabel,
  Button,
  TextField,
  Checkbox,
  Snackbar,
  RadioGroup,
  Radio
} from "@material-ui/core";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  Email,
  Phone,
  Home,
} from "@material-ui/icons";
import { FaRoad } from "react-icons/all";
import InputAdornment from "@material-ui/core/InputAdornment";
import MuiAlert from "@material-ui/lab/Alert";
import { Autocomplete } from "@material-ui/lab";

//Components
import Map from "../../components/Map/Map.component";

import { useGeolocation } from "../../hooks/useGeoLocation";
import { connect } from "react-redux";
import { postBranch, updateBranch, getAllCountry, getAddress, getTimeZone, getAllstate } from "../../redux";
import { useHistory } from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateBranchPage = ({ state, fetchApi, location, match }) => {
  const history = useHistory();
  const id = match.params.id;
  // const [showPassword, setshowPassword] = useState(false)
  // const [showConfirmPassword, setshowConfirmPassword] = useState(false)
  const [branchFromData, setBranchFromData] = useState({
    branch_contact_email: "",
    branch_order_cc_mail: "",
    branch_contact_number1: "",
    branch_contact_number2: "",
    branch_name: "",
    branch_street_number: "",
    branch_area: "",
    branch_city: "",
    branch_address: "",
    branch_state: "",
    branch_country: "",
    country: {},
    branch_country_code: "",
    branch_phone_code: "",
    branch_country_currency: "",
    branch_time_zone: "",
    time_zone: {},
    branch_latitute: "",
    branch_longitute: "",
    branch_delivery_charge_base_km: "",
    branch_delivery_charge_base_price: "",
    branch_delivery_charge_additional_per_km: "",
    branch_delivery_time_base_km: "",
    branch_delivery_time_base_minutes: "",
    branch_delivery_time_additional_per_km: "",
    branch_secret_password: "",
    branch_payment_is_cod_available: false,
    branch_pickup_from_branch_available: false,
    branch_delivery_from_branch_available: false,
    branch_reservation_from_branch_available: false
  });

  useEffect(() => {
    branchFromData.branch_latitute && branchFromData.branch_longitute && fetchApi(getAddress(branchFromData.branch_latitute, branchFromData.branch_longitute))
    branchFromData.branch_latitute && branchFromData.branch_longitute && fetchApi(getTimeZone(branchFromData.branch_latitute, branchFromData.branch_longitute))
  }, [branchFromData.branch_latitute, branchFromData.branch_longitute])

  useEffect(() => {
    state?.branch?.address && !state?.countryCurrency?.loader && setBranchFromData({
      ...branchFromData, branch_state: state?.countryCurrency?.states?.find(res => res === state?.branch?.address?.branch_state),
      country: state?.countryCurrency?.allCountry?.find(res => res.name === state?.branch?.address?.country),
      branch_address: state?.branch?.address?.branch_address, branch_city: state?.branch?.address?.branch_city,
      branch_time_zone: state?.branch?.time_zone,
      branch_country_currency: branchFromData?.country?.currency_symbol,
      branch_phone_code: branchFromData?.country?.phone
    })
  }, [state?.branch])

  useEffect(() => {
    fetchApi(getAllstate(branchFromData.branch_country))
  }, [branchFromData.branch_country])

  useEffect(() => {
    if (location && location.state) {
      console.log(location.state)
      setBranchFromData({
        ...branchFromData, ...location.state,
      });
    }
  }, [location]);

  useEffect(() => {
    if (state.branch.status === "success") {
      setBranchFromData({
        branch_contact_email: "",
        branch_order_cc_mail: "",
        branch_contact_number1: "",
        branch_contact_number2: "",
        branch_name: "",
        branch_street_number: "",
        branch_area: "",
        branch_city: "",
        branch_address: "",
        branch_state: "",
        branch_country: "",
        country: {},
        branch_country_code: "",
        branch_phone_code: "",
        branch_country_currency: "",
        branch_time_zone: "",
        time_zone: {},
        branch_delivery_charge_base_km: "",
        branch_delivery_charge_base_price: "",
        branch_delivery_charge_additional_per_km: "",
        branch_delivery_time_base_km: "",
        branch_delivery_time_base_minutes: "",
        branch_delivery_time_additional_per_km: "",
        branch_secret_password: "",
        branch_payment_is_cod_available: false,
        branch_pickup_from_branch_available: false,
        branch_delivery_from_branch_available: false,
        branch_reservation_from_branch_available: false
      });
      location.state &&
        setTimeout(() => {
          history.push(
            `/${state?.login?.data?.adminstrator_branch_id}/restaurant-basic`
          );
        }, 500);
    }
  }, [state?.branch]);

  //  const passwordCheck = (event) => {
  //     event.target.value !== branchFromData.branch_password
  //  }

  const geoLocation = useGeolocation();

  const handleDragEnd = (t, map, c) => {
    try {
      setBranchFromData({
        ...branchFromData,
        branch_latitute: c.latLng.lat(),
        branch_longitute: c.latLng.lng(),
      });
      console.log(c.latLng.lat(), c.latLng.lng());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!location?.state) {
      geoLocation.loaded &&
        setBranchFromData({
          ...branchFromData,
          branch_latitute: geoLocation?.coordinates?.lat,
          branch_longitute: geoLocation?.coordinates?.lng,
        });
    }
  }, [geoLocation, location?.state]);

  useEffect(() => {
    fetchApi(getAllCountry())
  }, [fetchApi])

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            paddingTop: "10px",
            paddingLeft: 120,
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
            paddingRight: 20,
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRight style={{ fontSize: 15 }} />}
          >
            <Link color="inherit" href="/" style={{ fontSize: 15 }}>
              Home
            </Link>
            <Link
              color="inherit"
              href={`/${id}/restaurant-basic`}
              style={{ fontSize: 15 }}
            >
              Branch
            </Link>
            <Typography
              color="textPrimary"
              style={{ fontSize: 15, color: "var(--orange)" }}
            >
              Update your branch details
            </Typography>
          </Breadcrumbs>
          <Grid container>
            <Grid item sm={6} xs={12}>
              <Paper style={{ padding: "2rem", marginTop: "3%" }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log(branchFromData);
                    branchFromData._id ? fetchApi(updateBranch(branchFromData)) : fetchApi(postBranch(branchFromData))
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        label="Contact Email"
                        value={branchFromData.branch_contact_email}
                        name="branch_contact_email"
                        onChange={(e) =>
                          setBranchFromData({
                            ...branchFromData,
                            branch_contact_email: e.target.value,
                          })
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                        icon={<Email />}
                        type="email"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        label="Order Cc Mail"
                        value={branchFromData.branch_order_cc_mail}
                        name="cc_mail"
                        onChange={(e) =>
                          setBranchFromData({
                            ...branchFromData,
                            branch_order_cc_mail: e.target.value,
                          })
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                        icon={<Email />}
                        type="email"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        label="Contact Number"
                        value={branchFromData.branch_contact_number1}
                        name="branch_contact_number1"
                        onChange={(e) =>
                          setBranchFromData({
                            ...branchFromData,
                            branch_contact_number1: e.target.value,
                          })
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                        icon={<Phone />}
                        type="tel"
                        // min='10'
                        max={10}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        label="Alternate Contact Number(Optional)"
                        value={branchFromData.branch_contact_number2}
                        name="branch_contact_number2"
                        onChange={(e) =>
                          setBranchFromData({
                            ...branchFromData,
                            branch_contact_number2: e.target.value,
                          })
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                        icon={<Phone />}
                        type="tel"
                        // min='10'
                        max={10}
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    label="Branch Name"
                    fullWidth
                    value={branchFromData.branch_name}
                    name="branch_name"
                    onChange={(e) =>
                      setBranchFromData({
                        ...branchFromData,
                        branch_name: e.target.value,
                      })
                    }
                    style={{ marginBottom: "1rem" }}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    required
                    label="Branch Address"
                    fullWidth
                    value={branchFromData.branch_address}
                    name="branch_address"
                    onChange={(e) =>
                      setBranchFromData({
                        ...branchFromData,
                        branch_address: e.target.value,
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Home />
                        </InputAdornment>
                      ),
                    }}
                    style={{ marginBottom: "1rem" }}
                  />

                  <div
                    style={{
                      margin: "0 0 1rem 0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField type="number"
                      variant="outlined"
                      size="small"
                      required
                      label="Latitude"
                      value={branchFromData.branch_latitute}
                      name="branch_latitute"
                      onChange={(e) =>
                        setBranchFromData({
                          ...branchFromData,
                          branch_latitute: e.target.value,
                        })
                      }
                      style={{ width: "49%" }}
                    />
                    <TextField type="number"
                      variant="outlined"
                      size="small"
                      required
                      label="Longitude"
                      value={branchFromData.branch_longitute}
                      name="branch_longitute"
                      onChange={(e) =>
                        setBranchFromData({
                          ...branchFromData,
                          branch_longitute: e.target.value,
                        })
                      }
                      style={{ width: "49%" }}
                    />
                  </div>

                  <Autocomplete
                    options={branchFromData?.country?.timezones ? branchFromData?.country?.timezones : []}
                    value={branchFromData.branch_time_zone}
                    onChange={(e, newValue) => setBranchFromData({
                      ...branchFromData,
                      branch_time_zone: newValue ? newValue : "",
                    })}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} label="Time Zone" required variant="outlined" size="small"
                      style={{ width: '100%', marginBottom: '1rem' }} />}
                  />

                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Autocomplete
                        options={state.countryCurrency.allCountry}
                        value={branchFromData.country}
                        onChange={(e, newValue) => setBranchFromData({
                          ...branchFromData,
                          country: newValue,
                          branch_time_zone: "",
                          branch_country: newValue ? newValue.name : "",
                          branch_country_code: newValue ? newValue.country_code : "",
                          branch_country_currency: newValue ? newValue.currency_symbol : "",
                          branch_phone_code: newValue ? newValue.phone : "",
                          branch_state: "",
                        })}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Country" required variant="outlined" size="small"
                          style={{ width: '100%', marginBottom: '1rem' }} />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        options={state?.countryCurrency?.loader ? [] : state?.countryCurrency?.states}
                        value={branchFromData.branch_state}
                        onChange={(e, newValue) => setBranchFromData({
                          ...branchFromData,
                          branch_state: newValue ? newValue : "",
                        })}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="States" required variant="outlined" size="small"
                          style={{ width: '100%', marginBottom: '1rem' }} />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        variant="outlined"
                        size="small"
                        required
                        label="City"
                        value={branchFromData.branch_city}
                        name="branch_city"
                        onChange={(e) =>
                          setBranchFromData({
                            ...branchFromData,
                            branch_city: e.target.value,
                          })
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>

                  </Grid>

                  <Grid container spacing={2}>
                    {/* <Grid item xs={3}>
                      <TextField disabled
                        variant="outlined"
                        size="small"
                        required
                        label="Phone"
                        value={branchFromData.branch_country_code}
                        name="branch_country_code"
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid> */}
                    <Grid item xs={6}>
                      <TextField disabled
                        variant="outlined"
                        size="small"
                        required
                        label="Currency"
                        value={branchFromData.branch_country_currency && String.fromCharCode(branchFromData.branch_country_currency)}
                        name="branch_country_currency"
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField disabled
                        variant="outlined"
                        size="small"
                        required
                        label="Phone"
                        value={branchFromData.branch_phone_code}
                        name="branch_phone_code"
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                  </Grid>


                  <Grid container>
                    <Grid item xs={7}>
                      <Typography style={{ marginTop: "10px" }}>Do you offer pickup from your branch?</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <RadioGroup
                        row>
                        <FormControlLabel
                          control={<Radio color="primary" checked={branchFromData.branch_pickup_from_branch_available}
                            onChange={() => setBranchFromData({ ...branchFromData, branch_pickup_from_branch_available: true })} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" checked={!branchFromData.branch_pickup_from_branch_available}
                            onChange={() => setBranchFromData({ ...branchFromData, branch_pickup_from_branch_available: false })} />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={7}>
                      <Typography style={{ marginTop: "10px" }}>Do you offer delivery from your branch?</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <RadioGroup
                        row>
                        <FormControlLabel
                          control={<Radio color="primary" checked={branchFromData.branch_delivery_from_branch_available}
                            onChange={() => setBranchFromData({ ...branchFromData, branch_delivery_from_branch_available: true })} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" checked={!branchFromData.branch_delivery_from_branch_available}
                            onChange={() => setBranchFromData({ ...branchFromData, branch_delivery_from_branch_available: false })} />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={7}>
                      <Typography style={{ marginTop: "10px" }}>Do you offer Reservations from your branch?</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <RadioGroup
                        row>
                        <FormControlLabel
                          control={<Radio color="primary" checked={branchFromData.branch_reservation_from_branch_available}
                            onChange={() => setBranchFromData({ ...branchFromData, branch_reservation_from_branch_available: true })} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" checked={!branchFromData.branch_reservation_from_branch_available}
                            onChange={() => setBranchFromData({ ...branchFromData, branch_reservation_from_branch_available: false })} />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>


                  <div style={{ display: "flex" }}>
                    <TextField type="number"
                      variant="outlined"
                      size="small"
                      required
                      helperText="Delivery Charge Base km"
                      value={branchFromData.branch_delivery_charge_base_km}
                      name="branch_delivery_charge_base_km"
                      onChange={(e) =>
                        setBranchFromData({
                          ...branchFromData,
                          branch_delivery_charge_base_km: e.target.value,
                        })
                      }
                      style={{ width: "33%", marginRight: "1rem" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>Km</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField type="number"
                      variant="outlined"
                      size="small"
                      required
                      helperText="Delivery Charge - Base Price"
                      value={branchFromData.branch_delivery_charge_base_price}
                      name="branch_delivery_charge_base_price"
                      onChange={(e) =>
                        setBranchFromData({
                          ...branchFromData,
                          branch_delivery_charge_base_price: e.target.value,
                        })
                      }
                      style={{ width: "33%", marginRight: "1rem" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>{branchFromData.branch_country_currency && String.fromCharCode(branchFromData.branch_country_currency)}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField type="number"
                      variant="outlined"
                      size="small"
                      required
                      helperText="Delivery Charge Additional Per km"
                      value={
                        branchFromData.branch_delivery_charge_additional_per_km
                      }
                      name="branch_delivery_charge_additional_per_km"
                      onChange={(e) =>
                        setBranchFromData({
                          ...branchFromData,
                          branch_delivery_charge_additional_per_km: e.target.value
                        })
                      }
                      style={{ width: "33%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>{branchFromData.branch_country_currency && String.fromCharCode(branchFromData.branch_country_currency)}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextField type="number"
                      variant="outlined"
                      size="small"
                      required
                      helperText="Delivery Time Base km"
                      value={branchFromData.branch_delivery_time_base_km}
                      name="branch_delivery_time_base_km"
                      onChange={(e) =>
                        setBranchFromData({
                          ...branchFromData,
                          branch_delivery_time_base_km: e.target.value,
                        })
                      }
                      style={{ width: "33%", marginRight: "1rem" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>
                              {
                                state?.configarationSettings?.data
                                  ?.configuration_distance_unit
                              }
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField type="number"
                      variant="outlined"
                      size="small"
                      required
                      helperText="Delivery Time - Base Minutes"
                      value={branchFromData.branch_delivery_time_base_minutes}
                      name="branch_delivery_time_base_minutes"
                      onChange={(e) =>
                        setBranchFromData({
                          ...branchFromData,
                          branch_delivery_time_base_minutes: e.target.value,
                        })
                      }
                      style={{ width: "33%", marginRight: "1rem" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>Min</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField type="number"
                      variant="outlined"
                      size="small"
                      required
                      helperText="Extra Traffic Time Per km"
                      value={
                        branchFromData.branch_delivery_time_additional_per_km
                      }
                      name="branch_delivery_time_additional_per_km"
                      onChange={(e) =>
                        setBranchFromData({
                          ...branchFromData,
                          branch_delivery_time_additional_per_km:
                            e.target.value,
                        })
                      }
                      style={{ width: "33%" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography>Min</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            branchFromData.branch_payment_is_cod_available
                          }
                          onChange={(e) =>
                            setBranchFromData({
                              ...branchFromData,
                              branch_payment_is_cod_available: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label="Cash On Delivary"
                      labelPlacement="end"
                    />
                  </FormControl>

                  {/* <PickupAndDeliveryPage /> */}

                  <div
                    style={{
                      width: "250px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "auto",
                    }}
                  >
                    <Button
                      className="button"
                      startIcon={<ChevronLeft />}
                      onClick={() =>
                        history.push(
                          `/${state?.login?.data?.adminstrator_branch_id}/restaurant-basic`
                        )
                      }
                    >
                      back
                    </Button>
                    {location.state ? (
                      <Button type="submit"
                        className="button"
                        startIcon={<Check />}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        className="button"
                        type="submit"
                        startIcon={<Check />}
                      >
                        save
                      </Button>
                    )}
                  </div>
                </form>
              </Paper>
            </Grid>

            <Grid item sm={6} xs={12}>
              <div
                style={{ marginLeft: 25, position: "relative", width: "100%" }}
              >
                {geoLocation.loaded && (
                  <Map
                    height="550px"
                    lat={branchFromData?.branch_latitute}
                    lng={branchFromData?.branch_longitute}
                    position="fixed"
                    width="40%"
                    dragend={handleDragEnd}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Snackbar open={state.branch.status} onClose={!state.branch.status}>
            <Alert
              onClose={!state.branch.status}
              severity={state.branch.status}
            >
              {state.branch.message}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (dispatchFun) => dispatch(dispatchFun),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBranchPage);
