import { fetchHomePagepopupBanner, addHomePagebannerImage, updateHomePagePopup } from '../../Api/api'
import { FETCH_HOMEPAGE_POPUP_BANNER, ADD_HOMEPAGE_POPUP_IMAGE, ADD_HOMEPAGE_POPUP_IMAGE_SUCCESS, ADD_HOMEPAGE_POPUP_IMAGE_FALIUR, UPDATE_HOMRPAGE_POPUP_BANNER } from './HomePagePopup.type'

export const getHomePagePopupBanner = () => async dispatch => {
    try {
        const { data } = await fetchHomePagepopupBanner()

        dispatch({ type: FETCH_HOMEPAGE_POPUP_BANNER, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const addHomePageBannerImage = (image) => dispatch => {
    try {
        dispatch({ type: ADD_HOMEPAGE_POPUP_IMAGE })
        addHomePagebannerImage(image).then(res => dispatch({ type: ADD_HOMEPAGE_POPUP_IMAGE_SUCCESS, payload: res.data })).catch(err => dispatch({ type: ADD_HOMEPAGE_POPUP_IMAGE_FALIUR, payload: err.message }))
    } catch (error) {
        dispatch({ type: ADD_HOMEPAGE_POPUP_IMAGE_FALIUR, payload: error.message })
    }
} 

export const updateHomepageBanner = (id, body) => async dispatch => {
    try {
        const variables = {
            _id: id,
            ...body
        }

        const { data } = await updateHomePagePopup(variables)

        dispatch({ type: UPDATE_HOMRPAGE_POPUP_BANNER, payload: data })
        dispatch(getHomePagePopupBanner())
    } catch (error) {
        console.log(error)
    }
}