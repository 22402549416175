import {
  Breadcrumbs,
  Typography,
  Link,
  Paper,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Snackbar,
  makeStyles,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  colors
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { ChevronRight, Check, ChevronLeft, RadioButtonUnchecked } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import clsx from "clsx";

import { updatePickupDelivaryOption, getPickupDelivaryOption } from "../../redux/pickupDelivary/pickupDelivaryActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const PickupAndDeliveryPage = ({ match }) => {
  const classes = useStyles()
  const history = useHistory()
  const state = useSelector(state => state)
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("pickup");
  const dispatch = useDispatch()
  const id = match.params.id
  const [value, setValue] = React.useState({
    _id: '',
    pickup: "true",
    delivery: "true",
    reservation: "true",
    pickup_from_branch_available: true,
    delivery_from_branch_available: false,
    reservation_from_branch_available: true
  })

  useEffect(() => {
    state?.login?.data?.adminstrator_branch_id && dispatch(getPickupDelivaryOption(state?.login?.data?.adminstrator_branch_id))
  }, [dispatch])

  useEffect(() => {
    setValue({
      ...value, _id: state?.pickupDelivary?.data?._id,
      pickup: state?.pickupDelivary?.data.pickup_from_branch_available?.toString(),
      delivery: state?.pickupDelivary?.data?.delivery_from_branch_available?.toString(),
      reservation: state?.pickupDelivary?.data.reservation_from_branch_available?.toString(),
    }
    )
  }, [state?.pickupDelivary?.data?._id])

  return (
    <>
      {
        !state?.branch?.loader &&
        <div
          style={{
            width: "100%",
            height: "100vh",
            background: colors.grey[200],
            position: "relative",
          }} className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0%", right: "63%", zIndex: 1100 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Restaurant Basics
          </Typography>
            </div>
            <List>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: selectState === "branch" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer"
                  }}
                  onClick={() => history.push(`/${id}/restaurant-basic`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Branch</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: selectState === "pickup" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer"
                  }}
                  onClick={() => history.push(`/${id}/restaurant-basic/pickup-delivary`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Pickup/Delivery</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "deliveryzone" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer"
                  }}
                  onClick={() => history.push(`/${id}/restaurant-basic/deliveryzone`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Delivery Zones</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "operationalhours"
                        ? "var(--orange)"
                        : "#ffffff",
                    cursor: "pointer"
                  }}
                  onClick={() => history.push(`/${id}/restaurant-basic/operationalhours`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Operational Hours</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: selectState === "coupon" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer"
                  }}
                  onClick={() => history.push(`/${id}/restaurant-basic/coupon`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Coupon</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#ffffff",
                    cursor: "pointer"
                  }}
                  onClick={() => history.push(`/${id}/restaurant-basic/fetures`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Feture</Typography>
                </div>
              </ListItem>
            </List>
          </SwipeableDrawer>
          <div style={{ paddingLeft: 20, paddingRight: 20, }} className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
                  </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Pickup options
                  </Typography>
            </Breadcrumbs>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper
                style={{
                  padding: "2rem",
                  marginTop: "15px",
                  width: 600,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body1" style={{ fontSize: "1.3rem" }}>
                  Pickup Options
                    </Typography>
                <Divider style={{ margin: "8px 0 8px 0" }} />

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="body2">
                    {" "}
                        Do you offer pickup from your branch?
                      </Typography>
                  <RadioGroup
                    style={{
                      display: "inline",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    value={value.pickup}
                    onChange={e => setValue({ ...value, pickup: e.target.value, pickup_from_branch_available: e.target.value === 'true' ? true : false })}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio style={{ color: "var(--orange)" }} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio style={{ color: "var(--orange)" }} />}
                      label="No"
                    />
                  </RadioGroup>
                  <Typography variant="body2" style={{ marginTop: 10 }}>
                    {" "}
                        Do you offer delivery from your branch?
                      </Typography>
                  <RadioGroup
                    style={{
                      display: "inline",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    value={value.delivery}
                    onChange={(e) => setValue({ ...value, delivery: e.target.value, delivery_from_branch_available: e.target.value === 'true' ? true : false })}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio style={{ color: "var(--orange)" }} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio style={{ color: "var(--orange)" }} />}
                      label="No"
                    />
                  </RadioGroup>
                  <Typography variant="body2" style={{ marginTop: 10 }}>
                    {" "}
                        Do you offer Reservations from your branch?
                      </Typography>
                  <RadioGroup
                    style={{
                      display: "inline",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    value={value.reservation}
                    onChange={(e) => setValue({ ...value, reservation: e.target.value, reservation_from_branch_available: e.target.value === 'true' ? true : false })}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio style={{ color: "var(--orange)" }} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio style={{ color: "var(--orange)" }} />}
                      label="No"
                    />
                  </RadioGroup>
                </div>
                <Button
                  className="button"
                  startIcon={<Check />}
                  style={{
                    alignSelf: "flex-end",
                    width: 100,
                  }}
                  onClick={() => dispatch(updatePickupDelivaryOption(value))}>
                  save
                    </Button>
              </Paper>
              <Snackbar open={state.pickupDelivary.status}
                onClose={state.pickupDelivary.status}>
                <Alert onClose={!state.pickupDelivary.status} severity={state.pickupDelivary.status}>
                  {state.pickupDelivary.message}
                </Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      }
    </>);
};
