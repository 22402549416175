import React, { useState } from 'react'
import { Box, colors, Typography, Divider, Paper, Button, TextField, makeStyles, Snackbar,InputAdornment, IconButton } from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "@material-ui/lab";
import { changepassword, handlechangepassword } from '../../redux/login/loginActions';
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyle = makeStyles(() => ({
    formControl: {
        marginBottom: "1.5rem"
    },
    changpwbg: {
        width: "100%",
        height: `calc(100vh - 65px)`,
        background: colors.grey[200],
        position: "relative",
        display: 'flex',
        justifyContent: "center",
        alignItems: "center"
    },
    padding: {
        padding: "30px"
    }
}))

export const ChangePassword = () => {
    const classes = useStyle()
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const [formValue, setformValue] = useState({
        current_password: "",
        current_password_see : false,
        new_password: "",
        new_password_see : false,
        repeat_new_password: "",
        repeat_new_password_see: false
    })
    const onchange = (e) => {
        setformValue({ ...formValue, [e.target.name]: e.target.value })
    }

    const onsubmit = (e) => {
        dispatch(changepassword({ ...formValue, _id: state?.login?.data?._id }))
        console.log({ ...formValue, _id: state?.login?.data?._id })
        e.preventDefault()
    }

    return (
        <div className={classes.changpwbg}>
            <Paper className={classes.padding}>
                <Typography variant="h6" >Change password</Typography>
                <Divider style={{ margin: '20px 0' }} />
                <form onSubmit={e => onsubmit(e)}>
                    <TextField type={formValue.current_password_see ? "text" : "password"} variant="outlined" size="small" 
                        fullWidth className={classes.formControl} 
                        error={formValue.current_password && formValue.current_password.length < 8}
                        helperText={formValue.current_password && formValue.current_password.length < 8 && "Password Length Should Be Atleast 8"}
                        required label='Current password' name="current_password" 
                        value={formValue.current_password} onChange={(e) => onchange(e)} 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setformValue({...formValue, current_password_see: !formValue.current_password_see})}
                                    onMouseDown={() => setformValue({...formValue, current_password_see: false})}
                                    edge="end"
                                  >                                    
                                  {!formValue.current_password_see ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>,
                          }}
                        />
                    <TextField type={formValue.new_password_see ? "text" : "password"}
                        error={formValue.new_password && formValue.new_password.length < 8}
                        helperText={formValue.new_password && formValue.new_password.length < 8 && "Password Length Should Be Atleast 8"}
                        variant="outlined" size="small" fullWidth className={classes.formControl} label='New password'
                        required name="new_password" value={formValue.new_password} onChange={(e) => onchange(e)} 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setformValue({...formValue, new_password_see: !formValue.new_password_see})}
                                    onMouseDown={() => setformValue({...formValue, new_password_see: false})}
                                    edge="end"
                                  >                                    
                                  {!formValue.new_password_see ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>,
                          }}/>
                    <TextField type={formValue.repeat_new_password_see ? "text" : "password"}
                        error={formValue.repeat_new_password && formValue.repeat_new_password.length < 8}
                        helperText={formValue.repeat_new_password && formValue.repeat_new_password.length < 8 && "Password Length Should Be Atleast 8"}
                        variant="outlined" size="small" fullWidth className={classes.formControl} label='New password repeat'
                        required name="repeat_new_password" value={formValue.repeat_new_password} onChange={(e) => onchange(e)} 
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                 <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setformValue({...formValue, repeat_new_password_see: !formValue.repeat_new_password_see})}
                                    onMouseDown={() => setformValue({...formValue, repeat_new_password_see: false})}
                                    edge="end"
                                  >                                    
                                  {!formValue.repeat_new_password_see ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>,
                          }}/>
                    <Box display='flex' alignItems="center" justifyContent='flex-end'>
                        <Button className="text-white" color="primary" variant="contained" type="button">cancel</Button>
                        <Button style={{ marginLeft: 20 }} color="primary" variant="contained" type="submit" className="text-white"
                            disabled={formValue.repeat_new_password.length < 8 || formValue.current_password.length < 8 || formValue.new_password.length < 8}
                        >submit</Button>
                    </Box>
                </form>
            </Paper>
            <Snackbar
                open={state.login.status}
                onClose={() => dispatch(handlechangepassword())}>
                <Alert
                    onClose={() => dispatch(handlechangepassword())}
                    severity={state.login.status}
                >
                    {state.login.message}
                </Alert>
            </Snackbar>
        </div>
    )
}
