import React from 'react'
import { Switch, Route, BrowserRouter as Router,Redirect } from "react-router-dom";

// import {useSelector} from 'react-redux';
// import { useHistory } from "react-router-dom";

import { Header } from '../../components/Header/Header.component'

import { DashBoard } from "../DashBoard/DashBoard";
import { OrderPage } from "../OrderPage/OrderPage";
import { RestaurantBesicPage } from "../RestaurantBesicPage/RestaurantBesicPage";
import { UsersPage } from "../UsersPage/UsersPage.jsx";
import  CreateBranchPage from "../CreateBranchPage/CreateBranchPage";
import { MenuSetup } from "../MenuSetup/MenuSetup";
import  CreateCategory  from "../CreateCategory/CreateCategory";
import { CreateItem } from "../CreateItem/CreateItem";
import { DelivaryBoy } from "../DelivaryBoy/DelivaryBoy.jsx";
import { Frontend } from "../Frontend/Frontend";
import  AddIngredient from "../AddIngredient/AddIngredient";
import {Settings} from "../Settings/Settings"
import { CmsPage } from "../CmsSocialMedia/CmsPage";
import { AdvanceSettings } from "../Advance-Settings/AdvanceSettings";
import {CreateDelivaryBoy} from '../DelivaryBoy/CreateDelivaryBoy'
import { Preferences } from '../RestaurantBesicPage/Preferences'
import { Report } from '../Report/Report'
import { AccessControl } from '../AccessControl/AccessControl'
import { Profile } from '../Profile/Profile'
import { ChangePassword } from '../Profile/ChangePassword'
import { AccountUpdate } from '../Profile/AccountUpdate'
import { DelevaryZones } from '../RestaurantBesicPage/DelevaryZones'
import { Operationalhours } from '../RestaurantBesicPage/Operationalhours'
import { PickupAndDeliveryPage } from '../RestaurantBesicPage/PickupAndDeliveryPage'
import { CuponsAndOffers } from '../Settings/CuponsAndOffers/CuponsAndOffers'
import { ItemSetupPage } from '../MenuSetup/ItemSetupPage'
import Ingredient from '../MenuSetup/Ingredient'
import { CustomerTestimonial } from '../UsersPage/CustomerTestimonial'
import { CustomerFeedback } from '../UsersPage/Feedback'
import { NewsSub } from '../UsersPage/NewsSub'
import { Rating } from '../UsersPage/Rating'
import { CustomerGroup } from '../UsersPage/CustomerGroup'
import { CustomerList } from '../UsersPage/CustomerList'
import { Brands } from '../Settings/Brands'
import { IconLogo } from '../Settings/IconLogo'
import { LanguageSettings } from '../Settings/Language'
import { Multicountry } from '../Settings/Multicountry'
import { Onesignal } from '../Settings/OneSignal'
import { ThemeSettings } from '../Settings/ThemeSettings'
import PushNotifications from '../Settings/PushNotification'
import { BannerImageSlide } from '../Frontend/BannerImageSlide'
import { HomepageBanner } from '../Frontend/HomepageBanner'
import { SocialMedia } from '../CmsSocialMedia/SocialMediia'
import { AnalyticsSetting } from '../Advance-Settings/AnalyticsSetting'
import { ConfigureEmail } from '../Frontend/ConfigureEmail'
import { ConfigureSms } from '../Frontend/ConfigureSms'
import { GDPR } from '../Advance-Settings/GDPR'
import { GoogleMapApiKey } from '../Advance-Settings/GoogleMapApiKey'
import { PreOrderDalivery } from '../Advance-Settings/PreOrderDalivery'
import { SocialMediaLogin } from '../Advance-Settings/SocialMediaLogin'
import { PromotionalPage } from '../Report/promotionalPage'
import { CreateAdministrator } from "../AccessControl/CreateAdministrator";
import { Administrator } from '../AccessControl/Administrator'
import { CreateRole } from '../AccessControl/CreateRole'
import { Fetures } from '../Frontend/Feture/Fetures';
import { PaymentGateWay } from '../RestaurantBesicPage/PaymentGateWay';
import { MenuTime } from '../RestaurantBesicPage/MenuTime';


export function AdminPage() {
    // const history = useHistory();
    // const state = useSelector(state => state)
    return (
        <>
            <Router>
                    <Header />
                <Switch>
                    <Route path="/" component={DashBoard} exact />
                    <Route path="/:id/orders-page" component={OrderPage} exact />
                    <Route path="/:id/menu-setup" component={MenuSetup} exact />
                    <Route path='/:id/menu-setup/ingredient' component={Ingredient} />
                    <Route path='/:id/menu-setup/item-setup' component={ItemSetupPage} />
                    <Route path="/:id/create-delivaryboy" component={CreateDelivaryBoy} exact />
                    <Route path="/:id/menu-setup/create-category" component={CreateCategory} exact />
                    <Route path="/:id/menu-setup/create-item" component={CreateItem} exact />
                    <Route path="/:id/menu-setup/create-ingredient" component={AddIngredient} exact />
                    <Route path="/:id/users-basic" component={UsersPage} exact />
                    {/* <Route path='/:id/users-basic/customer-testimonial' component={CustomerTestimonial} /> */}
                    {/* <Route path='/:id/users-basic/news-sub' component={NewsSub} /> */}
                    {/* <Route path='/:id/users-basic/customer-feedback' component={CustomerFeedback} /> */}
                    {/* <Route path='/:id/users-basic/rating' component={Rating} /> */}
                    <Route path='/:id/users-basic/customer-group' component={CustomerGroup} />
                    <Route path='/:id/users-basic/customer-list' component={CustomerList} />
                    <Route path="/:id/delivary-boy" component={DelivaryBoy} exact />
                    <Route path="/:id/frontend" component={Frontend} exact />
                    <Route path='/:id/frontend/banner-image-slider' component={BannerImageSlide} />
                    <Route path='/:id/frontend/homepage-banner' component={HomepageBanner} />
                    {/* <Route path='/:id/frontend/social-media' component={SocialMedia} /> */}
                    {/* <Route path="/:id/settings" component={Multicountry} /> */}
                    {/* <Route path="/:id/user-settings/brands" component={Brands} /> */}
                    {/* <Route path="/:id/user-settings/icon-logo" component={IconLogo} /> */}
                    {/* <Route path="/:id/user-settings/language-setting" component={LanguageSettings} /> */}
                    {/* <Route path="/:id/user-settings/multi-country" component={Multicountry} /> */}
                    {/* <Route path="/:id/user-settings/one-signal" component={Onesignal} /> */}
                    {/* <Route path="/:id/user-settings/them-setting" component={ThemeSettings} /> */}
                    <Route path="/:id/user-settings/coupon" component={CuponsAndOffers} />
                    <Route path="/:id/user-settings/push-notifications" component={PushNotifications} />
                    <Route path='/:id/user-settings/configure-email' component={ConfigureEmail} />
                    <Route path='/:id/user-settings/configure-sms' component={ConfigureSms} />
                    <Route path="/:id/cms" component={CmsPage} exact />
                    <Route path="/:id/cms/social-media" component={SocialMedia} exact />
                    {/* <Route path='/:id/advance-settings/analytics-settings' component={AnalyticsSetting} /> */}
                    {/* <Route path='/:id/advance-settings/gdpr' component={GDPR} /> */}
                    {/* <Route path='/:id/advance-settings/google-map-api-key' component={GoogleMapApiKey} /> */}
                    {/* <Route path='/:id/advance-settings/pre-order-delivary' component={PreOrderDalivery} /> */}
                    <Route path='/:id/advance-settings/social-media-login' component={SocialMediaLogin} />
                    <Route path='/:id/report/seo' component={PromotionalPage} exact />
                    <Route path='/:id/report' component={Report} exact />
                    <Route path='/:id/access-control' component={Administrator} exact />
                    <Route path='/:id/restaurant-basic/create-administrator' component={CreateAdministrator} exact />
                    {/* <Route path='/:id/access-control/create-role' component={CreateRole} exact /> */}
                    <Route path='/:id/restaurant-basic/administrator' component={Administrator} exact />
                    <Route path='/:id/profile' component={Profile} />
                    <Route path='/:id/change-password' component={ChangePassword} />
                    <Route path='/:id/account-update' component={AccountUpdate} />
                    <Route path="/:id/restaurant-basic" component={RestaurantBesicPage} exact />
                    <Route path='/:id/restaurant-basic/deliveryzone' component={DelevaryZones} exact />
                    {/* <Route path='/:id/restaurant-basic/pickup-delivary' component={PickupAndDeliveryPage} exact /> */}
                    <Route path='/:id/restaurant-basic/operationalhours' component={Operationalhours} exact />
                    <Route path="/:id/restaurant-basic/create-branch" component={CreateBranchPage} exact />
                    <Route path="/:id/restaurant-basic/fetures" component={Fetures} exact />
                    <Route path="/:id/restaurant-basic/payement-getways" component={PaymentGateWay} exact />
                    <Route path='/:id/restaurant-basic/preferences' component={Preferences} />
                    <Route path='/:id/restaurant-basic/menu-time' component={MenuTime} exact />
                </Switch>
            </Router>
        </>
    )
}
