import axios from "axios"

const baseURL = 'https://indianspicebrooklyn-g5whtncwfa-uc.a.run.app/api/'

export const fetchData = (endPoint) => axios.get(baseURL + endPoint)

export const postData = (endPoint, body) => axios.post(baseURL + endPoint, body)

export const authentication = (token) => axios.post(baseURL + "tokenIsValid", '',
    {
        headers: { 'x-auth-token': token }
    }
)

export const updateData = (endPoint, body) => axios.put(baseURL + endPoint, body)

export const deleteData = (endPoint, body) => axios.post(baseURL + endPoint, body)

export const getAllPaymentgetway = () => axios.get(`${baseURL}/get_payment_gateway`)

export const updatePaymentgetway = (body) => axios.put(`${baseURL}/update_payment_gateway`, body)

export const offAllPaymentGetway = (body) => axios.post(`${baseURL}/switch_off_all_payment_gateway`, body)

export const fetchTwilioSmsconfigure = () => axios.get(`${baseURL}/get_config_sms_twilio`)

export const fetchTextlocalSmsConfigure = () => axios.get(`${baseURL}/get_config_sms_textlocal`)

export const updateSmsconfigure = (body) => axios.put(`${baseURL}/update_config_sms`, body)

export const fetchGDPR = () => axios.get(`${baseURL}/get_gdpr`)

export const updateGDPR = (body) => axios.put(`${baseURL}/update_gdpr`, body)

export const fetchEmailConfigure = () => axios.get(`${baseURL}/get_config_email`)

export const updateEmailConfigure = (body) => axios.put(`${baseURL}/update_config_email`, body)

export const fetchPreDeliveryDay = () => axios.get(`${baseURL}/get_all_pre_order`)

export const fetchAnalytics = () => axios.get(`${baseURL}/get_analytics`)

export const updateAnalytics = (body) => axios.put(`${baseURL}/update_analytics`, body)

export const fetchPromotional = () => axios.get(`${baseURL}/get_promotion`)

export const updatePromotional = (body) => axios.put(`${baseURL}/update_promotion`, body)

export const fetchGoogleMapApiKey = () => axios.get(`${baseURL}/get_google_map_api_key`)

export const updateGoogleMapApikey = (body) => axios.put(`${baseURL}/update_google_map_api`, body)

export const fetchSocialMediaLogin = () => axios.get(`${baseURL}/get_all_social_media_login`)

export const updateSocialMedialogin = (body) => axios.put(`${baseURL}/update_login_config`, body)

export const addCustomerGroup = (body) => axios.post(`${baseURL}/add_customer_group`, body)

export const updatingCustomerGroup = (body) => axios.put(`${baseURL}/update_customer_group`, body) 

export const deletingCustomerGroup = (body) => axios.post(`${baseURL}/delete_customer_group`, body)

export const fetchAllCustomerGroup = () => axios.get(`${baseURL}/get_all_customer_group`)

export const addingUserToCustomerGroup = (body) => axios.post(`${baseURL}/add_user_to_customer_group`, body)

export const removinguserFromCustomerGroup = (body) => axios.post(`${baseURL}/remove_user_from_customer_group`, body)

//Users
export const searchUserByWord = (value) => axios.get(`${baseURL}/search_user/${value}?page=1&limit=10`)

export const userbyStatus = (value) => axios.get(`${baseURL}/get_user_by_status/${value}?limit=10&page=1`)

//delivaryBoy
export const searchingDeliveryBoyByWord = (value) => axios.get(`${baseURL}/search_delivery_boy/${value}?page=1&limit=10`)

//category
export const searchingCategoryByWord = (value) => axios.get(`${baseURL}/search_category/${value}?page=1&limit=10`)

export const categorybyStatus = (value) => axios.get(`${baseURL}/get_main_category_by_status/${value}?limit=10&page=1
`)

//Item setup
export const itembyStatus = (value, page) => axios.get(`${baseURL}/get_item_by_status/${value}?limit=10&page=${page}`) 

//Ingredient
export const searchingIngredientByWord = (value) => axios.get(`${baseURL}/search_ingredient/${value}?page=1&limit=10`)

export const ingredientbyStatus = (value) => axios.get(`${baseURL}/get_ingredient_by_status/${value}?limit=10&page=1`)

//currency
export const fetchCurrency = () => axios.get(`${baseURL}/get_currency`)

//orders
export const orderbyBookingType = (value, page) => axios.get(`${baseURL}/get_order_by_order_booking_type/${value}?limit=10&page=${page}`)

//homepage banner
export const fetchHomePagepopupBanner = () => axios.get(`${baseURL}/get_home_page_popup_banner`)

export const addHomePagebannerImage = (image) => axios.post(`${baseURL}/add_home_page_popup_banner_image`, image)

export const updateHomePagePopup = (body) => axios.put(`${baseURL}update_home_page_popup_banner`, body)