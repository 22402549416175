import React, { useState, useEffect } from "react";

import {
  Breadcrumbs,
  TextField,
  Grid,
  FormGroup,
  FormControlLabel,
  Modal,
  IconButton, makeStyles, SwipeableDrawer, Typography, colors, Link, Paper, Divider, Box,
  Button, ButtonGroup, CircularProgress
} from "@material-ui/core";
import { SwipeDrawer } from './swipedrawer/swipeDrawer';
import clsx from "clsx";

import { ChevronRight, ChevronLeft, Check } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllPaymentgetway,
  updatePaymentGetway,
  offAllPaymentGetWay,
} from "../../redux/AdvanceSetting/AdvanceSetting.action";
import { IOSSwitch } from "../../components/Buttons/IOSSwitch.component";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const PaymentGateWay = () => {
  const dispatch = useDispatch();
  const classes = useStyles()
  const [drawerOpen, setdrawerOpen] = useState(true);
  const paymentGetwayDetails = useSelector(
    (state) => state?.advanceSetting?.paymentGetWay?.response
  );
  const [confirmModal, setConfirmModal] = useState(false);
  const [allPaymentGetwayStatus, setallPaymentGetwayStatus] = useState(false);
  const [selectPaymentGetway, setSelectPaymentGetway] = useState("");
  const [alreadyOnPayment, setAlreadyOnPayment] = useState("");

  const [paypal, setpaypal] = useState({
    status: false,
    environment: "",
    liveMerchantId: "",
    liveClientId: "",
    liveClientSecretKey: "",
    testMarchantid: "",
    testClientId: "",
    testClientSecretKey: "",
  });

  const [stripe, setstripe] = useState({
    status: false,
    environment: "",
    liveSecretKey: "",
    testSecretKey: "",
    livePublicKey: "",
    testPublicKey: "",
  });

  const [razorpay, setrazorpay] = useState({
    status: false,
    environment: "",
    stageMerchantId: "",
    stageSecretKey: "",
    prodMerchantId: "",
    prodSecretKey: "",
  });

  useEffect(() => {
    dispatch(fetchAllPaymentgetway());
  }, [dispatch]);

  useEffect(() => {
    paymentGetwayDetails?.map((payment) => {
      if (payment.status) {
        setallPaymentGetwayStatus(true);
        setSelectPaymentGetway(payment?.payment_gateway_name);
      }
      // else {
      //     setallPaymentGetwayStatus(false)
      // }

      if (payment?.payment_gateway_name === "stripe") {
        setstripe({
          ...stripe,
          environment:
            payment?.stripe_enviroment !== null
              ? payment?.stripe_enviroment
              : "",
          liveSecretKey:
            payment?.stripe_live_secret_key !== null
              ? payment?.stripe_live_secret_key
              : "",
          testSecretKey:
            payment?.stripe_test_secret_key !== null
              ? payment?.stripe_test_secret_key
              : "",
          livePublicKey:
            payment?.stripe_live_public_key !== null
              ? payment?.stripe_live_public_key
              : "",
          testPublicKey:
            payment?.stripe_test_public_key !== null
              ? payment?.stripe_test_public_key
              : "",
          status: payment?.status,
        });
      } else if (payment?.payment_gateway_name === "razorpay") {
        setrazorpay({
          ...razorpay,
          environment:
            payment?.razorpay_enviroment !== null
              ? payment?.razorpay_enviroment
              : "",
          prodMerchantId:
            payment?.razorpay_prod_merchant_id !== null
              ? payment?.razorpay_prod_merchant_id
              : "",
          stageMerchantId:
            payment?.razorpay_stage_merchant_id !== null
              ? payment?.razorpay_stage_merchant_id
              : "",
          stageSecretKey:
            payment?.razorpay_stage_secret_key !== null
              ? payment?.razorpay_stage_secret_key
              : "",
          prodSecretKey:
            payment?.razorpay_prod_secret_key !== null
              ? payment?.razorpay_prod_secret_key
              : "",
          status: payment?.status,
        });
      } else if (payment?.payment_gateway_name === "paypal") {
        setpaypal({
          ...paypal,
          environment:
            payment?.paypal_enviroment !== null
              ? payment?.paypal_enviroment
              : "",
          liveClientId:
            payment?.paypal_live_client_id !== null
              ? payment?.paypal_live_client_id
              : "",
          liveMerchantId:
            payment?.paypal_live_merchant_id !== null
              ? payment?.paypal_live_merchant_id
              : "",
          testClientId:
            payment?.paypal_test_client_id !== null
              ? payment?.paypal_test_client_id
              : "",
          testMarchantid:
            payment?.paypal_test_merchant_id !== null
              ? payment?.paypal_test_merchant_id
              : "",
          liveClientSecretKey:
            payment?.paypal_live_client_secret_key !== null
              ? payment?.paypal_live_client_secret_key
              : "",
          testClientSecretKey:
            payment?.paypal_test_client_secret_key !== null
              ? payment?.paypal_test_client_secret_key
              : "",
          status: payment?.status,
        });
      }
    });
  }, [paymentGetwayDetails]);

  const [
    autoCompleteOptions,
    //  setautoCompleteOptions
  ] = useState(["Development", "Production"]);

  const paymentGetwaysOptions = ["Paypal", "Stripe", "Razorpay"];

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = () => {
    // e.preventDefault()
    try {
      const paypalVariable = {
        paypal_enviroment: paypal.environment,
        paypal_live_client_id: paypal.liveClientId,
        paypal_live_merchant_id: paypal.liveMerchantId,
        paypal_test_client_id: paypal.testClientId,
        paypal_test_merchant_id: paypal.testMarchantid,
        paypal_live_client_secret_key: paypal.liveClientSecretKey,
        paypal_test_client_secret_key: paypal.testClientSecretKey,
        status: alreadyOnPayment === "paypal" ? false : paypal.status,
      };

      const stripeVariables = {
        stripe_enviroment: stripe.environment,
        stripe_live_secret_key: stripe.liveSecretKey,
        stripe_test_secret_key: stripe.testSecretKey,
        stripe_live_public_key: stripe.livePublicKey,
        stripe_test_public_key: stripe.testPublicKey,
        status: alreadyOnPayment === "stripe" ? false : stripe.status,
      };

      const rozarpayVariables = {
        razorpay_enviroment: razorpay.environment,
        razorpay_prod_merchant_id: razorpay.prodMerchantId,
        razorpay_stage_merchant_id: razorpay.stageMerchantId,
        razorpay_stage_secret_key: razorpay.stageSecretKey,
        razorpay_prod_secret_key: razorpay.prodSecretKey,
        status: alreadyOnPayment === "razorpay" ? false : razorpay.status,
      };

      paymentGetwayDetails?.map((payment) => {
        if (payment?.payment_gateway_name === "stripe") {
          if (
            selectPaymentGetway === "stripe" ||
            alreadyOnPayment === "stripe"
          ) {
            dispatch(updatePaymentGetway(stripeVariables, payment?._id));
          }
        } else if (payment?.payment_gateway_name === "razorpay") {
          if (
            selectPaymentGetway === "razorpay" ||
            alreadyOnPayment === "razorpay"
          ) {
            dispatch(updatePaymentGetway(rozarpayVariables, payment?._id));
          }
        } else if (payment?.payment_gateway_name === "paypal") {
          if (
            selectPaymentGetway === "paypal" ||
            alreadyOnPayment === "paypal"
          ) {
            dispatch(updatePaymentGetway(paypalVariable, payment?._id));
          }
        }
      });
      setConfirmModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const findOnPaymentGetway = () => {
    try {
      let paymentGetway = "";
      paymentGetwayDetails?.map((item) => {
        if (selectPaymentGetway !== item?.payment_gateway_name) {
          if (item?.status) {
            paymentGetway = item?.payment_gateway_name;
            setAlreadyOnPayment(item?.payment_gateway_name);
            setConfirmModal(true);
          }
        }
      });
      if (paymentGetway === "") {
        handleSubmit();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {
        // !state?.branch?.loader &&
        <div style={{
          paddingTop: "10px",
          width: "100%",
          height: "100%",
          background: colors.grey[200],
          position: "relative",
          display: 'flex'
        }} >
          <>
            <div
              style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
              className={classes.drawerHeader}
            >
              <IconButton>
                {drawerOpen ? (
                  <ChevronLeft onClick={() => setdrawerOpen(false)} />
                ) : (
                  <ChevronRight onClick={() => setdrawerOpen(true)} />
                )}
              </IconButton>
            </div>
            <SwipeableDrawer
              className={classes.drawer}
              anchor="left"
              variant="persistent"
              open={drawerOpen}
              onClose={() => setdrawerOpen(false)}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
                <Typography align="center" variant="h6">
                  Restaurant Basics
                </Typography>
              </div>
              <SwipeDrawer />
            </SwipeableDrawer>
          </>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              // color: selectState === "coupon" ? "var(--orange)" : "#ffffff",
              cursor: "pointer"
            }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })} >
            <div style={{ padding: '0 20px', width: '100%' }} >
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight style={{ fontSize: 15 }} />}
              >
                <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                  Home
                </Link>
                <Typography
                  color="textPrimary"
                  style={{ fontSize: 15, color: "var(--orange)" }}
                >
                  Preferences
                </Typography>
              </Breadcrumbs>
              {/* { */}
              {/* state?.preference?.loader ? <div className="loaderBg"><CircularProgress color="secondary" /></div> */}
              {/* : */}
              <Paper style={{ padding: "2rem", margin: "15px auto 5rem auto", width: "70%" }}>
                <form>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      paddingBottom: "10px",
                    }}
                  >
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item xs={9}>
                        <Typography variant="h6">Payment Gateway Settings</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>{allPaymentGetwayStatus ? "On" : "Off"}</Grid>
                          <Grid item>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    checked={allPaymentGetwayStatus}
                                    onChange={(e) => {
                                      setallPaymentGetwayStatus(e.target.checked);
                                      dispatch(offAllPaymentGetWay());
                                    }}
                                  />
                                }
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <Autocomplete
                      value={selectPaymentGetway}
                      onChange={(event, newValue) => {
                        setSelectPaymentGetway(newValue?.toLowerCase());
                      }}
                      style={{ marginTop: 20 }}
                      options={paymentGetwaysOptions}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderOption={(option) => option}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Payment Getways Options"
                          variant="outlined"
                          size="small"
                          style={{ width: "100%", marginBottom: "1.5rem" }}
                        />
                      )}
                    />
                    {selectPaymentGetway === "paypal" && (
                      <>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item xs={9}>
                            <Typography variant="h6">Paypal</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>{paypal.status ? "On" : "Off"}</Grid>
                              <Grid item>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        checked={paypal.status}
                                        onChange={(e) => {
                                          // const variables = {
                                          //     status: e.target.checked
                                          // }
                                          setpaypal({
                                            ...paypal,
                                            status: e.target.checked,
                                          });
                                          // paymentGetwayDetails?.map(payment => {
                                          //     if(payment?.payment_gateway_name === 'paypal'){
                                          //         dispatch(updatePaymentGetway(variables, payment?._id))
                                          //     }
                                          // })
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider style={{ marginBottom: "1rem" }} />
                        {paypal.status && (
                          <>
                            <Autocomplete
                              value={paypal.environment}
                              onChange={(event, newValue) => {
                                setpaypal({ ...paypal, environment: newValue });
                              }}
                              options={autoCompleteOptions}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              renderOption={(option) => option}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Paypal Environment"
                                  variant="outlined"
                                  size="small"
                                  style={{ width: "100%", marginBottom: "1.5rem" }}
                                />
                              )}
                            />
                            <TextField
                              id="outlined-basic"
                              label="Paypal Live Merchant Id"
                              variant="outlined"
                              size="small"
                              value={paypal.liveMerchantId}
                              onChange={(e) =>
                                setpaypal({ ...paypal, liveMerchantId: e.target.value })
                              }
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="Paypal Live Client Id"
                              value={paypal.liveClientId}
                              onChange={(e) =>
                                setpaypal({ ...paypal, liveClientId: e.target.value })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />
                            <TextField
                              id="outlined-basic"
                              label="Paypal Live Client Secret Key"
                              value={paypal.liveClientSecretKey}
                              onChange={(e) =>
                                setpaypal({
                                  ...paypal,
                                  liveClientSecretKey: e.target.value,
                                })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="Paypal Test Merchant Id"
                              value={paypal.testMarchantid}
                              onChange={(e) =>
                                setpaypal({ ...paypal, testMarchantid: e.target.value })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="Paypal Test Client Id"
                              variant="outlined"
                              size="small"
                              value={paypal.testClientId}
                              onChange={(e) =>
                                setpaypal({ ...paypal, testClientId: e.target.value })
                              }
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="Paypal Test Client Secret Key"
                              value={paypal.testClientSecretKey}
                              onChange={(e) =>
                                setpaypal({
                                  ...paypal,
                                  testClientSecretKey: e.target.value,
                                })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              width={200}
                              margin="auto"
                            >
                              <Button startIcon={<ChevronLeft />} className="button">
                                back
                              </Button>
                              <Button
                                onClick={() => findOnPaymentGetway()}
                                className="button"
                                startIcon={<Check />}
                              >
                                save
                              </Button>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                    {selectPaymentGetway === "stripe" && (
                      <>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item xs={9}>
                            <Typography variant="h6">Stripe</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>{stripe.status ? "On" : "Off"}</Grid>
                              <Grid item>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        checked={stripe.status}
                                        onChange={(e) => {
                                          // const variables = {
                                          //     status: e.target.checked
                                          // }
                                          setstripe({
                                            ...stripe,
                                            status: e.target.checked,
                                          });
                                          // paymentGetwayDetails?.map(payment => {
                                          //     if(payment?.payment_gateway_name === 'stripe'){
                                          //         dispatch(updatePaymentGetway(variables, payment?._id))
                                          //     }
                                          // })
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Divider style={{ marginBottom: "1rem" }} />
                        {stripe.status && (
                          <>
                            <Autocomplete
                              value={stripe.environment}
                              onChange={(event, newValue) => {
                                setstripe({ ...stripe, environment: newValue });
                              }}
                              options={autoCompleteOptions}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              renderOption={(option) => option}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Stripe Environment"
                                  variant="outlined"
                                  size="small"
                                  style={{ width: "100%", marginBottom: "1.5rem" }}
                                />
                              )}
                            />
                            <TextField
                              id="outlined-basic"
                              label="Stripe Live Secret Key"
                              variant="outlined"
                              size="small"
                              value={stripe.liveSecretKey}
                              onChange={(e) =>
                                setstripe({ ...stripe, liveSecretKey: e.target.value })
                              }
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="Stripe Live Public Key"
                              value={stripe.livePublicKey}
                              onChange={(e) =>
                                setstripe({ ...stripe, livePublicKey: e.target.value })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />
                            <TextField
                              id="outlined-basic"
                              label="Stripe Test Secret Key"
                              value={stripe.testSecretKey}
                              onChange={(e) =>
                                setstripe({ ...stripe, testSecretKey: e.target.value })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="Stripe Test Public Key"
                              value={stripe.testPublicKey}
                              onChange={(e) =>
                                setstripe({ ...stripe, testPublicKey: e.target.value })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              width={200}
                              margin="auto"
                            >
                              <Button startIcon={<ChevronLeft />} className="button">
                                back
                              </Button>
                              <Button
                                onClick={() => findOnPaymentGetway()}
                                className="button"
                                startIcon={<Check />}
                              >
                                save
                              </Button>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                    {selectPaymentGetway === "razorpay" && (
                      <>
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item xs={9}>
                            <Typography variant="h6">Razorpay</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>{razorpay.status ? "On" : "Off"}</Grid>
                              <Grid item>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        checked={razorpay.status}
                                        onChange={(e) => {
                                          // const variables = {
                                          //     status: e.target.checked
                                          // }
                                          setrazorpay({
                                            ...razorpay,
                                            status: e.target.checked,
                                          });
                                          // paymentGetwayDetails?.map(payment => {
                                          //     if(payment?.payment_gateway_name === 'razorpay'){
                                          //         dispatch(updatePaymentGetway(variables, payment?._id))
                                          //     }
                                          // })
                                        }}
                                      />
                                    }
                                  />
                                </FormGroup>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Divider style={{ marginBottom: "1rem" }} />
                        {razorpay.status && (
                          <>
                            <Autocomplete
                              value={razorpay.environment}
                              onChange={(event, newValue) => {
                                setrazorpay({ ...razorpay, environment: newValue });
                              }}
                              options={autoCompleteOptions}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              renderOption={(option) => option}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Razorpay Environment"
                                  variant="outlined"
                                  size="small"
                                  style={{ width: "100%", marginBottom: "1.5rem" }}
                                />
                              )}
                            />
                            <TextField
                              id="outlined-basic"
                              label="Razorpay Stage Merchant ID"
                              variant="outlined"
                              size="small"
                              value={razorpay.stageMerchantId}
                              onChange={(e) =>
                                setrazorpay({
                                  ...razorpay,
                                  stageMerchantId: e.target.value,
                                })
                              }
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="Razorpay Stage Secret Key"
                              value={razorpay.stageSecretKey}
                              onChange={(e) =>
                                setrazorpay({
                                  ...razorpay,
                                  stageSecretKey: e.target.value,
                                })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />
                            <TextField
                              id="outlined-basic"
                              label="Razorpay Prod Merchant ID"
                              value={razorpay.prodMerchantId}
                              onChange={(e) =>
                                setrazorpay({
                                  ...razorpay,
                                  prodMerchantId: e.target.value,
                                })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />

                            <TextField
                              id="outlined-basic"
                              label="Razorpay Prod Secret Key"
                              value={razorpay.prodSecretKey}
                              onChange={(e) =>
                                setrazorpay({
                                  ...razorpay,
                                  prodSecretKey: e.target.value,
                                })
                              }
                              variant="outlined"
                              size="small"
                              style={{ width: "100%", marginBottom: "1.5rem" }}
                            />
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                              width={200}
                              margin="auto"
                            >
                              <Button startIcon={<ChevronLeft />} className="button">
                                back
                              </Button>
                              <Button
                                onClick={() => findOnPaymentGetway()}
                                className="button"
                                startIcon={<Check />}
                              >
                                save
                              </Button>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </form>
              </Paper>
              {/* } */}
            </div>
          </div>
        </div>
      }
    </>
  )
};
