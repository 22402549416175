import React, { useState, useEffect } from "react";

import {
  Box,
  Breadcrumbs,
  Button,
  colors,
  Link,
  Paper,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Snackbar,
  SwipeableDrawer,
  ListItem,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  ChevronRight,
  Autorenew,
  Search,
  Edit,
  Delete,
  AddCircle,
  CloudUpload,
  ChevronLeft,
  RadioButtonUnchecked,
} from "@material-ui/icons";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import clsx from "clsx";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllCatagory,
  postfetureData,
  addfetureFormImage,
  deletefetureFormImage,
  updatefetureForm,
  getfetureDataAll,
  getfetureByApplyToId,
  deletefetureForm,
  postfetureItem,
} from "../../../redux";
import { useHistory } from "react-router";
import AddFetureProduct from "./AddFetureProduct";
import { IOSSwitch } from "../../../components/Buttons/IOSSwitch.component";
import { SwipeDrawer } from "../swipeDrawer";

let allfeture = [{ status: true, _id: "12345", category_name: "Home" }];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  index.feture_list = [];
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}
const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // flexGrow: 1,
    // width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  list: {
    border: "1px solid lavender",
    padding: "20px",
  },
  image: {
    width: "150px",
    height: "150px",
    margin: "auto",
    border: "1px solid black",
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "800px",
    position: "relative",
  },
  formControl: {
    marginBottom: "1rem",
  },
  margin: {
    marginBottom: "1.5rem",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: theme.spacing(16),
    backgroundColor: "#f5f5f5",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const Fetures = () => {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [addfeture, setaddfeture] = useState(false);
  const [addfetureItem, setaddfetureItem] = useState(false);
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [viewFeture, setviewFeture] = useState({});
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const id = state?.branch?.activeBranch?._id;

  const [formValue, setformValue] = useState({
    branch_id: "",
    featured_item_image: "",
    featured_item_tag: "",
    featured_item_position: "",
    featured_item_applied_to: {},
    featured_item_applied_to_id: "",
    featured_item_name: "",
    featured_item_item_id: [],
  });

  useEffect(() => {
    // dispatch(getAllCatagory())
    allfeture[value]?._id ?
      dispatch(
        getfetureByApplyToId(
          `/featured_item_list/${state?.login?.data?.adminstrator_branch_id}/${allfeture[value]._id}`
        )
      ) : getfetureByApplyToId(
        `/featured_item_list/${state?.login?.data?.adminstrator_branch_id}/12345`
      )
  }, [dispatch]);

  // useEffect(() => {
  //    state.catagory.data && dispatch(getfetureByApplyToId(`/featured_item_list/${state?.login?.data?.adminstrator_branch_id}/${allfeture[0]._id}?limit=10&page=1`))
  // }, [])

  useEffect(() => {
    if (state?.catagory?.data) {
      const allcat = [...allfeture];
      state?.catagory?.data.map((res) => {
        allcat.push(res);
        res.sub_category.map((sub_cat) => allcat.push(sub_cat));
      });
      allfeture = allcat;
    }
    console.log(allfeture);
  }, [state?.catagory?.data]);

  useEffect(() => {
    state?.login?.data?.adminstrator_branch_id &&
      setformValue({
        ...formValue,
        branch_id: state?.login?.data?.adminstrator_branch_id,
      });
  }, [state?.login?.data?.adminstrator_branch_id]);

  useEffect(() => {
    state?.feture?.status === "success" && handleClose();
  }, [state?.feture?.status]);

  useEffect(() => {
    state?.feture?.image_link &&
      setformValue({
        ...formValue,
        featured_item_image: state?.feture?.image_link,
      });
  }, [state?.feture?.image_link]);

  // useEffect(() => {
  //     state?.catagory?.data?.length > 0 &&
  //     dispatch(getfetureDataAll([...allfeture, ...state?.catagory?.data]))
  // }, [state?.catagory?.data])

  // useEffect(() => {
  //     effect
  //     return () => {
  //         cleanup
  //     }
  // }, [input])

  const handleClickaddfeture = (value = formValue) => {
    setaddfeture(true);
    setformValue(value);
  };

  const handleClose = () => {
    setformValue({
      ...formValue,
      _id: null,
      featured_item_image: "",
      featured_item_tag: "",
      featured_item_position: "",
      featured_item_applied_to: {},
      featured_item_applied_to_id: "",
      featured_item_name: "",
      featured_item_item_id: [],
    });
    setaddfeture(false);
  };

  const handleChange = (event, newValue) => {
    // console.log(newValue)
    dispatch(
      getfetureByApplyToId(
        `/featured_item_list/${state?.login?.data?.adminstrator_branch_id}/${allfeture[newValue]._id}`
      )
    );
    setValue(newValue);
  };

  const formValueChange = (e) => {
    setformValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const selectFetureApplyId = (e, newValue) => {
    setformValue({
      ...formValue,
      featured_item_applied_to: newValue,
      featured_item_applied_to_id: newValue._id,
    });
  };

  const addItemToFeture = (featured_id, items) => {
    items.map((res) => {
      dispatch(
        postfetureItem(`add_item_to_feature_item`, {
          featured_item_id: featured_id,
          item_id: res,
        })
      );
    });
  };

  const removeItemToFeture = (featured_id, items) => {
    items.map((res) => {
      dispatch(
        postfetureItem(`remove_item_from_feature_item`, {
          featured_item_id: featured_id,
          item_id: res,
        })
      );
    });
  };

  const closeAddItem = (condition) => setaddfetureItem(false);

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0%", right: "63%", zIndex: 1100 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            style={{ marginTop: "10px" }}
            open={drawerOpen}
            o
            style={{ marginTop: "10px" }}
            nClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Web And App Builder
              </Typography>
            </div>
            <SwipeDrawer />
          </SwipeableDrawer>
          {state?.catagory?.loader ? (
            <CircularProgress />
          ) : (
            <div
              className={clsx(classes.content, {
                [classes.contentShift]: drawerOpen,
              })}
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                position: "relative",
              }}
            >
              <Box
                style={{
                  overflow: 1,
                  width: drawerOpen
                    ? `calc(100vw - ${drawerWidth + 150}px)`
                    : `calc(100vw - ${150}px)`,
                }}
              >
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRight style={{ fontSize: 15 }} />}
                >
                  <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                    Home
                  </Link>
                  <Typography
                    color="textPrimary"
                    style={{ fontSize: 15, color: "var(--orange)" }}
                  >
                    Feture
                  </Typography>
                </Breadcrumbs>
                <Paper style={{ marginTop: "15px", padding: "15px" }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom="1.5em"
                  >
                    <Typography variant="h5">All featured pages</Typography>

                    <Button
                      startIcon={<AddCircle />}
                      variant="contained"
                      color="primary"
                      className="button"
                      onClick={() => handleClickaddfeture()}
                    >
                      Create Featured products
                    </Button>
                  </Box>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="scrollable force tabs example"
                    >
                      {allfeture?.map((res) => (
                        <Tab label={res.category_name} {...a11yProps(res)} />
                      ))}
                    </Tabs>
                  </AppBar>
                  <TabPanel>
                    {state?.feture?.loading ? (
                      <CircularProgress />
                    ) : state?.feture?.data?.length > 0 ? (
                      state?.feture?.data?.map((res) => (
                        <List className={classes.list}>
                          {/* {generate( */}
                          <Grid container spacing={2}>
                            <Grid
                              item
                              sm={2}
                              alignItems="flex-start"
                              style={{ display: "flex" }}
                            >
                              <Box className={classes.image}>
                                {res.featured_item_image ? (
                                  <img
                                    src={res.featured_item_image}
                                    width="100%"
                                    height="100%"
                                    alt="feture"
                                  ></img>
                                ) : (
                                  <Typography
                                    variant="button"
                                    color="textSecondary"
                                  >
                                    Images Not Avaliable
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                            <Grid
                              item
                              sm={3}
                              alignItems="center"
                              style={{ display: "flex" }}
                            >
                              <Box>
                                <Typography variant="h6">
                                  {res.featured_item_name}
                                </Typography>
                                <Typography variant="button">
                                  {res.featured_item_tag}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              sm={4}
                              alignItems="center"
                              style={{ display: "flex" }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.margin}
                                onClick={() => {
                                  setaddfetureItem(true);
                                  setviewFeture(res);
                                }}
                              >
                                {res?.featured_item_item_id?.length} products
                                avaliale in this feture
                              </Button>
                            </Grid>
                            <Grid
                              item
                              sm={1}
                              style={{ display: "flex" }}
                              alignItems="center"
                              justify="center"
                            >
                              <IOSSwitch
                                checked={res.status}
                                onChange={() =>
                                  dispatch(
                                    updatefetureForm({
                                      _id: res._id,
                                      status: !res.status,
                                    })
                                  )
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              sm={1}
                              style={{ display: "flex" }}
                              alignItems="center"
                              justify="center"
                            >
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() =>
                                    handleClickaddfeture({
                                      ...res,
                                      featured_item_applied_to:
                                        allfeture[value],
                                    })
                                  }
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                            <Grid
                              item
                              sm={1}
                              style={{ display: "flex" }}
                              alignItems="center"
                              justify="center"
                            >
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() =>
                                    dispatch(deletefetureForm(res))
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                          {/* )} */}
                        </List>
                      ))
                    ) : (
                      <Paper className={classes.paper} elevation={0}>
                        <Typography
                          variant="button"
                          color="textSecondary"
                          className={classes.margin}
                        >
                          You have no Featured product In{" "}
                          {allfeture[value].category_name}
                        </Typography>
                        <Button
                          startIcon={<AddCircle />}
                          variant="contained"
                          color="primary"
                          className="button"
                          onClick={() => handleClickaddfeture()}
                        >
                          Create Featured products
                        </Button>
                      </Paper>
                    )}
                  </TabPanel>
                </Paper>
              </Box>

              <Dialog
                maxWidth="lg"
                open={addfetureItem}
                onClose={() => setaddfetureItem(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <AddFetureProduct
                  feture={viewFeture}
                  addItem={addItemToFeture}
                  removeItem={removeItemToFeture}
                  dialogClose={closeAddItem}
                />
              </Dialog>

              <Dialog
                maxWidth="lg"
                open={addfeture}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  style={{ backgroundColor: colors.blue[50] }}
                  id="max-width-dialog-title"
                >
                  Create Featured products
                </DialogTitle>
                <form
                  className={classes.form}
                  onSubmit={(e) => {
                    formValue._id
                      ? dispatch(updatefetureForm(formValue))
                      : dispatch(postfetureData(formValue));
                    e.preventDefault();
                  }}
                >
                  <DialogContent>
                    <Grid container spacing={3}>
                      <Grid item sm={3}>
                        <Box className={classes.image}>
                          {formValue.featured_item_image ? (
                            <img
                              src={formValue.featured_item_image}
                              width="100%"
                              height="100%"
                              alt="icon"
                            ></img>
                          ) : (
                            <Typography variant="body1" color="textSecondary">
                              Size (400 px * 400 px)
                            </Typography>
                          )}
                        </Box>

                        <Box className={classes.center}>
                          <input
                            name="icon_image"
                            accept="image/*"
                            id="contained-button-file"
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => {
                              if (formValue.featured_item_image) {
                                let public_id = formValue.featured_item_image
                                  .split("/")[8]
                                  .split(".")[0];
                                dispatch(
                                  deletefetureFormImage({
                                    image_id: `featured_item_image/${public_id}`,
                                  })
                                );
                              }
                              const file = e.target.files[0];
                              const formData = new FormData();
                              formData.append("featured_item_image", file);
                              dispatch(addfetureFormImage(formData));
                            }}
                          />
                          <label htmlFor="contained-button-file">
                            <Button
                              variant="contained"
                              color="default"
                              className="button"
                              startIcon={<CloudUpload />}
                              component="span"
                            >
                              Upload Image
                            </Button>
                          </label>
                        </Box>
                      </Grid>
                      <Grid item sm={9}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          className={classes.formControl}
                          required
                          label="Featured Name"
                          name="featured_item_name"
                          value={formValue.featured_item_name}
                          onChange={(e) => formValueChange(e)}
                        />
                        <TextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          className={classes.formControl}
                          required
                          label="Feture Tag"
                          name="featured_item_tag"
                          value={formValue.featured_item_tag}
                          onChange={(e) => formValueChange(e)}
                        />
                        <Autocomplete
                          fullWidth
                          id="combo-box-demo"
                          className={classes.formControl}
                          options={allfeture}
                          value={formValue.featured_item_applied_to}
                          getOptionLabel={(option) => option.category_name}
                          onChange={(e, newValue) =>
                            selectFetureApplyId(e, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              label="Applied To Page"
                              variant="outlined"
                              size="small"
                              // label="Apply to page"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={() => handleClose()} color="secondary">
                      Back
                    </Button>
                    <Button type="submit" color="primary" autoFocus>
                      {formValue._id ? "Update" : "Submit"}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>

              <Snackbar
                open={state.feture.status}
                onClose={state.feture.status}
              >
                <Alert
                  onClose={!state.feture.status}
                  severity={state.feture.status}
                >
                  {state.feture.message}
                </Alert>
              </Snackbar>
            </div>
          )}
        </div>
      )}
    </>
  );
};
