import React, { useState } from "react";

import {
    Breadcrumbs,
    Link,
    Typography,
    makeStyles,
    withStyles,
    Divider,
    Switch,
    Button,
    IconButton,
    useMediaQuery,
    useTheme,
    TextField,
    Grid,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    FormControl,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    FormControlLabel,
    FormGroup,
    SwipeableDrawer,
    List,
    ListItem,
    colors
} from "@material-ui/core";
import { ChevronRight, ChevronLeft, Check, RadioButtonUnchecked } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom'
import clsx from "clsx";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
const filter = createFilterOptions();


const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  formControl: {
    margin: theme.spacing(1),
    width: '25ch',
    color: "var(--orange)"
},
imageBackGround: {
    width: "200px",
    height: "200px",
    margin: "auto",
    border: 1,
},
center: { display: "flex", justifyContent: "center", alignItems: "center", padding: "0.5rem" }
}));

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: "#52d869",
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: "#52d869",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


export const LanguageSettings = ({ match }) => {
    const history = useHistory()
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [drawerOpen, setdrawerOpen] = useState(true);
    const [selectState, setselectState] = useState("language setting");
    const id = match.params.id
    const [formValue, setformValue] = useState({
        icon: '', logo: '', caption: "", color: "", font_size: '',
    })
    const [status, setStatus] = useState(true);
    const [stage, setStage] = useState('All');
    const [value, setValue] = React.useState('');
    const [autoCompleteOptions, 
        // setautoCompleteOptions
    ] = useState([{ title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    ])

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event) => {
        setStage(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div style={{
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
        }}
          className={classes.root}>
            <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Settings
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "configaration" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/settings`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Configaration</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "icon_logo" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/icon-logo`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Icon Logo Caption</Typography>
            </div>
          </ListItem>

          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "brandings" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/brands`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Brandings</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Multicountry" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/multi-country`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Country Currency</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "language setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/language-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Language Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "home text" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("home text")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Home Text</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "theme setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/them-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Theme Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Onesignal Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/one-signal`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Onesignal Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "push notification" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/push-notifications`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Send Push Notification</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "loyalty points" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("loyalty points")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Loyalty Points</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div style={{ paddingLeft: 20, paddingRight: 20 }} className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}>
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
                <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                    Home
            </Link>
                <Typography
                    color="textPrimary"
                    style={{ fontSize: 15, color: "var(--orange)" }}
                >
                    Language Setting
        </Typography>
            </Breadcrumbs>

            <Paper style={{ padding: "2rem", margin: "15px auto" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        paddingBottom: "10px"
                    }}
                >
                    <Typography variant="h6">Language</Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        padding: "20px 0"
                    }}>

                    <Button variant="contained" className="button"
                        startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                        Add Language
                    </Button>
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title"> <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<ChevronRight style={{ fontSize: 15 }} />}
                        >
                            <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                                Home
                            </Link>
                            <Typography color="secondary" style={{ fontSize: 15 }} onClick={() => setOpen(false)}>Language</Typography>
                            <Typography
                                color="textPrimary"
                                style={{ fontSize: 15, color: "var(--orange)" }}
                            >
                                Create Language
                            </Typography>
                        </Breadcrumbs></DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <form>
                                    <TextField label="Language Code" variant="outlined" size="small" defaultValue=""
                                        style={{ width: '100%', marginBottom: '1.5rem' }} />
                                    <TextField label="Language Name" variant="outlined" size="small" defaultValue=""
                                        style={{ width: '100%', marginBottom: '1.5rem' }} />
                                    <TextField label="Native Name" variant="outlined" size="small" defaultValue=""
                                        style={{ width: '100%', marginBottom: '1.5rem' }} />
                                    <Typography component="div" style={{ width: '100%', marginBottom: '1.5rem' }}>
                                        Is Rtl
                                            <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>Off</Grid>
                                            <Grid item>
                                                <IOSSwitch value={formValue.sku} onChange={(e) => {
                                                    formValue.sku = e.target.value
                                                    setformValue({ ...formValue, ...formValue.sku })
                                                }} name="sku" />
                                            </Grid>
                                            <Grid item>On</Grid>
                                        </Grid>
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        width={200}
                                        margin="auto"
                                    >
                                        <Button startIcon={<ChevronLeft />} className="button" onClick={handleClose}>
                                            back
                                        </Button>
                                        <Button className="button" startIcon={<Check />} onClick={handleClose}>
                                            save
                                        </Button>
                                    </Box>

                                </form>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={stage}
                                onChange={handleChange}
                                className={classes.selectEmpty}
                                size="small"
                            >
                                <MenuItem value="All">
                                    <em>All</em>
                                </MenuItem>
                                <MenuItem value={'Active'}>Active</MenuItem>
                                <MenuItem value={'InActive'}>InActive</MenuItem>
                            </Select>
                        </FormControl>
                        <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    
                                } else if (newValue && newValue.inputValue) {
                                   
                                } else {
                                    setValue(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== '') {
                                    filtered.push({
                                        inputValue: params.inputValue,
                                        title: `Add "${params.inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            options={autoCompleteOptions}
                            getOptionLabel={(option) => {
                                // e.g value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.title;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(option) => option.title}
                            style={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Search" variant="outlined" />
                            )}
                        />
                        {/* <Button
                            variant="contained"
                            style={{
                                padding: '15px 0',
                                background: "var(--orange)",
                                color: "#ffffff",
                                height: '100%'
                            }}
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                        >
                        </Button> */}
                    </div>
                </div>
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sl.No</TableCell>
                            <TableCell>Language Name</TableCell>
                            <TableCell>Is Rtl</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>1</TableCell>
                            <TableCell>Icon</TableCell>
                            <TableCell>test</TableCell>
                            <TableCell>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                checked={status}
                                                onChange={(e) => setStatus(e.target.checked)}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </TableCell>
                            <TableCell style={{ display: "flex", justifyContent: "space-between" }}>
                                {/* <Button size="small" variant="outlined" size="small" color="secondary" className={classes.margin}>
                                    Small
                                </Button> */}
                                <IconButton aria-label="delete">
                                    <EditIcon />
                                </IconButton>
                                <IconButton aria-label="delete" color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
        </div>
    );
};