import React from 'react'
import { Box, Breadcrumbs, colors, Link, Paper, Typography, Divider } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';

//Components
import { ChevronRight, AccountCircle, Edit } from '@material-ui/icons'

export const Profile = ({ match }) => {
    const id = match.params.id
    const history = useHistory()
    const state = useSelector(state => state)
    return (
        <>
            {
                !state?.branch?.loader &&
                <div style={{
                    paddingLeft: "100px",
                    width: "100%",
                    height: "100%",
                    background: colors.grey[200],
                    position: "relative",
                    display: 'flex'
                }}>
                    <Box style={{ padding: 20 }} width='100%' >
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<ChevronRight style={{ fontSize: 15 }} />}
                        >
                            <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                                Home
        </Link>
                            <Typography
                                color="textPrimary"
                                style={{ fontSize: 15, color: "var(--orange)" }}
                            >
                                Profile
        </Typography>
                        </Breadcrumbs>
                        <Paper style={{ padding: "2rem", margin: "15px auto 5rem auto", width: "50%", position: 'relative' }}>
                            <Edit style={{ position: 'absolute', top: '5%', right: '5%', border: '1px solid grey', borderRadius: '50%' }} className='edit' onClick={() => history.push(`/${id}/account-update`)} />
                            <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' >
                                <AccountCircle style={{ fontSize: '6rem', color: 'grey', }} />
                                <Typography variant="body1" style={{ fontSize: '1rem' }} >IndianSpice</Typography>
                            </Box>
                            <Box marginTop='20px'>
                                <Typography variant="body1" style={{ fontSize: '1rem', marginBottom: '2rem' }} >Details</Typography>
                                <Divider />
                                <Box padding='15px 10px' width='100%' display="flex" alignItems='center' justifyContent='flex-start' bgcolor={colors.grey[100]}  >
                                    <Typography style={{ width: '40%' }} >UserName</Typography>
                                    <Typography style={{ width: '60%' }}>{state.login?.data?.administrator_user_name}</Typography>
                                </Box>
                                <Divider />
                                <Box padding='15px 10px' width='100%' display="flex" alignItems='center' justifyContent='flex-start'  >
                                    <Typography style={{ width: '40%' }} >Name</Typography>
                                    <Typography style={{ width: '60%' }}>{state.login?.data?.administrator_first_name} {state.login?.data?.administrator_last_name}</Typography>
                                </Box>
                                <Divider />
                                <Box padding='15px 10px' width='100%' display="flex" alignItems='center' justifyContent='flex-start' bgcolor={colors.grey[100]}    >
                                    <Typography style={{ width: '40%' }} >Email</Typography>
                                    <Typography style={{ width: '60%' }}>{state.login?.data?.administrator_email}</Typography>
                                </Box>
                                <Divider />
                            </Box>
                        </Paper>
                    </Box>
                </div>
            }</>)
}
