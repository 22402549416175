import React, { useState } from "react";
import "./Map.css";

import {
  Map as GoogleMap,
  GoogleApiWrapper,
  Marker,
  Polygon,
  InfoWindow,
} from "google-maps-react";

import app_logo from "../../assets/logos/app_logo.png";

import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyAM7yjSDq3X-pzq2A0mNEcbYcqMMRHLlIQ");
Geocode.enableDebug();

const Map = ({
  google,
  lat,
  lng,
  height,
  position,
  width,
  triangleCoords = [],
  handleClick,
  dragend,
  defailtTriange,
  draggable = true,
  branchLat,
  branchLng,
}) => {
  const mapStyles = {
    width: "100%",
    height: height,
    border: "1px solid grey",
  };
  const [showInfo, setShowInfo] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  });

  const onMarkerClick = (props, marker, e) =>
    setShowInfo({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  // const onMapClicked = (t, map, c) => {
  //   handleClick && handleClick(t, map, c)
  //   if (showInfo.showingInfoWindow) {
  //     setShowInfo({
  //       showingInfoWindow: false,
  //       activeMarker: null
  //     })
  //   }
  // };

  return (
    <div
      style={{ position: position, top: "20%", width: width, height: height }}
    >
      <GoogleMap
        google={google}
        zoom={10}
        style={mapStyles}
        initialCenter={{
          lat: lat,
          lng: lng,
        }}
        center={{ lat: lat, lng: lng }}
        onClick={(t, map, c) => handleClick && handleClick(t, map, c)}
      >
        <Polygon
          paths={triangleCoords}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor="#0000FF"
          fillOpacity={0.35}
        />
        {defailtTriange?.map((i) => (
          <Polygon
            key={i._id}
            paths={i.delivery_zone_arr}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor="#0000FF"
            fillOpacity={0.35}
          />
        ))}
        <Marker
          draggable={draggable}
          position={{
            lat: lat,
            lng: lng,
          }}
          onDragstart={(e) => console.log("start drag marker")}
          onDragend={
            (t, map, c) => dragend && dragend(t, map, c)
            // setLatlng({ lat: c.latLng.lat(), lng: c.latLng.lng() })
          }
          optimized={false}
        />
        <Marker
          draggable={draggable}
          position={{
            lat: branchLat,
            lng: branchLng,
          }}
          onDragstart={(e) => console.log("start drag marker")}
          onDragend={(t, map, c) => dragend && dragend(t, map, c)}
          icon={app_logo}
          optimized={false}
          onClick={onMarkerClick}
          name={"Indian Spice"}
        />
        <InfoWindow
          visible={showInfo.showingInfoWindow}
          marker={showInfo.activeMarker}
        >
          <p>{showInfo.selectedPlace.name}</p>
        </InfoWindow>
      </GoogleMap>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyAM7yjSDq3X-pzq2A0mNEcbYcqMMRHLlIQ",
})(Map);
