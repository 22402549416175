import { FETCH_FETURE_REQUEST,FETCH_FETURE_SUCCESS,FETCH_FETURE_BY_APPLY_TO_ID_SUCCESS,
    FETCH_FETURE_FALIOUR,FETURE_DATA,FETURE_IMAGE,GET_ALL_FETURE_APPLIED_ON } from "./fetureTypes";
import { fetchData,postData,updateData,deleteData } from "../../Api/api";

export const fetchfetureRequest = () => {
    return {
        type: FETCH_FETURE_REQUEST
    }
}

export const fetchfetureSuccess = (data) => {
    return {
        type : FETCH_FETURE_SUCCESS,
        payload : data
    }
}

export const fetchfetureFaliour = (data) => {
    return {
        type : FETCH_FETURE_FALIOUR, 
        payload : data
    }
}

export const fetureFormData = (data) => {
    return {
        type : FETURE_DATA,
        payload : data
    }
}

export const fetureFormImage = (data) => {
    return {
        type : FETURE_IMAGE,
        payload: data
    }
}

export const fetchfetureByApplyToId = (data) => {
    return {
        type: FETCH_FETURE_BY_APPLY_TO_ID_SUCCESS,
        payload: data
    }
}

export const getfetureDataAll = (allTab) => {
    return {
        type: GET_ALL_FETURE_APPLIED_ON,
        payload: allTab
    }
}

export const getfetureAll = (endpoint = `featured_item_list`) => {
    return (dispatch) => {
        dispatch(fetchfetureRequest())
        fetchData(endpoint).then(
            response => dispatch(fetchfetureSuccess(response.data.response))
        ).catch(
            error => dispatch(fetchfetureFaliour(error.message))
        )
    }
}

export const getfetureByApplyToId = (endpoint) => {
        return (dispatch) => {
            dispatch(fetchfetureRequest())
            fetchData(endpoint).then(
                response => dispatch(fetchfetureByApplyToId(response.data))
            ).catch(
                error => dispatch(fetchfetureFaliour(error.message))
            )
        }
}

export const postfetureData = (body) => {
    return (dispatch) => {
        dispatch(fetchfetureRequest())
        postData(`add_feature_item`,body).then(
            response => {
                dispatch(fetureFormData(response.data))
                response.data.status === 'success' && 
                dispatch(getfetureByApplyToId(`/featured_item_list/${body.branch_id}/${body.featured_item_applied_to_id}`))
            }
        ).catch(
            error => dispatch(fetchfetureFaliour(error.message))
        )
    }
}

export const postfetureItem = (endpoint, body) => {
    return (dispatch) => {
        dispatch(fetchfetureRequest())
        postData(endpoint,body).then(
            response => {
                dispatch(fetureFormData(response.data))
                response.data.status === 'success' && 
                dispatch(getfetureByApplyToId(`/featured_item_list/${response.data.response.branch_id}/${response.data.response.featured_item_applied_to_id}`))
            }
        ).catch(
            error => dispatch(fetchfetureFaliour(error.message))
        )
    }
}

export const updatefetureForm = (body) => {
    return (dispatch) => {
        dispatch(fetchfetureRequest())
        updateData(`update_featured_item`,body).then(
            response => {
                dispatch(fetureFormData(response.data))
                response.data.status === 'success' && 
                dispatch(getfetureByApplyToId(`/featured_item_list/${response.data.response.branch_id}/${response.data.response.featured_item_applied_to_id}`))

            }
        ).catch(
            error => dispatch(fetchfetureRequest(error.message))
        )
    }
}
export const deletefetureForm = (body) => {
    return (dispatch) => {
        dispatch(fetchfetureRequest())
        deleteData(`delete_feature_item`,{_id : body._id}).then(
            response => {
                dispatch(fetureFormData(response.data))
                response.data.status === 'success' && 
                dispatch(getfetureByApplyToId(`/featured_item_list/${body.branch_id}/${body.featured_item_applied_to_id}`))
            }
        ).catch(
            error => dispatch(fetchfetureRequest(error.message))
        )
    }
}

export const addfetureFormImage = (body) => {
    return (dispatch) => {
        postData(`add_featured_item_image`,body).then(
            response => dispatch(fetureFormImage(response.data.image_url))
        ).catch(
            error => dispatch(fetchfetureFaliour(error.message))
        )
    }
}

export const deletefetureFormImage = (body) => {
    return (dispatch) => {
        postData(`remove_image`,body).then(
            response => dispatch(fetureFormImage(''))
        ).catch(
            error => dispatch(fetchfetureFaliour(error.message))
        )
    }
}