import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllBranch,
  getBranchById,
  auth,
  updateActiveBranch,
} from "../../redux";

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Button,
  colors,
  Menu,
  MenuItem,
  makeStyles,
  List,
  ListItem,
  Drawer,
  Divider,
  FormGroup,
  FormControlLabel,
  Tooltip,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import {
  AccountCircle,
  ExpandMore,
  ViewComfy,
  ShoppingCart,
  Settings,
  Restaurant,
  People,
  DirectionsBike,
  Public,
  Receipt,
  Assessment,
  VpnKey,
  ExitToApp,
} from "@material-ui/icons";

import { useHistory } from "react-router-dom";

import { IOSSwitch } from "../Buttons/IOSSwitch.component";

import "./Header.css";

const drawerWidth = 100;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1500,
  },
  drawerPaper: {
    width: drawerWidth,
    // marginTop: 60,
    zIndex: 1500,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
  listStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: colors.grey[300],
    // padding: 0,
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const Header = () => {
  const allState = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [branch, setbranch] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [langAnchorEl, setLangAnchorEl] = React.useState(null);
  const [status, setStatus] = React.useState(true);
  const [id, setid] = useState("");
  const [selectPath, setSelectPath] = useState();

  console.log(selectPath);

  // useEffect(() => {
  //   if(window.location.pathname.split('/')[1]){
  //     setid(window.location.pathname.split('/')[1])
  //     setbranch(allState?.branch?.activeBranch?.branch_name)
  //   }
  //   else if (allState?.branch?.data?.length > 0) {
  //     setbranch(allState?.branch?.data[0]?.branch_name)
  //     setid(allState?.branch?.data[0]?._id)
  //   }
  // }, [allState?.branch])

  useEffect(() => {
    dispatch(getBranchById(allState?.login?.data?.adminstrator_branch_id));
    setid(allState?.login?.data?.adminstrator_branch_id);
  }, [allState?.login?.data]);

  useEffect(() => {
    setbranch(allState?.branch?.activeBranch?.branch_name);
  }, [allState?.branch?.activeBranch?.branch_name]);

  const isMenuOpen = Boolean(anchorEl);
  const isLangMenuOepn = Boolean(langAnchorEl);
  const handleMenuClose = (result) => {
    setAnchorEl(null);
    window.location.pathname.split("/")[1] &&
      result._id !== undefined &&
      window.location.replace(window.location.pathname.replace(id, result._id));
    setid(result._id);
  };

  const handleLangMenuClose = (event) => {
    setLangAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuOpen = (event) => {
    setLangAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const langMenuId = "primary-Lang-Menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      value={branch}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {allState &&
        allState?.branch?.data?.map((res) => (
          <MenuItem
            key={res._id}
            value={res}
            onClick={() => handleMenuClose(res)}
          >
            {res.branch_name}
          </MenuItem>
        ))}
    </Menu>
  );

  const renderLanguageMenu = (
    <Menu
      anchorEl={langAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={langMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isLangMenuOepn}
      onClose={handleLangMenuClose}
    >
      <MenuItem onClick={handleLangMenuClose}>ENG</MenuItem>
      <MenuItem onClick={handleLangMenuClose}>HINDI</MenuItem>
    </Menu>
  );

  console.log(history.location.pathname);

  const drawer = (
    <div className={classes.toolbar}>
      <List style={{ background: "#43454E" }} id="myDIV">
        <ListItem
          className={`${classes.listStyle}`}
          onClick={() => {
            history.push("/");
            setSelectPath(window.location.pathname);
          }}
          style={{
            color: !window.location.pathname.split("/")[2]
              ? "var(--orange)"
              : colors.grey[300],
          }}
        >
          <IconButton>
            <ViewComfy
              style={{
                fontSize: "2rem",
                color: !window.location.pathname.split("/")[2]
                  ? "var(--orange)"
                  : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            DASHBOARD
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          className={`${classes.listStyle}`}
          onClick={() => {
            history.push(`/${id}/orders-page`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "orders-page"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <ShoppingCart
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "orders-page"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            ORDERS
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          className={`${classes.listStyle} `}
          onClick={() => {
            history.push(`/${id}/users-basic`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "users-basic"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <People
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "users-basic"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            USERS
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          className={`${classes.listStyle} `}
          onClick={() => {
            history.push(`/${id}/menu-setup`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "menu-setup"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <Restaurant
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "menu-setup"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            MENU SETUP
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          className={`${classes.listStyle} `}
          onClick={() => {
            history.push(`/${id}/restaurant-basic`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "restaurant-basic"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <Settings
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "restaurant-basic"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            RESTAURANT BASICS
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          className={classes.listStyle}
          onClick={() => {
            history.push(`/${id}/frontend`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "frontend"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <Public
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "frontend"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            WEB & APP BUILDER
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          className={classes.listStyle}
          onClick={() => {
            history.push(`/${id}/user-settings/coupon`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "settings" ||
                window.location.pathname.split("/")[2] === "user-settings"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <Settings
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "settings" ||
                    window.location.pathname.split("/")[2] === "user-settings"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            PROMOTION
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          className={classes.listStyle}
          onClick={() => {
            history.push(`/${id}/delivary-boy`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "delivary-boy"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <DirectionsBike
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "delivary-boy"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            DELIVERY BOY
          </Typography>
        </ListItem>
        <Divider />


        <ListItem
          className={classes.listStyle}
          onClick={() => {
            history.push(`/${id}/cms`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "cms"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <Receipt
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "cms"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            CMS & SOCIAL
          </Typography>
        </ListItem>
        <Divider />
        {/* <ListItem
          className={classes.listStyle}
          onClick={() => {
            history.push(`/${id}/advance-settings`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "advance-settings"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <Settings
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "advance-settings"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            Advance Settings
          </Typography>
        </ListItem> 
        <Divider /> */}
        {/* <ListItem
          className={classes.listStyle}
          onClick={() => {
            history.push(`/${id}/preferences`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "preferences"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <Settings
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "preferences"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            Preferences
          </Typography>
        </ListItem> */}
        <Divider />
        <ListItem
          className={classes.listStyle}
          onClick={() => {
            history.push(`/${id}/report`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "report"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <Assessment
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "report"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            Report
          </Typography>
        </ListItem>
        <Divider />
        {/* <ListItem
          className={classes.listStyle}
          onClick={() => {
            history.push(`/${id}/access-control`);
            setSelectPath(window.location.pathname);
          }}
          style={{
            color:
              window.location.pathname.split("/")[2] === "access-control"
                ? "var(--orange)"
                : colors.grey[300],
          }}
        >
          <IconButton>
            <VpnKey
              style={{
                fontSize: "2rem",
                color:
                  window.location.pathname.split("/")[2] === "access-control"
                    ? "var(--orange)"
                    : colors.grey[300],
              }}
            />
          </IconButton>
          <Typography align="center" variant="caption">
            Access Control
          </Typography>
        </ListItem> */}
        <Divider />
      </List>
    </div>
  );

  return (
    <>
      {!allState?.branch?.loader ? (
        <div style={{ position: "relative", height: 65 }}>
          <AppBar
            position="fixed"
            style={{ background: "#ffffff", color: "#000000" }}
          >
            <Toolbar>
              <Box style={{ flexGrow: 1 }} />
              <FormGroup>
                <FormControlLabel
                  label={
                    allState?.branch?.activeBranch?.status
                      ? "Store Open"
                      : "Store Close"
                  }
                  control={
                    <IOSSwitch
                      checked={allState?.branch?.activeBranch?.status}
                      onChange={(e) =>
                        dispatch(
                          updateActiveBranch({
                            _id: allState?.branch?.activeBranch?._id,
                            status: e.target.checked,
                          })
                        )
                      }
                    />
                  }
                />
              </FormGroup>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled
                  style={{
                    marginRight: 10,
                    color: colors.grey[500],
                  }}
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  endIcon={<ExpandMore />}
                >
                  {branch}
                </Button>
                <Button
                  style={{
                    marginRight: 10,
                    color: colors.grey[500],
                  }}
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={langMenuId}
                  aria-haspopup="true"
                  onClick={handleLanguageMenuOpen}
                  endIcon={<ExpandMore />}
                >
                  En
                </Button>
                <LightTooltip
                  arrow={true}
                  title={
                    <Box
                      style={{
                        width: 220,
                        display: "flex",
                        flexDirection: "column",
                        padding: "1rem",
                        alignItems: "flex-start",
                      }}
                    >
                      <Button
                        fullWidth
                        startIcon={<Settings />}
                        variant="text"
                        onClick={() => history.push(`/${id}/profile`)}
                      >
                        Account setting
                      </Button>
                      <Button
                        fullWidth
                        startIcon={<VpnKey />}
                        variant="text"
                        onClick={() => history.push(`/${id}/change-password`)}
                      >
                        Change Password
                      </Button>
                      <Button
                        fullWidth
                        startIcon={<ExitToApp />}
                        variant="text"
                        onClick={() => {
                          localStorage.setItem("auth-token", "");
                          dispatch(auth(localStorage.getItem("auth-token")));
                        }}
                      >
                        Log out
                      </Button>
                    </Box>
                  }
                  interactive
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>
                      Hi,{" "}
                      {allState?.login?.data?.administrator_user_name
                        ? allState?.login?.data?.administrator_user_name
                        : "User"}
                    </Typography>
                    <IconButton>
                      <AccountCircle style={{ fontSize: "2rem" }} />
                    </IconButton>
                  </Box>
                </LightTooltip>
              </Box>
              {renderMenu}
              {renderLanguageMenu}
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            {drawer}
          </Drawer>
        </div>
      ) : (
        <div className="loaderBg">
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  );
};
