import { FETCH_INGRIDINENT_REQUEST, FETCH_INGRIENT_FALIOUR, FETCH_INGRIENT_SUCCESS, 
    INGERIDIENT_DATA, INGRIDIENT_IMAGE,FETCH_INGRIENT_BY_ID_SUCCESS } from "./ingridientTypes";

const initialState = {
    loading: false,
    totalPage: 0,
    currentPage: 0,
    data: [],
    status: '',
    message: '',
    image_link: ''
}

export const ingridientReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INGRIDINENT_REQUEST:
            return {
                ...state, loading: true,
            }
        case FETCH_INGRIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                totalPage: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                data: action.payload.response,
                status: '',
                message: '',
                image_link: ''
            }
        case FETCH_INGRIENT_BY_ID_SUCCESS :
            return {
                 ...state, data: action.payload
            }
        
        case FETCH_INGRIENT_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: action.payload
            }
        case INGERIDIENT_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }

        case INGRIDIENT_IMAGE: 
            return {
                ...state,
                image_link : action.payload
            }

        default:
            return state
    }
}