import {
  Breadcrumbs,
  Button,
  Divider,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  CircularProgress,
  IconButton,
  Snackbar,
  ListItem,
  List,
  SwipeableDrawer,
  colors,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  Delete,
  Edit,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAdministrator,
  updateAdministrator,
  deleteAdministrator,
} from "../../redux";
import MuiAlert from "@material-ui/lab/Alert";
import { IOSSwitch } from "../../components/Buttons/IOSSwitch.component";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { SwipeDrawer } from "../RestaurantBesicPage/swipedrawer/swipeDrawer";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  editButton: {
    border: "1px solid grey",
    borderRadius: "50%",
  },
  deleteButton: {
    border: "1px solid lightcoral",
    borderRadius: "50%",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function datePipe(date) {
  return date;
}

export const Administrator = ({ match }) => {
  const history = useHistory();
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("administrator");
  const [del, setDel] = useState("");
  const id = match.params.id;
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [roleList, _roleList] = useState([
    { role_name: "Main Admin", _id: "main_admin" },
    { role_name: "Administrator", _id: "administrator" },
    { role_name: "Branch manager", _id: "branch_manager" },
    { role_name: "new role", _id: "new_role" },
  ]);

  useEffect(() => {
    dispatch(getAdministrator());
  }, [dispatch]);

  const update = (administrator) => {
    administrator.branch = state?.branch?.data?.find(
      (res) => administrator.adminstrator_branch_id === res._id
    );
    administrator.administrator_role = roleList?.find(
      (res) => administrator.administrator_role_id === res._id
    );
    history.push({
      pathname: "create-administrator",
      state: administrator,
    });
  };

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            paddingTop: "10px",
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
            display: "flex",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Restaurant Basics
              </Typography>
            </div>
            <SwipeDrawer />
          </SwipeableDrawer>
          <div
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
              </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Administrator
              </Typography>
            </Breadcrumbs>
            <Paper
              style={{
                padding: "2rem",
                margin: "15px auto 5rem auto",
                width: "100%",
              }}
            >
              <Typography variant="h6">Administrator</Typography>
              <Divider style={{ margin: "10px 0" }} />
              <Button
                className="button"
                onClick={() =>
                  history.push(`/${id}/restaurant-basic/create-administrator`)
                }
              >
                add administrator
              </Button>
              <Divider style={{ margin: "10px 0" }} />
              {state?.Administrator?.loading ? (
                <CircularProgress></CircularProgress>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Admininstrator Name</TableCell>
                      <TableCell>Role Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Last Login</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state?.administrator?.data &&
                      state?.administrator?.data?.length > 0
                      ? state?.administrator?.data?.map((res) => (
                        <TableRow>
                          <TableCell>1</TableCell>
                          <TableCell>
                            {res.administrator_first_name}{" "}
                            {res.administrator_last_name}
                          </TableCell>
                          <TableCell>
                            {
                              roleList.find(
                                (role) =>
                                  role._id === res.administrator_role_id
                              )?.role_name
                            }
                          </TableCell>
                          <TableCell>{res.administrator_email}</TableCell>
                          <TableCell>
                            {datePipe(res.administrator_last_login)}
                          </TableCell>
                          <TableCell>
                            <IOSSwitch
                              checked={res.status}
                              onClick={() =>
                                dispatch(
                                  updateAdministrator({
                                    _id: res._id,
                                    status: !res.status,
                                  })
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Edit">
                              <IconButton onClick={() => history.push({
                                pathname: `/${id}/restaurant-basic/create-administrator`,
                                state: res
                              })}>
                                <Edit
                                  className={`${classes.editButton} edit`}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <IconButton onClick={() => setDel(res)}>
                                <Delete
                                  className={`${classes.deleteButton} delete`}
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                      : "No data found"}
                  </TableBody>
                </Table>
              )}
            </Paper>
            <Snackbar
              open={state?.administrator?.status}
              onClose={state?.administrator?.status}
            >
              <Alert
                onClose={!state?.administrator?.status}
                severity={state?.administrator?.status}
              >
                {state?.administrator?.message}
              </Alert>
            </Snackbar>
            <Dialog
              open={del}
              onClose={() => setDel("")}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  DO YOU REALLY WANT TO DELETE
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDel("")} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setDel("");
                    dispatch(deleteAdministrator(del));
                  }}
                  color="primary"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
};
