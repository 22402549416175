export const FETCH_ALL_PAYMENT_GETWAY = 'EETCH_ALL_PAYMENT_GETWAY'
export const UPDATE_PAYMENT_GETWAY = 'UPDATE_PAYMENT_GETWAY'
export const OFF_ALL_PAYMENT_GETWAY = 'OFF_ALL_PAYMENT_GETWAY'
export const FETCH_TWILIO_SMS_CONFIGURE = 'FETCH_TWILIO_SMS_CONFIGURE'
export const FETCH_TEXTLOCAL_SMS_CONFIGURE = 'FETCH_TEXTLOCAL_SMS_CONFIGURE'
export const UPDATE_SMS_CONFIGURE = 'UPDATE_SMS_CONFIGURE'
export const FETCH_GDPR = 'FETCH_GDPR'
export const UPDATE_GDPR = 'UPDATE_GDPR'
export const FETCH_EMAIL_CONFIGURE = 'FETCH_EMAIL_CONFIGURE'
export const UPDATE_EMAIL_CONFIGURE = 'UPDATE_EMAIL_CONFIGURE'
export const FETCH_PRE_DELIVERY_DAY = 'FETCH_PRE_DELIVERY_DAY'
export const FETCH_ANALYTICS = 'FETCH_ANALYTICS'
export const UPDATE_ANALYTICS = 'UPDATE_ANALYTICS'
export const FETCH_PROMOTIONAL_PAGE = 'FETCH_PROMOTIONAL_PAGE'
export const UPDATE_PROMOTIONAL_PAGE = 'UPDATE_PROMOTIONAL_PAGE'
export const FETCH_GOOGLE_MAP_API_KEY = 'FETCH_GOOGLE_MAP_API_KEY'
export const UPDATE_GOOGLE_MAP_API_KEY = 'UPDATE_GOOGLE_MAP_API_KEY'
export const FETCH_SOCIAL_MEDIA_LOGIN = 'FETCH_SOCIAL_MEDIA_LOGIN'
export const UPDATE_SOCIAL_MEDIA_LOGIN = 'UPDATE_SOCIAL_MEDIA_LOGIN'