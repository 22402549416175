import React, { useState } from "react";

import {
    Breadcrumbs,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
    Divider,
    IconButton,
    // useMediaQuery,
    colors,
    TextField,
    SwipeableDrawer,
    List,
    ListItem
} from "@material-ui/core";
import { ChevronLeft, ChevronRight, RadioButtonUnchecked } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom'
import clsx from "clsx";

const filter = createFilterOptions();


const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));


export const NewsSub = ({ match }) => {
    const classes = useStyles();
    const history = useHistory()
    // const [open, setOpen] = useState(false);
    // const [availability, setAvailability] = useState("open");
    const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, 
    // setselectState
  ] = useState("Newsletter Subscribers");
    const [value, setValue] = React.useState('');
    const id = match.params.id
    const [autoCompleteOptions, 
        // setautoCompleteOptions
    ] = useState([{ title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    ])
    // const [autoOpen, toggleAutoOpen] = React.useState(false);
    // const [dialogValue, setDialogValue] = React.useState({
    //     title: '',
    //     year: '',
    // });
    // const handleAutoClose = () => {
    //     setDialogValue({
    //         title: '',
    //         year: '',
    //     });

    //     // toggleAutoOpen(false);
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     setValue({
    //         title: dialogValue.title,
    //         year: parseInt(dialogValue.year, 10),
    //     });

    //     // handleClose();
    // };

    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // const handleChange = (event) => {
    //     setStage(event.target.value);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    return (
        <div style={{
            width: "100%",
            height: "100vh",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root} >
            <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Users
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "user" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Users</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Newsletter Subscribers" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/news-sub`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Newsletter Subscribers</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Customer feedback" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/customer-feedback`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Customer feedback</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Customer Testimonial" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/customer-testimonial`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Customer Testimonial</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Rating" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/rating`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Rating</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "customer-group" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/customer-group`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Customer Group</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div  style={{ paddingLeft: 20, paddingRight: 20 }} className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}>
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
                <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                    Home
        </Link>
                <Typography
                    color="textPrimary"
                    style={{ fontSize: 15, color: "var(--orange)" }}
                >
                    NewsLetter Subscribers
        </Typography>
            </Breadcrumbs>

            <Paper style={{ padding: "2rem", margin: "15px auto"}}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        paddingBottom: "10px"
                    }}
                >
                    <Typography variant="h6">NewsLetter Subscribers</Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        padding: "20px 0"
                    }}>
                        <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    // timeout to avoid instant validation of the dialog's form.
                                    // setTimeout(() => {
                                        // toggleAutoOpen(true);
                                        // setDialogValue({
                                        //     title: newValue,
                                        //     year: '',
                                        // });
                                    // });
                                } else if (newValue && newValue.inputValue) {
                                    // toggleAutoOpen(true);
                                    // setDialogValue({
                                    //     title: newValue.inputValue,
                                    //     year: '',
                                    // });
                                } else {
                                    setValue(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== '') {
                                    filtered.push({
                                        inputValue: params.inputValue,
                                        title: `Add "${params.inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            options={autoCompleteOptions}
                            getOptionLabel={(option) => {
                                // e.g value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                return option.title;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            renderOption={(option) => option.title}
                            style={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Search" variant="outlined" />
                            )}
                        />
                        {/* <Button
                            variant="contained"
                            style={{
                                padding: '15px 0',
                                background: "var(--orange)",
                                color: "#ffffff",
                                height: '100%'
                            }}
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                        >
                        </Button> */}
                        
                        </div>
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Email ID	</TableCell>
                            <TableCell>Service Rating</TableCell>
                            <TableCell>Quality Rating</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>1</TableCell>
                            <TableCell>test</TableCell>
                            <TableCell>4.5</TableCell>
                            <TableCell>0</TableCell>
                            <TableCell>
                                <IconButton aria-label="delete" color="secondary">
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
      </div>
        </div>
    );
};

