import { FETCH_FETURE_REQUEST,FETCH_FETURE_SUCCESS,FETCH_FETURE_BY_APPLY_TO_ID_SUCCESS,
    FETCH_FETURE_FALIOUR,FETURE_DATA,FETURE_IMAGE,GET_ALL_FETURE_APPLIED_ON } from "./fetureTypes";

const initialState = {
    loading: false,
    totalPage: 0,
    currentPage: 0,
    data: [],
    status: '',
    message: '',
    image_link: ''
}

export const fetureReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FETURE_REQUEST:
            return {
                ...state, loading: true,
            }
        case GET_ALL_FETURE_APPLIED_ON: 
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        
        case FETCH_FETURE_SUCCESS: 
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case FETCH_FETURE_BY_APPLY_TO_ID_SUCCESS:
            // console.log(state.data.findIndex(cat => cat._id === action.payload.response[0].featured_item_applied_to_id))
            return {
                ...state,
                loading: false,
                totalPage: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                data: action.payload.response,
                status: '',
                message: '',
                image_link: ''
            }
        // case FETCH_FETURE_BY_ID_SUCCESS :
        //     // console.log(state.data.findIndex(cat => cat._id === action.payload.response[0].featured_item_applied_to_id))
        //     return {
        //          ...state, data: action.payload
        //     }
        
        case FETCH_FETURE_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: action.payload
            }
        case FETURE_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }

        case FETURE_IMAGE: 
            return {
                ...state,
                image_link : action.payload
            }

        default:
            return state
    }
}