import React, { useState, useEffect } from 'react'
import {
  IconButton, makeStyles, SwipeableDrawer, Typography, colors, Breadcrumbs, Link, Paper, Divider, Box,
  Button, ButtonGroup, CircularProgress
} from '@material-ui/core'
import clsx from "clsx";
import { Check, ChevronLeft, ChevronRight, RadioButtonUnchecked } from '@material-ui/icons';

import { Input } from '../../components/Input/Input.component'
import { useSelector, useDispatch } from "react-redux";
import { getpreference, updatepreference } from "../../redux";
import { SwipeDrawer } from './swipedrawer/swipeDrawer';

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const Preferences = () => {
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("branch");
  const [formValue, setformValue] = useState({
    order_number: "", order_number_is_prefix: false,
    customer_number: "", customer_number_is_prefix: false, status: true
  })

  useEffect(() => {
    dispatch(getpreference())
  }, [dispatch])

  useEffect(() => {
    state?.preference?.data && setformValue(state?.preference?.data)
  }, [state?.preference?.data])

  return (
    <>
      {
        !state?.branch?.loader &&
        <div style={{
          paddingTop: "10px",
          width: "100%",
          height: "100%",
          background: colors.grey[200],
          position: "relative",
          display: 'flex'
        }} >
          <>
            <div
              style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
              className={classes.drawerHeader}
            >
              <IconButton>
                {drawerOpen ? (
                  <ChevronLeft onClick={() => setdrawerOpen(false)} />
                ) : (
                  <ChevronRight onClick={() => setdrawerOpen(true)} />
                )}
              </IconButton>
            </div>
            <SwipeableDrawer
              className={classes.drawer}
              anchor="left"
              variant="persistent"
              open={drawerOpen}
              onClose={() => setdrawerOpen(false)}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
                <Typography align="center" variant="h6">
                Restaurant Basics
          </Typography>
              </div>
              <SwipeDrawer />
            </SwipeableDrawer>
          </>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              // color: selectState === "coupon" ? "var(--orange)" : "#ffffff",
              cursor: "pointer"
            }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })} >
            <div style={{ padding: '0 20px', width: '100%' }} >
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight style={{ fontSize: 15 }} />}
              >
                <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                  Home
                </Link>
                <Typography
                  color="textPrimary"
                  style={{ fontSize: 15, color: "var(--orange)" }}
                >
                  Preferences
                </Typography>
              </Breadcrumbs>
              {
                state?.preference?.loader ? <div className="loaderBg"><CircularProgress color="secondary" /></div>
                  :
                  <Paper style={{ padding: "2rem", margin: "15px auto 5rem auto", width: "70%" }}>
                    <Typography variant="h6" >Preferences</Typography>
                    <Divider style={{ margin: '10px 0' }} />
                    <Box display='flex' alignItems="center" >
                      <Box marginRight="2em">
                        <Typography variant="h6">Order Number: (ex: BC-000012 or 000012-BC)</Typography>
                        <Typography variant='body1' >An order number can be followed by prefix/suffix text. You can edit the prefix/suffix text below to suit your business needs.
                        </Typography>
                        <Input style={{ width: '100%', marginTop: '2rem' }} value={formValue.order_number}
                          onChange={(e) => setformValue({ ...formValue, order_number: e.target.value })} />
                      </Box>
                      <Box display='flex' justifyContent='center' alignItems="center" flexDirection='column' >
                        <Typography variant="body2" style={{ marginBottom: '5px' }} >Extensen</Typography>
                        <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                          <Button color={formValue.order_number_is_prefix ? "primary" : "secondary"}
                            onClick={() => setformValue({ ...formValue, order_number_is_prefix: true })}>Prefix</Button>
                          <Button color={!formValue.order_number_is_prefix ? "primary" : "secondary"}
                            onClick={() => setformValue({ ...formValue, order_number_is_prefix: false })}>Postfix</Button>
                        </ButtonGroup>
                      </Box>
                    </Box>
                    <Box display='flex' alignItems="center" marginTop='2rem'>
                      <Box marginRight="2em">
                        <Typography variant="h6">Customer Number: (ex: CUS-0012 or 0012-CUS)</Typography>
                        <Typography variant='body1' >A customer number can be followed by prefix/suffix text. You can edit the prefix/suffix text below to suit your business needs.
                        </Typography>
                        <Input style={{ width: '100%', marginTop: '2rem' }} value={formValue.customer_number}
                          onChange={(e) => setformValue({ ...formValue, customer_number: e.target.value })} />
                      </Box>
                      <Box display='flex' justifyContent='center' alignItems="center" flexDirection='column' >
                        <Typography variant="body2" style={{ marginBottom: '5px' }} >Extensen</Typography>
                        <ButtonGroup color="secondary" aria-label="outlined secondary button group">
                          <Button color={formValue.customer_number_is_prefix ? "primary" : "secondary"}
                            onClick={() => setformValue({ ...formValue, customer_number_is_prefix: true })}>Prefix</Button>
                          <Button color={!formValue.customer_number_is_prefix ? "primary" : "secondary"}
                            onClick={() => setformValue({ ...formValue, customer_number_is_prefix: false })}>Postfix</Button>
                        </ButtonGroup>
                      </Box>
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='center' marginTop='2rem' >
                      <Button className="button" startIcon={<Check />} onClick={() => dispatch(updatepreference(formValue))}>save</Button>
                    </Box>
                  </Paper>
              }
            </div>
          </div>
        </div>
      }
    </>
    )
}
