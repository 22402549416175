import { HANDLE_CUPON_REQUEST, HANDLE_CUPON_SUCCESS, HANDLE_CUPON_FALIOUR, 
    HANDLE_CUPON_FORM_DATA, GET_CUPON_DATA_BY_ID } from "./cuponTypes";

const initialState = {
    loading: false,
    data: [],
    activeBranch: {},
    totalPages: 0,
    currentPage: 0,
    response: '',
    status: '',
    message: ''
}

export const couponReduder = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_CUPON_REQUEST:
            return {
                ...state, loading: true
            }
        case HANDLE_CUPON_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.response,
                totalPages: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                status: '',
                message: ''
            }
        case GET_CUPON_DATA_BY_ID:
            return {
                ...state,
                loading: false,
                activeBranch: action.payload
            }
        case HANDLE_CUPON_FALIOUR:
            return {
                ...state,
                loading: false,
                data: [],
                status: 'error',
                message: 'Network Error'
            }
        case HANDLE_CUPON_FORM_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === "success" ? action.payload.status : action.payload.status !== "formFail" && 'error',
                message: action.payload.message,
                response: action.payload.status === "formFail" ? action.payload.name : ''
            }
        default:
            return state
    }
}