import React, { useState, useEffect } from 'react'
import {
    Box, Button, Grid, Paper, Checkbox, Typography, Modal, colors, IconButton, FormControl,
    RadioGroup, FormControlLabel, Radio, Dialog, Tooltip,
    CircularProgress, Snackbar
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { Search, FiberManualRecord, Close, Delete, Edit } from '@material-ui/icons'
import { HiOutlineArrowCircleRight } from 'react-icons/all'
import { Pagination, } from "@material-ui/lab";
// import { useHistory } from 'react-router'
import { getAllCupon, updateCupon, deleteCupon } from '../../../redux'
import { useSelector, useDispatch } from "react-redux";
import { Input } from '../../../components/Input/Input.component'
import { CreateManualDiscount } from './CreateManualDiscount'
import Slide from '@material-ui/core/Slide';
import moment from 'moment'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export const Discount = ({ drawerOpen, setdrawerOpen, id }) => {
    // const history = useHistory()
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const [isHiddenModal, setIsHiddenModal] = useState(false)
    const [createDiscountModal, setCreateDiscountModal] = useState(false)
    const [selectDiscountMathod, setSelectDiscountMathod] = useState('manual-discount')
    const [discount, setdiscount] = useState('')
    const [summery, setSummery] = useState({})
    const [edit, setEdit] = useState(null)
    const handleMouseEnter = (res) => {
        try {
            setdrawerOpen(false)
            setIsHiddenModal(true)
            setSummery(res)
        } catch (error) {
            console.log(error)
        }
    }

    const handleClose = () => {
        setCreateDiscountModal(false)
        setdiscount('')
        setEdit(null)
        setSelectDiscountMathod('')
    }

    useEffect(() => {
        setdiscount(selectDiscountMathod)
    }, [selectDiscountMathod])

    useEffect(() => {
        dispatch(getAllCupon())
    }, [dispatch])

    const hoverModal = () => {
        return (
            <div style={{ width: "25%", position: "fixed", padding: "0 3rem", right: "0", }}  >
                <Paper style={{ padding: "1rem", marginTop: "3%", borderRadius: '15px', backgroundColor: colors.grey[50] }} variant="outlined" >
                    <Typography variant='body1' >Summary</Typography>
                    <Typography variant="h6" align='center' style={{ marginTop: '2rem' }} >
                        {summery.coupon_discount_code ? summery.coupon_discount_code : `COUPON APPLIED AUTOMATECALLY`}
                    </Typography>
                    <Box display='flex' alignItems='center' marginTop='1rem' >
                        <FiberManualRecord style={{ marginRight: '1rem' }} color='primary' />
                        <Typography variant='h6' >
                            {
                                summery.coupon_discount_type_flat_off ?
                                    `${summery.coupon_discount_value} Rs Off` :
                                    `${summery.coupon_discount_value}% Off`
                            }
                            {summery.coupon_applied_to_entire_order && ` On Entire Order On Order Above ${summery.coupon_applied_to_entire_order_minimum_cart_value}`}
                            {summery.coupon_applied_to_specific_collection && ` On Entire Collection Products`}
                            {summery.coupon_applied_to_specific_item && ` On This Product`}
                        </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' marginTop='1rem' >
                        <FiberManualRecord style={{ marginRight: '1rem' }} color='primary' />
                        <Typography variant='h6' >{summery.coupon_customer_eligibility_everyone && 'For All Customer'}</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' marginTop='1rem' >
                        <FiberManualRecord style={{ marginRight: '1rem' }} color='primary' />
                        <Typography variant='h6' >$5 off entire order</Typography>
                    </Box>
                </Paper>
                {
                    summery.coupon_terms_and_conditions &&
                    <Paper style={{ padding: "1rem", marginTop: "3%", borderRadius: '15px' }} variant="outlined" >
                        <Typography variant="body2" >{summery.coupon_terms_and_conditions}</Typography>
                    </Paper>
                }
            </div>
        )
    }

    return (<>
        {
            state?.coupon?.loading ? <div className="loaderBg"><CircularProgress color="secondary" /></div> :
                <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', position: 'relative' }} >
                    <Modal style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} open={createDiscountModal}
                        onClose={() => {
                            setCreateDiscountModal(false)
                            setEdit(null)
                        }} >
                        <div style={{ backgroundColor: colors.grey[50], borderRadius: 20 }} >
                            <Box display='flex' alignItems='center' justifyContent='space-between' padding='2rem' bgcolor={colors.blue[50]} borderRadius='20px' >
                                <Typography>Create new discount code</Typography>
                                <IconButton onClick={() => {
                                    setCreateDiscountModal(false)
                                    setEdit(null)
                                }}>
                                    <Close />
                                </IconButton>
                            </Box>
                            <FormControl >
                                <RadioGroup name="selectDiscountMathod"
                                    value={selectDiscountMathod} onChange={e => setSelectDiscountMathod(e.target.value)}
                                >
                                    <Box padding='2rem'>
                                        <Grid container>
                                            <Grid item sm={8} alignItems='flex-start' style={{ display: 'flex' }} >
                                                <FormControlLabel control={<Radio color='primary' />} value='manual-discount' />
                                                <Box>
                                                    <Typography variant='body1' >Manual Discount Code</Typography>
                                                    <Typography variant='body2' >While enter a code at checkout customer will get discount</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4} style={{ display: 'flex' }} alignItems='center' justify='center' >
                                                <Tooltip title="First Choose Discount Method as Manual Discount
                                         And Then You Can Open the form" aria-label="add">
                                                    <IconButton>
                                                        <HiOutlineArrowCircleRight onClick={() => {
                                                            setdiscount('manual-discount')
                                                            // if (selectDiscountMathod !== '') {
                                                            //     history.push(`/${id}/manual-discount`)
                                                            // }
                                                        }} className='edit' style={{ fontSize: '2rem', borderRadius: '50%' }} />

                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Dialog maxWidth="xl"
                                        open={selectDiscountMathod === discount}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={() => handleClose()}
                                        aria-labelledby="alert-dialog-slide-title"
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <CreateManualDiscount discountType={discount === 'manual-discount'}
                                            alldialogOff={() => setCreateDiscountModal('')}
                                            dialogOff={() => setdiscount('')} edit={edit} />
                                    </Dialog>

                                    <Box padding='2rem'>
                                        <Grid container >
                                            <Grid item sm={8} alignItems='flex-start' style={{ display: 'flex' }} >
                                                <FormControlLabel control={<Radio color='primary' />} value='automatic-discount' />
                                                <Box>
                                                    <Typography variant='body1' >Automatic Discount Code </Typography>
                                                    <Typography variant='body2' >Customer will get a discount automatically while add product in cart.</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item sm={4} style={{ display: 'flex' }} alignItems='center' justify='center' >
                                                <Tooltip title="First Choose Discount Method as Autometic Discount
                                         And Then You Can Open the form" aria-label="add">
                                                    <IconButton>
                                                        <HiOutlineArrowCircleRight className='edit' style={{ fontSize: '2rem', borderRadius: '50%' }}
                                                            onClick={() => {
                                                                setdiscount('automatic-discount')
                                                                // if (selectDiscountMathod !== '') {
                                                                //     history.push(`/${id}/manual-discount`)
                                                                // }
                                                            }}
                                                        />

                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </RadioGroup>
                            </FormControl>
                            <Box padding='2rem' display='flex' alignItems='center' justifyContent='flex-end' >
                                <Button className='button' onClick={() => {
                                    setCreateDiscountModal(false)
                                    setEdit(null)
                                }} >cancel</Button>
                            </Box>
                        </div>
                    </Modal>
                    <Box width={drawerOpen ? '100%' : '75%'} margin={isHiddenModal ? '0' : 'auto'} >
                        <Paper style={{ padding: "2rem", width: '100%' }}>
                            <Box display='flex' alignItems='center' >
                                <Button className='select-button' disableRipple style={{ marginRight: '2rem' }} >Manual Discount</Button>
                                <Button className="select-button">Automatic Discount </Button>
                            </Box>
                            <Box display='flex' alignItems='center' marginTop='2rem'>
                                <Grid container>
                                    <Grid item sm={8} alignItems='center' >
                                        <Button className="select-button" style={{ marginRight: '2rem' }}>All</Button>
                                        <Button className="select-button" style={{ marginRight: '2rem' }}>Active</Button>
                                        <Button className="select-button" style={{ marginRight: '2rem' }}>Scheduled</Button>
                                        <Button className="select-button" >Expired</Button>
                                    </Grid>
                                    <Grid item sm={4} alignItems='center' >
                                        <Button className="select-button" onClick={() => {
                                            setCreateDiscountModal(true)
                                        }} >Create new discount code</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid container style={{ marginTop: '2rem' }}>
                                <Grid item alignItems='center' sm={8} >
                                    <Input style={{ width: '90%' }} label='Search' icon={<Search />} />
                                </Grid>
                                <Grid item alignItems='center' sm={4} justify="flex-end" >
                                    <Button className="select-button" style={{ marginRight: '2rem' }}>Sort</Button>
                                    <Button className="select-button">Filter</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        {
                            state?.coupon?.data?.length > 0 ?
                                state?.coupon?.data?.map(
                                    res =>
                                        <Paper style={{ padding: "1rem", marginTop: "3%", borderRadius: '15px', backgroundColor: colors.grey[50] }} variant="outlined"
                                            onMouseEnter={() => handleMouseEnter(res)}
                                            onMouseLeave={e => {
                                                setIsHiddenModal(false)
                                                // setdrawerOpen(true)
                                            }}
                                        >
                                            <Grid container>
                                                <Grid item sm={1} >
                                                    <Checkbox />
                                                </Grid>
                                                <Grid item sm={3} >
                                                    <Typography variant="body1">{res.coupon_name}</Typography>
                                                    <Typography variant='body2' >+ {
                                                        res.coupon_discount_type_flat_off ?
                                                            `${res.coupon_discount_value} Rs Off` :
                                                            `${res.coupon_discount_value}% Off`
                                                    }
                                                        {res.coupon_applied_to_entire_order && ` On Entire Order On Order Above ${res.coupon_applied_to_entire_order_minimum_cart_value}`}
                                                        {res.coupon_applied_to_specific_collection &&
                                                            ` On Entire ${res.coupon_applied_to_specific_collection_id.featured_item_name} Collection Products`}
                                                        {res.coupon_applied_to_specific_item && ` On This ${res.coupon_applied_to_specific_item.item_name}`}
                                                    </Typography>
                                                    <Typography variant='body2' >
                                                        {res.coupon_customer_eligibility_everyone && '+ For All Customer'}
                                                        {res.coupon_customer_eligibility_group_of_customer && 
                                                        `+ For All ${res.coupon_customer_eligibility_group_of_customer_id.customer_group_name} group customers`}
                                                        {res.coupon_customer_eligibility_specific_customer && 
                                                        `+ ${res.coupon_customer_eligibility_specific_customer_id.phone_number}`}
                                                    </Typography>
                                                    <Typography variant='body2' >{res.coupon_limit_to_one_use_per_customer ?
                                                        `+ Only One Time Use Per Customer` : `+ No Limitaion Using Cupon Per Customer`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={1} alignItems='center'>
                                                    {
                                                        res.status ?
                                                            <Button variant='outlined' color='primary' size='small'
                                                                onClick={() => dispatch(updateCupon({ _id: res._id, status: !res.status }))}
                                                            >Active</Button> :
                                                            <Button variant='outlined' color='secondary' size='small'
                                                                onClick={() => dispatch(updateCupon({ _id: res._id, status: !res.status }))}
                                                            >Off</Button>
                                                    }
                                                </Grid>
                                                <Grid item sm={2} alignItems='center' >
                                                    <Typography variant="body1">0/{res.coupon_number_of_times_the_discount_can_be_used ?
                                                        res.coupon_number_of_times_the_discount_can_be_used_value : "Unlimited"} used</Typography>
                                                </Grid>
                                                <Grid item sm={2}>
                                                    <Typography variant="body1">Active from</Typography>
                                                    <Typography variant="body2">{res.coupon_available_from ?
                                                        `${moment(res.coupon_available_from).format('L')} ${moment(res.coupon_available_from).format('LT')}` :
                                                        `Coupon has No Limited Time`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={2}>
                                                    <Typography variant="body1">Active Up to</Typography>
                                                    <Typography variant="body2">{res.coupon_available_to ?
                                                        `${moment(res.coupon_available_to).format('L')} ${moment(res.coupon_available_to).format('LT')}` :
                                                        `Coupon has No Limited Time`}</Typography>
                                                </Grid>
                                                <Grid item sm={1} >
                                                    <IconButton onClick={() => {
                                                        setEdit(res)
                                                        setCreateDiscountModal(true)
                                                        setSelectDiscountMathod(res.coupon_add_discount_code_manually ? 'manual-discount' : 'automatic-discount')
                                                        setdiscount(res.coupon_add_discount_code_manually ? 'manual-discount' : 'automatic-discount')
                                                    }} >
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton>
                                                        <Delete onClick={() => dispatch(deleteCupon({ _id: res._id }))} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                )
                                :
                                <Paper style={{
                                    padding: "1rem", marginTop: "3%", borderRadius: '15px',
                                    backgroundColor: colors.grey[50], height: '150px'
                                }} elevation={0}>
                                    <Typography variant="button" color="textSecondary" style={{ margin: 'auto' }}>
                                        You have no Cupons Yet
                                    </Typography>
                                    <Button className="select-button" onClick={() => {
                                        setCreateDiscountModal(true)
                                    }} >Create new discount code</Button>
                                </Paper>
                        }
                        {
                            state?.coupon?.totalPages > 1 &&
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                marginTop="2rem"
                            >
                                <Pagination count={state?.coupon.totalPages} page={state?.coupon?.currentPage} color="primary"
                                    onChange={(e, value) => dispatch(getAllCupon(`get_all_coupons?limit=10&page=${value.toString()}`))}
                                />
                            </Box>
                        }
                    </Box>
                    {isHiddenModal && hoverModal()}
                </div>
        }
        <Snackbar open={state.coupon.status}
            onClose={!state.coupon.status}>
            <Alert onClose={!state.coupon.status} severity={state.coupon.status}>
                {state.coupon.message}
            </Alert>
        </Snackbar>
    </>
    )
}
