import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Cupons } from "../Cupons/Cupons";

import {
  colors,
  List,
  ListItem,
  SwipeableDrawer,
  makeStyles,
  IconButton,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { RadioButtonUnchecked } from "@material-ui/icons";
import clsx from "clsx";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

//pages
import BranchPage from "./BranchPage";
import { getAllBranch } from "../../redux";
import { SwipeDrawer } from "./swipedrawer/swipeDrawer";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    minHeight: "100vh",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    minHeight: "100vh",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const RestaurantBesicPage = ({ match }) => {
  const history = useHistory();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("branch");
  const id = match.params.id;
  useEffect(() => {
    dispatch(getAllBranch());
  }, [dispatch]);
  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            // paddingLeft: "12%",
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          {/* <Header /> */}
          <>
            <div
              style={{
                position: "fixed",
                top: "0%",
                right: "63%",
                zIndex: 1100,
              }}
              className={classes.drawerHeader}
            >
              <IconButton>
                {drawerOpen ? (
                  <ChevronLeft onClick={() => setdrawerOpen(false)} />
                ) : (
                  <ChevronRight onClick={() => setdrawerOpen(true)} />
                )}
              </IconButton>
            </div>
            <SwipeableDrawer
              className={classes.drawer}
              anchor="left"
              variant="persistent"
              open={drawerOpen}
              onClose={() => setdrawerOpen(false)}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                  color: "#ffffff",
                  marginBottom: 20,
                }}
              >
                <Typography align="center" variant="h6">
                  Restaurant Basics
                </Typography>
              </div>
              <SwipeDrawer />
            </SwipeableDrawer>

            <div
              style={{
                color: selectState === "coupon" ? "var(--orange)" : "#ffffff",
                cursor: "pointer",
              }}
              className={clsx(classes.content, {
                [classes.contentShift]: drawerOpen,
              })}
            >
              {
                state.branch.loading ? (
                  <div
                    className={clsx(classes.content, {
                      [classes.contentShift]: drawerOpen,
                    })}
                  >
                    <div className="loaderBg">
                      <CircularProgress color="secondary" />
                    </div>
                  </div>
                ) : (
                  <BranchPage id={id} />
                )
                //     {/* {selectState === "pickup" && <div style={{ width: '100%' }}>
                //        {
                //         state.pickupDelivary.loader ? <CircularProgress></CircularProgress> : <PickupAndDeliveryPage />
                //       }
                //     </div>}
                //     {selectState === 'deliveryzone' && <div style={{ width: '100%' }}>
                //       {
                //         state.operationalhours.loader ? <CircularProgress></CircularProgress> : <DelevaryZones />
                //       }
                //       </div>
                //     }
                //     {selectState === "operationalhours" && <Operationalhours />} */}
                // {/* {selectState === "coupon" && <Cupons/>} */}
                // {/* {selectState === 'coupon' && <CuponsAndOffers setdrawerOpen={setdrawerOpen} drawerOpen={drawerOpen} id={id} />} */}
              }
            </div>
          </>
        </div>
      )}
    </>
  );
};
