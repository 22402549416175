import React, { useState, useEffect } from "react";

import {
  Breadcrumbs,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  Divider,
  Button,
  IconButton,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  useMediaQuery,
  useTheme,
  TextField,
  SwipeableDrawer,
  List,
  ListItem,
  colors,
  CircularProgress,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  RadioButtonUnchecked,
} from "@material-ui/icons";
// import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/AddCircle";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { getAllCountry, getCountry, updateCountry } from "../../redux";
import { useCurrency } from "../../hooks/useCurrency";

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// const filter = createFilterOptions();

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "25ch",
    color: "var(--orange)",
  },

  imageBackGround: {
    height: "100px",
    width: "100px",
    margin: "auto",
    border: "1px soild black",
  },
}));

// const IOSSwitch = withStyles((theme) => ({
//   root: {
//     width: 42,
//     height: 26,
//     padding: 0,
//     margin: theme.spacing(1),
//   },
//   switchBase: {
//     padding: 1,
//     "&$checked": {
//       transform: "translateX(16px)",
//       color: theme.palette.common.white,
//       "& + $track": {
//         backgroundColor: "#52d869",
//         opacity: 1,
//         border: "none",
//       },
//     },
//     "&$focusVisible $thumb": {
//       color: "#52d869",
//       border: "6px solid #fff",
//     },
//   },
//   thumb: {
//     width: 24,
//     height: 24,
//   },
//   track: {
//     borderRadius: 26 / 2,
//     border: `1px solid ${theme.palette.grey[400]}`,
//     backgroundColor: theme.palette.grey[50],
//     opacity: 1,
//     transition: theme.transitions.create(["background-color", "border"]),
//   },
//   checked: {},
//   focusVisible: {},
// }))(({ classes, ...props }) => {
//   return (
//     <Switch
//       focusVisibleClassName={classes.focusVisible}
//       disableRipple
//       classes={{
//         root: classes.root,
//         switchBase: classes.switchBase,
//         thumb: classes.thumb,
//         track: classes.track,
//         checked: classes.checked,
//       }}
//       {...props}
//     />
//   );
// });

export const Multicountry = ({ match }) => {
  const currency = useCurrency();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, 
    // setselectState
  ] = useState("Multicountry");
  // const [status, setStatus] = useState(true);
  // const [stage, setStage] = useState("All");
  const [formValue, setformValue] = React.useState({});
  const id = match.params.id;
  const [autoCompleteOptions, setautoCompleteOptions] = useState([]);
  const [autoCompleteValue, setautoCompleteValue] = useState({});

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const handleChange = (event) => {
  //   setStage(event.target.value);
  // };

  const handleClose = () => {
    setOpen(false);
    setautoCompleteValue(
      autoCompleteOptions.find(
        (res) =>
          res.code === state?.countryCurrency?.data?.[0].currency_country_code
      )
    );
  };

  useEffect(() => {
    dispatch(getAllCountry());
    dispatch(getCountry(`get_currency`));
  }, [dispatch]);

  useEffect(() => {
    setautoCompleteOptions(state?.countryCurrency?.allCountry);
  }, [state?.countryCurrency?.allCountry]);

  useEffect(() => {
    setformValue(state?.countryCurrency?.data?.[0]);
    setautoCompleteValue(
      autoCompleteOptions.find(
        (res) =>
          res.code === state?.countryCurrency?.data?.[0].currency_country_code
      )
    );
  }, [state?.countryCurrency?.data]);

  const onChange = (value) => {
    setformValue({
      ...formValue,
      currency_country_capital: value.capital,
      currency_country_code: value.code,
      currency_country_name: value.name,
      currency_country_phone: value.phone,
      currency_code: value.currency,
      currency_symbol: value.currency_symbol,
    });
  };

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Settings
              </Typography>
            </div>
            <List>
              {/* <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "configaration"
                        ? "var(--orange)"
                        : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/settings`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Configaration</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "icon_logo" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/user-settings/icon-logo`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Icon Logo Caption</Typography>
                </div>
              </ListItem> */}

              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "brandings" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/brands`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Brandings</Typography>
            </div>
          </ListItem> */}
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "Multicountry"
                        ? "var(--orange)"
                        : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    history.push(`/${id}/user-settings/multi-country`)
                  }
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Country Currency</Typography>
                </div>
              </ListItem>
              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "language setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/language-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Language Setting</Typography>
            </div>
          </ListItem> */}
              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "home text" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("home text")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Home Text</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "theme setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/them-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Theme Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Onesignal Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/one-signal`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Onesignal Setting</Typography>
            </div>
          </ListItem> */}
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "push notification"
                        ? "var(--orange)"
                        : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    history.push(`/${id}/user-settings/push-notifications`)
                  }
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Send Push Notification</Typography>
                </div>
              </ListItem>
              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "loyalty points" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("loyalty points")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Loyalty Points</Typography>
            </div>
          </ListItem> */}
            </List>
          </SwipeableDrawer>
          <div
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
              </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Country And Currency
              </Typography>
            </Breadcrumbs>

            {state?.countryCurrency?.loader ? (
              <div className="loaderBg">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Paper style={{ padding: "2rem", margin: "15px auto" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    paddingBottom: "10px",
                  }}
                >
                  <Typography variant="h6">Country And Currency</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    padding: "20px 0",
                  }}
                >
                  <Button
                    variant="contained"
                    className="button"
                    startIcon={<AddIcon />}
                    onClick={() => setOpen(true)}
                  >
                    Add Another Country
                  </Button>
                  {/* <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={stage}
                  onChange={handleChange}
                  className={classes.selectEmpty}
                  size="small"
                >
                  <MenuItem value="All">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={'Active'}>Active</MenuItem>
                  <MenuItem value={'InActive'}>InActive</MenuItem>
                </Select>
              </FormControl>
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    // timeout to avoid instant validation of the dialog's form.

                  } else if (newValue && newValue.inputValue) {

                  } else {
                    setValue(newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      title: `Add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                options={autoCompleteOptions}
                getOptionLabel={(option) => {
                  // e.g value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.title;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => option.title}
                style={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label="Search" variant="outlined" />
                )}
              />
            </div> */}
                </div>
                <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="responsive-dialog-title">
                    {" "}
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      separator={<ChevronRight style={{ fontSize: 15 }} />}
                    >
                      <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                        Home
                      </Link>
                      <Typography
                        color="secondary"
                        style={{ fontSize: 15 }}
                        onClick={() => setOpen(false)}
                      >
                        Country And Currency
                      </Typography>
                      <Typography
                        color="textPrimary"
                        style={{ fontSize: 15, color: "var(--orange)" }}
                      >
                        Add Country
                      </Typography>
                    </Breadcrumbs>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <form className={classes.root}>
                        <Autocomplete
                          size="small"
                          value={autoCompleteValue}
                          id="checkboxes-tags-demo"
                          options={autoCompleteOptions}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, newValue) => {
                            setautoCompleteValue(newValue);
                            newValue && onChange(newValue);
                          }}
                          // renderOption={(option, { selected }) => (
                          //   <React.Fragment>
                          //     <Checkbox onChange={() => setautoCompleteOptions(option)}
                          //       icon={icon}
                          //       checkedIcon={checkedIcon}
                          //       style={{ marginRight: 8 }}
                          //       checked={formValue.currency_country_code === option.code} value={option}
                          //     />
                          //     {option.name}
                          //   </React.Fragment>
                          // )}
                          style={{ width: 500 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Choose Countries"
                            />
                          )}
                        />
                      </form>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setOpen(false);
                        dispatch(updateCountry(formValue));
                      }}
                      color="secondary"
                      variant="contained"
                    >
                      Submit
                    </Button>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Divider />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl.No</TableCell>
                      <TableCell>Country Name</TableCell>
                      <TableCell>COUNTRY CODE</TableCell>
                      <TableCell>CAPITAL</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>CURRENCY CODE</TableCell>
                      <TableCell>CURRENCY SYMBOL</TableCell>
                      {/* <TableCell>Edit</TableCell> */}
                      {/* <TableCell>Action</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state?.countryCurrency?.data?.map((res) => (
                      <TableRow>
                        <TableCell>1</TableCell>
                        <TableCell>{res.currency_country_name}</TableCell>
                        <TableCell>{res.currency_country_code}</TableCell>
                        <TableCell>{res.currency_country_capital}</TableCell>
                        <TableCell>{res.currency_country_phone}</TableCell>
                        <TableCell>{res.currency_code}</TableCell>
                        <TableCell>{currency}</TableCell>
                        {/* <TableCell>
                  
                </TableCell> */}
                        {/* <TableCell style={{ display: "flex", justifyContent: "space-between" }}>
                  <IconButton aria-label="delete" color="secondary">
                    <DeleteIcon />
                  </IconButton>
                </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            )}
          </div>
        </div>
      )}
    </>
  );
};
