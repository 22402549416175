import { ADD_HOMEPAGE_POPUP_IMAGE, ADD_HOMEPAGE_POPUP_IMAGE_FALIUR, ADD_HOMEPAGE_POPUP_IMAGE_SUCCESS, FETCH_HOMEPAGE_POPUP_BANNER, UPDATE_HOMRPAGE_POPUP_BANNER } from './HomePagePopup.type'

const initialState = {
    data: {},
    error: '',
    status: '',
    message: '',
    imageUrl: ''
}

export const homePagePopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HOMEPAGE_POPUP_BANNER:
            return {
                ...state,
                data: action.payload?.response,
                status: '',
                message: '',
            }
        case ADD_HOMEPAGE_POPUP_IMAGE:
            return {
                ...state
            }
        case ADD_HOMEPAGE_POPUP_IMAGE_SUCCESS:
            return {
                ...state,
                imageUrl: action.payload?.image_url
            }
        case ADD_HOMEPAGE_POPUP_IMAGE_FALIUR:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_HOMRPAGE_POPUP_BANNER:
            return {
                ...state,
                updateHomepage: action.payload,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }
        default:
            return state
    }
}