import React, { useState, useEffect } from "react";

import {
  Breadcrumbs,
  Link,
  Typography,
  makeStyles,
  withStyles,
  Divider,
  Switch,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  TextField,
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  FormGroup,
  CircularProgress,
  Snackbar,
  InputAdornment,
  SwipeableDrawer,
  List,
  ListItem,
  colors,
} from "@material-ui/core";
import {
  ChevronRight,
  ChevronLeft,
  Check,
  CloudUpload,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  getIconLogoDataAll,
  postIconLogo,
  addIconImage,
  addLogoImage,
  updateIconLogo,
  deleteIconLogo,
} from "../../redux";

const filter = createFilterOptions();

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "25ch",
    color: "var(--orange)",
  },
  imageBackGround: {
    width: "200px",
    height: "200px",
    margin: "auto",
    border: 1,
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const IconLogo = ({ match }) => {
  const history = useHistory();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, 
    // setselectState
  ] = useState("icon_logo");
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const id = match.params.id;
  // const [availability, setAvailability] = useState("open");
  const [formValue, setformValue] = useState({
    icon: "",
    logo: "",
    caption: "",
    color: "#000000",
    font_size: "",
  });
  const [stage, setStage] = useState("All");
  const [value, setValue] = React.useState("");
  const [
    autoCompleteOptions,
    // setautoCompleteOptions
  ] = useState([
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
    { title: "Schindler's List", year: 1993 },
  ]);

  useEffect(() => {
    dispatch(getIconLogoDataAll());
  }, [dispatch]);

  useEffect(() => {
    setformValue({ ...formValue, icon: state?.iconlogo?.icon_image });
  }, [state?.iconlogo?.icon_image]);

  useEffect(() => {
    setformValue({ ...formValue, logo: state?.iconlogo?.logo_image });
  }, [state?.iconlogo?.logo_image]);

  useEffect(() => {
    state?.iconlogo?.status === "success" &&
      setformValue({
        icon: "",
        logo: "",
        caption: "",
        color: "#000000",
        font_size: "",
      });
  }, [state?.iconlogo?.status]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event) => {
    setStage(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setformValue({
      icon: "",
      logo: "",
      caption: "",
      color: "#000000",
      font_size: "",
    });
  };

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Settings
              </Typography>
            </div>
            <List>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "configaration"
                        ? "var(--orange)"
                        : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/settings`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Configaration</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "icon_logo" ? "var(--orange)" : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push(`/${id}/user-settings/icon-logo`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Icon Logo Caption</Typography>
                </div>
              </ListItem>

              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "brandings" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/brands`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Brandings</Typography>
            </div>
          </ListItem> */}
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "Multicountry"
                        ? "var(--orange)"
                        : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    history.push(`/${id}/user-settings/multi-country`)
                  }
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Country Currency</Typography>
                </div>
              </ListItem>
              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "language setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/language-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Language Setting</Typography>
            </div>
          </ListItem> */}
              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "home text" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("home text")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Home Text</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "theme setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/them-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Theme Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Onesignal Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/one-signal`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Onesignal Setting</Typography>
            </div>
          </ListItem> */}
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "push notification"
                        ? "var(--orange)"
                        : "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    history.push(`/${id}/user-settings/push-notifications`)
                  }
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Send Push Notification</Typography>
                </div>
              </ListItem>
              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "loyalty points" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("loyalty points")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Loyalty Points</Typography>
            </div>
          </ListItem> */}
            </List>
          </SwipeableDrawer>
          <div
            style={{ paddingLeft: 20, paddingRight: 20 }}
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
              </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Icon Logo Caption
              </Typography>
            </Breadcrumbs>

            {state.iconlogo.loading ? (
              <div className="loaderBg">
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <Paper style={{ padding: "2rem", margin: "15px auto" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    paddingBottom: "10px",
                  }}
                >
                  <Typography variant="h6">Icon Logo Caption</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    padding: "20px 0",
                  }}
                >
                  <Button
                    variant="contained"
                    className="button"
                    startIcon={<AddIcon />}
                    onClick={() => setOpen(true)}
                  >
                    Add Icon Logo
                  </Button>
                  <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitle id="responsive-dialog-title">
                      {" "}
                      <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<ChevronRight style={{ fontSize: 15 }} />}
                      >
                        <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                          Home
                        </Link>
                        <Typography
                          color="secondary"
                          style={{ fontSize: 15 }}
                          onClick={() => setOpen(false)}
                        >
                          Icon Logo Caption
                        </Typography>
                        <Typography
                          color="textPrimary"
                          style={{ fontSize: 15, color: "var(--orange)" }}
                        >
                          Add Icon Logo
                        </Typography>
                      </Breadcrumbs>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <form
                          onSubmit={(e) => {
                            setOpen(false);
                            formValue._id
                              ? dispatch(updateIconLogo(formValue))
                              : dispatch(postIconLogo(formValue));
                            console.log(formValue);
                            e.preventDefault();
                          }}
                        >
                          {/* <Autocomplete
                                        value={formValue.fontEndFont}
                                        style={{ width: "100%" }}
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === 'string') {
                                                // timeout to avoid instant validation of the dialog's form.
                                                setValue(newValue);
                                            } else if (newValue && newValue.inputValue) {
                                                setValue('');
                                            } else {
                                                setValue(newValue);
                                            }
                                        }}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);

                                            if (params.inputValue !== '') {
                                                filtered.push({
                                                    inputValue: params.inputValue,
                                                    title: `No Results Found`,
                                                });
                                            }

                                            return filtered;
                                        }}
                                        options={autoCompleteOptions}
                                        getOptionLabel={(option) => {
                                            // e.g value selected with enter, right from the input
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.title;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        renderOption={(option) => option.title}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField {...params} label="Country" variant="outlined" size="small"
                                                style={{ width: '100%', marginBottom: '1.5rem' }} />
                                        )}
                                    /> */}
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={5}>
                              <Box
                                style={{
                                  width: "100%",
                                  marginBottom: "1.5rem",
                                }}
                              >
                                <Box
                                  border={1}
                                  borderColor="secondary"
                                  className={classes.center}
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                    margin: "auto",
                                  }}
                                >
                                  {formValue.icon ? (
                                    <img
                                      src={formValue.icon}
                                      width="100%"
                                      height="100%"
                                      alt="icon"
                                    ></img>
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      Size (400 px * 400 px)
                                    </Typography>
                                  )}
                                </Box>
                                <Box className={classes.center}>
                                  <input
                                    name="icon_image"
                                    accept="image/*"
                                    id="contained-button-file"
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={(e) => {
                                      // if (formValue.icon) {
                                      //   let public_id = ingridentFromData.ingredient_image.split("/")[8].split(".")[0];
                                      //   fetchApi(deleteIngridientImage({ "image_id": `ingredient_image/${public_id}` }))
                                      // }
                                      const file = e.target.files[0];
                                      const formData = new FormData();
                                      formData.append("icon_image", file);
                                      dispatch(addIconImage(formData));
                                    }}
                                  />
                                  <label htmlFor="contained-button-file">
                                    <Button
                                      variant="contained"
                                      color="default"
                                      className="button"
                                      startIcon={<CloudUpload />}
                                      component="span"
                                    >
                                      Upload Image
                                    </Button>
                                  </label>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <Box
                                style={{
                                  width: "100%",
                                  marginBottom: "1.5rem",
                                }}
                              >
                                <Box
                                  border={1}
                                  borderColor="secondary"
                                  className={classes.center}
                                  style={{
                                    width: "250px",
                                    height: "150px",
                                    margin: "auto",
                                  }}
                                >
                                  {formValue.logo ? (
                                    <img
                                      src={formValue.logo}
                                      width="100%"
                                      height="100%"
                                      alt="icon"
                                    ></img>
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      color="textSecondary"
                                    >
                                      Size (400 px * 400 px)
                                    </Typography>
                                  )}
                                </Box>
                                <Box className={classes.center}>
                                  <input
                                    name="logo_image"
                                    accept="image/*"
                                    id="contained-button-file1"
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={(e) => {
                                      // if (ingridentFromData.ingredient_image) {
                                      //   let public_id = ingridentFromData.ingredient_image.split("/")[8].split(".")[0];
                                      //   fetchApi(deleteIngridientImage({ "image_id": `ingredient_image/${public_id}` }))
                                      // }
                                      const file = e.target.files[0];
                                      const formData = new FormData();
                                      formData.append("logo_image", file);
                                      dispatch(addLogoImage(formData));
                                    }}
                                  />
                                  <label htmlFor="contained-button-file1">
                                    <Button
                                      variant="contained"
                                      color="default"
                                      className="button"
                                      startIcon={<CloudUpload />}
                                      component="span"
                                    >
                                      Upload Image
                                    </Button>
                                  </label>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={8}>
                              <TextField
                                label="Write Caption"
                                variant="outlined"
                                size="small"
                                value={formValue.caption}
                                name="caption"
                                onChange={(e) => {
                                  setformValue({
                                    ...formValue,
                                    caption: e.target.value,
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  marginBottom: "1.5rem",
                                }}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                type="Number"
                                required={formValue.caption}
                                label="Text Size"
                                variant="outlined"
                                size="small"
                                value={formValue.font_size}
                                name="font_size"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      px
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  setformValue({
                                    ...formValue,
                                    font_size: e.target.value,
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  marginBottom: "1.5rem",
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <input
                                type="color"
                                required={formValue.caption}
                                name="color"
                                style={{
                                  height: "43px",
                                  border: "none",
                                  backgroundColor: "transparent",
                                }}
                                value={formValue.color}
                                onChange={(e) => {
                                  setformValue({
                                    ...formValue,
                                    color: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            width={200}
                            margin="auto"
                          >
                            <Button
                              startIcon={<ChevronLeft />}
                              className="button"
                              onClick={handleClose}
                            >
                              back
                            </Button>
                            <Button
                              type="submit"
                              className="button"
                              startIcon={<Check />}
                            >
                              {formValue._id ? "Update" : "Save"}
                            </Button>
                          </Box>
                        </form>
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={stage}
                        onChange={handleChange}
                        className={classes.selectEmpty}
                        size="small"
                      >
                        <MenuItem value="All">
                          <em>All</em>
                        </MenuItem>
                        <MenuItem value={"Active"}>Active</MenuItem>
                        <MenuItem value={"InActive"}>InActive</MenuItem>
                      </Select>
                    </FormControl>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                        } else if (newValue && newValue.inputValue) {
                        } else {
                          setValue(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            title: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      options={autoCompleteOptions}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option.title;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      renderOption={(option) => option.title}
                      style={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search"
                          variant="outlined"
                        />
                      )}
                    />
                    {/* <Button
                            variant="contained"
                            style={{
                                padding: '15px 0',
                                background: "var(--orange)",
                                color: "#ffffff",
                                height: '100%'
                            }}
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                        >
                        </Button> */}
                  </div>
                </div>
                <Divider />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl.No</TableCell>
                      <TableCell>Icon</TableCell>
                      <TableCell>Logo</TableCell>
                      <TableCell>Caption</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell colSpan={2}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {state?.iconlogo &&
                    state.iconlogo.data.map((res, i) => (
                      <TableBody>
                        <TableRow>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>
                            <img
                              src={res.icon}
                              width="100px"
                              height="100px"
                              alt="icon"
                            ></img>
                          </TableCell>
                          <TableCell>
                            <img
                              src={res.logo}
                              width="100px"
                              height="100px"
                              alt="icon"
                            ></img>
                          </TableCell>
                          <TableCell
                            style={{
                              color: res.color,
                              fontSize: res.font_size + "px",
                            }}
                          >
                            {res.caption}
                          </TableCell>
                          <TableCell>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    checked={res.status}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        dispatch(
                                          updateIconLogo({
                                            _id: res._id,
                                            status: e.target.checked,
                                          })
                                        );
                                        state.iconlogo.data.map((item) => {
                                          if (item._id !== res._id) {
                                            dispatch(
                                              updateIconLogo({
                                                _id: item._id,
                                                status: !e.target.checked,
                                              })
                                            );
                                          }
                                        });
                                      }
                                    }}
                                  />
                                }
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            style={{ height: "100%" }}
                          >
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setformValue(res);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            style={{ height: "100%" }}
                          >
                            {!res.status && (
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() =>
                                  dispatch(deleteIconLogo({ _id: res._id }))
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                </Table>
              </Paper>
            )}

            <Snackbar
              open={state.iconlogo.status}
              onClose={!state.iconlogo.status}
            >
              <Alert
                onClose={!state.iconlogo.status}
                severity={state.iconlogo.status}
              >
                {state.iconlogo.message}
              </Alert>
            </Snackbar>
          </div>
        </div>
      )}
    </>
  );
};
