import {HANDLE_LOGIN_FALIOUR, HANDLE_LOGIN_SUCCESS, HANDLE_LOGIN_REQUEST, HANDLE_LOGIN_FORM_DATA,
     HANDLE_CHANGE_PASSWORD_FALIOUR, HANDLE_CHANGE_PASSWORD_REQUEST, HANDLE_CHANGE_PASSWORD_SUCCESS} from './loginTypes'
import { deleteData, postData, authentication } from "../../Api/api";

export const fetchloginRequest = () => {
    return {
        type: HANDLE_LOGIN_REQUEST
    }
}

export const fetchloginFaliour = (data) => {
    return {
        type: HANDLE_LOGIN_FALIOUR,
        payload: data
    }
}

export const handleloginData = (data) => {
    return {
        type: HANDLE_LOGIN_FORM_DATA,
        payload: data
    }
}

export const authData = (data) => {
    return {
        type: HANDLE_LOGIN_SUCCESS,
        payload: data
    }
}

export const handlechangepassword = () => {
    return {
        type: HANDLE_CHANGE_PASSWORD_REQUEST,
    }
}

export const handlechangepasswordsuccess = (data) => {
    return {
        type: HANDLE_CHANGE_PASSWORD_SUCCESS,
        payload: data
    }
}

export const handlechangepasswordfaliour = (data) => {
    return {
        type: HANDLE_CHANGE_PASSWORD_FALIOUR,
        payload: data
    }
}


export const postlogin = (body) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        postData(`administrator_login`, body).then(
            response => {
                dispatch(handleloginData(response.data))
                // response.data.status === 'success' && fetchloginSucess()
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const auth = (body) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        authentication(body).then(
            response => {
                dispatch(authData(response.data))
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const deletelogin = (body) => {
    return (dispatch) => {
        dispatch(fetchloginRequest())
        deleteData(`delete_login`, {_id: body._id}).then(
            response => {
                dispatch(handleloginData(response.data))
                // response.data.status === 'success' && dispatch(getlogin(`get_all_login`))
            }
        ).catch(
            error => dispatch(fetchloginFaliour(error.message))
        )
    }
}

export const changepassword = (body) => {
    return (dispatch) => {
        dispatch(handlechangepassword())
        postData(`update_administrator_password`, body).then(
            response => {
                dispatch(handlechangepasswordsuccess(response.data))
            }
        ).catch(
            error => dispatch(handlechangepasswordfaliour(error.message))
        )
    }
}