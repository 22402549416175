import {FETCH_OPERATIONAL_HOURS_EDITDATA,FETCH_OPERATIONAL_HOURS_FALIOUR,
    FETCH_OPERATIONAL_HOURS_SUCCESS,FETCH_OPERATIONAL_HOURS_REQUEST} from "./operationalhoursType";
import {fetchData, updateData } from "../../Api/api";

export const fetchoperationalhours = () => {
    return {
        type: FETCH_OPERATIONAL_HOURS_REQUEST
    }
}

export const fetchoperationalhoursSuceess = (data) => {
    return {
        type: FETCH_OPERATIONAL_HOURS_SUCCESS,
        payload: data
    }
}

export const fetchoperationalhoursFaliour = (data) => {
    return {
        type: FETCH_OPERATIONAL_HOURS_FALIOUR,
        payload :data
    }
}

export const fetchoperationalhoursData = (data) => {
    return {
        type: FETCH_OPERATIONAL_HOURS_EDITDATA,
        payload: data
    }
}

export const getoperationalhours = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchoperationalhours())
        fetchData(`get_operational_hours`).then(
            response => dispatch(fetchoperationalhoursSuceess(response.data.response))
        ).catch(
            error => fetchoperationalhoursFaliour(error.message)
        )
    }
}


export const updateoperationalhours = (body) => {
    return (dispatch) => {
        updateData(`update_operational_hours`,body).then(
            response => {dispatch(fetchoperationalhoursData(response.data))
            response.data.status=== 'success' && dispatch(getoperationalhours(response.data.response.branch_id))
        }
        ).catch(
            error => fetchoperationalhoursFaliour(error.message)
        )
    }
}