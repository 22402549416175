import React, { useState } from "react";

import {
    Breadcrumbs,
    Link,
    Paper,
    Typography,
    makeStyles,
    withStyles,
    Switch,
    Button,
    TextField,
    Grid,
    Box,
    ButtonGroup,
    InputAdornment,
    ListItem,
    List,
    SwipeableDrawer,
    IconButton,
    colors
} from "@material-ui/core";
import { ChevronRight, Check, RadioButtonUnchecked, ChevronLeft } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom'
import clsx from "clsx";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: "#52d869",
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: "#52d869",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export const ThemeSettings = ({ match }) => {
    const history = useHistory()
    const classes = useStyles();
    const [drawerOpen, setdrawerOpen] = useState(true);
    const [selectState, 
        // setselectState
    ] = useState("theme setting");
    const id = match.params.id
    const [formValue, setformValue] = useState({
        selectedTimezone: '', fontEndFont: '', hourFormat: '', distance_unit: '',
        AppName: '', AppEmail: [{ email: '' }], AppNumber: [{ app_number: '' }], AppAddress: [{ address: '' }], sku: false,
    })

    return (
        <div style={{
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
        }}
          className={classes.root}>
            <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Settings
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "configaration" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/settings`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Configaration</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "icon_logo" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/icon-logo`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Icon Logo Caption</Typography>
            </div>
          </ListItem>

          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "brandings" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/brands`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Brandings</Typography>
            </div>
          </ListItem> */}
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Multicountry" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/multi-country`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Country Currency</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "language setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/language-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Language Setting</Typography>
            </div>
          </ListItem> */}
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "home text" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("home text")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Home Text</Typography>
            </div>
          </ListItem> */}
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "theme setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/them-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Theme Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Onesignal Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/one-signal`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Onesignal Setting</Typography>
            </div>
          </ListItem> */}
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "push notification" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/push-notifications`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Send Push Notification</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "loyalty points" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("loyalty points")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Loyalty Points</Typography>
            </div>
          </ListItem> */}
        </List>
      </SwipeableDrawer>
      <div style={{ paddingLeft: 20, paddingRight: 20 }} className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}>
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
                <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                    Home
        </Link>
                <Typography
                    color="textPrimary"
                    style={{ fontSize: 15, color: "var(--orange)" }}
                >
                    Theme Settings
        </Typography>
            </Breadcrumbs>

            <form>
                <Paper style={{ padding: "2rem", margin: "15px auto", width: "100%" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            paddingBottom: "10px"
                        }}
                    >
                        <Typography variant="h6">Header Colour Options</Typography>
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs>

                            <Box style={{ padding: "10px" }}>

                                <TextField id="outlined-basic" label="Header Background Color" variant="outlined" size="small"
                                    style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                                    value={formValue.color}
                                                    onChange={(e) => {
                                                        formValue.color = e.target.value
                                                        setformValue({ ...formValue, ...formValue.color })
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }} />

                                <TextField id="outlined-basic" label="Header Text Color" variant="outlined" size="small"
                                    style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                                    value={formValue.color}
                                                    onChange={(e) => {
                                                        formValue.color = e.target.value
                                                        setformValue({ ...formValue, ...formValue.color })
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }} />

                                <TextField id="outlined-basic" label="Frontend app name color Text Color" variant="outlined" size="small"
                                    style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                                    value={formValue.color}
                                                    onChange={(e) => {
                                                        formValue.color = e.target.value
                                                        setformValue({ ...formValue, ...formValue.color })
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }} />

                                <TextField id="outlined-basic" label="App Primary Color" variant="outlined" size="small"
                                    style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                                    value={formValue.color}
                                                    onChange={(e) => {
                                                        formValue.color = e.target.value
                                                        setformValue({ ...formValue, ...formValue.color })
                                                    }} />
                                            </InputAdornment>
                                        ),
                                    }} />

                            </Box>
                        </Grid>
                        <Grid item xs>

                        </Grid>

                    </Grid>
                </Paper>

                <Paper style={{ padding: "2rem", margin: "15px auto", width: "50%" }}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            paddingBottom: "10px"
                        }}
                    >
                        <Typography variant="h6">Header Colour Options</Typography>
                    </div>
                    <Box style={{ padding: "10px" }}>

                        <TextField id="outlined-basic" label="App Secondary Color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Button Color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Button Hover Color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Font Color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Footer Apps Color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Footer Text Color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Footer Title Color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Footer Content Background Color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />
                        <TextField id="outlined-basic" label="Dark Color Change for text" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Link text Color Change" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Contact us background color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Contact us text color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Home page slider background change" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Home page banner text color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Mobile View menu background change" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Mobile View menu text change" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Newsletter box background color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Newsletter box text color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Newsletter textbox background color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Newsletter textbox text color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Newsletter button background color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Frontend floating checkout button background color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Frontend floating checkout button text color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Frontend how it work background color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />
                        <TextField id="outlined-basic" label="Frontend how it work icon background color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <TextField id="outlined-basic" label="Frontend how it work icon text color" variant="outlined" size="small"
                            style={{ width: '100%', marginBottom: '1.5rem' }} InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <input type="color" style={{ height: "30px", border: "none", backgroundColor: "transparent" }}
                                            value={formValue.color}
                                            onChange={(e) => {
                                                formValue.color = e.target.value
                                                setformValue({ ...formValue, ...formValue.color })
                                            }} />
                                    </InputAdornment>
                                ),
                            }} />

                        <Typography component="div" style={{ width: '100%', marginBottom: '1.5rem' }}>
                            Show Header Bottom Image
                                <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>Off</Grid>
                                <Grid item>
                                    <IOSSwitch value={formValue.sku} onChange={(e) => {
                                        formValue.sku = e.target.value
                                        setformValue({ ...formValue, ...formValue.sku })
                                    }} name="sku" />
                                </Grid>
                                <Grid item>On</Grid>
                            </Grid>
                        </Typography>

                        <Typography component="div" style={{ width: '100%', marginBottom: '1.5rem' }}>
                            View Type
                            <ButtonGroup color="primary" aria-label="outlined primary button group" style={{ width: '100%'}}>
                                <Button>Grid</Button>
                                <Button>List</Button>
                            </ButtonGroup>
                        </Typography>

                        <Box display="flex" alignItems="center" justifyContent="space-between" width={200} margin="auto">
                            <Button startIcon={<CloseIcon />} className="button">
                                Cancel
                            </Button>
                            <Button className="button" startIcon={<Check />}>
                                save
                                    </Button>
                        </Box>

                    </Box>

                </Paper>


            </form>
        </div>

        </div>
    );
};

