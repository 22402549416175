import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { Cupons } from "../Cupons/Cupons";

import {
    List,
    ListItem,
    Typography,
} from "@material-ui/core";
import { RadioButtonUnchecked } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

export const SwipeDrawer = () => {
    const history = useHistory();
    const state = useSelector((state) => state);
    const [selectState, setselectState] = useState("restaurant-basic");
    const id = state?.branch?.activeBranch?._id;

    useEffect(() => {
        setselectState(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
    }, [])

    return (
        <>
            {!state?.branch?.loader && (
                <List>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "coupon" ? "var(--orange)" : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/user-settings/coupon`)
                            }
                            >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Coupon</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "push-notifications"
                                        ? "var(--orange)"
                                        : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/user-settings/push-notifications`)
                            }
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography>Push Notification</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color:
                                    selectState === "configure-email"
                                        ? "var(--orange)"
                                        : "#ffffff",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                history.push(`/${id}/user-settings/configure-email`)
                            }
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                                <Typography>Email Notification</Typography>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: selectState === "configure-sms" ? "var(--orange)" : "#ffffff",
                                cursor: "pointer"
                            }}
                            onClick={() => history.push(`/${id}/user-settings/configure-sms`)}
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                                <Typography>SMS Configaration</Typography>
                        </div>
                    </ListItem>
                    {/* <ListItem>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: selectState === "preferences" ? "var(--orange)" : "#ffffff",
                                cursor: "pointer"
                            }}
                            onClick={() => history.push(`/${id}/restaurant-basic/preferences`)}
                        >
                            <RadioButtonUnchecked
                                style={{ fontSize: "1rem", marginRight: 20 }}
                            />
                            <Typography> Popup Notification </Typography>
                        </div>
                    </ListItem> */}
                </List>
            )}
        </>
    );
};
