import {
    Box, Grid, Divider, Typography, makeStyles, colors, withStyles, Table, TableRow,
    TableContainer, TableCell, TableHead, TableBody, Button
} from '@material-ui/core'
import { Print } from "@material-ui/icons";
import React, { useRef } from 'react'
import ReactToPrint from "react-to-print";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { useCurrency } from "../../../hooks/useCurrency";

const useStyles = makeStyles((theme) => ({

    button: {
        marginRight: theme.spacing(1)
    },
    order_details: {
        background: "#e8fff8",
        padding: "10px"
    },
    containerPadding: {
        margin: "10px 30px",
    },
    pdfPadding: {
        padding: "30px"
    },
    display: {
        display: 'flex',
        justifyContent: "space-between",
    },
    relative: {
        position: "relative"
    }
}))

const ColorButton = withStyles((theme) => ({
    root: {
        color: "#ffffff",
        backgroundColor: "#000000",
        '&:hover': {
            backgroundColor: "#000000",
        },
        fontStyle: "normal",
        margin: "10px",
        position: "absolute",
        right: "0",
    },
}))(Button);

const StyledTableCell = withStyles((theme) => ({
    head: {
        border: `1px solid ${colors.grey[300]}`
    },
    body: {
        fontSize: 14,
        border: `1px solid ${colors.grey[300]}`,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);



export const OrderDetails = ({ order }) => {
    const classes = useStyles()
    const componentRef = useRef(null);
    const currency = useCurrency()
    return (
        <>
            <div className={classes.relative}>
                <ReactToPrint
                    trigger={() => <ColorButton variant="contained" color="primary" >
                        <Print />print</ColorButton>}
                    content={() => componentRef.current}
                />
                {/* <div style={{ display: "none", width: "100%" }}>
                    <OrderaPdf ref={componentRef} val={23} />
                </div> */}
            </div>

            <div ref={componentRef} className={classes.pdfPadding}>
                <Typography variant="h5" gutterBottom>
                    Customer Details
                </Typography>
                <Divider />
                <Grid container>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            Full Name :
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="subtitle2" gutterBottom>
                            {order.order_delivery_full_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            Mobile :
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="subtitle2" gutterBottom>
                            {order.order_delivery_phone_number}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" gutterBottom>
                            Address :
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="subtitle2" gutterBottom>
                            {order.order_delivery_address_details}
                        </Typography>
                    </Grid>
                </Grid>
                {/* <Typography variant="h5" gutterBottom>
                Branch Details
            </Typography>
            <Divider />
            <Grid container className={classes.containerPadding}>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Name :
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="subtitle2" gutterBottom>
                        {order.branch_id.branch_name}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Mobile :
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="subtitle2" gutterBottom>
                        {order.branch_id.branch_contact_number1}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Contact Email :
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="subtitle2" gutterBottom>
                        {order.branch_id.branch_contact_email}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Address :
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="subtitle2" gutterBottom>
                        {order.branch_id.branch_area}, {order.branch_id.branch_city}, {order.branch_id.branch_state}, {order.branch_id.branch_country}
                    </Typography>
                </Grid>
            </Grid> */}
                <Box className={classes.order_details}>
                    <Typography variant="h5" gutterBottom>
                        Order Details
                    </Typography>
                    <Divider />
                    <Grid container>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Order ID :
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="subtitle2" gutterBottom>
                                {order.order_id}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Order Date :
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="subtitle2" gutterBottom>
                                {moment(order.createdAt).tz("America/New_York").format('L')} {moment(order.createdAt).tz("America/New_York").format('LT')}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Order Status :
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="subtitle2" gutterBottom>
                            {
                                  order.order_status_delivered.status
                                    ? "delivered"
                                    : order.order_status_on_the_way.status
                                      ? "on_the_way"
                                      : order.order_status_accepted.status
                                        ? "accepted"
                                        : order.order_status_rejected.status
                                          ? "rejected"
                                          : order.order_status_pending.status &&
                                          "pending"
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Payment Option :
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="subtitle2" gutterBottom>
                                {order.order_payment_provider}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom>
                                Payment Status :
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="subtitle2" gutterBottom>
                                {order?.order_payment_status}
                            </Typography>
                        </Grid>
                    </Grid>

                </Box>
                <TableContainer>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Sl.No</StyledTableCell>
                                <StyledTableCell>Item</StyledTableCell>
                                <StyledTableCell>Item Name</StyledTableCell>
                                <StyledTableCell>Quantity</StyledTableCell>
                                <StyledTableCell>Price (LKR)</StyledTableCell>
                                <StyledTableCell>Variety - Price (LKR)</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order && order?.order_items?.map((row, i) =>
                                row.item_id ? <StyledTableRow key={row._id}>
                                    <StyledTableCell component="th" scope="row">
                                        {i + 1}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <img src={row.item_id.item_cover_image} width="40px" height="40px" />
                                    </StyledTableCell>
                                    <StyledTableCell>{row.item_id.item_name}</StyledTableCell>
                                    <StyledTableCell>{row.item_quantity}</StyledTableCell>
                                    <StyledTableCell>{currency} {row.item_id.item_price}</StyledTableCell>
                                    <StyledTableCell>{currency} {row.item_id.item_price * row.item_quantity}</StyledTableCell>
                                </StyledTableRow> : 
                                <StyledTableRow>
                                    <StyledTableCell colSpan={5}>This Item Are Not Avaliable Now In This App</StyledTableCell>
                                </StyledTableRow>
                            )}
                            <StyledTableRow>
                                <StyledTableCell colSpan={5}></StyledTableCell>
                                <StyledTableCell>
                                    <div className={classes.display}>
                                        <Typography variant="subtitle2">SubTotal</Typography>
                                        <Typography variant="subtitle1">{currency} {order.order_grand_total}</Typography>
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}
