import { FETCH_BANNER_REQUEST, FETCH_BANNER_SUCCESS, FETCH_BANNER_FALIOUR, GET_ALL_BANNER_APPLIED_ON, 
     FETCH_BANNER_BY_APPLY_TO_ID_SUCCESS, BANNER_IMAGE, BANNER_DATA} from "./bannerTypes";

const initialState = {
    loading: false,
    totalPage: 0,
    currentPage: 0,
    data: [],
    status: '',
    message: '',
    image_link: ''
}

export const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BANNER_REQUEST:
            return {
                ...state, loading: true,
            }
        case GET_ALL_BANNER_APPLIED_ON: 
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        
        case FETCH_BANNER_SUCCESS: 
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case FETCH_BANNER_BY_APPLY_TO_ID_SUCCESS:
            // console.log(state.data.findIndex(cat => cat._id === action.payload.response[0].featured_item_applied_to_id))
            return {
                ...state,
                loading: false,
                totalPage: action.payload.totalPages,
                currentPage: action.payload.currentPage,
                data: action.payload.response,
                status: '',
                message: '',
                image_link: ''
            }
        // case FETCH_FETURE_BY_ID_SUCCESS :
        //     // console.log(state.data.findIndex(cat => cat._id === action.payload.response[0].featured_item_applied_to_id))
        //     return {
        //          ...state, data: action.payload
        //     }
        
        case FETCH_BANNER_FALIOUR:
            return {
                ...state,
                loading: false,
                status: 'error',
                message: action.payload
            }
        case BANNER_DATA:
            return {
                ...state,
                loading: false,
                status: action.payload.status === 'success' ? action.payload.status : 'error',
                message: action.payload.message
            }

        case BANNER_IMAGE: 
            return {
                ...state,
                image_link : action.payload
            }

        default:
            return state
    }
}