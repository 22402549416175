import {
  Breadcrumbs,
  Link,
  Typography,
  colors,
  Paper,
  Divider,
  makeStyles,
  Box,
  Button,
  TextField,
  Snackbar,
  ListItem,
  List,
  SwipeableDrawer,
  IconButton,
} from "@material-ui/core";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  CloudUpload,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import { getMainCatagory, postSubmit, postSubSubmit, editSubCatagory, editMainCatagory, imageUpload, imageDelete } from "../../redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";



//Components
import { connect } from "react-redux";


const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  center: { display: "flex", justifyContent: "center", alignItems: "center", padding: "0.5rem" }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateCategory = ({ state, fetchApi, location, match }) => {
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("category");
  const id = match.params.id
  const [catagoryFromData, setcatagoryFromData] = useState({
    "category_name": "",
    "category_image": "",
    "parent_catagory": "",
    "main_category_id": ""
  })
  const history = useHistory();

  useEffect(() => {
    if (location && location.state) {
      setcatagoryFromData({ ...location.state })
    }
    // return () => {
    //   cleanup
    // }
  }, [location])

  useEffect(() => {
    if (state.catagory.imageLink) {
      setcatagoryFromData({ ...catagoryFromData, "category_image": state.catagory.imageLink })
    } else if (state.catagory.status === 'success') {
      setcatagoryFromData(
        {
          "category_name": "",
          "category_image": "",
          "main_category_id": "",
          "parent_catagory": "",
        }
      )
      location && location.state && setTimeout(() => {
        history.push(`/${state?.branch?.activeBranch?._id}/menu-setup`);
      }, 1000);
    }
  }, [state.catagory, catagoryFromData])


  useEffect(() => {
    fetchApi(getMainCatagory())
  }, [fetchApi])

  return (<>
    {
      !state?.branch?.loader &&
      <div
        style={{
          // paddingTop: "8%",
          width: "100%",
          height: "100%",
          background: colors.grey[200],
          position: "relative",
        }}
        className={classes.root}
      >
        <div
          style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
          className={classes.drawerHeader}
        >
          <IconButton>
            {drawerOpen ? (
              <ChevronLeft onClick={() => setdrawerOpen(false)} />
            ) : (
              <ChevronRight onClick={() => setdrawerOpen(true)} />
            )}
          </IconButton>
        </div>
        <SwipeableDrawer
          className={classes.drawer}
          anchor="left"
          variant="persistent"
          open={drawerOpen}
          onClose={() => setdrawerOpen(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
            <Typography align="center" variant="h6">
              Menu Setup
          </Typography>
          </div>
          <List>
            <ListItem>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: selectState === "category" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
                }}
                onClick={() => history.push(`/${id}/menu-setup`)}
              >
                <RadioButtonUnchecked
                  style={{ fontSize: "1rem", marginRight: 20 }}
                />
                <Typography>Category</Typography>
              </div>
            </ListItem>
            <ListItem>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color:
                    selectState === "itemsetup" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
                }}
                onClick={() => history.push(`/${id}/menu-setup/item-setup`)}
              >
                <RadioButtonUnchecked
                  style={{ fontSize: "1rem", marginRight: 20 }}
                />
                <Typography>Items Setup</Typography>
              </div>
            </ListItem>
            <ListItem>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color:
                    selectState === "ingredient" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
                }}
                onClick={() => history.push(`/${id}/menu-setup/ingredient`)}
              >
                <RadioButtonUnchecked
                  style={{ fontSize: "1rem", marginRight: 20 }}
                />
                <Typography>Ingredient</Typography>
              </div>
            </ListItem>
          </List>
        </SwipeableDrawer>
        <div
          style={{ paddingLeft: 15, paddingRight: 15 }}
          className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRight style={{ fontSize: 15 }} />}
          >
            <Link color="inherit" href="/" style={{ fontSize: 15 }}>
              Home
        </Link>
            <Link color="inherit" href={`/${id}/menu-setup`} style={{ fontSize: 15 }}>
              Category
        </Link>
            <Typography
              color="textPrimary"
              style={{ fontSize: 15, color: "var(--orange)" }}
            >
              Create
        </Typography>
          </Breadcrumbs>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="center"
            flexDirection="column"
          >
            <Paper style={{ padding: "2rem", marginTop: "20px", width: 600 }}>
              <Typography variant="h6">Create</Typography>
              <Divider style={{ margin: "20px 0 20px 0" }} />

              <TextField label="Catagory Name" variant="outlined" size="small"
                style={{ width: '100%', marginBottom: '1.5rem' }} value={catagoryFromData.category_name}
                onChange={e => setcatagoryFromData({ ...catagoryFromData, category_name: e.target.value })} />

              {
                location.state ? catagoryFromData.parent_catagory && <TextField
                  disabled
                  id="outlined-disabled"
                  label="Parent Catagory"
                  size="small"
                  value={catagoryFromData.parent_catagory}
                  variant="outlined" style={{ width: '100%', marginBottom: '1rem' }}
                /> :
                  <Autocomplete
                    id="combo-box-demo"
                    options={state.catagory.data}
                    onChange={(e, newValue) => setcatagoryFromData({ ...catagoryFromData, main_category_id: newValue._id })}
                    getOptionLabel={(option) => option.category_name}
                    renderInput={(params) => <TextField {...params} label="Select Parent Category (If any)" variant="outlined" size="small"
                      style={{ width: '100%', marginBottom: '1rem' }} />}
                  />
              }

              <Typography variant="caption" display="block" gutterBottom style={{ color: 'red', marginBottom: '1rem' }}>
                *Note : If Main Catagory Is Not provided Conseider Catagory As Main Catagory
          </Typography>
              <Box style={{ width: '100%' }} >
                <Box border={1} borderColor="secondary" className={classes.center} style={{ width: "150px", height: "150px", margin: "auto" }}>
                  {
                    catagoryFromData.category_image ? <img
                      src={catagoryFromData.category_image}
                      alt="product"
                      width="100%"
                      height="100%"
                    /> :
                      <Typography variant="body1" color="textSecondary">
                        Size (400 px * 400 px)
              </Typography>
                  }
                </Box>
                <Box className={classes.center}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file" onChange={e => {
                      if (catagoryFromData.category_image) {
                        let public_id = catagoryFromData.category_image.split("/")[8].split(".")[0];
                        fetchApi(imageDelete({ "image_id": `category_image/${public_id}` }))
                      }
                      const file = e.target.files[0];
                      const formData = new FormData();
                      formData.append('category_image', file)
                      console.log(file)
                      fetchApi(imageUpload(formData))
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="default"
                      className="button"
                      startIcon={<CloudUpload />}
                      component="span"
                    >
                      Upload Image
              </Button>
                  </label>
                </Box>
              </Box>
            </Paper>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width={200}
              marginTop="20px"
              paddingBottom="5%"
            >
              <Button startIcon={<ChevronLeft />} className="button" onClick={() => {
                history.push(`/${state?.branch?.activeBranch?._id}/menu-setup`);
              }}>
                back
          </Button>
              {
                location.state ? <Button className="button" startIcon={<Check />} onClick={() => {
                  catagoryFromData.main_category_id ?
                    fetchApi(editSubCatagory(catagoryFromData)) : fetchApi(editMainCatagory(catagoryFromData))
                }}>
                  UpDate
            </Button> :
                  <Button className="button" startIcon={<Check />} onClick={() => catagoryFromData.main_category_id ?
                    fetchApi(postSubSubmit(catagoryFromData)) : fetchApi(postSubmit(catagoryFromData))}>
                    save
            </Button>
              }
            </Box>
          </Box>
          <Snackbar open={state.catagory.status}
            onClose={!state.catagory.status}>
            <Alert onClose={!state.catagory.status} severity={state.catagory.status}>
              {state.catagory.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    }</>);
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (dispatchAction) => dispatch(dispatchAction)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory)
