import React, { useState,useEffect } from "react";

import {
  colors,
  List,
  ListItem,
  SwipeableDrawer,
  makeStyles,
  IconButton,
  Typography,
} from "@material-ui/core";
import { RadioButtonUnchecked } from "@material-ui/icons";
import clsx from "clsx";
import { useHistory } from 'react-router-dom'

//Components
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Configaration } from "./Configaration";
// import { Brands } from "./Brands";
// import { IconLogo } from "./IconLogo";
// import {LanguageSettings} from "./Language"
// import {ThemeSettings} from "./ThemeSettings"
// import { Multicountry } from "./Multicountry";
// import { Onesignal } from "./OneSignal";
import { useSelector } from 'react-redux';
// import { getConfigaration,updateConfigaration } from '../../redux'
// import PushNotification from "./PushNotification";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const Settings = ({ match }) => {
  const history = useHistory()
  const classes = useStyles();
  const state = useSelector(state => state)
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("configaration");
  const id = match.params.id

  useEffect(() => {
    setselectState(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
  }, [])
  //   useEffect(() => {
  //     dispatch(getConfigaration())
  // }, [dispatch])

  return (
    <>
      {
        !state?.branch?.loader &&
        <div
          style={{
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Settings
              </Typography>
            </div>
            <List>
              {/* <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: selectState === "configaration" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => setselectState("configaration")}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Configaration</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: selectState === "icon_logo" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(`/${id}/user-settings/icon-logo`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Icon Logo Caption</Typography>
                </div>
              </ListItem> */}

              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "brandings" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/brands`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Brandings</Typography>
            </div>
          </ListItem> */}
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "Multicountry" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(`/${id}/user-settings/multi-country`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Country Currency</Typography>
                </div>
              </ListItem>
              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "language setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/language-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Language Setting</Typography>
            </div>
          </ListItem> */}
              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "home text" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("home text")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Home Text</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "theme setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/them-setting`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Theme Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Onesignal Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/user-settings/one-signal`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Onesignal Setting</Typography>
            </div>
          </ListItem> */}
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: selectState === "push notification" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(`/${id}/user-settings/push-notifications`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Send Push Notification</Typography>
                </div>
              </ListItem>
              {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "loyalty points" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("loyalty points")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Loyalty Points</Typography>
            </div>
          </ListItem> */}
            </List>
          </SwipeableDrawer>
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: drawerOpen,
            })}
          >
            {/* <Multicountry /> */}
            <Configaration />
            {/* {selectState === "icon_logo" && <IconLogo />}
        {selectState === "brandings" && <Brands />}
        {selectState === "language setting" && <LanguageSettings />}
        {selectState === "theme setting" && <ThemeSettings />}
        {selectState === "Multicountry" && <Multicountry />}
        {selectState === "Onesignal Setting" && <Onesignal />}
        {selectState === "push notification" && <PushNotification />} */}
          </div>
        </div>
      }</>);
};
