import { FETCH_HANDLE_COUNTRY_CURRENCY_REQUEST, FETCH_HANDLE_COUNTRY_CURRENCY_SUCCESS, FETCH_ALL_COUNTRY_CURRENCY_SUCCESS,
    FETCH_HANDLE_COUNTRY_CURRENCY_FALIOUR,  FETCH_HANDLE_COUNTRY_CURRENCY_DATA, FETCH_ALL_COUNTRY_STATE_SUCCESS} from "./countryTypes";
import {postData,fetchData,updateData,deleteData} from '../../Api/api'

export const fetchCountryAccess = () => {
    return {
        type: FETCH_HANDLE_COUNTRY_CURRENCY_REQUEST
    }
}

export const fetchCountrySuccess = (data) => {
    return {
        type: FETCH_HANDLE_COUNTRY_CURRENCY_SUCCESS,
        payload: data
    }
}

export const fetchAllCountrySuccess = (data) => {
    return {
        type: FETCH_ALL_COUNTRY_CURRENCY_SUCCESS,
        payload: data
    }
}

export const fetchAllCountrystateSuccess = (data) => {
    return {
        type: FETCH_ALL_COUNTRY_STATE_SUCCESS,
        payload: data
    }
}

export const fetchCountryFaliour = (data) => {
    return {
        type: FETCH_HANDLE_COUNTRY_CURRENCY_FALIOUR,
        payload : data
    }
}

export const fetchdelivaryFormData = (data) => {
    return {
        type: FETCH_HANDLE_COUNTRY_CURRENCY_DATA,
        payload: data
    }
}

export const getCountry = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchCountryAccess())
        fetchData(endpoint).then(
            response => dispatch(fetchCountrySuccess(response.data.response))
        ).catch(
            error => dispatch(fetchCountryFaliour(error.message))
        )
    }
}

export const getAllCountry = () => {
    return (dispatch) => {
        dispatch(fetchCountryAccess())
        fetchData(`get_currency_json`).then(
            response => dispatch(fetchAllCountrySuccess(response.data.response))
        ).catch(
            error => dispatch(fetchCountryFaliour(error.message))
        )
    }
}

export const getAllstate = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchCountryAccess())
        fetchData(`get_state/${endpoint}`).then(
            response => {
                let array = []
                response?.data?.response?.map(res => array.push(res.name))
                dispatch(fetchAllCountrystateSuccess(array))
            }
        ).catch(
            error => dispatch(fetchCountryFaliour(error.message))
        )
    }
}

export const postCountry = (body) => {
    return (dispatch) => {
        dispatch(fetchCountryAccess())
        postData(`add_delivery_boy`,body).then(
            response => {
                dispatch(fetchdelivaryFormData(response.data))
                response.data.status === 'success' && dispatch(getCountry(`get_delivery_boy/${body.delivery_boy_branch_id}`))
            }
        ).catch(
            error => dispatch(fetchCountryFaliour(error.message))
        )
    }
}

export const updateCountry = (body) => {
    return (dispatch) => {
        dispatch(fetchCountryAccess())
        updateData(`update_currency`,body).then(
            response => {
                dispatch(fetchdelivaryFormData(response.data))
                response.data.status === 'success' && dispatch(getCountry(`get_currency`))
            }
        ).catch(
            error => dispatch(fetchCountryFaliour(error.message))
        )
    }
}

export const deleteCountry = (body) => {
    return (dispatch) => {
        deleteData(`delete_delivery_boy`,{_id : body._id}).then(
            response => {
                console.log(body.delivery_boy_branch_id)
                dispatch(fetchdelivaryFormData(response.data))
                response.data.status === 'success' && dispatch(getCountry(`get_delivery_boy/${body.delivery_boy_branch_id}`))
            }
        ).catch(
            error => dispatch(fetchCountryFaliour(error.message))
        )
    }
}