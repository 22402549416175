import React, { useState, useEffect } from 'react'
import { colors, IconButton, List, ListItem, makeStyles, SwipeableDrawer, Typography } from '@material-ui/core'
import { ChevronLeft, ChevronRight, RadioButtonUnchecked } from '@material-ui/icons';
import clsx from "clsx";
import { useHistory } from 'react-router-dom'

import { Role } from './Role'
// import { CreateRole } from './CreateRole'
// import { Administrator } from './Administrator'
// import { CreateAdministrator } from './CreateAdministrator'
import {useSelector, useDispatch} from 'react-redux'

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const AccessControl = ({ location, match }) => {
  const state = useSelector(state => state)
  const history = useHistory()
  const classes = useStyles()
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("role");
  const id = match.params.id

  useEffect(() => {
    location?.state && setselectState(location?.state)
  }, [location?.state])

  return (<>
    {
      !state?.branch?.loader &&
      <div style={{
        paddingTop: "10px",
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
        display: 'flex'
      }} className={classes.root} >
        <div
          style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
          className={classes.drawerHeader}
        >
          <IconButton>
            {drawerOpen ? (
              <ChevronLeft onClick={() => setdrawerOpen(false)} />
            ) : (
              <ChevronRight onClick={() => setdrawerOpen(true)} />
            )}
          </IconButton>
        </div>
        <SwipeableDrawer
          className={classes.drawer}
          anchor="left"
          variant="persistent"
          open={drawerOpen}
          onClose={() => setdrawerOpen(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
            <Typography align="center" variant="h6">
              Access Control
          </Typography>
          </div>
          <List>
            {/* <ListItem>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: selectState === "role" || selectState === 'create-role' ? "var(--orange)" : "#ffffff",
                  cursor: "pointer"
                }}
                onClick={() => setselectState("role")}
              >
                <RadioButtonUnchecked
                  style={{ fontSize: "1rem", marginRight: 20 }}
                />
                <Typography>Role</Typography>
              </div>
            </ListItem> */}
            <ListItem>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: selectState === "administrator" || selectState === 'create-administrator' ? "var(--orange)" : "#ffffff",
                  cursor: "pointer"
                }}
                onClick={() => history.push(`/${id}/access-control/administrator`)}
              >
                <RadioButtonUnchecked
                  style={{ fontSize: "1rem", marginRight: 20 }}
                />
                <Typography>Administrator</Typography>
              </div>
            </ListItem>
          </List>
        </SwipeableDrawer>
        <div className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })} >
          <Role setselectState={setselectState} id={id} />
        </div>
        {/* {selectState === 'create-role' && <CreateRole />}
            {selectState === 'administrator' && <Administrator />} */}
      </div>
    }</>)
}
