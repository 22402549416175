import { Breadcrumbs, Divider, Link, Paper, Typography, withStyles, SvgIcon, fade, Collapse, makeStyles, ListItem, List, IconButton, SwipeableDrawer, colors } from '@material-ui/core'
import { ChevronLeft, ChevronRight, RadioButtonUnchecked } from '@material-ui/icons'
import { TreeItem, TreeView } from '@material-ui/lab'
import { useSpring, animated } from 'react-spring/web.cjs';
import React, { useState } from 'react'
import clsx from "clsx";
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
import { Input } from '../../components/Input/Input.component'

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);


export const CreateRole = ({ match }) => {
  const state = useSelector(state => state)
  const history = useHistory()
  const classes = useStyles()
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("role");
  const id = match.params.id
  return (<>
    {
      !state?.branch?.loader &&
      <div style={{
        paddingTop: "10px",
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
        display: 'flex'
      }} className={classes.root} >
        <div
          style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
          className={classes.drawerHeader}
        >
          <IconButton>
            {drawerOpen ? (
              <ChevronLeft onClick={() => setdrawerOpen(false)} />
            ) : (
              <ChevronRight onClick={() => setdrawerOpen(true)} />
            )}
          </IconButton>
        </div>
        <SwipeableDrawer
          className={classes.drawer}
          anchor="left"
          variant="persistent"
          open={drawerOpen}
          onClose={() => setdrawerOpen(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
            <Typography align="center" variant="h6">
              Access Control
          </Typography>
          </div>
          <List>
            <ListItem>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: selectState === "role" || selectState === 'create-role' ? "var(--orange)" : "#ffffff",
                  cursor: "pointer"
                }}
                onClick={() => history.push(`/${id}/access-control`)}
              >
                <RadioButtonUnchecked
                  style={{ fontSize: "1rem", marginRight: 20 }}
                />
                <Typography>Role</Typography>
              </div>
            </ListItem>
            <ListItem>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: selectState === "administrator" || selectState === 'create-administrator' ? "var(--orange)" : "#ffffff",
                  cursor: "pointer"
                }}
                onClick={() => history.push(`/${id}/access-control/administrator`)}
              >
                <RadioButtonUnchecked
                  style={{ fontSize: "1rem", marginRight: 20 }}
                />
                <Typography>Administrator</Typography>
              </div>
            </ListItem>
          </List>
        </SwipeableDrawer>
        <div style={{ paddingLeft: 20, paddingRight: 20 }} className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })} >
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRight style={{ fontSize: 15 }} />}
          >
            <Link color="inherit" href="/" style={{ fontSize: 15 }}>
              Home
                </Link>
            <Link color="inherit" href={`/${id}/access-control`} style={{ fontSize: 15 }}>
              Roles
                </Link>
            <Typography
              color="textPrimary"
              style={{ fontSize: 15, color: "var(--orange)" }}
            >
              Create
                </Typography>
          </Breadcrumbs>
          <Paper style={{ padding: "2rem", margin: "15px auto 5rem auto", width: "70%" }}>
            <Typography variant="h6" >Create Role</Typography>
            <Divider style={{ margin: '10px 0' }} />
            <Input label='Role Name' style={{ width: '100%', marginTop: '2rem' }} />
            <TreeView
              style={{ marginTop: '2rem' }}
              defaultExpanded={['1', '3', '7']}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
            >
              <StyledTreeItem nodeId="1" label="Main">
                <StyledTreeItem nodeId="2" label="Hello" />
                <StyledTreeItem nodeId="3" label="Subtree with children">
                  <StyledTreeItem nodeId="6" label="Hello" />
                  <StyledTreeItem nodeId="7" label="Sub-subtree with children">
                    <StyledTreeItem nodeId="9" label="Child 1" />
                    <StyledTreeItem nodeId="10" label="Child 2" />
                    <StyledTreeItem nodeId="11" label="Child 3" />
                  </StyledTreeItem>
                  <StyledTreeItem nodeId="8" label="Hello" />
                </StyledTreeItem>
                <StyledTreeItem nodeId="4" label="World" />
                <StyledTreeItem nodeId="5" label="Something something" />
              </StyledTreeItem>
            </TreeView>
          </Paper>
        </div>
      </div>
    }</>)
}
