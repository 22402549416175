import { FETCH_PREFERENCE_REQUEST,FETCH_PREFERENCE_SUCCESS,FETCH_PREFERENCE_FALIOUR,FETCH_PREFERENCE_DATA } from "./preferenceTypes";
import {fetchData,updateData} from '../../Api/api'

export const fetchpreferenceAccess = () => {
    return {
        type: FETCH_PREFERENCE_REQUEST
    }
}

export const fetchpreferenceSuccess = (data) => {
    return {
        type: FETCH_PREFERENCE_SUCCESS,
        payload: data
    }
}

export const fetchpreferenceFaliour = (data) => {
    return {
        type: FETCH_PREFERENCE_FALIOUR,
        payload : data
    }
}

export const fetchpreferenceFormData = (data) => {
    return {
        type: FETCH_PREFERENCE_DATA,
        payload: data
    }
}

export const getpreference = () => {
    return (dispatch) => {
        dispatch(fetchpreferenceAccess())
        fetchData(`get_preferences`).then(
            response => dispatch(fetchpreferenceSuccess(response?.data?.response[0]))
        ).catch(
            error => dispatch(fetchpreferenceFaliour(error.message))
        )
    }
}


export const updatepreference = (body) => {
    return (dispatch) => {
        dispatch(fetchpreferenceAccess())
        updateData(`update_preferences`,body).then(
            response => {
                dispatch(fetchpreferenceFormData(response.data))
                response.data.status === 'success' && dispatch(getpreference())
            }
        ).catch(
            error => dispatch(fetchpreferenceFaliour(error.message))
        )
    }
}
