import React, { useState, useEffect } from 'react'
import {
    Box, colors, Grid, InputAdornment, Typography, RadioGroup, Radio, FormControl, FormControlLabel,
    DialogTitle, DialogContent, makeStyles, Button, TextField, Checkbox, TextareaAutosize, DialogActions, Snackbar,
    CircularProgress
} from '@material-ui/core'
import { Search } from '@material-ui/icons';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import MuiAlert from '@material-ui/lab/Alert';
import { Autocomplete } from '@material-ui/lab';
import { postCupon, getItemDataByName, getItemDataAll, searchUsersByWord, getfetureAll, updateCupon, handleCuponData, getUserDataAll } from '../../../redux'
import { useSelector, useDispatch } from "react-redux";
import { useCurrency } from '../../../hooks/useCurrency'
import { getAllCustomerGroup } from '../../../redux/CustomerGroup/CustomerGroup.action';
const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "1000px",
        position: "relative"
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CreateManualDiscount = ({ discountType, dialogOff, edit, alldialogOff }) => {
    const state = useSelector(state => state)
    const currency = useCurrency()
    const dispatch = useDispatch()
    const classes = useStyles();
    const [formValue, setformValue] = useState(edit ?
        {
            ...edit,
            coupon_discount_type: edit.coupon_discount_type_percentage ? 'percentage' : 'fixed_amount',
            coupon_applied_to: edit.coupon_applied_to_entire_order ? 'entire_order' :
                edit.coupon_applied_to_specific_collection ? 'specific_collection' : 'specific_products',
            coupon_customer_eligibility: edit.coupon_customer_eligibility_everyone ? "everyone" :
                edit.coupon_customer_eligibility_specific_customer ? "specific_customers" : "specific_group_of_customer",
            coupon_applied_to_specific_collection_name:
                edit.coupon_applied_to_specific_collection_id ? edit.coupon_applied_to_specific_collection_id : null,
            coupon_applied_to_specific_collection_id: edit.coupon_applied_to_specific_collection_id ?
                edit.coupon_applied_to_specific_collection_id._id : null,
            coupon_applied_to_specific_item_name:
                edit.coupon_applied_to_specific_item_id ? edit.coupon_applied_to_specific_item_id : null,
            coupon_applied_to_specific_item_id: edit.coupon_applied_to_specific_item_id ?
                edit.coupon_applied_to_specific_item_id._id : null,
            coupon_customer_eligibility_specific_customer_name: edit.coupon_customer_eligibility_specific_customer_id ?
                edit.coupon_customer_eligibility_specific_customer_id : null,
            coupon_customer_eligibility_specific_customer_id: edit.coupon_customer_eligibility_specific_customer_id ?
                edit.coupon_customer_eligibility_specific_customer_id._id : null,
            coupon_customer_eligibility_group_of_customer_name: edit.coupon_customer_eligibility_group_of_customer_id ?
                edit.coupon_customer_eligibility_group_of_customer_id : null,
            coupon_customer_eligibility_group_of_customer_id: edit.coupon_customer_eligibility_group_of_customer_id ?
                edit.coupon_customer_eligibility_group_of_customer_id._id : null,
        } :
        {
            coupon_add_discount_code_automatically: !discountType ? true : false,
            coupon_add_discount_code_manually: discountType ? true : false,
            coupon_name: '',
            coupon_discount_code: null,
            coupon_discount_type: 'percentage',
            coupon_discount_type_percentage: false,
            coupon_discount_type_flat_off: true,
            coupon_discount_value: 0,
            coupon_applied_to: 'entire_order',
            coupon_applied_to_entire_order: true,
            coupon_applied_to_entire_order_minimum_cart_value: 0,
            coupon_applied_to_specific_collection: false,
            coupon_applied_to_specific_collection_name: null,
            coupon_applied_to_specific_collection_id: null,
            coupon_applied_to_specific_item: false,
            coupon_applied_to_specific_item_name: null,
            coupon_applied_to_specific_item_id: null,
            coupon_customer_eligibility: "everyone",
            coupon_customer_eligibility_everyone: true,
            coupon_customer_eligibility_group_of_customer: false,
            coupon_customer_eligibility_group_of_customer_name: null,
            coupon_customer_eligibility_group_of_customer_id: null,
            coupon_customer_eligibility_specific_customer: false,
            coupon_customer_eligibility_specific_customer_name: null,
            coupon_customer_eligibility_specific_customer_id: null,
            coupon_number_of_times_the_discount_can_be_used: false,
            coupon_number_of_times_the_discount_can_be_used_value: 0,
            coupon_limit_to_one_use_per_customer: true,
            coupon_available_for_limited_date_time: true,
            coupon_available_from: null,
            coupon_available_to: null,
            coupon_terms_and_conditions: ''
        })

    // useEffect(() => {
    //     edit && setformValue(edit)
    //     edit && console.log(edit)
    // }, [edit])

    useEffect(() => {
        dispatch(getUserDataAll())
        dispatch(getAllCustomerGroup())
    }, [dispatch])

    const onchange = (e) => {
        setformValue({ ...formValue, [e.target.name]: e.target.value, })
    }

    useEffect(() => {
        setformValue({
            ...formValue,
            coupon_discount_type_percentage: formValue.coupon_discount_type === "percentage" ? true : false,
            coupon_discount_type_flat_off: formValue.coupon_discount_type === "fixed_amount" ? true : false
        })
    }, [formValue.coupon_discount_type])

    useEffect(() => {
        setformValue({
            ...formValue,
            coupon_applied_to_entire_order: formValue.coupon_applied_to === "entire_order" ? true : false,
            coupon_applied_to_specific_collection: formValue.coupon_applied_to === "specific_collection" ? true : false,
            coupon_applied_to_specific_item: formValue.coupon_applied_to === "specific_products" ? true : false,
        })
    }, [formValue.coupon_applied_to])

    useEffect(() => {
        setformValue({
            ...formValue,
            coupon_customer_eligibility_everyone: formValue.coupon_customer_eligibility === "everyone" ? true : false,
            coupon_customer_eligibility_group_of_customer: formValue.coupon_customer_eligibility === "specific_group_of_customer" ? true : false,
            coupon_customer_eligibility_specific_customer: formValue.coupon_customer_eligibility === "specific_customers" ? true : false,
            coupon_limit_to_one_use_per_customer: formValue.coupon_customer_eligibility === "specific_customers" ? false : formValue.coupon_limit_to_one_use_per_customer,
            coupon_customer_eligibility_group_of_customer_id: formValue.coupon_customer_eligibility === "specific_customers" ?
                null : formValue.coupon_customer_eligibility_group_of_customer_id,
            coupon_customer_eligibility_group_of_customer_name: formValue.coupon_customer_eligibility === "specific_customers" ?
                null : formValue.coupon_customer_eligibility_group_of_customer_name,
            coupon_customer_eligibility_specific_customer_name: formValue.coupon_customer_eligibility === "specific_group_of_customer" ?
                null : formValue.coupon_customer_eligibility_specific_customer_name,
            coupon_customer_eligibility_specific_customer_id: formValue.coupon_customer_eligibility === "specific_group_of_customer" ?
                null : formValue.coupon_customer_eligibility_specific_customer_id,
        })
    }, [formValue.coupon_customer_eligibility])

    useEffect(() => {
        setformValue({
            ...formValue, coupon_applied_to_specific_collection_id: formValue.coupon_applied_to_specific_collection ?
                formValue.coupon_applied_to_specific_collection_name ?
                    formValue.coupon_applied_to_specific_collection_name._id : null : null
        })
    }, [formValue.coupon_applied_to_specific_collection_name])

    useEffect(() => {
        setformValue({
            ...formValue, coupon_applied_to_specific_item_id: formValue.coupon_applied_to_specific_item ?
                formValue.coupon_applied_to_specific_item_name ?
                    formValue.coupon_applied_to_specific_item_name._id : null : null
        })
    }, [formValue.coupon_applied_to_specific_item_name])

    useEffect(() => {
        setformValue({
            ...formValue, coupon_customer_eligibility_specific_customer_id: formValue.coupon_customer_eligibility_specific_customer ?
                formValue.coupon_customer_eligibility_specific_customer_name ?
                    formValue.coupon_customer_eligibility_specific_customer_name._id : null : null,
        })
    }, [formValue.coupon_customer_eligibility_specific_customer_name])

    useEffect(() => {
        setformValue({
            ...formValue, coupon_customer_eligibility_group_of_customer_id: formValue.coupon_customer_eligibility_group_of_customer ?
                formValue.coupon_customer_eligibility_group_of_customer_name ?
                    formValue.coupon_customer_eligibility_group_of_customer_name._id : null : null,
        })
    }, [formValue.coupon_customer_eligibility_group_of_customer_name])

    useEffect(() => {
        discountType === '' && setformValue({
            coupon_add_discount_code_automatically: !discountType ? true : false,
            coupon_add_discount_code_manually: discountType ? true : false,
            coupon_name: '',
            coupon_discount_code: null,
            coupon_discount_type: 'percentage',
            coupon_discount_type_percentage: false,
            coupon_discount_type_flat_off: true,
            coupon_discount_value: 0,
            coupon_applied_to: 'entire_order',
            coupon_applied_to_entire_order: true,
            coupon_applied_to_entire_order_minimum_cart_value: 0,
            coupon_applied_to_specific_collection: false,
            coupon_applied_to_specific_collection_name: null,
            coupon_applied_to_specific_collection_id: null,
            coupon_applied_to_specific_item: false,
            coupon_applied_to_specific_item_name: null,
            coupon_applied_to_specific_item_id: null,
            coupon_customer_eligibility: "everyone",
            coupon_customer_eligibility_everyone: true,
            coupon_customer_eligibility_group_of_customer: false,
            coupon_customer_eligibility_group_of_customer_name: null,
            coupon_customer_eligibility_group_of_customer_id: null,
            coupon_customer_eligibility_specific_customer: false,
            coupon_customer_eligibility_specific_customer_name: null,
            coupon_customer_eligibility_specific_customer_id: null,
            coupon_number_of_times_the_discount_can_be_used: false,
            coupon_number_of_times_the_discount_can_be_used_value: 0,
            coupon_limit_to_one_use_per_customer: true,
            coupon_available_for_limited_date_time: true,
            coupon_available_from: null,
            coupon_available_to: null,
            coupon_terms_and_conditions: ''
        })
    }, [discountType])

    useEffect(() => {
        state?.coupon?.status === 'success' && alldialogOff() && setformValue({
            coupon_add_discount_code_automatically: !discountType ? true : false,
            coupon_add_discount_code_manually: discountType ? true : false,
            coupon_name: '',
            coupon_discount_code: null,
            coupon_discount_type: 'percentage',
            coupon_discount_type_percentage: false,
            coupon_discount_type_flat_off: true,
            coupon_discount_value: 0,
            coupon_applied_to: 'entire_order',
            coupon_applied_to_entire_order: true,
            coupon_applied_to_entire_order_minimum_cart_value: 0,
            coupon_applied_to_specific_collection: false,
            coupon_applied_to_specific_collection_name: null,
            coupon_applied_to_specific_collection_id: null,
            coupon_applied_to_specific_item: false,
            coupon_applied_to_specific_item_name: null,
            coupon_applied_to_specific_item_id: null,
            coupon_customer_eligibility: "everyone",
            coupon_customer_eligibility_everyone: true,
            coupon_customer_eligibility_group_of_customer: false,
            coupon_customer_eligibility_group_of_customer_name: null,
            coupon_customer_eligibility_group_of_customer_id: null,
            coupon_customer_eligibility_specific_customer: false,
            coupon_customer_eligibility_specific_customer_name: null,
            coupon_customer_eligibility_specific_customer_id: null,
            coupon_number_of_times_the_discount_can_be_used: false,
            coupon_number_of_times_the_discount_can_be_used_value: 0,
            coupon_limit_to_one_use_per_customer: true,
            coupon_available_for_limited_date_time: true,
            coupon_available_from: null,
            coupon_available_to: null,
            coupon_terms_and_conditions: ''
        })
    }, [state?.coupon?.status])

    const onSubmit = () => {
        if (formValue) {
            if (!formValue.coupon_name) {
                dispatch(handleCuponData({ status: 'formFail', message: 'discount Name is required', name: 'coupon_name' }))
            } else {
                if (discountType && !formValue.coupon_discount_code) {
                    dispatch(handleCuponData({ status: 'formFail', message: 'Discount Code is required', name: 'coupon_discount_code' }))
                } else {
                    if (formValue.coupon_discount_code == formValue.coupon_discount_code.toUpperCase()) {
                        if (formValue.coupon_available_to && formValue.coupon_available_from ||
                            !formValue.coupon_available_to && !formValue.coupon_available_from) {
                            if (!formValue.coupon_discount_value) {
                                dispatch(handleCuponData({ status: 'formFail', message: 'discount value is required', name: 'coupon_discount_value' }))
                            } else {
                                if (formValue.coupon_customer_eligibility_group_of_customer) {
                                    if (!formValue.coupon_customer_eligibility_group_of_customer_id) {
                                        dispatch(handleCuponData({ status: 'formFail', message: 'customer group is required', name: 'customer group is required' }))
                                    } else {
                                        if (formValue.coupon_applied_to_entire_order) {
                                            !formValue.coupon_applied_to_entire_order_minimum_cart_value ?
                                                dispatch(handleCuponData({
                                                    status: 'formFail',
                                                    message: 'Minimun cart Value is required & cart Value is Less Than Offer Value', name: 'coupon_applied_to_entire_order_minimum_cart_value'
                                                })) :
                                                parseInt(formValue.coupon_applied_to_entire_order_minimum_cart_value) <= parseInt(formValue.coupon_discount_value) ?
                                                    dispatch(handleCuponData({
                                                        status: 'formFail',
                                                        message: 'cart Value is Less Than Offer Value', name: 'coupon_applied_to_entire_order_minimum_cart_value'
                                                    })) : formValue._id ? dispatch(updateCupon(formValue)) : dispatch(postCupon(formValue))
                                        } else if (formValue.coupon_applied_to_specific_collection) {
                                            !formValue.coupon_applied_to_specific_collection_id ? dispatch(handleCuponData({
                                                status: 'formFail',
                                                message: 'Collection Name is required', name: 'coupon_applied_to_specific_collection_name'
                                            })) : formValue._id ? dispatch(updateCupon(formValue)) : dispatch(postCupon(formValue))
                                        } else if (formValue.coupon_applied_to_specific_item_id) {
                                            !formValue.coupon_applied_to_specific_item_id ? dispatch(handleCuponData({
                                                status: 'formFail',
                                                message: 'Item Name is required', name: 'coupon_applied_to_specific_item_name'
                                            })) : formValue._id ? dispatch(updateCupon(formValue)) : dispatch(postCupon(formValue))
                                        }
                                    }
                                } else if (formValue.coupon_customer_eligibility_specific_customer) {
                                    if (!formValue.coupon_customer_eligibility_specific_customer_id) {
                                        dispatch(handleCuponData({ status: 'formFail', message: 'customer is required', name: 'customer is required' }))
                                    } else {
                                        if (formValue.coupon_applied_to_entire_order) {
                                            !formValue.coupon_applied_to_entire_order_minimum_cart_value ?
                                                dispatch(handleCuponData({
                                                    status: 'formFail',
                                                    message: 'Minimun cart Value is required', name: 'coupon_applied_to_entire_order_minimum_cart_value'
                                                })) :
                                                parseInt(formValue.coupon_applied_to_entire_order_minimum_cart_value) <= parseInt(formValue.coupon_discount_value) ?
                                                    dispatch(handleCuponData({
                                                        status: 'formFail',
                                                        message: 'cart Value is Less Than Offer Value', name: 'coupon_applied_to_entire_order_minimum_cart_value'
                                                    })) : formValue._id ? dispatch(updateCupon(formValue)) : dispatch(postCupon(formValue))
                                        } else if (formValue.coupon_applied_to_specific_collection) {
                                            !formValue.coupon_applied_to_specific_collection_id ? dispatch(handleCuponData({
                                                status: 'formFail',
                                                message: 'Collection Name is required', name: 'coupon_applied_to_specific_collection_name'
                                            })) : formValue._id ? dispatch(updateCupon(formValue)) : dispatch(postCupon(formValue))
                                        } else if (formValue.coupon_applied_to_specific_item_id) {
                                            !formValue.coupon_applied_to_specific_item_id ? dispatch(handleCuponData({
                                                status: 'formFail',
                                                message: 'Item Name is required', name: 'coupon_applied_to_specific_item_name'
                                            })) : formValue._id ? dispatch(updateCupon(formValue)) : dispatch(postCupon(formValue))
                                        }
                                    }
                                } else {
                                    if (formValue.coupon_applied_to_entire_order) {
                                        !formValue.coupon_applied_to_entire_order_minimum_cart_value ?
                                            dispatch(handleCuponData({
                                                status: 'formFail',
                                                message: 'Minimun cart Value is required', name: 'coupon_applied_to_entire_order_minimum_cart_value'
                                            })) :
                                            parseInt(formValue.coupon_applied_to_entire_order_minimum_cart_value) <= parseInt(formValue.coupon_discount_value) ?
                                                dispatch(handleCuponData({
                                                    status: 'formFail',
                                                    message: 'cart Value is Less Than Offer Value', name: 'coupon_applied_to_entire_order_minimum_cart_value'
                                                })) : formValue._id ? dispatch(updateCupon(formValue)) : dispatch(postCupon(formValue))
                                    } else if (formValue.coupon_applied_to_specific_collection) {
                                        !formValue.coupon_applied_to_specific_collection_id ? dispatch(handleCuponData({
                                            status: 'formFail',
                                            message: 'Collection Name is required', name: 'coupon_applied_to_specific_collection_name'
                                        })) : formValue._id ? dispatch(updateCupon(formValue)) : dispatch(postCupon(formValue))
                                    } else if (formValue.coupon_applied_to_specific_item_id) {
                                        !formValue.coupon_applied_to_specific_item_id ? dispatch(handleCuponData({
                                            status: 'formFail',
                                            message: 'Item Name is required', name: 'coupon_applied_to_specific_item_name'
                                        })) : formValue._id ? dispatch(updateCupon(formValue)) : dispatch(postCupon(formValue))
                                    }
                                }
                            }
                        }
                    } else {
                        dispatch(handleCuponData({ status: 'formFail', message: 'Code Should be in caps', name: 'coupon_discount_code' }))
                    }
                }
            }
        }
    }

    return (
        <>
            <DialogTitle
                style={{ backgroundColor: colors.blue[50] }}
                id="max-width-dialog-title"
            >
                Manual Discount Code
            </DialogTitle>
            {/* <form className={classes.form} > */}
            <DialogContent className={classes.form}>
                <Box padding='0.5rem'>
                    <Grid container alignItems='center' >
                        <Grid item sm={4} justify='flex-start' >
                            <Typography variant="body1"
                                style={{ color: !formValue.coupon_name && state?.coupon?.response === 'coupon_name' ? 'red' : 'black' }}>
                                Discount Code Tag</Typography>
                        </Grid>
                        <Grid item sm={8} justify='flex-start' >
                            <TextField fullWidth variant="outlined" size="small" value={formValue.coupon_name}
                                error={!formValue.coupon_name && state?.coupon?.response === 'coupon_name'}
                                helperText={!formValue.coupon_name && state?.coupon?.response === 'coupon_name' && 'Name Is Required'}
                                name="coupon_name" onChange={(e) => onchange(e)} />
                        </Grid>
                    </Grid>
                </Box>
                {
                    discountType && <Box padding='0.5rem'>
                        <Grid container alignItems='center' >
                            <Grid item sm={4} justify='flex-start' >
                                <Typography variant="body1"
                                    style={{ color: !formValue.coupon_discount_code ? state?.coupon?.response === 'coupon_discount_code' ? 'red' : 'black' : 
                                    formValue.coupon_discount_code !== formValue.coupon_discount_code.toUpperCase() && state?.coupon?.response === 'coupon_discount_code' ? 'red' : 'black'}}
                                >Write Discount Code</Typography>
                            </Grid>
                            <Grid item sm={8} justify='flex-start' >
                                <TextField fullWidth variant="outlined" size="small" value={formValue.coupon_discount_code}
                                    error={!formValue.coupon_discount_code ? state?.coupon?.response === 'coupon_discount_code' : 
                                        formValue.coupon_discount_code !== formValue.coupon_discount_code.toUpperCase() && 
                                        state?.coupon?.response === 'coupon_discount_code'}
                                    helperText={!formValue.coupon_discount_code ? state?.coupon?.response === 'coupon_discount_code'
                                        && 'Discount Value Is Required' : formValue.coupon_discount_code !== formValue.coupon_discount_code.toUpperCase() &&
                                        state?.coupon?.message}
                                    name="coupon_discount_code" onChange={(e) => onchange(e)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button color="primary" onClick={() =>
                                                    setformValue({ ...formValue, coupon_discount_code: Math.random().toString(36).toUpperCase().substr(2, 5) })}>Auto Generate Code</Button>
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Box padding='0.5rem'>
                    <Grid container alignItems='start' >
                        <Grid item sm={4}>
                            <Typography variant="body1">Select discount type</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <FormControl component="fieldset" style={{ display: "flex" }}>
                                <RadioGroup aria-label="gender" name="coupon_discount_type"
                                    value={formValue.coupon_discount_type}
                                    onChange={(e) => {
                                        onchange(e)
                                    }}>
                                    <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
                                    <FormControlLabel value="fixed_amount" control={<Radio />} label="Fixed Amount" />
                                    {/* <FormControlLabel value="buy_x_get_y" control={<Radio />} label="Buy x Get Y" /> */}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
                <Box padding='0.5rem'>
                    <Grid container alignItems='center' >
                        <Grid item sm={4} justify='flex-start' >
                            <Typography variant="body1"
                                style={{
                                    color: !formValue.coupon_discount_value && state?.coupon?.response === 'coupon_discount_value' ?
                                        'red' : 'black'
                                }}>Discount Value </Typography>
                        </Grid>
                        <Grid item sm={8} justify='flex-start' >
                            <TextField fullWidth variant="outlined" type="Number" size="small" name="coupon_discount_value"
                                error={!formValue.coupon_discount_value && state?.coupon?.response === 'coupon_discount_value'}
                                helperText={!formValue.coupon_discount_value && state?.coupon?.response === 'coupon_discount_value'
                                    && 'Discount Value Is Required'}
                                value={formValue.coupon_discount_value} onChange={e => onchange(e)}
                                InputProps={{
                                    endAdornment: (
                                        // <InputAdornment position="end">
                                        <Typography variant="body1">
                                            {formValue.coupon_discount_type === 'percentage' ? '%' : currency}
                                        </Typography>
                                        // </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                    </Grid>
                </Box>
                {/* <Box padding='0.5rem'>
                    <Grid container alignItems='start' >
                        <Grid item sm={4}>
                            <Typography variant="body1">Applied to</Typography>
                        </Grid>
                        <Grid item sm={8}>
                            <FormControl component="fieldset" style={{ display: "flex" }}>
                                <RadioGroup aria-label="gender" name="coupon_applied_to"
                                    value={formValue.coupon_applied_to} onChange={(e) => onchange(e)}>
                                    <FormControlLabel value="entire_order" control={<Radio />} label="Entire order" />
                                    <FormControlLabel value="specific_collection" control={<Radio />} label="Specific collection" />
                                    <FormControlLabel value="specific_products" control={<Radio />} label="Specific products" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box> */}
                {/* {
                    formValue.coupon_applied_to === 'entire_order' ? */}
                <Box padding='0.5rem'>
                    <Grid container alignItems='center' spacing={1}>
                        <Grid item sm={4} justify='flex-start' >
                            <Typography variant="body1"
                                style={{
                                    color: !formValue.coupon_applied_to_entire_order_minimum_cart_value ?
                                        state?.coupon?.response === 'coupon_applied_to_entire_order_minimum_cart_value' && 'red'
                                        : formValue.coupon_applied_to_entire_order_minimum_cart_value <= formValue.coupon_discount_value ? 'red' : 'black'
                                }}
                            >Apply On Order Above</Typography>
                        </Grid>
                        <Grid item sm={8} justify='flex-start' >
                            <TextField fullWidth variant="outlined" size="small" type="Number"
                                error={!formValue.coupon_applied_to_entire_order_minimum_cart_value ? 
                                    state?.coupon?.response === 'coupon_applied_to_entire_order_minimum_cart_value' : 
                                    formValue.coupon_applied_to_entire_order_minimum_cart_value <= formValue.coupon_discount_value && 
                                    state?.coupon?.response === 'coupon_applied_to_entire_order_minimum_cart_value'}
                                helperText={!formValue.coupon_applied_to_entire_order_minimum_cart_value ? 
                                    state?.coupon?.response === 'coupon_applied_to_entire_order_minimum_cart_value' && 
                                    "Minimun Cart Value is Required" : 
                                    formValue.coupon_applied_to_entire_order_minimum_cart_value <= formValue.coupon_discount_value 
                                    && "Cart Value Should always Greater Than Offer Value"}
                                name="coupon_applied_to_entire_order_minimum_cart_value"
                                value={formValue.coupon_applied_to_entire_order_minimum_cart_value} onChange={e => onchange(e)}
                                InputProps={{
                                    endAdornment: (
                                        // <InputAdornment position="end">
                                        <Typography variant="body1">
                                            {currency}
                                        </Typography>
                                        // </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                    </Grid>
                </Box>
                {/* :

                        <Box padding='0.5rem'>
                            <Grid container alignItems='center' spacing={1}>
                                <Grid item sm={4} justify='flex-start' >
                                    <Typography variant="body1" style={{
                                        color: formValue.coupon_applied_to === 'specific_collection' ?
                                            (!formValue.coupon_applied_to_specific_collection_name &&
                                                state?.coupon?.response === 'coupon_applied_to_specific_collection_name' &&
                                                formValue.coupon_applied_to_specific_collection_name === null ? 'red' : 'black') :
                                            (!formValue.coupon_applied_to_specific_item_name &&
                                                state?.coupon?.response === 'coupon_applied_to_specific_item_name' &&
                                                formValue.coupon_applied_to_specific_item_name === null ? 'red' : 'black')
                                    }}
                                    >Search collection/product</Typography>
                                </Grid>
                                <Grid item sm={7} justify='flex-start' >
                                    {formValue.coupon_applied_to === 'specific_collection' ?
                                        <Autocomplete size="small"
                                            // open={state?.feture?.loading }
                                            // onOpen={() =>  state?.feture?.loading }
                                            // onClose={() => !state?.feture?.loading }
                                            value={formValue.coupon_applied_to_specific_collection_name}
                                            onChange={(event, newValue) => {
                                                // dispatch(getfetureAll(`search_featured_item_by_name/${formValue.coupon_applied_to_specific_collection_name}`))
                                                setformValue({ ...formValue, coupon_applied_to_specific_collection_name: newValue });
                                            }
                                            }
                                            getOptionLabel={(option) => option.featured_item_name}
                                            options={state?.feture?.data}
                                            // loading={state?.feture?.loading}
                                            renderInput={(params) => (
                                                <TextField name="coupon_applied_to_specific_collection_name"
                                                    error={!formValue.coupon_applied_to_specific_collection_name &&
                                                        state?.coupon?.response === 'coupon_applied_to_specific_collection_name' &&
                                                        formValue.coupon_applied_to_specific_collection_name === null
                                                    }
                                                    helperText={!formValue.coupon_applied_to_specific_collection_name &&
                                                        state?.coupon?.response === 'coupon_applied_to_specific_collection_name' &&
                                                        formValue.coupon_applied_to_specific_collection_name === null &&
                                                        state?.coupon?.message
                                                    }
                                                    {...params}
                                                    variant="outlined"
                                                    onChange={(event, newValue) => {
                                                        dispatch(getfetureAll(`/search_featured_item_by_featured_item_name/${event.target.value}`))
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {state?.feture?.loading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                        :
                                        <Autocomplete size="small"
                                            // open={!state?.item?.loading }
                                            // onOpen={() =>  !state?.item?.loading }
                                            // onClose={() => state?.item?.loading }
                                            value={formValue.coupon_applied_to_specific_item_name}
                                            onChange={(event, newValue) => {
                                                setformValue({ ...formValue, coupon_applied_to_specific_item_name: newValue });
                                            }
                                            }
                                            getOptionLabel={(option) => option.item_name}
                                            options={state?.item?.data}
                                            // loading={state?.feture?.loading}
                                            renderInput={(params) => (
                                                <TextField name="coupon_applied_to_specific_item_name"
                                                    error={!formValue.coupon_applied_to_specific_item_name &&
                                                        state?.coupon?.response === 'coupon_applied_to_specific_item_name' &&
                                                        formValue.coupon_applied_to_specific_item_name === null
                                                    }
                                                    helperText={!formValue.coupon_applied_to_specific_item_name &&
                                                        state?.coupon?.response === 'coupon_applied_to_specific_item_name' &&
                                                        formValue.coupon_applied_to_specific_item_name === null &&
                                                        state?.coupon?.message
                                                    }
                                                    {...params}
                                                    variant="outlined"
                                                    onChange={(event) => {
                                                        dispatch(getItemDataByName(event.target.value))
                                                        // setformValue({ ...formValue, coupon_applied_to_specific_item_name: newValue });
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {state?.feture?.loading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        )
                                                    }}
                                                />
                                            )}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                } */}
                <Box padding='0.5rem'>
                    <Grid container>
                        <Grid item sm={4} justify='flex-start' >
                            <Typography variant="body1" >Customer Eligibility</Typography>
                        </Grid>
                        <Grid item sm={8} justify='flex-start' >
                            <FormControl component="fieldset">
                                <RadioGroup name="coupon_customer_eligibility"
                                    value={formValue.coupon_customer_eligibility} onChange={(e) => onchange(e)}>
                                    <FormControlLabel value="everyone" control={<Radio />} label="Everyone" />
                                    <FormControlLabel value="specific_group_of_customer" control={<Radio />} label="Specific Group of customers" />
                                    <FormControlLabel value="specific_customers" control={<Radio />} label="Specific Customers" />
                                </RadioGroup>
                            </FormControl>
                            {
                                formValue.coupon_customer_eligibility !== 'everyone' &&
                                <Grid container alignItems='center' spacing={1}>
                                    <Grid item sm={10} justify='flex-start'>
                                        {formValue.coupon_customer_eligibility === 'specific_group_of_customer' ?
                                            <Autocomplete size="small"
                                                // open={state?.feture?.loading }
                                                // onOpen={() =>  state?.feture?.loading }
                                                // onClose={() => !state?.feture?.loading }
                                                value={formValue.coupon_customer_eligibility_group_of_customer_name}
                                                onChange={(event, newValue) => {
                                                    // dispatch(getfetureAll(`search_featured_item_by_name/${formValue.coupon_customer_eligibility_group_of_customer_name}`))
                                                    setformValue({ ...formValue, coupon_customer_eligibility_group_of_customer_name: newValue });
                                                }
                                                }
                                                getOptionLabel={(option) => option.customer_group_name}
                                                options={state?.customerGroup?.customerGroupList ?
                                                    state?.customerGroup?.customerGroupList?.response : []}
                                                // loading={state?.feture?.loading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Specific Customer Group"
                                                        error={!formValue.coupon_customer_eligibility_group_of_customer_name &&
                                                            state?.coupon?.response === 'customer group is required' &&
                                                            formValue.coupon_customer_eligibility_group_of_customer_name === null
                                                        }
                                                        helperText={!formValue.coupon_customer_eligibility_group_of_customer_name &&
                                                            state?.coupon?.response === 'customer group is required' &&
                                                            formValue.coupon_customer_eligibility_group_of_customer_name === null &&
                                                            state?.coupon?.message
                                                        }
                                                        onChange={(event) => {
                                                            // dispatch(getAllCustomerGroup())
                                                            // dispatch(getfetureAll(`/search_featured_item_by_featured_item_name/${event.target.value}`))
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {state?.feture?.loading ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                    />
                                                )}
                                            />
                                            :
                                            <Autocomplete size="small"
                                                // open={!state?.user?.loading }
                                                // onOpen={() =>  !state?.user?.loading }
                                                // onClose={() => state?.user?.loading }
                                                value={formValue.coupon_customer_eligibility_specific_customer_name}
                                                onChange={(event, newValue) => {
                                                    setformValue({ ...formValue, coupon_customer_eligibility_specific_customer_name: newValue });
                                                }
                                                }
                                                getOptionLabel={(option) => `${option.phone_number}${option.first_name && `(${option.first_name} ${option.last_name})`}`}
                                                options={state?.user?.userList}
                                                loading={state?.user?.loading}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={!formValue.coupon_customer_eligibility_specific_customer_name &&
                                                            state?.coupon?.response === 'customer is required' &&
                                                            formValue.coupon_customer_eligibility_specific_customer_name === null
                                                        }
                                                        helperText={!formValue.coupon_customer_eligibility_specific_customer_name &&
                                                            state?.coupon?.response === 'customer is required' &&
                                                            formValue.coupon_customer_eligibility_specific_customer_name === null &&
                                                            state?.coupon?.message
                                                        }
                                                        variant="outlined"
                                                        label="Spefic Customer"
                                                        onChange={(event) => {
                                                            dispatch(searchUsersByWord(event.target.value))
                                                            // setformValue({ ...formValue, coupon_customer_eligibility_specific_customer_name: newValue });
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {state?.user?.loader ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            )
                                                        }}
                                                    />
                                                )}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            }
                            {/* <Box display='flex' alignItems='center' >
                                <Button color="primary" style={{ marginRight: '1rem' }} >Abandoned cart</Button>
                                <Button color="primary" style={{ marginRight: '1rem' }}>Email subscriber</Button>
                                <Button color="primary">New Customer</Button>
                            </Box> */}
                        </Grid>
                    </Grid>
                </Box>
                <Box padding='0.5rem'>
                    <Grid container alignItems='center' >
                        <Grid item sm={4} justify='flex-start' >
                            {
                                formValue.coupon_number_of_times_the_discount_can_be_used &&
                                <Typography variant="body1" >Usages limit</Typography>
                            }
                        </Grid>
                        <Grid item sm={7} >
                            <Box display='flex' alignItems='center' >
                                <Checkbox checked={formValue.coupon_number_of_times_the_discount_can_be_used}
                                    name="coupon_number_of_times_the_discount_can_be_used"
                                    onChange={e => setformValue({
                                        ...formValue,
                                        coupon_number_of_times_the_discount_can_be_used: e.target.checked
                                    })} />
                                <Typography variant='body1' >No of times the discount can be used in total</Typography>
                            </Box>
                            {
                                formValue.coupon_number_of_times_the_discount_can_be_used &&
                                <TextField type='number' variant="outlined" style={{ width: 200, marginLeft: '10px' }}
                                    name="coupon_number_of_times_the_discount_can_be_used_value"
                                    value={formValue.coupon_number_of_times_the_discount_can_be_used_value}
                                    size='small' onChange={e => onchange(e)} />
                            }
                            {
                                formValue.coupon_customer_eligibility
                                !== 'specific_customers' &&
                                <Box display='flex' alignItems='center' >
                                    <Checkbox checked={formValue.coupon_limit_to_one_use_per_customer}
                                        name="coupon_limit_to_one_use_per_customer"
                                        onChange={e => setformValue({
                                            ...formValue,
                                            coupon_limit_to_one_use_per_customer: e.target.checked
                                        })} />
                                    <Typography variant='body1' >Limit to one use per customer</Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Box>
                <Box padding='0.5rem'>
                    <Grid container alignItems='center'>
                        <Grid item sm={2} justify='flex-start' >
                            <Typography variant="body1" >Active Date</Typography>
                        </Grid>
                        <Grid item sm={4} justify='flex-start' >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={formValue.coupon_available_to && !formValue.coupon_available_from}
                                    helperText={formValue.coupon_available_to && !formValue.coupon_available_from &&
                                        'Coupon StartDate is necessary'}
                                    margin="normal"
                                    style={{ width: 200 }}
                                    label="DD-MM-YY"
                                    format="dd/MM/yyyy"
                                    value={formValue.coupon_available_from}
                                    onChange={(date) => setformValue({ ...formValue, coupon_available_from: date })}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item sm={2} justify='flex-start' >
                            <Typography variant="body1" >Active Time</Typography>
                        </Grid>
                        <Grid item sm={4} justify='flex-start' >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    error={formValue.coupon_available_to && !formValue.coupon_available_from}
                                    helperText={formValue.coupon_available_to && !formValue.coupon_available_from &&
                                        'Coupon StartTime is necessary'}
                                    margin="normal"
                                    label="HH:MM"
                                    value={formValue.coupon_available_from}
                                    onChange={(date) => setformValue({ ...formValue, coupon_available_from: date })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Box>
                <Box padding='0.5rem'>
                    <Grid container alignItems='center'>
                        <Grid item sm={2} justify='flex-start' >
                            <Typography variant="body1" >End Date</Typography>
                        </Grid>
                        <Grid item sm={4} justify='flex-start' >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    error={!formValue.coupon_available_to && formValue.coupon_available_from}
                                    helperText={!formValue.coupon_available_to && formValue.coupon_available_from &&
                                        'Coupon EndDate is necessary'}
                                    margin="normal"
                                    style={{ width: 200 }}
                                    label="DD-MM-YY"
                                    format="dd/MM/yyyy"
                                    value={formValue.coupon_available_to}
                                    onChange={(date) => setformValue({ ...formValue, coupon_available_to: date })}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item sm={2} justify='flex-start' >
                            <Typography variant="body1" >End Time</Typography>
                        </Grid>
                        <Grid item sm={4} justify='flex-start' >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    error={!formValue.coupon_available_to && formValue.coupon_available_from}
                                    helperText={!formValue.coupon_available_to && formValue.coupon_available_from &&
                                        'Coupon EndTime is necessary'}
                                    margin="normal"
                                    label="HH:MM"
                                    value={formValue.coupon_available_to}
                                    onChange={(date) => setformValue({ ...formValue, coupon_available_to: date })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Typography variant="body1" style={{ margin: '1rem 0' }} >Terms & Condition</Typography>
                    <TextField fullWidth value={formValue.coupon_terms_and_conditions} name="coupon_terms_and_conditions"
                        id="standard-multiline-static" onChange={e => onchange(e)}
                        multiline
                        rows={4}
                        variant="outlined"
                    />
                    {/* <TextareaAutosize rowsMin={5} style={{ width: '100%' }} /> */}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => dialogOff()}>
                    Cancel
                </Button>
                {
                    formValue._id ? <Button color="primary" onClick={() => onSubmit()}>
                        Update
                    </Button> :
                        <Button color="primary" onClick={() => onSubmit()}>
                            Save
                        </Button>
                }
            </DialogActions>
            {/* </form> */}
            <Snackbar open={state.coupon.status}
                onClose={!state.coupon.status}>
                <Alert onClose={!state.coupon.status} severity={state.coupon.status}>
                    {state.coupon.message}
                </Alert>
            </Snackbar>
        </>

    )
}
