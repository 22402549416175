import { FETCH_ALL_CUSTOMERS_GROUP, ADD_CUSTOMER_GROUP,  DELETE_CUSTOMER_GROUP,  UPDATE_CUSTOMER_GROUP, USER_ADD_REMOVE,USER_ADD_REMOVE_FALIUR, USER_ADD_REMOVE_SUCCESS } from './CustomerGroup.type'

const initialState = {
    data:null,
    message: '',
    status: ''
}

const customerGroupReducer = (state = initialState, action) => {
    switch(action.type){
        case FETCH_ALL_CUSTOMERS_GROUP:
            return{
                ...state,
                customerGroupList: action.payload
            }
        case ADD_CUSTOMER_GROUP:
            return{
                ...state,
                addCustomerGroup: action.payload
            }
        case DELETE_CUSTOMER_GROUP:
            return{
                ...state,
                deleteCustomerGroup: action.payload
            }
        case UPDATE_CUSTOMER_GROUP:
            return{
                ...state,
                updateCustomerGroup: action.payload
            }
        case USER_ADD_REMOVE:
            return{
                ...state,
                loading: true
            }
        case USER_ADD_REMOVE_SUCCESS:
            return{
                ...state,
                data: action.payload,
                loading: false,
                message: action.payload.message,
                status: 'success'
            }
        case USER_ADD_REMOVE_FALIUR:
            return{
                ...state,
                message: action.payload,
                loading: false,
                status: 'fail'
            }
        default:
            return state
    }
}

export default customerGroupReducer