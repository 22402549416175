import React, { useEffect } from "react";

import {
  Typography,
  colors,
  Box,
  Card,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import { getAllOrder, getUserDataAll, getAllBranch } from "../../redux";

import { useCurrency } from "../../hooks/useCurrency";

export const DashBoard = () => {
  const currency = useCurrency();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const order = state?.order;
  const branch = state?.branch;
  const user = state?.user;
  const activeCustomers = user?.userList?.filter((i) => i.status);

  useEffect(() => {
    dispatch(getAllOrder());
    dispatch(getUserDataAll());
    dispatch(getAllBranch());
  }, [dispatch]);

  return (
    <>
      {!state?.branch?.loader && (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
            paddingLeft: 115,
            paddingRight: 15,
          }}
        >
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ padding: "2rem 0" }}
          >
            TOTAL SUMMARY
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Card
              elevation={10}
              style={{
                background:
                  "linear-gradient(to bottom right, #FF8661, #FEA2C3)",
                width: "23%",
                padding: "1rem",
                color: "#ffffff",
                height: 120,
              }}
            >
              <Typography>Total Active Customers</Typography>
              <Typography variant="h5" style={{ marginTop: "2rem" }}>
                {activeCustomers?.length}
              </Typography>
            </Card>
            <Card
              elevation={10}
              style={{
                background:
                  "linear-gradient(to bottom right, #39CEC0, #149FD1)",
                width: "23%",
                padding: "1rem",
                color: "#ffffff",
                height: 120,
              }}
            >
              <Typography>Total Orders</Typography>
              <Typography variant="h5" style={{ marginTop: "2rem" }}>
                {order?.data?.length}
              </Typography>
            </Card>
            <Card
              elevation={10}
              style={{
                background:
                  "linear-gradient(to bottom right, #EA6FE9, #8A64F7)",
                width: "23%",
                padding: "1rem",
                color: "#ffffff",
                height: 120,
              }}
            >
              <Typography>Total Branches</Typography>
              <Typography variant="h5" style={{ marginTop: "2rem" }}>
                {branch?.data?.length}
              </Typography>
            </Card>
            <Card
              elevation={10}
              style={{
                background:
                  "linear-gradient(to bottom right, #F1CD17, #43CE80)",
                width: "23%",
                padding: "1rem",
                color: "#ffffff",
                height: 120,
              }}
            >
              <Typography>Total Turnover</Typography>
              <Typography variant="h5" style={{ marginTop: "2rem" }}>
                {currency} {order?.total_accepted_amount}
              </Typography>
            </Card>
          </Box>
          <Grid container style={{ padding: "2rem 0" }}>
            <Grid item sm={6}>
              <Typography variant="h5" color="textSecondary">
                TODAY
              </Typography>
              <Typography color="textSecondary" style={{ margin: "1rem 0" }}>
                Friday, May 28, 2021
              </Typography>
              <TableContainer
                component={Paper}
                style={{ marginTop: "1rem", width: "90%", padding: ".5rem" }}
              >
                <Table style={{ border: "1px solid grey" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Total Orders</TableCell>
                      <TableCell>{order?.data?.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Sales</TableCell>
                      <TableCell>
                        {currency} {order?.total_delivered_amount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pending Orders</TableCell>
                      <TableCell>{order?.total_pending_orders}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Delivered Orders</TableCell>
                      <TableCell>{order?.total_delivered_orders}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Rejected Orders</TableCell>
                      <TableCell>{order?.total_rejected_orders}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item sm={6}>
              <Typography variant="h5" color="textSecondary">
                THIS WEEK
              </Typography>
              <Typography color="textSecondary" style={{ margin: "1rem 0" }}>
                Friday, May 28, 2021
              </Typography>
              <TableContainer
                component={Paper}
                style={{ marginTop: "1rem", width: "90%", padding: ".5rem" }}
              >
                <Table style={{ border: "1px solid grey" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>Total Orders</TableCell>
                      <TableCell>{order?.data?.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Sales</TableCell>
                      <TableCell>
                        {currency} {order?.total_delivered_amount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Pending Orders</TableCell>
                      <TableCell>{order?.total_pending_orders}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Delivered Orders</TableCell>
                      <TableCell>{order?.total_delivered_orders}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Rejected Orders</TableCell>
                      <TableCell>{order?.total_rejected_orders}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>New customers</TableCell>
                      <TableCell>0</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      )}{" "}
    </>
  );
};
