import {  FETCH_ALL_CUSTOMERS_GROUP, ADD_CUSTOMER_GROUP, DELETE_CUSTOMER_GROUP, 
    // ADD_USER_TO_CUSTOMER_GROUP,
    //  REMOVE_USER_FROM_CUSTOMER_GROUP, 
     UPDATE_CUSTOMER_GROUP, USER_ADD_REMOVE, USER_ADD_REMOVE_FALIUR, USER_ADD_REMOVE_SUCCESS } from './CustomerGroup.type'
import { addCustomerGroup, fetchAllCustomerGroup,  deletingCustomerGroup, addingUserToCustomerGroup, removinguserFromCustomerGroup, updatingCustomerGroup } from '../../Api/api'

export const getAllCustomerGroup = () => async dispatch => {
    try {
        const { data } = await fetchAllCustomerGroup()
        dispatch({ type: FETCH_ALL_CUSTOMERS_GROUP, payload: data })
    } catch (error) {
        console.log(error)
    }
}

export const createCustomerGroup = (body) => async dispatch => {
    try {
        const { data } = await addCustomerGroup(body)

        dispatch({ type: ADD_CUSTOMER_GROUP, payload: data })
        if(data?.status === 'success'){
            dispatch(getAllCustomerGroup())
        }
    } catch (error) {
        console.log(error)
    }
}

export const deleteCustomerGroup = (id) => async dispatch => {
    try {
        const variables = {
            _id: id
        }

        const { data } = await deletingCustomerGroup(variables)
        dispatch({ type: DELETE_CUSTOMER_GROUP, payload: data })
        if(data?.status === 'success'){
            dispatch(getAllCustomerGroup())
        }
    } catch (error) {
        console.log(error)
    }
}

export const updateCustomerGroup = (body) => async dispatch => {
    try {
        const { data } = await updatingCustomerGroup(body)
        dispatch({ type: UPDATE_CUSTOMER_GROUP, payload: data })
        dispatch(getAllCustomerGroup())
    } catch (error) {
        console.log(error)
    }
}

export const addUserToCustomerGroup = (body) =>  dispatch => {
    try {
        dispatch({ type: USER_ADD_REMOVE })
        addingUserToCustomerGroup(body).then(res => {
            dispatch({ type: USER_ADD_REMOVE_SUCCESS, payload: res.data })
        }).catch(er => {
            dispatch({ type: USER_ADD_REMOVE_FALIUR, payload: er.message })
        })
    } catch (error) {
        dispatch({ type: USER_ADD_REMOVE_FALIUR, payload: error.message })
    }
}

export const removeUserFromCustomerGroup = (body) => dispatch => {
    try {
        dispatch({ type: USER_ADD_REMOVE })
        removinguserFromCustomerGroup(body).then(res => {
            dispatch({ type: USER_ADD_REMOVE_SUCCESS, payload: res.data })
        }).catch(er => {
            dispatch({ type: USER_ADD_REMOVE_FALIUR, payload: er.message })
        })
    } catch (error) {
        dispatch({ type: USER_ADD_REMOVE_FALIUR, payload: error.message })
    }
}