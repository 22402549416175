import React, { useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  colors,
  Divider,
  Link,
  Paper,
  Typography,
  makeStyles,
  TextField,
  Snackbar,
  ListItem,
  List,
  SwipeableDrawer,
  IconButton
} from "@material-ui/core";

//Components
import {
  Check,
  ChevronLeft,
  ChevronRight,
  CloudUpload,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';

import { connect } from "react-redux";
import { updateIngridient, postInGrientData, addIngridientImage, deleteIngridientImage } from "../../redux"

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import clsx from "clsx";


const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  margin: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  center: { display: "flex", justifyContent: "center", alignItems: "center", padding: "0.5rem" }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AddIngredient = ({ state, fetchApi, location, match }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const classes = useStyles();
  const history = useHistory();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("ingredient");
  const id = match.params.id
  const [ingridentFromData, setIngridentFromData] = useState({
    ingredient_name: '', ingredient_description: '', ingredient_image: ''
  })

  useEffect(() => {
    if (location.state) {
      setIngridentFromData(location.state)
      const contentBlock = htmlToDraft(location.state.ingredient_description);
      console.log(location.state.ingredient_description)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
      }
      // setEditorState(location.state.ingredient_description)
      // return () => {
      //   cleanup
      // }
    }
  }, [location])

  useEffect(() => {
    if (state.ingridient.status === 'success') {
      setIngridentFromData({
        ingredient_image: '',
        ingredient_name: '',
        ingredient_description: '',
      })
      setEditorState(EditorState.createEmpty());
      location.state && setTimeout(() => {
        history.push({
          pathname: `/${state?.branch?.activeBranch?._id}/menu-setup/ingredient`,
        })
      }, 1000)
    } else if (state?.ingridient?.image_link) {
      setIngridentFromData({
        ...ingridentFromData,
        ingredient_image: state?.ingridient.image_link
      })
    }
    // return () => {
    //   cleanup
    // }
  }, [state?.ingridient])


  return (
    <>
      {
        !state?.branch?.loader &&
        <div
          style={{
            // paddingTop: "8%",
            // paddingLeft: "12%",
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}
        >
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Menu Setup
              </Typography>
            </div>
            <List>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: selectState === "category" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(`/${id}/menu-setup`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Category</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "itemsetup" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(`/${id}/menu-setup/item-setup`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Items Setup</Typography>
                </div>
              </ListItem>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color:
                      selectState === "ingredient" ? "var(--orange)" : "#ffffff",
                    cursor: 'pointer'
                  }}
                  onClick={() => history.push(`/${id}/menu-setup/ingredient`)}
                >
                  <RadioButtonUnchecked
                    style={{ fontSize: "1rem", marginRight: 20 }}
                  />
                  <Typography>Ingredient</Typography>
                </div>
              </ListItem>
            </List>
          </SwipeableDrawer>
          <div style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: "5%" }} className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
              </Link>
              <Link color="inherit" href={`/${id}/menu-setup/ingredient`} style={{ fontSize: 15 }}>
                Ingredients
              </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Create
              </Typography>
            </Breadcrumbs>
            <Paper style={{ padding: "2rem", margin: "auto", width: "60%" }}>
              <Typography variant="h6">Create</Typography>
              <Divider style={{ margin: "10px 0 10px 0" }} />
              {/* <Input
          label="Ingredient Description"
          style={{ width: "100%", marginTop: "3%" }}
          multiline={true}
          row={3}
        /> */}
              <TextField label="Ingredient Name" variant="outlined" size="small" value={ingridentFromData.ingredient_name}
                style={{ width: '100%', marginBottom: '1.5rem' }} onChange={(e) => setIngridentFromData({ ...ingridentFromData, ingredient_name: e.target.value })} />

              <TextField variant="outlined" label="Ingridient Description"
                rows={3} multiline style={{ marginBottom: "1rem" }} fullWidth
                value={ingridentFromData.ingredient_description}
                onChange={(e) => {
                  setIngridentFromData({ ...ingridentFromData, ingredient_description: e.target.value})

                }} />
              {/* <div style={{
                border: '1px solid rgb(224, 224, 224)',
                minHeight: '12em', padding: '10px', borderRadius: '5px'
              }}>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) => {
                    setEditorState(e)
                    setIngridentFromData({ ...ingridentFromData, ingredient_description: draftToHtml(convertToRaw(editorState.getCurrentContent())) })
                  }} />
              </div> */}
              <Box style={{ width: '100%', marginTop: '1rem' }} >
                <Box border={1} borderColor="secondary" className={classes.center} style={{ width: "150px", height: "150px", margin: "auto" }}>
                  {ingridentFromData.ingredient_image ? <img src={ingridentFromData.ingredient_image}
                    alt="product" width="100%" height="100%"
                  /> :
                    <Typography variant="body1" color="textSecondary">
                      Size (400 px * 400 px)
                    </Typography>
                  }
                </Box>
                <Box className={classes.center}>
                  <input
                    accept="image/*" className={classes.input} id="contained-button-file" type="file"
                    onChange={e => {
                      if (ingridentFromData.ingredient_image) {
                        let public_id = ingridentFromData.ingredient_image.split("/")[8].split(".")[0];
                        fetchApi(deleteIngridientImage({ "image_id": `ingredient_image/${public_id}` }))
                      }
                      const file = e.target.files[0];
                      const formData = new FormData();
                      formData.append('ingredient_image', file)
                      fetchApi(addIngridientImage(formData))
                    }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="default"
                      className="button"
                      startIcon={<CloudUpload />}
                      component="span"
                    >
                      Upload Image
                    </Button>
                  </label>
                </Box>
              </Box>
            </Paper>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="center"
              flexDirection="column"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width={200}
                marginTop="15px"
                paddingBottom="5%"
              >
                <Button startIcon={<ChevronLeft />} className="button" onClick={() =>
                  history.push({
                    pathname: `/${state?.branch?.activeBranch?._id}/menu-setup/ingredient`,
                  })
                }>
                  back
                </Button>
                {
                  location.state ?
                    <Button className="button" color="secondary" startIcon={<Check />} onClick={() => fetchApi(updateIngridient(ingridentFromData))}>
                      Update
                    </Button> :
                    <Button className="button" startIcon={<Check />} onClick={() => fetchApi(postInGrientData(ingridentFromData))}>
                      Save
                    </Button>
                }
              </Box>
            </Box>
            <Snackbar open={state.ingridient.status}
              onClose={!state.ingridient.status}>
              <Alert onClose={!state.ingridient.status} severity={state.ingridient.status}>
                {state.ingridient.message}
              </Alert>
            </Snackbar>
          </div>
        </div>
      }</>);
};

const mapStateToProps = (state) => {
  return {
    state: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (dispatchAction) => dispatch(dispatchAction)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIngredient)
