import { FETCH_ALL_PAYMENT_GETWAY, UPDATE_PAYMENT_GETWAY, FETCH_TEXTLOCAL_SMS_CONFIGURE,FETCH_TWILIO_SMS_CONFIGURE, UPDATE_SMS_CONFIGURE, FETCH_GDPR, FETCH_EMAIL_CONFIGURE, UPDATE_EMAIL_CONFIGURE, FETCH_PRE_DELIVERY_DAY, UPDATE_GDPR, FETCH_ANALYTICS, UPDATE_ANALYTICS, FETCH_PROMOTIONAL_PAGE, UPDATE_PROMOTIONAL_PAGE, OFF_ALL_PAYMENT_GETWAY, FETCH_GOOGLE_MAP_API_KEY, UPDATE_GOOGLE_MAP_API_KEY, FETCH_SOCIAL_MEDIA_LOGIN, UPDATE_SOCIAL_MEDIA_LOGIN } from './AdvanceSetting.type'

export const paymentReducer = (state = [], action) => {
    switch(action.type){
        case FETCH_ALL_PAYMENT_GETWAY:
            return{
                ...state,
                paymentGetWay: action.payload
            }
        case UPDATE_PAYMENT_GETWAY:
            return{
                ...state,
                updatePayment: action.payload
            }
        case OFF_ALL_PAYMENT_GETWAY:
            return{
                ...state,
                offAllPaymentGetway: action.payload
            }
        case FETCH_TWILIO_SMS_CONFIGURE:
            return{
                ...state,
                twilioSms: action.payload
            }
        case FETCH_TEXTLOCAL_SMS_CONFIGURE:
            return{
                ...state,
                textLocal: action.payload
            }
        case UPDATE_SMS_CONFIGURE:
            return{
                ...state,
                updateSmsConfigure: action.payload
            }
        case FETCH_GDPR:
            return{
                ...state,
                gdpr: action.payload
            }
        case UPDATE_GDPR:
            return{
                ...state,
                updateGDPR: action.payload
            }
        case FETCH_EMAIL_CONFIGURE:
            return{
                ...state,
                emailConfigure: action.payload
            }
        case UPDATE_EMAIL_CONFIGURE:
            return{
                ...state,
                updateEmailConfigure: action.payload
            }
        case FETCH_PRE_DELIVERY_DAY:
            return{
                ...state,
                preDeliveryDay: action.payload
            }
        case FETCH_ANALYTICS:
            return {
                ...state,
                analytics: action.payload
            }
        case UPDATE_ANALYTICS:
            return{
                ...state,
                updateAnalytics: action.payload
            }
        case FETCH_PROMOTIONAL_PAGE:
            return {
                ...state,
                promotionalPage: action.payload
            }
        case UPDATE_PROMOTIONAL_PAGE:
            return{
                ...state,
                updatePromotionalPage: action.payload
            }
        case FETCH_GOOGLE_MAP_API_KEY:
            return{
                ...state,
                googeApiKey: action.payload
            }
        case UPDATE_GOOGLE_MAP_API_KEY:
            return{
                ...state,
                updateGoogleApiKey: action.payload
            }
        case FETCH_SOCIAL_MEDIA_LOGIN:
            return{
                ...state,
                socialMediaLogin: action.payload
            }
        case UPDATE_SOCIAL_MEDIA_LOGIN: 
            return {
                ...state,
                updateSocialMediaLogin: action.payload
            }
        default:
            return state
    }
}