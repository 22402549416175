import {  FETCH_INGRIDINENT_REQUEST,FETCH_INGRIENT_FALIOUR,FETCH_INGRIENT_SUCCESS,
    INGERIDIENT_DATA,INGRIDIENT_IMAGE,FETCH_INGRIENT_BY_ID_SUCCESS } from "./ingridientTypes";
import { fetchData,postData,updateData,deleteData, searchingIngredientByWord, ingredientbyStatus } from "../../Api/api";

export const fetchIngrientRequest = () => {
    return {
        type: FETCH_INGRIDINENT_REQUEST
    }
}

export const fetchIngrientSuccess = (data) => {
    return {
        type : FETCH_INGRIENT_SUCCESS,
        payload : data
    }
}

export const fetchIngridentFaliour = (data) => {
    return {
        type : FETCH_INGRIENT_FALIOUR, 
        payload : data
    }
}

export const ingridientData = (data) => {
    return {
        type : INGERIDIENT_DATA,
        payload : data
    }
}

export const ingridientImage = (data) => {
    return {
        type : INGRIDIENT_IMAGE,
        payload: data
    }
}

export const fetchIngrientByIdSuccess = (data) => {
    return {
        type: FETCH_INGRIENT_BY_ID_SUCCESS,
        payload: data
    }
}

export const getInGrientDataAll = (endpoint = `get_all_ingredient`) => {
    return (dispatch) => {
        dispatch(fetchIngrientRequest())
        fetchData(endpoint).then(
            response => dispatch(fetchIngrientSuccess(response.data))
        ).catch(
            error => dispatch(fetchIngridentFaliour(error.message))
        )
    }
}

export const getInGrientById = (endpoint) => {
    return (dispatch) => {
        dispatch(fetchIngrientRequest())
        fetchData(`/get_ingredient_by_id/${endpoint}`).then(
            response => dispatch(fetchIngrientByIdSuccess([response.data.response]))
        ).catch(
            error => dispatch(fetchIngridentFaliour(error.message))
        )
    }
}

export const postInGrientData = (body) => {
    return (dispatch) => {
        dispatch(fetchIngrientRequest())
        postData(`add_ingredient`,body).then(
            response => {
                dispatch(ingridientData(response.data))
                response.data.status === 'success' && dispatch(getInGrientDataAll(`get_all_ingredient?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchIngridentFaliour(error.message))
        )
    }
}

export const updateIngridient = (body) => {
    return (dispatch) => {
        dispatch(fetchIngrientRequest())
        updateData(`update_ingredient`,body).then(
            response => {
                dispatch(ingridientData(response.data))
                response.data.status === 'success' && dispatch(getInGrientDataAll(`get_all_ingredient?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchIngrientRequest(error.message))
        )
    }
}
export const deleteIngridient = (body) => {
    return (dispatch) => {
        dispatch(fetchIngrientRequest())
        deleteData(`delete_ingredient`,body).then(
            response => {
                dispatch(ingridientData(response.data))
                response.data.status === 'success' && dispatch(getInGrientDataAll(`get_all_ingredient?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchIngrientRequest(error.message))
        )
    }
}

export const addIngridientImage = (body) => {
    return (dispatch) => {
        dispatch(fetchIngrientRequest())
        postData(`add_ingredient_image`,body).then(
            response => dispatch(ingridientImage(response.data.image_url))
        ).catch(
            error => dispatch(fetchIngridentFaliour(error.message))
        )
    }
}

export const deleteIngridientImage = (body) => {
    return (dispatch) => {
        dispatch(fetchIngrientRequest())
        postData(`remove_image`,body).then(
            response => dispatch(ingridientImage(''))
        ).catch(
            error => dispatch(fetchIngridentFaliour(error.message))
        )
    }
}

export const searchIngredientByWord = (value) => dispatch => {
    try {
        dispatch(fetchIngrientRequest())
        searchingIngredientByWord(value).then(res => {
            dispatch(fetchIngrientSuccess(res.data))
        }).catch(er => {
            dispatch(fetchIngridentFaliour(er.message))
        })
    } catch (error) {
        dispatch(fetchIngridentFaliour(error.message))
    }
}

export const ingredientByStatus = (value) => dispatch => {
    try {
        dispatch(fetchIngrientRequest())
        ingredientbyStatus(value).then(res => {
            dispatch(fetchIngrientSuccess(res.data))
        }).catch(error => dispatch(fetchIngridentFaliour(error.message)))
    } catch (error) {
        dispatch(fetchIngridentFaliour(error.message))
    }
}