import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  FormControl,
  Link,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormGroup,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  IconButton,
  Snackbar,
  withStyles,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import {
  AddCircle,
  ChevronRight,
  Delete,
  Edit,
  FilterList,
  Help,
  Search,
  ExpandMore,
} from "@material-ui/icons";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

//Components
import { IOSSwitch } from "../../components/Buttons/IOSSwitch.component";
import { BootstrapSelectInput } from "../../components/Input/SelectInput.component";
import MuiAlert from "@material-ui/lab/Alert";
import {
  getAllCatagory,
  deleteSubCatagory,
  editSubCatagoryStatus,
  deleteMainCatagory,
  editMainCatagoryStatus,
  imageDelete,
  searchCategoryByWord,
  categoryByStatus,
} from "../../redux";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const CategoryPage = ({ catagoryData, fetchApi, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activity, setActivity] = useState("all");
  const [del, setDel] = useState("");
  const classes = useStyles();
  useEffect(() => {
    fetchApi(getAllCatagory());
    // return () => {
    //   cleanup
    // }
  }, [fetchApi]);

  return (
    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRight style={{ fontSize: 15 }} />}
      >
        <Link color="inherit" href="/" style={{ fontSize: 15 }}>
          Home
        </Link>
        <Typography
          color="textPrimary"
          style={{ fontSize: 15, color: "var(--orange)" }}
        >
          Category
        </Typography>
      </Breadcrumbs>
      <Paper style={{ padding: "2rem", marginTop: "5%" }}>
        <Typography variant="h6">Category</Typography>
        <Divider style={{ margin: "20px 0 20px 0" }} />
        <Typography
          variant="body2"
          style={{ display: "flex", alignItems: "center", marginBottom: 15 }}
          color="primary"
        >
          <Help fontSize="small" /> Create categories like Beverages, Desserts,
          Sides etc...
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            {/* <Button
              style={{ marginRight: 10 }}
              variant="contained"
              color="primary"
              className="button"
            >
              <FilterList />
            </Button> */}
            <Button
              startIcon={<AddCircle />}
              variant="contained"
              color="primary"
              className="button"
              onClick={() =>
                history.push({
                  pathname: `/${id}/menu-setup/create-category`,
                })
              }
            >
              Add category
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControl className={classes.margin}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={activity}
                onChange={(e) => {
                  setActivity(e.target.value);
                  if (e.target.value === "active") {
                    dispatch(categoryByStatus(true));
                  } else if (e.target.value === "inactive") {
                    dispatch(categoryByStatus(false));
                  } else {
                    dispatch(getAllCatagory());
                  }
                }}
                input={<BootstrapSelectInput />}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">InActive</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              color="primary"
              label="Search..."
              onChange={(e) => {
                if (e.target.value !== "") {
                  dispatch(searchCategoryByWord(e.target.value));
                } else {
                  dispatch(getAllCatagory());
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
                inputProps: { style: { padding: 10 } },
              }}
            />
          </Box>
        </Box>
        <Divider style={{ margin: "20px 0 20px 0" }} />
        <div>
          {catagoryData.loader ? <CircularProgress color="inherit" color="primary" />
           : catagoryData.error ? (
            <h2>error</h2>
          ) : (
            <>
              <Grid container style={{ padding: "16px" }}>
                <Grid item xs={1} style={{ fontWeight: "500" }}>
                  Sl.No
                </Grid>
                <Grid item xs={3} style={{ fontWeight: "500" }}>
                  Catagory Image
                </Grid>
                <Grid item xs={4} style={{ fontWeight: "500" }}>
                  Catagory Name
                </Grid>
                <Grid item xs={2} style={{ fontWeight: "500" }}>
                  Status
                </Grid>
                <Grid item xs={2} style={{ fontWeight: "500" }}>
                  Action
                </Grid>
              </Grid>
              {catagoryData?.data?.length > 0 ? (
                catagoryData?.data?.map((res, i) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={
                        res?.sub_category?.length > 0 ? (
                          <ExpandMore titleAccess="subcategory" />
                        ) : (
                          <div style={{ width: "24px", height: "24px" }}></div>
                        )
                      }
                      aria-label="Expand"
                      aria-controls="additional-actions2-content"
                      id="additional-actions2-header"
                    >
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={1}
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                        >
                          {i + 1}
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          onClick={(event) => event.stopPropagation()}
                        >
                          {res.category_image && (
                            <img
                              src={res.category_image}
                              alt="product"
                              width={100}
                              height={100}
                              style={{
                                objectFit: "fill",
                              }}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Typography>{res.category_name}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                          onClick={(event) => event.stopPropagation()}
                        >
                          <FormGroup style={{ width: "42px" }}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={res.status}
                                  onChange={() =>
                                    fetchApi(
                                      editMainCatagoryStatus({
                                        ...res,
                                        status: !res.status,
                                      })
                                    )
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{ marginTop: "auto", marginBottom: "auto" }}
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <IconButton title="Edit">
                              <Edit
                                className="edit"
                                style={{
                                  borderRadius: "50%",
                                  border: "1px solid grey",
                                }}
                                onClick={() =>
                                  history.push({
                                    pathname: `/${id}/menu-setup/create-category`,
                                    state: res,
                                  })
                                }
                              />
                            </IconButton>
                            <IconButton
                              title="Delete"
                              onClick={() => {
                                if (res.category_image) {
                                  let public_id = res.category_image
                                    .split("/")[8]
                                    .split(".")[0];
                                  fetchApi(
                                    imageDelete({
                                      image_id: `category_image/${public_id}`,
                                    })
                                  );
                                }
                                fetchApi(deleteMainCatagory({ _id: res._id }));
                              }}
                            >
                              <Delete
                                className="delete"
                                style={{
                                  borderRadius: "50%",
                                  border: "1px solid lightcoral",
                                }}
                              />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    {res?.sub_category?.length > 0 && (
                      <AccordionDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ fontWeight: 500 }}>
                                Sl.No
                              </TableCell>
                              <TableCell style={{ fontWeight: 500 }}>
                                CataGory Image
                              </TableCell>
                              <TableCell style={{ fontWeight: 500 }}>
                                Catagory Name
                              </TableCell>
                              <TableCell style={{ fontWeight: 500 }}>
                                Parent Catagory
                              </TableCell>
                              <TableCell style={{ fontWeight: 500 }}>
                                Status
                              </TableCell>
                              <TableCell style={{ fontWeight: 500 }}>
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {res.sub_category.map((sub_cat, j) => (
                            <TableRow>
                              <TableCell>{j + 1}</TableCell>
                              <TableCell>
                                {sub_cat.category_image && (
                                  <img
                                    src={sub_cat.category_image}
                                    alt="product"
                                    width={100}
                                    height={100}
                                    style={{
                                      objectFit: "fill",
                                      borderRadius: "50%",
                                      border: "1px solid grey",
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <Typography>{sub_cat.category_name}</Typography>
                              </TableCell>
                              <TableCell>{res.category_name}</TableCell>
                              <TableCell>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        checked={sub_cat.status}
                                        onChange={() =>
                                          fetchApi(
                                            editSubCatagoryStatus({
                                              ...sub_cat,
                                              status: !sub_cat.status,
                                            })
                                          )
                                        }
                                      />
                                    }
                                  />
                                </FormGroup>
                              </TableCell>
                              <TableCell>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <IconButton title="Edit">
                                    <Edit
                                      className="edit"
                                      style={{
                                        borderRadius: "50%",
                                        border: "1px solid grey",
                                      }}
                                      onClick={() =>
                                        history.push({
                                          pathname: `/${id}/menu-setup/create-category`,
                                          state: {
                                            ...sub_cat,
                                            parent_catagory: res.category_name,
                                          },
                                        })
                                      }
                                    />
                                  </IconButton>
                                  <IconButton
                                    title="Delete"
                                    onClick={() => {
                                      if (del.category_image) {
                                        let public_id = del.category_image
                                          .split("/")[8]
                                          .split(".")[0];
                                        fetchApi(
                                          imageDelete({
                                            image_id: `category_image/${public_id}`,
                                          })
                                        );
                                      }
                                      fetchApi(
                                        deleteSubCatagory({ _id: sub_cat._id })
                                      );
                                    }}
                                  >
                                    <Delete
                                      className="delete"
                                      style={{
                                        borderRadius: "50%",
                                        border: "1px solid lightcoral",
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </AccordionDetails>
                    )}
                  </Accordion>
                ))
              ) : (
                <Typography align="center">No More Category</Typography>
              )}
            </>
          )}
        </div>
      </Paper>
      <Snackbar open={catagoryData.status} onClose={!catagoryData.status}>
        <Alert onClose={!catagoryData.status} severity={catagoryData.status}>
          {catagoryData.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    catagoryData: state.catagory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (dispatchAction) => dispatch(dispatchAction),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
