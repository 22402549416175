import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import {
  Breadcrumbs,
  FormControl,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  FormControlLabel,
  withStyles,
  Divider,
  FormGroup,
  Switch,
  Button,
  IconButton,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  useMediaQuery,
  useTheme,
  TextField,
  Snackbar,
  CircularProgress,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { ChevronRight, MoreVert, Search } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircle";
import MuiAlert from "@material-ui/lab/Alert";

import {
  getUserDataAll,
  postUserData,
  updateUserData,
  deleteUserData,
  searchUsersByWord,
  userByStatus,
  getActiveUser
} from "../../redux";
import { CustomerAddress } from "./customerDetails/customerAddress";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "25ch",
    color: "var(--orange)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const UsersIndex = ({ userData, _handelApiCall }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    _handelApiCall(getUserDataAll());
    // return () => {
    //     cleanup
    // }
  }, [_handelApiCall]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState("All");
  const [order, setorder] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [userFormData, setuserFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    loyality_points: 0,
    subscribed_to_news_letter: false,
    phoneError: "",
    emailError: "",
  });
  console.log(userFormData.phoneError !== "" ? true : false);

  const status = userData.status === "success";

  useEffect(() => {
    setuserFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      loyality_points: 0,
      subscribed_to_news_letter: false,
    });
    // return () => {
    //     cleanup
    // }
    setOpen(false);
    setDialog(false);
  }, [status]);


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
    userData.status = "";
    setuserFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      loyality_points: 0,
      subscribed_to_news_letter: false,
    });
  };

  const _handelSubmit = () => {
    userFormData._id
      ? _handelApiCall(updateUserData(userFormData))
      : _handelApiCall(postUserData(userFormData));
  };

  return (
    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<ChevronRight style={{ fontSize: 15 }} />}
      >
        <Link color="inherit" href="/" style={{ fontSize: 15 }}>
          Home
        </Link>
        <Typography
          color="textPrimary"
          style={{ fontSize: 15, color: "var(--orange)" }}
        >
          Users
        </Typography>
      </Breadcrumbs>

      <Paper style={{ padding: "2rem", margin: "15px auto" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            paddingBottom: "10px",
          }}
        >
          <Typography variant="h6">Users</Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            padding: "20px 0",
          }}
        >
          <Button
            variant="contained"
            className="button"
            startIcon={<AddIcon />}
            onClick={() => setOpen(true)}
          >
            Add User
          </Button>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {" "}
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight style={{ fontSize: 15 }} />}
              >
                <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                  Home
                </Link>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: 15 }}
                  onClick={() => setOpen(false)}
                >
                  Users
                </Typography>
                <Typography
                  color="textPrimary"
                  style={{ fontSize: 15, color: "var(--orange)" }}
                >
                  Create User
                </Typography>
              </Breadcrumbs>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    label="First Name"
                    variant="outlined"
                    size="small"
                    defaultValue={userFormData.first_name}
                    onChange={(e) =>
                      setuserFormData({
                        ...userFormData,
                        first_name: e.target.value,
                      })
                    }
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    size="small"
                    defaultValue={userFormData.last_name}
                    onChange={(e) =>
                      setuserFormData({
                        ...userFormData,
                        last_name: e.target.value,
                      })
                    }
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    defaultValue={userFormData.email}
                    onChange={(e) =>
                      setuserFormData({
                        ...userFormData,
                        email: e.target.value,
                      })
                    }
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />
                  <TextField
                    label="Username"
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />
                  <TextField
                    error={userFormData.phoneError !== "" ? true : false}
                    label="Mobile Number"
                    variant="outlined"
                    helperText={userFormData.phoneError}
                    size="small"
                    defaultValue={userFormData.phone_number}
                    onChange={(e) => {
                      if (!e.target.value.match(/^[A-Za-z]+$/)) {
                        setuserFormData({
                          ...userFormData,
                          phone_number: e.target.value,
                          phoneError: "can't enter alphabet in this field!",
                        });
                      } else {
                        setuserFormData({
                          ...userFormData,
                          phoneError: "can't enter alphabet in this field!",
                        });
                      }
                    }}
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />
                  <TextField
                    label="Loyalty Points"
                    variant="outlined"
                    size="small"
                    defaultValue={userFormData.loyality_points}
                    onChange={(e) =>
                      setuserFormData({
                        ...userFormData,
                        loyality_points: e.target.value,
                      })
                    }
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />
                </form>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={_handelSubmit}
                color="secondary"
                variant="contained"
              >
                {userFormData._id ? "Update" : "Submit"}
              </Button>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControl
              size="small"
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={stage}
                onChange={(e) => {
                  setStage(e.target.value);
                  if (e.target.value === "InActive") {
                    dispatch(userByStatus(false));
                  } else if (e.target.value === "Active") {
                    dispatch(userByStatus(true));
                  } else {
                    dispatch(getUserDataAll());
                  }
                }}
                className={classes.selectEmpty}
                size="small"
              >
                <MenuItem value="All">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="InActive">InActive</MenuItem>
              </Select>
            </FormControl>
            <TextField
              size="small"
              label="search..."
              onChange={(e) => {
                if (e.target.value !== "") {
                  dispatch(searchUsersByWord(e.target.value));
                } else {
                  dispatch(getUserDataAll());
                }
              }}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sl.No</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell colSpan={3}>Action</TableCell>
            </TableRow>
          </TableHead>
          {userData?.loader ? (
            <CircularProgress />
          ) : userData?.error ? (
            <h2>error</h2>
          ) : (
            <TableBody>
              {userData?.userList?.length > 0 ? (
                userData?.userList?.map((res, i) => (
                  <TableRow key={res._id}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{res.phone_number}</TableCell>
                    <TableCell>{res.first_name}</TableCell>
                    <TableCell>{res.last_name}</TableCell>
                    <TableCell>{res.email}</TableCell>
                    <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <IOSSwitch
                              checked={res.status}
                              onChange={(e) =>
                                _handelApiCall(
                                  updateUserData({
                                    _id: res._id,
                                    status: e.target.checked,
                                  })
                                )
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      {/* <Button size="small" variant="outlined" size="small" color="secondary" className={classes.margin}>
                                            Small
                                        </Button> */}
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setuserFormData(res);
                            setOpen(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="delete"
                          color="secondary"
                          onClick={() => {
                            setDialog(true);
                            setuserFormData(res);
                            userData.status = "";
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Order">
                        <IconButton
                          onClick={() => {
                            dispatch(getActiveUser(`get_all_orders/${res._id}`))
                            setorder(true)
                            setorder(res)
                            }}>
                          <MoreVert />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Typography align="center">No More user</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </Paper>
      <Dialog
        open={dialog}
        keepMounted
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are You Surely Want to delete {userFormData.first_name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() =>
              _handelApiCall(deleteUserData({ _id: userFormData._id }))
            }
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              setDialog(false);
              setuserFormData({
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                loyality_points: 0,
                subscribed_to_news_letter: false,
              });
            }}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={userData.status} onClose={userData.status}>
        <Alert onClose={!userData.status} severity={userData.status}>
          {userData.message}
        </Alert>
      </Snackbar>

      <Dialog maxWidth="lg" fullWidth="lg"
        fullScreen={fullScreen}
        open={order}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {" "}
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRight style={{ fontSize: 15 }} />}
          >
            <Link color="inherit" href="/" style={{ fontSize: 15 }}>
              Home
            </Link>
            <Typography
              color="textSecondary"
              style={{ fontSize: 15 }}
              onClick={() => setorder(false)}
            >
              Users
            </Typography>
            <Typography
              color="textPrimary"
              style={{ fontSize: 15, color: "var(--orange)" }}
            >
              User Details
            </Typography>
          </Breadcrumbs>
        </DialogTitle>
        <DialogContent>
          <CustomerAddress order={order} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setorder(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    _handelApiCall: (action) => dispatch(action),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersIndex);
