import React, { useState, useEffect } from "react";
import {
  IconButton,
  List,
  ListItem,
  makeStyles,
  SwipeableDrawer,
  Typography,
  colors,
  Breadcrumbs,
  Paper,
  Divider,
  Button,
  Box,
  Modal,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Badge,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import {
  ChevronLeft,
  ChevronRight,
  RadioButtonUnchecked,
  Close,
  Help,
  Delete,
  Edit,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllCustomerGroup,
  createCustomerGroup,
  deleteCustomerGroup,
  updateCustomerGroup,
} from "../../redux/CustomerGroup/CustomerGroup.action";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
    // marginTop: 65,
    // zIndex: 200
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
    // marginTop: 65,
    // zIndex: 200
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const CustomerGroup = ({ match }) => {
  const dispatch = useDispatch();
  const customerGroups = useSelector(
    (state) => state?.customerGroup?.customerGroupList?.response
  );
  const history = useHistory();
  const classes = useStyles();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [selectState, 
    // setselectState
  ] = useState("customer-group");
  const [customer_group_name, setCustomer_group_name] = useState("");
  const [editGroup, seteditGroup] = useState(false);
  const [userListModal, setUserListModal] = useState(false);
  const [customerGroupDetails, setcustomerGroupDetails] = useState();
  const [del, setDel] = useState("");
  const id = match.params.id;

  useEffect(() => {
    dispatch(getAllCustomerGroup());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      if (editGroup) {
        const variables = {
          customer_group_name,
          customer_group_customers:
            customerGroupDetails?.customer_group_customers,
          _id: customerGroupDetails?._id,
        };

        dispatch(updateCustomerGroup(variables));
        setCustomer_group_name("");
        setCreateGroupModal(false);
        seteditGroup(false);
      } else {
        const variabes = {
          customer_group_name,
          customer_group_offer_id: null,
        };

        dispatch(createCustomerGroup(variabes));
        setCustomer_group_name("");
        setCreateGroupModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCustomerGroup = (id) => {
    try {
      dispatch(deleteCustomerGroup(id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <Modal
        open={userListModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          setUserListModal(false);
          setcustomerGroupDetails();
        }}
      >
        <div style={{ width: "50%", background: "#ffffff", padding: "1rem" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Users List</Typography>
            <IconButton
              onClick={() => {
                setUserListModal(false);
                setcustomerGroupDetails();
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider style={{ marginTop: "2rem" }} />
          <Table>
            <TableBody>
              {customerGroupDetails?.customer_group_customers?.map((item) => (
                <TableRow>
                  <TableCell>{item.phone_number}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Modal>
      <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Users
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "user" ? "var(--orange)" : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/${id}/users-basic`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Users</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Newsletter Subscribers" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/news-sub`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Newsletter Subscribers</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Customer feedback" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/customer-feedback`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Customer feedback</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Customer Testimonial" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/customer-testimonial`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Customer Testimonial</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Rating" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/users-basic/rating`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Rating</Typography>
            </div>
          </ListItem> */}
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "customer-group"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/${id}/users-basic/customer-group`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Customer Group</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div
        style={{ paddingLeft: 20, paddingRight: 20 }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            Customer Group
          </Typography>
        </Breadcrumbs>
        <Paper style={{ padding: "2rem", margin: "15px auto" }}>
          <Typography variant="h5">Customer Group</Typography>
          <Divider style={{ margin: "1rem 0" }} />
          <Button className="button" onClick={() => setCreateGroupModal(true)}>
            Create a Customer Group
          </Button>
          {customerGroups?.length > 0 ? (
            <Box marginTop="2rem">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl.no</TableCell>
                      <TableCell>Customer Group name</TableCell>
                      <TableCell>Add</TableCell>
                      <TableCell>Veiw</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerGroups?.map((group, index) => (
                      <TableRow>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{group.customer_group_name}</TableCell>
                        <TableCell>
                          <Button
                            className="button"
                            onClick={() =>
                              history.push({
                                pathname: `/${id}/users-basic/customer-list`,
                                state: {
                                  customerGroup:
                                    group.customer_group_customers.map(
                                      (item) => item._id
                                    ),
                                  groupId: group._id,
                                  type: "add",
                                },
                              })
                            }
                          >
                            add customer
                          </Button>
                          {group.customer_group_customers.length > 0 && (
                            <Button
                              style={{ marginLeft: ".5rem" }}
                              className="button"
                              onClick={() =>
                                history.push({
                                  pathname: `/${id}/users-basic/customer-list`,
                                  state: {
                                    customerGroup:
                                      group.customer_group_customers.map(
                                        (item) => item._id
                                      ),
                                    groupId: group._id,
                                    type: "remove",
                                  },
                                })
                              }
                            >
                              remove customer
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          {group.customer_group_customers.length > 0 ? (
                            <Badge
                              badgeContent={`${
                                group.customer_group_customers.length - 1
                              } more`}
                              invisible={
                                group.customer_group_customers.length === 1
                                  ? true
                                  : false
                              }
                              color="primary"
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  setcustomerGroupDetails(group);
                                  setUserListModal(true);
                                }}
                              >
                                {
                                  group?.customer_group_customers[0]
                                    ?.phone_number
                                }
                              </Button>
                            </Badge>
                          ) : (
                            "No Customer Available"
                          )}{" "}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                setDel(group._id);
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => {
                                seteditGroup(true);
                                setcustomerGroupDetails(group);
                                setCustomer_group_name(
                                  group.customer_group_name
                                );
                                setCreateGroupModal(true);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Box
              padding="2rem"
              bgcolor={colors.grey[300]}
              marginTop="1rem"
              borderRadius="20px"
            >
              <Typography align="center" variant="body1">
                no groups
              </Typography>
            </Box>
          )}
        </Paper>
        <Modal
          open={createGroupModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            width="70%"
            bgcolor="#ffffff"
            style={{ outline: "none", borderRadius: "20px" }}
          >
            <Box
              padding="1rem"
              bgcolor={colors.orange[50]}
              style={{ borderRadius: "20px" }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography color="textSecondary" variant="h6">
                Create a Customer Group
              </Typography>
              <IconButton onClick={() => setCreateGroupModal(false)}>
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box padding="1rem">
                <Paper style={{ padding: "1rem" }}>
                  <Typography variant="h6" color="textSecondary">
                    Group Details
                  </Typography>
                  <TextField
                    value={customer_group_name}
                    onChange={(e) => setCustomer_group_name(e.target.value)}
                    fullWidth
                    size="small"
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">Group Name</Typography>{" "}
                        <Help />{" "}
                      </Box>
                    }
                    variant="outlined"
                    style={{ marginTop: "1rem" }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Such as "retail" or "Wholesale".Group name is not visible to
                    customers in store.
                  </Typography>
                </Paper>
                <Paper style={{ padding: "1rem", marginTop: "2rem" }}>
                  <Typography variant="h6" color="textSecondary">
                    Category Level Details
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginTop: "1rem" }}
                  >
                    <Typography variant="body2">
                      No category lavel discounts have been created for this
                      group.
                    </Typography>
                    <a href="/">Create one now</a>
                  </Box>
                </Paper>
                <Paper style={{ padding: "1rem", marginTop: "2rem" }}>
                  <Typography variant="h6" color="textSecondary">
                    Product Level Details
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginTop: "1rem" }}
                  >
                    <Typography variant="body2">
                      No Product lavel discounts have been created for this
                      group.
                    </Typography>
                    <a href="/">Create one now</a>
                  </Box>
                </Paper>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  marginTop="2rem"
                >
                  <Button
                    className="edit"
                    style={{ marginRight: "1rem" }}
                    onClick={() => setCreateGroupModal(false)}
                  >
                    cancel
                  </Button>
                  <Button className="button" type="submit">
                    save
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Modal>
        <Dialog
          open={del}
          onClose={() => setDel("")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              DO YOU REALLY WANT TO DELETE
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDel("")} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setDel("");
                handleDeleteCustomerGroup(del);
              }}
              color="primary"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
