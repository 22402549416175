import {GET_ORDER_DATA_BY_ID, HANDLE_ORDER_SUCCESS, HANDLE_ORDER_FALIOUR, HANDLE_ORDER_FORM_DATA, HANDLE_ORDER_REQUEST,GET_ORDER_DATA_BY_NAME} from "./orderTypes";
import { fetchData, postData, updateData, orderbyBookingType } from "../../Api/api";

export const fetchOrderRequest = () => {
    return {
        type: HANDLE_ORDER_REQUEST
    }
}

export const fetchOrderSucess = (data) => {
    return {
        type: HANDLE_ORDER_SUCCESS,
        payload: data
    }
}

export const fetchOrderSucessById = (data) => {
    return {
        type: GET_ORDER_DATA_BY_ID,
        payload: data
    }
}

export const fetchOrderSucessByName = (data) => {
    return {
        type: GET_ORDER_DATA_BY_NAME,
        payload: data
    }
}

export const fetchOrderFaliour = (data) => {
    return {
        type: HANDLE_ORDER_FALIOUR,
        payload: data
    }
}

export const handleOrderData = (data) => {
    return {
        type: HANDLE_ORDER_FORM_DATA,
        payload: data
    }
}

export const getAllOrder = (endpoint = `get_all_orders?page=1&limit=10`) => {
    return (dispatch) => {
        dispatch(fetchOrderRequest())
        fetchData(endpoint).then(
            response => dispatch(fetchOrderSucess(response.data))
        ).catch(
            error => dispatch(fetchOrderFaliour(error.message))
        )
    }
}

export const getOrderbyName = (endpoint = `get_all_orders?page=1&limit=10`) => {
    return (dispatch) => {
        dispatch(fetchOrderRequest())
        fetchData(endpoint).then(
            response => dispatch(fetchOrderSucessByName(response.data))
        ).catch(
            error => dispatch(fetchOrderFaliour(error.message))
        )
    }
}

export const getOrderById = (id) => {
    return (dispatch) => {
        dispatch(fetchOrderRequest())
        fetchData(`get_Order/${id}`).then(
            response => dispatch(fetchOrderSucessById(response.data.response))
        ).catch(
            error => dispatch(fetchOrderFaliour(error.message))
        )
    }
}

export const postOrder = (body) => {
    return (dispatch) => {
        dispatch(fetchOrderRequest())
        postData(`add_Order`, body).then(
            response => {
                dispatch(handleOrderData(response.data))
                response.data.status === 'success' && dispatch(getAllOrder(`get_all_orders?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchOrderFaliour(error.message))
        )
    }
}

export const updateOrder = (body) => {
    return (dispatch) => {
        dispatch(fetchOrderRequest())
        updateData(`update_order`, body).then(
            response => {
                dispatch(handleOrderData(response.data))
                response.data.status === 'success' && dispatch(getAllOrder(`get_all_orders?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchOrderFaliour(error.message))
        )
    }
}

export const updateOrderStatus = (body) => {
    return (dispatch) => {
        dispatch(fetchOrderRequest())
        postData(`update_order_status`, body).then(
            response => {
                dispatch(handleOrderData(response.data))
                response.data.status === 'success' && dispatch(getAllOrder(`get_all_orders?page=1&limit=10`))
            }
        ).catch(
            error => dispatch(fetchOrderFaliour(error.message))
        )
    }
}

export const orderByBookingType = (value, page) => dispatch => {
    try {
        dispatch(fetchOrderRequest())
        orderbyBookingType(value, page).then(res => {
            dispatch(fetchOrderSucess(res.data))
        }).catch(error => dispatch(fetchOrderFaliour(error.message)))
    } catch (error) {
        dispatch(fetchOrderFaliour(error.message))
    }
}