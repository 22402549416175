import {
  Paper,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  makeStyles,
  List,
  SwipeableDrawer,
  IconButton,
  colors,
  Box,
  Breadcrumbs, Button, Link,
  TextField, Typography, Dialog, DialogContent, DialogActions, DialogTitle,
  Snackbar, ListItem,
  CircularProgress, FormControl, FormControlLabel, RadioGroup, Radio
} from "@material-ui/core";

import { ChevronRight, Autorenew, Search, Edit, Delete, AddCircle, CloudUpload, ChevronLeft, RadioButtonUnchecked } from '@material-ui/icons'
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from 'react-router-dom'

//Components
// import { Image } from "../../components/CustomImage/Image.component";
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import "./Banner.css";
import { Autocomplete } from '@material-ui/lab'

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';

import { useDispatch, useSelector } from 'react-redux'
import {
  postbannerData, getItemDataAll, getAllCatagory,
  getItemDataAllForAddons, deletefetureFormImage, updatebannerForm,
  getfetureByApplyToId, deletebannerForm, postfetureItem, getbannerByApplyToId, addbannerFormImage
} from '../../redux'
import AddFetureProduct from './Feture/AddFetureProduct';
import { IOSSwitch } from '../../components/Buttons/IOSSwitch.component';
import { SwipeDrawer } from "./swipeDrawer";

let allfeture = [{ status: true, _id: "12345", category_name: "Home" }]
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   index.feture_list = []
//   return {
//     id: `scrollable-force-tab-${index}`,
//     'aria-controls': `scrollable-force-tabpanel-${index}`,
//   };
// }

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  image: {
    width: "100%", height: "150px", margin: "auto", border: "1px solid black", marginBottom: "1rem",
    display: "flex", justifyContent: "center", alignItems: "center", padding: "0.5rem"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "800px",
    position: "relative"
  },
  formControl: {
    marginBottom: "1rem"
  },
  margin: {
    marginBottom: "1.5rem"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: theme.spacing(16),
    backgroundColor: "#f5f5f5"
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  marginRight: {
    marginRight: "20px"
  }
}));

export const BannerImageSlide = ({ match }) => {
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("banner");
  const classes = useStyles()
  const history = useHistory()
  const id = match.params.id
  const [value, setValue] = React.useState(0);
  const [addfeture, setaddfeture] = useState(false)
  const [addfetureItem, setaddfetureItem] = useState(false)
  const [viewFeture, setviewFeture] = useState({})
  const state = useSelector(state => state)
  const dispatch = useDispatch()

  const [formValue, setformValue] = useState({
    branch_id: '',
    is_banner: true,
    is_featured_item: false,
    applied_to_page: {},
    applied_to_page_id: "12345",
    applied_to_item_id: null,
    apply_on_item: {},
    applied_to_category: {},
    applied_to_main_category_id: null,
    banner_image: null,
    applied_featured_item_id: null,
    apply_on: 'catagory',
    applied_featured_item: {}
  })

  useEffect(() => {
    dispatch(getAllCatagory())
    dispatch(getbannerByApplyToId(`get_page_design_by_page_id/12345`))
    dispatch(getItemDataAll(`get_all_item`))
  }, [dispatch])

  useEffect(() => {
    if (state?.catagory?.data) {
      const allcat = [...allfeture]
      state?.catagory?.data.map(res => {
        allcat.push(res)
        res.sub_category.map(sub_cat => allcat.push(sub_cat))
      })
      allfeture = allcat
    }
    console.log(allfeture)
  }, [state?.catagory?.data])

  useEffect(() => {
    state?.login?.data?.adminstrator_branch_id && setformValue({ ...formValue, branch_id: state?.login?.data?.adminstrator_branch_id })
  }, [state?.login?.data?.adminstrator_branch_id])

  useEffect(() => {
    state?.banner?.status === 'success' && handleClose()
  }, [state?.banner?.status])

  useEffect(() => {
    state?.banner?.image_link && setformValue({ ...formValue, banner_image: state?.banner?.image_link })
  }, [state?.banner?.image_link])


  // useEffect(() => {
  //     state?.catagory?.data?.length > 0 && 
  //     dispatch(getfetureDataAll([...allfeture, ...state?.catagory?.data]))
  // }, [state?.catagory?.data])

  // useEffect(() => {
  //     effect
  //     return () => {
  //         cleanup
  //     }
  // }, [input])

  const handleClickaddBanner = (value = formValue) => {
    console.log(value)
    setaddfeture(true);
    setformValue(value)
  };

  const handleClose = () => {
    setformValue({
      ...formValue,
      _id: '',
      branch_id: '',
      applied_to_page: {},
      applied_to_page_id: '12345',
      is_banner: true,
      is_featured_item: false,
      applied_to_item_id: null,
      apply_on_item: {},
      applied_to_category: {},
      applied_to_main_category_id: null,
      banner_image: null,
      applied_featured_item: {},
      applied_featured_item_id: null,
      apply_on: 'catagory',
    })
    setaddfeture(false);
  };

  const handleChange = (event, newValue) => {
    // console.log(newValue)
    dispatch(getbannerByApplyToId(`get_page_design_by_page_id/${allfeture[newValue]._id}?limit=10&page=1`))
    setformValue({ ...formValue, applied_to_page_id: allfeture[newValue]._id, applied_to_page: allfeture[newValue] })
    setValue(newValue);
  };

  const handleApply = (e) => {
    if (formValue.is_banner) {
      switch (e.target.value) {
        case 'catagory':
          setformValue({
            ...formValue, apply_on: e.target.value, apply_on_item: {}, applied_to_item_id: null,
            applied_featured_item: {}, applied_featured_item_id: null
          })
        case 'feture':
          setformValue({
            ...formValue, apply_on: e.target.value, apply_on_item: {},
            applied_to_item_id: null, applied_to_category: {}, applied_to_main_category_id: null
          })
        case 'item':
          setformValue({
            ...formValue, apply_on: e.target.value, applied_featured_item: {}, applied_featured_item_id: null,
            applied_to_category: {}, applied_to_main_category_id: null
          })
        default:
          setformValue({
            ...formValue, apply_on: e.target.value, applied_featured_item: {}, applied_featured_item_id: null,
            applied_to_category: {}, applied_to_main_category_id: null, apply_on_item: {}, applied_to_item_id: null,
          })
      }
    }
  }

  const formValueChange = () => {
    setformValue({
      ...formValue,
      is_banner: formValue.is_featured_item ? true : false,
      is_featured_item: formValue.is_banner ? true : false,
    })
  }

  const selectBannerApplyId = (e, newValue, val, val2) => {
    if (newValue) {
      setformValue({ ...formValue, [val]: newValue, [val2]: newValue._id })
      if (val === 'applied_to_page') {
        setformValue({
          ...formValue, [val]: newValue, [val2]: newValue._id,
          applied_featured_item: {}, applied_featured_item_id: null, apply_on_item: {}, applied_to_item_id: null
        })
      }
    } else {
      setformValue({ ...formValue, [val]: {}, [val2]: null })
    }
  }

  useEffect(() => {
    if (formValue.applied_to_page_id) {
      dispatch(getfetureByApplyToId(`/featured_item_list/${state?.login?.data?.adminstrator_branch_id}/${formValue.applied_to_page_id}`))
      if (formValue.applied_to_page_id === '12345') {
        dispatch(getItemDataAllForAddons(`get_all_item`))
      } else {
        dispatch(getItemDataAllForAddons(`item_list_by_category_any_cat_id/${formValue.applied_to_page_id}`))
      }
    }
  }, [formValue.applied_to_page_id])


  useEffect(() => {
    if (formValue.is_featured_item) {
      setformValue(
        {
          ...formValue, banner_image: null,
          applied_to_item_id: null,
          applied_to_main_category_id: null
        })
    }
  }, [formValue.is_featured_item])


  const addItemToFeture = (featured_id, items) => {
    items.map(res => {
      dispatch(postfetureItem(`add_item_to_feature_item`, {
        featured_item_id: featured_id,
        item_id: res
      }))
    })
  }

  const removeItemToFeture = (featured_id, items) => {
    items.map(res => {
      dispatch(postfetureItem(`remove_item_from_feature_item`, {
        featured_item_id: featured_id,
        item_id: res
      }))
    })
  }

  const closeAddItem = (condition) => setaddfetureItem(false)

  function itemRenderer(item, index) {
    return (
      <List className={classes.list} key={item._id}>
        {item.is_featured_item ?
          <Grid container spacing={2}>
            <Grid item sm={2} alignItems='flex-start'>
              <Typography variant="overline" style={{ color: "red" }} display="block" gutterBottom>
                feture As Banner
              </Typography>
              <Box className={classes.image}>
                {
                  item.applied_featured_item_id.featured_item_image ?
                    <img src={item.applied_featured_item_id.featured_item_image} width="100%" height="100%"
                      alt="feture"></img> :
                    <Typography variant="button" color="textSecondary">
                      Images Not Avaliable
                    </Typography>
                }
              </Box>
            </Grid>
            <Grid item sm={3} alignItems='center' style={{ display: 'flex' }} >
              <Box>
                <Typography variant='h6' >{item.applied_featured_item_id.featured_item_name}</Typography>
                <Typography variant='button' >{item.applied_featured_item_id.featured_item_tag}</Typography>
              </Box>
            </Grid>
            <Grid item sm={4} alignItems='center' style={{ display: 'flex' }} >
              <Button variant="contained" size="small" color="primary"
                className={classes.margin} onClick={() => {
                  setaddfetureItem(true)
                  setviewFeture(item.applied_featured_item_id)
                }}>
                {item?.applied_featured_item_id?.featured_item_item_id?.length} products avaliale in this feture
              </Button>
            </Grid>
            <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
              <IOSSwitch checked={item.status} onChange={() =>
                dispatch(updatebannerForm({ _id: item._id, status: !item.status }))} />
            </Grid>
            <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
              <IconButton onClick={() => handleClickaddBanner({
                ...item,
                applied_to_page: allfeture[value],
                applied_featured_item: item.applied_featured_item_id,
                applied_featured_item_id: item.applied_featured_item_id._id
              })}>
                <Edit />
              </IconButton>
            </Grid>
            <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
              <IconButton onClick={() => dispatch(deletebannerForm(item))}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid> :
          <Grid container spacing={2}>
            <Grid item sm={2} alignItems='flex-start' >
              <Typography variant="overline" style={{ color: "red" }} display="block" gutterBottom>
                Single Image Banner
              </Typography>
              <Box className={classes.image}>
                <img src={item.banner_image} width="100%" height="100%"
                  alt="feture"></img>
              </Box>
            </Grid>
            <Grid item sm={2} alignItems='center' style={{ display: 'flex' }} >
              <Box>
                <Typography variant='h6' >Applied On :</Typography>
              </Box>
            </Grid>
            <Grid item sm={5} alignItems='center' style={{ display: 'flex' }} >
              {
                item?.applied_to_main_category_id &&
                <Typography variant='button' >{(allfeture.find(cat =>
                  cat._id === item?.applied_to_main_category_id)).category_name}</Typography>
              }
              <Typography variant='button' >{item?.applied_featured_item_id?.featured_item_name}</Typography>
            </Grid>
            <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
              <IOSSwitch checked={item.status} onChange={() =>
                dispatch(updatebannerForm({ _id: item._id, status: !item.status }))} />
            </Grid>
            <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
              <IconButton onClick={() => {
                handleClickaddBanner({
                  ...item,
                  applied_to_page: allfeture[value],
                  apply_on: item.applied_to_main_category_id ? 'catagory' :
                    item.applied_to_item_id ? 'item' : 'feture',
                  applied_to_category: item.applied_to_main_category_id ?
                    allfeture.find(cat => item.applied_to_main_category_id &&
                      cat._id === item.applied_to_main_category_id) : {},
                  applied_featured_item: item.applied_featured_item_id ? item.applied_featured_item_id : {},
                  applied_featured_item_id: item.applied_featured_item_id ?
                    item.applied_featured_item_id._id : null,
                  apply_on_item: item?.applied_to_item_id ?
                    state?.item?.data?.find(item =>
                      item._id === item?.applied_to_item_id) : {}
                })
                console.log(item)
              }}>
                <Edit />
              </IconButton>
            </Grid>
            <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
              <IconButton onClick={() => dispatch(deletebannerForm(item))}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        }
      </List>
    );
  }

  function handleRLDDChange(reorderedItems) {
    // console.log(reorderedItems)
    // setdragItems(reorderedItems)
  }

  return (
    <>
      {
        !state?.branch?.loader &&
        <div style={{
          width: "100%",
          height: "100vh",
          background: colors.grey[200],
          position: "relative",
        }} className={classes.root} >
          <div
            style={{ position: "fixed", top: "0%", right: "63%", zIndex: 1100 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            style={{ marginTop: "10px" }} open={drawerOpen}
            o style={{ marginTop: "10px" }} nClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Web And App Builder              
              </Typography>
            </div>
            <SwipeDrawer />
          </SwipeableDrawer>
          {
            state?.catagory?.loader ? <CircularProgress /> :
              <div className={clsx(classes.content, {
                [classes.contentShift]: drawerOpen,
              })} style={{ paddingLeft: 20, paddingRight: 20, position: "relative" }} >

                <Box style={{ overflow: 1, width: drawerOpen ? `calc(100vw - ${drawerWidth + 150}px)` : `calc(100vw - ${150}px)` }}>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<ChevronRight style={{ fontSize: 15 }} />}
                  >
                    <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                      Home
                    </Link>
                    <Typography
                      color="textPrimary"
                      style={{ fontSize: 15, color: "var(--orange)" }}
                    >
                      Banner
                    </Typography>
                  </Breadcrumbs>
                  <Paper style={{ marginTop: '15px', padding: "15px", }}>

                    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="1.5em">
                      <Typography variant='h5' >All Pages Banner</Typography>

                      <Button
                        startIcon={<AddCircle />}
                        variant="contained"
                        color="primary"
                        className="button"
                        onClick={() => handleClickaddBanner()}
                      >
                        Create Banner
                      </Button>
                    </Box>
                    <AppBar position="static" color="default">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="scrollable force tabs example"
                      >
                        {
                          allfeture?.map(res => <Tab label={res.category_name}
                          // {...a11yProps(res)} 
                          />)
                        }

                      </Tabs>
                    </AppBar>
                    <TabPanel>
                      {
                        state?.banner?.loading ? <CircularProgress></CircularProgress> :
                          state?.banner?.data?.length > 0 ?
                            // <RLDD className="example"
                            // items={state?.banner?.data}
                            // itemRenderer={() => itemRenderer(state?.banner?.data,)}
                            // onChange={handleRLDDChange}
                            // />
                            state?.banner?.data?.map(res =>
                              <List className={classes.list}>
                                {res.is_featured_item ?
                                  <Grid container spacing={2}>
                                    <Grid item sm={2} alignItems='flex-start'>
                                      <Typography variant="overline" style={{ color: "red" }} display="block" gutterBottom>
                                        feture As Banner
                                      </Typography>
                                      <Box className={classes.image}>
                                        {
                                          res.applied_featured_item_id.featured_item_image ?
                                            <img src={res.applied_featured_item_id.featured_item_image} width="100%" height="100%"
                                              alt="feture"></img> :
                                            <Typography variant="button" color="textSecondary">
                                              Images Not Avaliable
                                            </Typography>
                                        }
                                      </Box>
                                    </Grid>
                                    <Grid item sm={3} alignItems='center' style={{ display: 'flex' }} >
                                      <Box>
                                        <Typography variant='h6' >{res.applied_featured_item_id.featured_item_name}</Typography>
                                        <Typography variant='button' >{res.applied_featured_item_id.featured_item_tag}</Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item sm={4} alignItems='center' style={{ display: 'flex' }} >
                                      <Button variant="contained" size="small" color="primary"
                                        className={classes.margin} onClick={() => {
                                          setaddfetureItem(true)
                                          setviewFeture(res.applied_featured_item_id)
                                        }}>
                                        {res?.applied_featured_item_id?.featured_item_item_id?.length} products avaliale in this feture
                                      </Button>
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
                                      <IOSSwitch checked={res.status} onChange={() =>
                                        dispatch(updatebannerForm({ _id: res._id, status: !res.status }))} />
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
                                      <IconButton onClick={() => handleClickaddBanner({
                                        ...res,
                                        applied_to_page: allfeture[value],
                                        applied_featured_item: res.applied_featured_item_id,
                                        applied_featured_item_id: res.applied_featured_item_id._id,
                                        apply_on: res.applied_to_main_category_id ? 'catagory' :
                                          res.applied_to_item_id ? 'item' : 'feture',
                                        applied_to_category: res.applied_to_main_category_id ?
                                          allfeture.find(cat => res.applied_to_main_category_id &&
                                            cat._id === res.applied_to_main_category_id) : {},
                                        applied_to_main_category_id: res.applied_to_main_category_id ? res.applied_to_main_category_id._id : null,
                                        applied_featured_item: res.applied_featured_item_id ? res.applied_featured_item_id : {},
                                        applied_featured_item_id: res.applied_featured_item_id ?
                                          res.applied_featured_item_id._id : null,
                                        apply_on_item: res?.applied_to_item_id ?
                                          state?.item?.data?.find(item =>
                                            item._id === res?.applied_to_item_id) : {}
                                      })}>
                                        <Edit />
                                      </IconButton>
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
                                      <IconButton onClick={() => dispatch(deletebannerForm(res))}>
                                        <Delete />
                                      </IconButton>
                                    </Grid>
                                  </Grid> :
                                  <Grid container spacing={2}>
                                    <Grid item sm={2} alignItems='flex-start' >
                                      <Typography variant="overline" style={{ color: "red" }} display="block" gutterBottom>
                                        Single Image Banner
                                      </Typography>
                                      <Box className={classes.image}>
                                        <img src={res.banner_image} width="100%" height="100%"
                                          alt="feture"></img>
                                      </Box>
                                    </Grid>
                                    <Grid item sm={2} alignItems='center' style={{ display: 'flex' }} >
                                      <Box>
                                        <Typography variant='h6' >Applied On :</Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item sm={5} alignItems='center' style={{ display: 'flex' }} >
                                      {
                                        res?.applied_to_main_category_id ?
                                          // <Typography variant='button' >{(allfeture.find(cat =>
                                          //   cat._id === res?.applied_to_main_category_id)).category_name}</Typography>
                                          <Typography variant='button' >{res?.applied_to_main_category_id?.category_name} (Category)</Typography> :
                                          res?.applied_featured_item_id ?
                                            <Typography variant='button' >{res?.applied_featured_item_id?.featured_item_name} (Feture)</Typography> :
                                            res?.applied_to_item_id ? <Typography variant='button' >{res?.applied_to_item_id?.item_name} (Item)</Typography> :
                                              <Typography variant='button' >No Link</Typography>
                                      }
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
                                      <IOSSwitch checked={res.status} onChange={() =>
                                        dispatch(updatebannerForm({ _id: res._id, status: !res.status }))} />
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
                                      <IconButton onClick={() => {
                                        handleClickaddBanner({
                                          ...res,
                                          applied_to_page: allfeture[value],
                                          apply_on: res.applied_to_main_category_id ? 'catagory' :
                                            res.applied_to_item_id ? 'item' : 'feture',
                                          applied_to_category: res?.applied_to_main_category_id ? res?.applied_to_main_category_id : {},
                                          applied_to_main_category_id: res?.applied_to_main_category_id ? res?.applied_to_main_category_id._id : null,
                                          // allfeture.find(cat => res.applied_to_main_category_id &&
                                          //   cat._id === res.applied_to_main_category_id) : {},
                                          applied_featured_item: res.applied_featured_item_id ? res.applied_featured_item_id : {},
                                          // applied_featured_item_id: res.applied_featured_item_id ?
                                          //   res.applied_featured_item_id._id : null,
                                          apply_on_item: res?.applied_to_item_id ? res?.applied_to_item_id : {},
                                          applied_to_item_id: res?.applied_to_item_id ? res?.applied_to_item_id?._id : null
                                          // state?.item?.data?.find(item =>
                                          //   item._id === res?.applied_to_item_id) : {}
                                        })
                                        console.log(res)
                                      }}>
                                        <Edit />
                                      </IconButton>
                                    </Grid>
                                    <Grid item sm={1} style={{ display: 'flex' }} alignItems='center' justify='center' >
                                      <IconButton onClick={() => dispatch(deletebannerForm(res))}>
                                        <Delete />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                }
                              </List>
                            )
                            // </RLDD>
                            :
                            <Paper className={classes.paper} elevation={0}>
                              <Typography variant="button" color="textSecondary" className={classes.margin}>
                                You have no Banner In {allfeture[value].category_name}
                              </Typography>
                              <Button
                                startIcon={<AddCircle />}
                                variant="contained"
                                color="primary"
                                className="button"
                                onClick={() => handleClickaddBanner()}
                              >
                                Create Banner
                              </Button>
                            </Paper>
                      }
                    </TabPanel>

                  </Paper>
                </Box>

                <Dialog maxWidth="lg"
                  open={addfetureItem}
                  onClose={() => setaddfetureItem(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <AddFetureProduct feture={viewFeture} addItem={addItemToFeture}
                    removeItem={removeItemToFeture} dialogClose={closeAddItem} />
                </Dialog>

                <Dialog maxWidth="lg"
                  open={addfeture}
                  onClose={() => handleClose()}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >

                  <DialogTitle
                    style={{ backgroundColor: colors.blue[50] }}
                    id="max-width-dialog-title"
                  >
                    Create Banner
                  </DialogTitle>
                  <form className={classes.form} onSubmit={e => {
                    console.log(formValue)
                    formValue._id ? dispatch(updatebannerForm(formValue)) :
                      dispatch(postbannerData(formValue))
                    e.preventDefault()
                  }}>
                    <DialogContent >

                      <Grid container spacing={3}>
                        {
                          formValue.is_banner &&
                          <Grid item sm={3} >
                            <Box className={classes.image}>
                              {
                                formValue.banner_image ?
                                  <img src={formValue.banner_image} width="100%" height="100%" alt="icon"></img> :
                                  <Typography variant="body1" color="textSecondary">
                                    Size (400 px * 400 px)
                                  </Typography>
                              }
                            </Box>

                            <Box className={classes.center}>
                              <input name="icon_image"
                                accept="image/*"
                                id="contained-button-file"
                                style={{ display: "none" }}
                                type="file"
                                onChange={e => {
                                  if (formValue.banner_image) {
                                    let public_id = formValue.banner_image.split("/")[8].split(".")[0];
                                    dispatch(deletefetureFormImage({ "image_id": `featured_item_image/${public_id}` }))
                                  }
                                  const file = e.target.files[0];
                                  const formData = new FormData();
                                  formData.append('banner_image', file)
                                  dispatch(addbannerFormImage(formData))
                                }}
                              />
                              <label htmlFor="contained-button-file">
                                <Button
                                  variant="contained"
                                  color="default"
                                  className="button"
                                  startIcon={<CloudUpload />}
                                  component="span"
                                >
                                  Upload Image
                                </Button>
                              </label>
                            </Box>
                          </Grid>
                        }
                        <Grid item sm={formValue.is_banner ? 9 : 12}>
                          <Grid container alignItems="center">
                            <Grid className={classes.marginRight}>
                              <Typography color="secondary">Banner Type:</Typography>
                            </Grid>
                            <Grid>
                              <FormControl>
                                <RadioGroup
                                  row required
                                  onChange={() => formValueChange()}>
                                  <FormControlLabel checked={formValue.is_banner}
                                    control={<Radio color="primary" />}
                                    label="Single Banner"
                                  />
                                  <FormControlLabel checked={formValue.is_featured_item}
                                    control={<Radio color="primary" />}
                                    label="Feture"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                          {/* <Autocomplete fullWidth className={classes.formControl}
                            options={allfeture} value={formValue.applied_to_page}
                            getOptionLabel={(option) => option.category_name}
                            onChange={(e, newValue) => selectBannerApplyId(e, newValue, "applied_to_page", "applied_to_page_id")}
                            renderInput={(params) =>
                              <TextField {...params} required label="Combo box" variant="outlined" size="small" label="Apply to page" />}
                          /> */}
                          {
                            formValue.is_banner ?
                              <Grid container alignItems="center">
                                <Grid className={classes.marginRight}>
                                  <Typography color="secondary">Applied To Link:</Typography>
                                </Grid>
                                <Grid>
                                  <FormControl>
                                    <RadioGroup
                                      row required={formValue.is_banner}
                                      value={formValue.apply_on}
                                      onChange={(e) => handleApply(e)}
                                    >

                                      <FormControlLabel value="catagory"
                                        control={<Radio color="primary" />}
                                        label="Catagory Pages"
                                      />
                                      <FormControlLabel value="feture"
                                        control={<Radio color="primary" />}
                                        label="Fetures"
                                      />
                                      <FormControlLabel value="item"
                                        control={<Radio color="primary" />}
                                        label="Item"
                                      />
                                      <FormControlLabel value="no_link"
                                        control={<Radio color="primary" />}
                                        label="No Link"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid xs={12}>
                                  {
                                    formValue.apply_on === 'catagory' &&
                                    <Autocomplete fullWidth className={classes.formControl}
                                      options={allfeture.filter(res => res._id !== '12345')} value={formValue.applied_to_category}
                                      getOptionLabel={(option) => option.category_name}
                                      onChange={(e, newValue) => selectBannerApplyId(e, newValue, "applied_to_category", "applied_to_main_category_id")}
                                      renderInput={(params) =>
                                        <TextField {...params} required={formValue.is_banner && formValue.apply_on === 'catagory'}
                                          label="Combo box" variant="outlined" size="small" label="Redirect Catagory To Page" />}
                                    />
                                  }
                                  {
                                    formValue.apply_on === 'feture' &&
                                    <Autocomplete fullWidth className={classes.formControl}
                                      options={state?.feture?.data} value={formValue.applied_featured_item}
                                      getOptionLabel={(option) => option.featured_item_name}
                                      onChange={(e, newValue) => selectBannerApplyId(e, newValue, "applied_featured_item", "applied_featured_item_id")}
                                      renderInput={(params) =>
                                        <TextField {...params} required={formValue.is_banner && formValue.apply_on === 'feture'}
                                          label="Combo box" variant="outlined" size="small" label="Redirect Feture To Page" />}
                                    />
                                  }
                                  {
                                    formValue.apply_on === 'item' &&
                                    <Autocomplete fullWidth className={classes.formControl}
                                      options={state?.item?.addons} value={formValue.apply_on_item}
                                      getOptionLabel={(option) => option.item_name}
                                      onChange={(e, newValue) => selectBannerApplyId(e, newValue, "apply_on_item", "applied_to_item_id")}
                                      renderInput={(params) =>
                                        <TextField {...params} required={formValue.is_banner && formValue.apply_on === 'item'}
                                          label="Combo box" variant="outlined" size="small" label="Redirect Item To Page" />}
                                    />
                                  }
                                </Grid>
                              </Grid>
                              :
                              <Autocomplete fullWidth className={classes.formControl}
                                options={state?.feture?.data} value={formValue.applied_featured_item}
                                getOptionLabel={(option) => option.featured_item_name}
                                onChange={(e, newValue) => selectBannerApplyId(e, newValue, "applied_featured_item", "applied_featured_item_id")}
                                renderInput={(params) =>
                                  <TextField {...params} required={formValue.is_featured_item}
                                    label="Combo box" variant="outlined" size="small" label="Add Feture" />}
                              />
                          }
                        </Grid>
                      </Grid>
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={() => handleClose()} color="secondary">
                        Back
                      </Button>
                      <Button type="submit" color="primary" autoFocus>
                        {
                          formValue._id ? "Update" : "Submit"
                        }
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>

                <Snackbar open={state.banner.status || state.feture.status}
                  onClose={!state.banner.status || !state.feture.status}>
                  <Alert onClose={!state.banner.status || !state.feture.status} severity={state.banner.status || state.feture.status}>
                    {state.banner.message}{state.feture.message}
                  </Alert>
                </Snackbar>

              </div>
          }
        </div>
      }
    </>)


  // function itemRenderer(item, index) {
  //   return (
  //     <TableRow className="item">
  //       <TableCell>
  //         <img className="image_size"
  //          src="https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" />
  //       </TableCell>
  //       <TableCell>
  //         <OpenWith />
  //         <p className="title">{item.title}</p>
  //         <p className="body">{item.body}</p>
  //         <div className="small">
  //           item.id: {item.id} - index: {index}
  //         </div>
  //       </TableCell>
  //       <TableCell>
  //           <Tooltip title="View">
  //             <Visibility
  //               className="edit"
  //               style={{
  //                 borderRadius: "50%",
  //                 marginRight: 5,
  //                 border: "1px solid grey",
  //               }}
  //             />
  //           </Tooltip>
  //           <Tooltip title="Delete">
  //             <Delete
  //               className="delete"
  //               style={{
  //                 borderRadius: "50%",
  //                 border: "1px solid lightcoral",
  //               }}
  //             />
  //           </Tooltip>
  //       </TableCell>
  //     </TableRow>
  //     // <div className={styles.item}>
  //     //   <p className="title">{item.title}</p>
  //     //   <p className="body">{item.body}</p>
  //     //   <div className="small">
  //     //     item.id: {item.id} - index: {index}
  //     //   </div>
  //     // </div>
  //   );
  // }

  // function handleRLDDChange(reorderedItems) {
  //   // console.log(reorderedItems)
  //   setdragItems(reorderedItems)
  // }

  // return (
  //   <div style={{
  //     width: "100%",
  //     height: "100%",
  //     background: colors.grey[200],
  //     position: "relative",
  //   }}
  //     className={classes.root}>
  //     <div
  //       style={{ position: "fixed", top: "0", left: "30%", zIndex: 1500 }}
  //       className={classes.drawerHeader}
  //     >
  //       <IconButton>
  //         {drawerOpen ? (
  //           <ChevronLeft onClick={() => setdrawerOpen(false)} />
  //         ) : (
  //           <ChevronRight onClick={() => setdrawerOpen(true)} />
  //         )}
  //       </IconButton>
  //     </div>
  //     <SwipeableDrawer
  //       className={classes.drawer}
  //       anchor="left"
  //       variant="persistent"
  //       open={drawerOpen}
  //       onClose={() => setdrawerOpen(false)}
  //       classes={{
  //         paper: classes.drawerPaper,
  //       }}
  //     >
  //       <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
  //         <Typography align="center" variant="h6">
  //           Front End
  //         </Typography>
  //       </div>
  //       <List>
  //         {/* <ListItem>
  //           <div
  //             style={{
  //               cursor: "pointer",
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               color: selectState === "gallery" ? "var(--orange)" : "#ffffff",
  //             }}
  //             onClick={() => history.push(`/${id}/frontend`)}
  //           >
  //             <RadioButtonUnchecked
  //               style={{ fontSize: "1rem", marginRight: 20 }}
  //             />
  //             <Typography>Gallery</Typography>
  //           </div>
  //         </ListItem> */}
  //         <ListItem>
  //           <div
  //             style={{
  //               cursor: "pointer",
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               color:
  //                 selectState === "socialmedia" ? "var(--orange)" : "#ffffff",
  //             }}
  //             onClick={() => history.push(`/${id}/frontend`)}
  //           >
  //             <RadioButtonUnchecked
  //               style={{ fontSize: "1rem", marginRight: 20 }}
  //             />
  //             <Typography>Social Media Page Urls</Typography>
  //           </div>
  //         </ListItem>
  //         <ListItem>
  //           <div
  //             style={{
  //               cursor: "pointer",
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               color: selectState === "banner" ? "var(--orange)" : "#ffffff",
  //             }}
  //             onClick={() => history.push(`/${id}/frontend/banner-image-slider`)}
  //           >
  //             <RadioButtonUnchecked
  //               style={{ fontSize: "1rem", marginRight: 20 }}
  //             />
  //             <Typography>Banner - Image Slide</Typography>
  //           </div>
  //         </ListItem>
  //         <ListItem>
  //           <div
  //             style={{
  //               cursor: "pointer",
  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               color:
  //                 selectState === "Homepage Banner" ? "var(--orange)" : "#ffffff",
  //             }}
  //             onClick={() => history.push(`/${id}/frontend/homepage-banner`)}
  //           >
  //             <RadioButtonUnchecked
  //               style={{ fontSize: "1rem", marginRight: 20 }}
  //             />
  //             <Typography>Home Page Popup Banner</Typography>
  //           </div>
  //         </ListItem>
  //       </List>
  //     </SwipeableDrawer>
  //     <div style={{ padding: "0 20px" }} className={clsx(classes.content, {
  //       [classes.contentShift]: drawerOpen,
  //     })}>
  //       <Breadcrumbs
  //         aria-label="breadcrumb"
  //         separator={<ChevronRight style={{ fontSize: 15 }} />}
  //       >
  //         <Link color="inherit" href="/" style={{ fontSize: 15 }}>
  //           Home
  //           </Link>
  //         <Typography
  //           color="textPrimary"
  //           style={{ fontSize: 15, color: "var(--orange)" }}
  //         >
  //           Banner
  //           </Typography>
  //       </Breadcrumbs>
  //       <Paper style={{ padding: "2rem", marginTop: "3%" }}>
  //         <Typography variant="h6">Banner</Typography>
  //         <Divider style={{ margin: "2% 0" }} />
  //         <Table>
  //           <TableHead>
  //             <TableRow>
  //               <TableCell>Image</TableCell>
  //               <TableCell>Action</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             <RLDD className="example"
  //               items={dragItems}
  //               itemRenderer={itemRenderer}
  //               onChange={handleRLDDChange}
  //             />
  //           </TableBody>
  //         </Table>
  //       </Paper>
  //     </div>
  //   </div>
  // );
};

