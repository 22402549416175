import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  colors,
  Typography,
  Link,
  Paper,
  Divider,
  Box,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Card,
  Fab,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  CircularProgress,
  Tooltip,
  IconButton,
  withStyles,
  Zoom,
  Snackbar,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//Components
// import { Header } from "../../components/Header/Header.component";
import {
  ChevronRight,
  ExitToApp,
  Info,
  Room,
  Search,
  Visibility,
  WatchLater,
} from "@material-ui/icons";
import { BootstrapSelectInput } from "../../components/Input/SelectInput.component";
import { TrackOrder } from "./Details/TrackOrder";
import { OrderDetails } from "./Details/OrderDetails";
// import { Rescehudle } from './Details/Rescehudle'

import { useSelector, useDispatch } from "react-redux";
import {
  getAllOrder,
  getOrderbyName,
  updateOrder,
  orderByBookingType,
} from "../../redux";
import moment from "moment";
import 'moment-timezone';
import { RescheduleOrder } from "./Details/RescheduleOrder";
import AssignDelivaryBoy from "./Details/AssignDelivaryBoy";
import { Pagination, Alert } from "@material-ui/lab";
import ExportToExacl from "./ExportToExacl";
import { useCurrency } from "../../hooks/useCurrency";

import {
  GiTabletopPlayers,
  RiEBike2Fill,
  GiTakeMyMoney,
} from "react-icons/all";
import { EmailSms } from "./Details/EmailSms";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    width: 200,
  },
  marginSmall: {
    margin: theme.spacing(1),
  },
  iconsStyle: {
    color: colors.green[400],
    border: "1px solid green",
    borderRadius: "50%",
    padding: "5px",
    marginRight: 5,
    fontSize: "1.5rem",
    "&:hover": {
      background: colors.green[400],
      color: "#ffffff",
    },
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 115,
    marginRight: 15,
  },
  modelTitle: {
    backgroundColor: "#2C2E38",
    color: "#ffffff",
  },
  marginLeft: {
    marginLeft: "auto",
    width: "200px",
    display: "flex",
    justifyContent: "space-around",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let updateTimeSlot = 30;
export const OrderPage = () => {
  const currency = useCurrency();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activity, setActivity] = useState("all");
  const [status, setStatus] = useState("alltype");
  const [deliveryStatus, setdeliveryStatus] = useState("ontheway");
  const [order, setorder] = useState({});
  const [open, setOpen] = React.useState(false);
  const [modelOpen, setmodelOpen] = useState("Tracking Order");
  const [timer, settimer] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [search, setsearch] = useState("");
  const [searchDate, setsearchDate] = useState({
    formDate: moment(new Date()).subtract(7, "days").format(),
    toDate: new Date(),
  });
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    dispatch(getAllOrder());
  }, [dispatch]);

  useEffect(() => {
    if (!searchDate.formDate && !searchDate.toDate && !search) {
      dispatch(getAllOrder());
    }
  }, []);

  useEffect(() => {
    if (searchDate.formDate && searchDate.toDate) {
      setStatus("alltype");
      dispatch(
        getOrderbyName(
          `filter_order_by_date/${searchDate.formDate}/${searchDate.toDate}`
        )
      );
      setActivity("all");
    }
  }, [searchDate]);

  useEffect(() => {
    if (search) {
      setsearchDate({ formDate: null, toDate: null });
      setActivity("all");
      setStatus("alltype");
      dispatch(getOrderbyName(`search_order/${search}`));
    }
  }, [search]);

  useEffect(() => {
    if (seconds === 0) {
      searchDate.formDate && searchDate.toDate
        ? dispatch(
          getOrderbyName(
            `filter_order_by_date/${searchDate.formDate}/${searchDate.toDate}`
          )
        )
        : dispatch(getAllOrder());
    }
  }, [seconds]);

  // useEffect(() => {
  //   if (activity === "all") {
  //     !searchDate.formDate && !searchDate.toDate && dispatch(getAllOrder());
  //   } else {
  //     setsearchDate({ formDate: null, toDate: null });
  //     setStatus("alltype");
  //     setsearch("");
  //     dispatch(
  //       getOrderbyName(
  //         `filter_order_by_order_status/${activity}?limit=10&page=1`
  //       )
  //     );
  //   }
  //   // GET
  // }, [activity]);

  useEffect(() => {
    state?.order?.data && setSeconds(30);
  }, [state?.order?.data]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const transitionDuration = {
    enter: state?.order?.loading,
    exit: state?.order?.loading,
  };

  return (
    <>

      <div className={classes.content}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            Orders
          </Typography>
        </Breadcrumbs>
        <Paper
          style={{ padding: "1.5rem", marginTop: "3%", marginBottom: "3%" }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Orders</Typography>
            <Button
              startIcon={<Info />}
              style={{ marginLeft: 10 }}
              variant="outlined"
              color="primary"
            >
              Orders For All Branchs
            </Button>
            <div className={classes.marginLeft}>
              <ColorButton
                variant="contained"
                color="primary"
                onClick={() => dispatch(getAllOrder())}
                onMouseEnter={() => settimer(true)}
                onMouseLeave={() => settimer(false)}
              >
                {timer || state?.order?.loading ? (
                  <CircularProgress color="#ffffff" size="20px" />
                ) : (
                  seconds
                )}
              </ColorButton>
              <ExportToExacl />
            </div>
          </Box>
          <Divider style={{ margin: "20px 0 20px 0" }} />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              variant="outlined"
              value={search}
              onChange={(e) => setsearch(e.target.value)}
              color="primary"
              label="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="primary" />
                  </InputAdornment>
                ),
                inputProps: { style: { padding: 10 } },
              }}
            />
            <FormControl className={classes.margin}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={activity}
                onChange={(e) => setActivity(e.target.value)}
                input={<BootstrapSelectInput />}
              >
                <MenuItem value="all">Order Types</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="accepted">Accepted</MenuItem>
                <MenuItem value="on_the_way">On The Way</MenuItem>
                <MenuItem value="delivered">Delivered</MenuItem>
                <MenuItem value="rejected">Cancel</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.margin}>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  if (e.target.value === "delivery") {
                    dispatch(
                      orderByBookingType(
                        "delivery",
                        state?.order?.currentPage
                      )
                    );
                  } else if (e.target.value === "pickup") {
                    dispatch(
                      orderByBookingType("pick_up", state?.order?.currentPage)
                    );
                  } else if (e.target.value === "reservation") {
                    dispatch(
                      orderByBookingType(
                        "reservation",
                        state?.order?.currentPage
                      )
                    );
                  } else {
                    dispatch(getAllOrder());
                  }
                }}
                input={<BootstrapSelectInput />}
              >
                <MenuItem value="alltype">Delivary Type</MenuItem>
                <MenuItem value="delivery">Delivery</MenuItem>
                <MenuItem value="pickup">Pickup</MenuItem>
                <MenuItem value="reservation">Reservation</MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                style={{ width: 200 }}
                label="To Date"
                format="MM/dd/yyyy"
                value={searchDate.formDate}
                onChange={(date) =>
                  setsearchDate({ ...searchDate, formDate: date })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                style={{ width: 200 }}
                minDate={searchDate.formDate}
                label="From Date"
                format="MM/dd/yyyy"
                value={searchDate.toDate}
                onChange={(date) =>
                  setsearchDate({ ...searchDate, toDate: date })
                }
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          {state?.order?.data?.length > 0 ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                marginTop={2}
              >
                <Zoom
                  in={!state?.order?.loading}
                  // timeout={transitionDuration}
                  // style={{
                  //   transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
                  // }}
                  unmountOnExit
                >
                  <Card
                    style={{
                      width: "80%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "3% 5%",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Fab
                        style={{
                          background: colors.orange[400],
                          color: "#ffffff",
                        }}
                      >
                        <Typography>
                          {state?.order?.total_pending_orders}
                        </Typography>
                      </Fab>
                      <Typography style={{ marginTop: 5 }}>
                        Pending
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Fab
                        style={{
                          background: colors.green[400],
                          color: "#ffffff",
                        }}
                      >
                        <Typography>
                          {state?.order?.total_accepted_orders}
                        </Typography>
                      </Fab>
                      <Typography style={{ marginTop: 5 }}>
                        Accepted
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Fab
                        style={{
                          background: colors.green[600],
                          color: "#ffffff",
                        }}
                      >
                        <Typography>
                          {state?.order?.total_delivered_orders}
                        </Typography>
                      </Fab>
                      <Typography style={{ marginTop: 5 }}>
                        Delivered
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Fab
                        style={{
                          background: colors.pink[100],
                          color: "#ffffff",
                        }}
                      >
                        <Typography>
                          {state?.order?.total_rejected_orders}
                        </Typography>
                      </Fab>
                      <Typography style={{ marginTop: 5 }}>
                        Cancel
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Fab
                        style={{
                          background: colors.pink[700],
                          color: "#ffffff",
                        }}
                      >
                        <Typography>
                          {state?.order?.total_on_the_way_orders}
                        </Typography>
                      </Fab>
                      <Typography style={{ marginTop: 5 }}>Others</Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Fab
                        style={{
                          background: colors.purple[700],
                          color: "#ffffff",
                        }}
                      >
                        <Typography>{state?.order?.total_orders}</Typography>
                      </Fab>
                      <Typography style={{ marginTop: 5 }}>Total</Typography>
                    </Box>
                  </Card>
                </Zoom>
              </Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Order Number</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Branch</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>Order Total</TableCell>
                    <TableCell>Payment Status</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {state?.order?.loading ? (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  state?.order?.data?.map((res, i) => (
                    <TableBody>
                      <TableRow>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Tooltip title={res.order_id}>
                              <Typography
                              // style={{
                              //   whiteSpace: "nowrap",
                              //   width: 50,
                              //   overflow: "hidden",
                              //   textOverflow: "ellipsis",
                              // }}
                              >
                                {res.order_id}
                              </Typography>
                            </Tooltip>
                            {res?.order_booking_type === "reservation" ? (
                              <Tooltip title="Reservation" placement="top">
                                <IconButton
                                  style={{
                                    border: "1px solid red",
                                    borderRadius: "50%",
                                    padding: "5px",
                                    marginLeft: 5,
                                    fontSize: "1.2rem",
                                    color: "red",
                                  }}
                                >
                                  <GiTabletopPlayers />
                                </IconButton>
                              </Tooltip>
                            ) : res?.order_booking_type === "Delivery" ? (
                              <Tooltip title="Delivery" placement="top">
                                <IconButton
                                  style={{
                                    border: "1px solid green",
                                    borderRadius: "50%",
                                    padding: "5px",
                                    marginLeft: 5,
                                    fontSize: "1.2rem",
                                    color: "green",
                                  }}
                                >
                                  <RiEBike2Fill />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              res?.order_booking_type === "Pickup" && (
                                <Tooltip title="Pick up" placement="top">
                                  <IconButton
                                    style={{
                                      border: "1px solid orange",
                                      borderRadius: "50%",
                                      padding: "5px",
                                      marginLeft: 5,
                                      fontSize: "1.2rem",
                                      color: "orange",
                                    }}
                                  >
                                    <GiTakeMyMoney />
                                  </IconButton>
                                </Tooltip>
                              )
                            )}
                          </Box>
                          {
                            res.order_status_delivered.status
                              ? <div className="badge" style={{ backgroundColor: "#66bb6a" }}>Delivered</div>
                              : res.order_status_rejected.status
                                ? <div className="badge" style={{ backgroundColor: "#3443eb" }}>Calcel</div> :
                                moment(res.order_delivery_date_and_time).startOf("hour").fromNow().split(" ")[0] === 'in' ?
                                  moment(res.order_delivery_date_and_time).startOf("hour").fromNow() === `in ${1 || 2 || 3 || 5 || 6 || 7 || 8 || 9 || 10} minutes`
                                    ? <div className="badge" style={{ backgroundColor: "#f62d51" }}>ASAP</div> :
                                    <div className="badge" style={{ backgroundColor: "#ffbc34" }}>Later</div> :
                                  <div className="badge" style={{ backgroundColor: "rgb(123, 31, 162)" }}>Time Out</div>
                          }
                        </TableCell>
                        <TableCell>{res?.order_delivery_full_name}</TableCell>
                        <TableCell>{state?.branch?.activeBranch?.branch_name}</TableCell>
                        {/* <TableCell>{res._id}</TableCell> */}
                        {/* <TableCell>{res.order_delivery_full_name}</TableCell> */}
                        {/* <TableCell></TableCell> */}
                        <TableCell>
                          <p>{moment(res.createdAt).tz("America/New_York").format('LLLL')}</p>
                          {
                            res.order_status_delivered.status
                              ? <p><b>Delivered: </b>{moment(res.order_status_delivered.order_status_date_time).tz("America/New_York").format("LLLL")}</p>
                              : res.order_status_rejected.status
                                ? <p><b>Rejected: </b>{moment(res.order_status_rejected.order_status_date_time).tz("America/New_York").format("LLLL")}</p> :
                                moment(res.order_delivery_date_and_time).tz("America/New_York").startOf("hour").fromNow().split(" ")[2] === 'ago' ?
                                  <p><b>Time Out: </b>{moment(res.order_delivery_date_and_time).tz("America/New_York").fromNow()}</p> :
                                  <p><b>Remaining: </b>{moment(res.order_delivery_date_and_time).tz("America/New_York").fromNow()}</p>
                          }
                        </TableCell>
                        <TableCell>
                          {res.order_delivery_phone_number}
                        </TableCell>
                        <TableCell>
                          {currency} {res?.order_grand_total}
                        </TableCell>
                        <TableCell>
                          <p>{res?.order_payment_status}</p>
                          <p><b>Method:</b> {res?.order_payment_provider}</p>
                        </TableCell>
                        <TableCell>
                          <FormControl className={classes.marginSmall}>
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              value={
                                res.order_status_delivered.status
                                  ? "delivered"
                                  : res.order_status_on_the_way.status
                                    ? "on_the_way"
                                    : res.order_status_accepted.status
                                      ? "accepted"
                                      : res.order_status_rejected.status
                                        ? "rejected"
                                        : res.order_status_pending.status &&
                                        "pending"
                              }
                              onChange={(e) => {
                                setorder({
                                  ...res,
                                  order_status_name: e.target.value,
                                });
                                handleClickOpen();
                                setmodelOpen("Send Order And Sms");
                                console.log(e.target.value);
                                // dispatch(updateOrderStatus({ _id: res._id, order_status_name: e.target.value }))
                              }}
                              input={<BootstrapSelectInput />}
                            >
                              <MenuItem
                                value="pending"
                                disabled={!res.order_status_pending.status}
                              >
                                Pending
                              </MenuItem>
                              <MenuItem
                                value="accepted"
                                disabled={!res.order_status_pending.status}
                              >
                                Accepted
                              </MenuItem>
                              <MenuItem
                                value="on_the_way"
                                disabled={
                                  res.order_status_delivered.status ||
                                  res.order_status_rejected.status
                                }
                              >
                                On The Way
                              </MenuItem>
                              <MenuItem
                                value="delivered"
                                disabled={res.order_status_rejected.status}
                              >
                                {res.order_booking_type === "Delivery" ? "Delivered" : "Pickup"}
                              </MenuItem>
                              <MenuItem
                                value="rejected"
                                disabled={
                                  !res.order_status_pending.status &&
                                  !res.order_status_rejected.status
                                }
                              >
                                Cancel
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Tooltip title="Track Order">
                              <IconButton
                                className={classes.iconsStyle}
                                onClick={() => {
                                  setorder(res);
                                  handleClickOpen();
                                  setmodelOpen("Tracking Order");
                                }}
                              >
                                <Room />
                              </IconButton>
                            </Tooltip>
                            {res.order_booking_type === "Delivery" && res.order_status_pending.status && (
                              <Tooltip title="Assign Order">
                                <IconButton
                                  className={classes.iconsStyle}
                                  onClick={() => {
                                    setorder(res);
                                    handleClickOpen();
                                    setmodelOpen("Assign Delivery Boy");
                                  }}
                                >
                                  <ExitToApp />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title="Quick View">
                              <IconButton
                                className={classes.iconsStyle}
                                onClick={() => {
                                  setorder(res);
                                  handleClickOpen();
                                  setmodelOpen("Order Details");
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                            {res.order_status_pending.status && (
                              <Tooltip title="Reschudle Order">
                                <IconButton
                                  className={classes.iconsStyle}
                                  onClick={() => {
                                    setorder(res);
                                    handleClickOpen();
                                    setmodelOpen("Reschedule");
                                  }}
                                >
                                  <WatchLater />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                )}
              </Table>
            </>
          ) : (
            "no data found"
          )}
          {state?.order?.totalPage > 1 && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="auto"
            >
              <Pagination
                count={state?.order.totalPage}
                page={state?.order?.currentPage}
                color="primary"
                onChange={(e, value) =>
                  dispatch(
                    getAllOrder(
                      `get_all_orders?page=${value.toString()}&limit=10`
                    )
                  )
                }
              />
            </Box>
          )}
        </Paper>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullWidth="md"
          maxWidth="md"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
            className={classes.modelTitle}
          >
            {modelOpen}
          </DialogTitle>
          <DialogContent
            style={{ padding: modelOpen === "Order Details" && "0" }}
          >
            {modelOpen === "Tracking Order" && <TrackOrder order={order} />}
            {modelOpen === "Order Details" && <OrderDetails order={order} />}
            {modelOpen === "Reschedule" && <RescheduleOrder order={order} />}
            {modelOpen === "Assign Delivery Boy" && (
              <AssignDelivaryBoy order={order} />
            )}
            {modelOpen === "Send Order And Sms" && (
              <EmailSms
                modelCondition={open}
                order={order}
                modelClose={handleClose}
              />
            )}
          </DialogContent>
          {modelOpen !== "Send Order And Sms" && (
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                Close
              </Button>
              {modelOpen === "Reschedule" && (
                <Button
                  onClick={() =>
                    dispatch(
                      updateOrder({
                        _id: order._id,
                        order_delivery_reschedule_date_and_time:
                          order.order_delivery_reschedule_date_and_time,
                      })
                    )
                  }
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              )}
              {modelOpen === "Assign Delivery Boy" && (
                <Button
                  onClick={() =>
                    dispatch(
                      updateOrder({
                        _id: order._id,
                        order_delivery_boy: order.order_delivery_boy,
                      })
                    )
                  }
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              )}
            </DialogActions>
          )}
        </Dialog>
        <Snackbar open={state?.order?.status} onClose={!state?.order?.status}>
          <Alert
            onClose={!state?.order?.status}
            severity={state?.order?.status}
          >
            {state?.order?.message}
          </Alert>
        </Snackbar>
      </div>
      {" "}
    </>
  );
};
