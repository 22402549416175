import React, { useState, useEffect } from 'react'
import { Box, Breadcrumbs, Button, colors, Divider, Link, Paper, TextField, Typography, Snackbar } from '@material-ui/core'
import { ChevronRight, ChevronLeft, Check } from '@material-ui/icons'

import MuiAlert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from "react-redux"
import { updateAdministrator } from "../../redux"
import { useHistory } from "react-router-dom";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AccountUpdate = () => {
    const history = useHistory()
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const [formData, _formData] = useState({
        _id: '', adminstrator_branch_id: '', administrator_email: '', administrator_first_name: '', administrator_last_name: '',
        administrator_user_name: ''
    })

    useEffect(() => {
        _formData(state?.login?.data)
    }, [state?.login?.data])

    const onchange = (e) => {
        _formData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        state?.administrator?.status === 'success' && setTimeout(() => {
            history.push(`profile`)
        }, 1000);
    }, [state?.administrator?.status])

    return (
        <>
            {
                !state?.branch?.loader &&
                <div style={{
                    width: "100%",
                    height: "100%",
                    background: colors.grey[200],
                    position: "relative",
                    display: 'flex'
                }}>
                    <Box style={{ paddingLeft: 20, paddingRight: 20 }} width='100%' >
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<ChevronRight style={{ fontSize: 15 }} />}
                        >
                            <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                                Home
                </Link>
                            <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                                Administrator
                </Link>
                            <Typography
                                color="textPrimary"
                                style={{ fontSize: 15, color: "var(--orange)" }}
                            >
                                Profile
                </Typography>
                        </Breadcrumbs>
                        <Box style={{ paddingLeft: 20, paddingRight: 20, marginTop: '10%' }} width='100%'>
                            <Paper style={{ padding: "2rem", margin: "15px auto 5rem auto", width: "70%" }}>
                                <Typography variant="h6" >Update</Typography>
                                <Divider style={{ margin: '10px 0' }} />
                                <TextField variant="outlined" style={{ width: '100%', marginTop: '2rem' }} label="First Name" size="small"
                                    value={formData.administrator_first_name} name="administrator_first_name" onChange={e => onchange(e)} />
                                <TextField variant="outlined" style={{ width: '100%', marginTop: '2rem' }} label="Last Name" size="small"
                                    value={formData.administrator_last_name} name="administrator_last_name" onChange={e => onchange(e)} />
                                <TextField variant="outlined" style={{ width: '100%', marginTop: '2rem' }} label="Email" size="small"
                                    value={formData.administrator_email} name="administrator_email" onChange={e => onchange(e)} />
                                <TextField variant="outlined" style={{ width: '100%', marginTop: '2rem' }} label="AdminUsername" size="small"
                                    value={formData.administrator_user_name} name="administrator_user_name" onChange={e => onchange(e)} />
                                <Box display='flex' alignItems='center' justifyContent='center' marginTop='2rem' >
                                    <Button className='button' startIcon={<ChevronLeft />} style={{ marginRight: '2rem' }}
                                        onClick={() => history.push(`profile`)}>back</Button>
                                    <Button className='button' startIcon={<Check />} onClick={() => dispatch(updateAdministrator(formData))}>save</Button>
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                    <Snackbar open={state?.administrator?.status}
                        onClose={state?.administrator?.status}>
                        <Alert onClose={!state?.administrator?.status} severity={state?.administrator?.status}>
                            {state?.administrator?.message}
                        </Alert>
                    </Snackbar>
                </div>
            }</>)
}
