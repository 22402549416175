import { combineReducers } from 'redux'
import userReducers from './user/userReducers'
import { catagoryReducers } from "./catagory/catagoryReducers";
import { paymentReducer } from './AdvanceSetting/AdvanceSetting.reducer'
import {ingridientReducer} from './ingridient/ingridientReducers'
import {branchReduder} from './branch/branchReducers'
import {delivaryZoneReducer} from "./delivary-Zone/delivaryZoneReucers";
import { pickupDelivaryReducer } from "./pickupDelivary/pickupDelivaryReducer";
import {operationalhourReducer} from './operationalhours/operationalReducers'
import { ItemReducer } from './item/itemReducer';
import {delivaryBoyReducer} from './delivaryboy/delivaryboyReducers';
import { configartionReducer } from "./configation/configarationReducer";
import { iconLogoReducer } from "./iconlogo/iconlogoReducer";
import {socialmediaReducer} from './socialmedia/socialmediaReducer';
import {cmsPageReducer} from "./cmsPage/cmsPageReducer";
import {preferenceReducer} from './preference/preferenceReducer';
import {administratorReducer} from './administrator/administratorReducer'
import {loginReducer} from './login/loginReducer'
import {pushNotificationReducer} from './pushNotification/pushNotificationReducer'
import { fetureReducer } from './feture/fetureReducers';
import customerGroupReducer from './CustomerGroup/CustomerGroup.reducer'
import { couponReduder } from "./cupon/cuponReducer";
import { orderReduder } from './order/orderReducer';
import { currencyReducer } from './currency/currency.reducer'
import { countryCurrencyReducer } from "./countryCurrency/countryReducers";
import { bannerReducer } from "./banner/bannerReducers";
import { homePagePopupReducer } from './homePagePopupbanner/HomePagePopup.reducer'
import { menutimeReducer } from './menutime/menutimeReducer';

export default combineReducers({
    // cart: cartReducer,
    user : userReducers, 
    catagory : catagoryReducers,
    advanceSetting: paymentReducer,
    ingridient : ingridientReducer,
    branch : branchReduder,
    delivaryZone : delivaryZoneReducer,
    pickupDelivary:  pickupDelivaryReducer,
    operationalhours: operationalhourReducer,
    menutime: menutimeReducer,
    item: ItemReducer,
    delivaryBoy: delivaryBoyReducer,
    configarationSettings: configartionReducer,
    iconlogo: iconLogoReducer,
    socialmedia: socialmediaReducer,
    cmsPage: cmsPageReducer,
    preference: preferenceReducer,
    administrator: administratorReducer,
    login: loginReducer,
    pushNotification: pushNotificationReducer,
    feture: fetureReducer,
    customerGroup: customerGroupReducer,
    coupon: couponReduder,
    order: orderReduder,
    currency: currencyReducer,
    countryCurrency: countryCurrencyReducer,
    banner: bannerReducer,
    homepagePopup: homePagePopupReducer
})