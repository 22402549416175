import React, { useState, useEffect } from "react";

import {
  Breadcrumbs,
  Link,
  Paper,
  Typography,
  withStyles,
  Switch,
  Button,
  TextField,
  Grid,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  ListItem,
  IconButton,
  SwipeableDrawer,
  List,
  colors,
} from "@material-ui/core";
import {
  ChevronRight,
  ChevronLeft,
  Check,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getTwilioSmsConfigure,
  getTextlocalSmsConfigure,
  updateSmsConfigure,
} from "../../redux/AdvanceSetting/AdvanceSetting.action";
import { SwipeDrawer } from "../Settings/swipedrawer";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export const ConfigureSms = ({ match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("configureSms");
  const id = match.params.id;
  const twilioSms = useSelector(
    (state) => state?.advanceSetting?.twilioSms?.response
  );
  const textLocalSms = useSelector(
    (state) => state?.advanceSetting?.textLocal?.response
  );
  const [value, setValue] = useState({
    sms_config_name: "",
    twilio_sms_auth_token: "",
    twilio_sms_from: "",
    twilio_sms_country_code: "",
    status: true,
  });

  const [textLocalValue, setTextLocalValue] = useState({
    textlocal_sms_api_key: "",
    textlocal_sms_sender: "",
    textlocal_sms_text_local_url: "",
    status: true,
  });
  const [selectSMS, setSelectSMS] = useState("twilio");

  console.log(twilioSms, textLocalSms, selectSMS);

  useEffect(() => {
    dispatch(getTwilioSmsConfigure());
    dispatch(getTextlocalSmsConfigure());
  }, [dispatch]);

  useEffect(() => {
    twilioSms?.map((cf) => {
      setValue(cf);
    });
    textLocalSms?.map((cf) => {
      setTextLocalValue(cf);
    });
  }, [twilioSms, textLocalSms]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const twilioVariable = {
        ...value,
      };

      const textLocalVariable = {
        ...textLocalValue,
      };
      if (selectSMS === "twilio") {
        dispatch(updateSmsConfigure(twilioVariable, twilioSms[0]?._id));
      } else if (selectSMS === "textlocal") {
        dispatch(updateSmsConfigure(textLocalVariable, textLocalSms[0]?._id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      style={{
        paddingTop: "10px",
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
          PROMOTION
          </Typography>
        </div>
        <SwipeDrawer />
      </SwipeableDrawer>
      <div
        style={{ paddingLeft: 20, paddingRight: 20 }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            SMS Settings
          </Typography>
        </Breadcrumbs>

        <Paper style={{ padding: "2rem", margin: "15px auto", width: "50%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
              paddingBottom: "10px",
            }}
          >
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item xs={9}>
                <Typography variant="h6">SMS settings</Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>Off</Grid>
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={
                              selectSMS === "twilio"
                                ? value.status
                                : textLocalValue.status
                            }
                            onChange={(e) => {
                              selectSMS === "twilio"
                                ? setValue({
                                    ...value,
                                    status: e.target.checked,
                                  })
                                : setTextLocalValue({
                                    ...textLocalValue,
                                    status: e.target.checked,
                                  });
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item>On</Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div style={{ padding: "10px" }}>
            Gateway's
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  name="item_status"
                  value={selectSMS}
                  onChange={(e) => setSelectSMS(e.target.value)}
                >
                  <FormControlLabel
                    value="twilio"
                    control={<Radio color="primary" />}
                    label="Twilio"
                  />
                  <FormControlLabel
                    value="textlocal"
                    control={<Radio color="primary" />}
                    label="Text Locial(India)"
                  />
                </RadioGroup>
              </FormControl>
              {selectSMS === "twilio" && value.status && (
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Account ID"
                    variant="outlined"
                    size="small"
                    value={value.sms_config_name}
                    onChange={(e) =>
                      setValue({ ...value, sms_config_name: e.target.value })
                    }
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Auth Token"
                    value={value.twilio_sms_auth_token}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        twilio_sms_auth_token: e.target.value,
                      })
                    }
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="From"
                    value={value.twilio_sms_from}
                    onChange={(e) =>
                      setValue({ ...value, twilio_sms_from: e.target.value })
                    }
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Country Code"
                    variant="outlined"
                    size="small"
                    value={value.twilio_sms_country_code}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        twilio_sms_country_code: e.target.value,
                      })
                    }
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width={200}
                    margin="auto"
                  >
                    <Button startIcon={<ChevronLeft />} className="button">
                      back
                    </Button>
                    <Button
                      className="button"
                      startIcon={<Check />}
                      type="submit"
                    >
                      save
                    </Button>
                  </Box>
                </div>
              )}

              {selectSMS === "textlocal" && textLocalValue.status && (
                <div>
                  <TextField
                    id="outlined-basic"
                    label="API Key"
                    variant="outlined"
                    size="small"
                    value={textLocalValue.textlocal_sms_api_key}
                    onChange={(e) =>
                      setTextLocalValue({
                        ...textLocalValue,
                        textlocal_sms_api_key: e.target.value,
                      })
                    }
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Sender"
                    value={textLocalValue.textlocal_sms_sender}
                    onChange={(e) =>
                      setTextLocalValue({
                        ...textLocalValue,
                        textlocal_sms_sender: e.target.value,
                      })
                    }
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Textlocal URL"
                    variant="outlined"
                    size="small"
                    value={textLocalValue.textlocal_sms_text_local_url}
                    onChange={(e) =>
                      setTextLocalValue({
                        ...textLocalValue,
                        textlocal_sms_text_local_url: e.target.value,
                      })
                    }
                    style={{ width: "100%", marginBottom: "1.5rem" }}
                  />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width={200}
                    margin="auto"
                  >
                    <Button startIcon={<ChevronLeft />} className="button">
                      back
                    </Button>
                    <Button
                      className="button"
                      startIcon={<Check />}
                      type="submit"
                    >
                      save
                    </Button>
                  </Box>
                </div>
              )}
            </form>
            {/* Result */}
            {/* <div className='code'>
                        <pre
                            style={{
                                margin: '0 20px',
                                fontWeight: 500,
                                fontFamily: 'monospace',
                            }}
                        >
                            {JSON.stringify(selectedTimezone, null, 2)}
                        </pre>
                    </div> */}
          </div>
        </Paper>
      </div>
    </div>
  );
};
