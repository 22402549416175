import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Link,
  Paper,
  Typography,
  makeStyles,
  ListItem,
  List,
  SwipeableDrawer,
  IconButton,
  colors,
} from "@material-ui/core";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import {
  getPromotionalPage,
  updatePromotionalPage,
} from "../../redux/AdvanceSetting/AdvanceSetting.action";

import { Input } from "../../components/Input/Input.component";

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const PromotionalPage = ({ match }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("Promotional Page Setting");
  const id = match.params.id;
  const [value, setValue] = useState({
    site_descirption: "",
    site_keyword: "",
    site_title: "",
  });
  const promotionalPage = useSelector(
    (state) => state?.advanceSetting?.promotionalPage?.response
  );
  console.log(promotionalPage);
  useEffect(() => {
    dispatch(getPromotionalPage());
  }, [dispatch]);

  useEffect(() => {
    promotionalPage?.map((item) => setValue(item));
  }, [promotionalPage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(updatePromotionalPage(value, promotionalPage[0]?._id));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setselectState(window.location.pathname.split("/")[(window.location.pathname.split("/").length) - 1])
}, [window.location.pathname])

  return (
    <div
      style={{
        paddingTop: "10px",
        width: "100%",
        height: "100%",
        background: colors.grey[200],
        position: "relative",
      }}
      className={classes.root}
    >
      <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            SEO & ANALYTICS
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "report"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/report`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Report</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "seo"
                    ? "var(--orange)"
                    : "#ffffff",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/${id}/report/seo`)
              }
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>SEO</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div
        style={{ paddingLeft: 20, paddingRight: 20 }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<ChevronRight style={{ fontSize: 15 }} />}
        >
          <Link color="inherit" href="/" style={{ fontSize: 15 }}>
            Home
          </Link>
          <Typography
            color="textPrimary"
            style={{ fontSize: 15, color: "var(--orange)" }}
          >
            Social Media Login
          </Typography>
        </Breadcrumbs>
        <Paper
          style={{
            padding: "2rem",
            margin: "15px auto 5rem auto",
            width: "70%",
          }}
        >
          <Typography variant="h6">Promotion Set Up - SEO</Typography>
          <Divider style={{ margin: "10px 0" }} />
          <form onSubmit={handleSubmit}>
            <Input
              style={{ width: "100%", marginTop: "2rem" }}
              value={value.site_title}
              onChange={(e) =>
                setValue({ ...value, site_title: e.target.value })
              }
              label="OG Site Title"
            />
            <Input
              style={{ width: "100%", marginTop: "2rem" }}
              value={value.site_descirption}
              onChange={(e) =>
                setValue({ ...value, site_descirption: e.target.value })
              }
              label="OG Site Description"
            />
            <Input
              style={{ width: "100%", marginTop: "2rem" }}
              value={value.site_keyword}
              onChange={(e) =>
                setValue({ ...value, site_keyword: e.target.value })
              }
              label="OG Site Keyword"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginTop="2rem"
            >
              <Button className="button" startIcon={<Check />} type="submit">
                save
              </Button>
            </Box>
          </form>
        </Paper>
      </div>
    </div>
  );
};
