import React, { useState, useEffect } from "react";
import "./LoginPage.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Backdrop,
  Box,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  colors,
  Button,
  CircularProgress
} from "@material-ui/core";
import { Person, Lock, ChevronRight, RestaurantMenu } from "@material-ui/icons";
import { postlogin } from '../../redux';

//images
import bgImage from "../../assets/images/signinBgImage.png";

const useStyles = makeStyles(() => ({
  backdrop: {
    minHeight: "100vh",display:"flex",flexDirection: "column", justifyContent:"center", alignItems: "center",background:"red" 
  },
}));

export const LoginPage = () => {
  const history = useHistory();
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const classes = useStyles();
  const [formlogin, _formlogin] = useState({ administrator_email: '', administrator_password: '' })

  const _handleChange = (e) => {
    _formlogin({ ...formlogin, [e.target.name]: e.target.value })
  }

  const _handleLogin = (e) => {
    e.preventDefault()
    dispatch(postlogin(formlogin))
  }

  const [token, _token] = useState('')

  useEffect(() => {
    token && localStorage.setItem("auth-token", token)
  }, [token])

  useEffect(() => {
    _token(state.login.token)
    // for sometime
    // state?.login?.token && history.push('/')
  }, [state?.login?.token])

  useEffect(() => {
    state.login.status === 'green' && setTimeout(() => {
      history.push('/')
    }, 1000);
  }, [state?.login?.status])

  useEffect(() => {
    _formlogin({...formlogin, administrator_email: state?.login?.data?.administrator_email})
  }, [state?.login?.data])

  return (
    <div className={classes.backdrop}>
        <img
          alt="bgimage"
          src={bgImage} className="logo"
        />
        <Box className="login_form">
          <form onSubmit={e => _handleLogin(e)}>
            <TextField
              error={state?.login?.status === 'red'}
              fullWidth
              type="text"
              color="secondary"
              placeholder="Email"
              name="administrator_email"
              value={formlogin.administrator_email}
              onChange={e => _handleChange(e)}
              InputProps={{
                style: {
                  background: "#ffffff",
                  marginBottom: "5%",
                },
                startAdornment: (
                  <InputAdornment position="start">
                      <Person color="secondary"/>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            <TextField
              fullWidth
              error={state?.login?.status === 'red'}
              type="password"
              name="administrator_password"
              variant="outlined"
              color="secondary"
              value={formlogin.administrator_password}
              onChange={e => _handleChange(e)}
              placeholder="Password"
              InputProps={{
                style: { background: "#ffffff", marginBottom: "5%" },
                startAdornment: (
                  <InputAdornment position="start">
                      <Lock color="secondary"/>
                  </InputAdornment>
                ),
              }}
            />
            {
              state?.login?.status &&
              <small style={{ color: state?.login?.status, marginBottom: "1.5rem" }}>{state?.login?.message}</small>
            }
            <Button type="submit"
              fullWidth
              style={{ background: "#000000", color: "#ffffff" }}
              endIcon={<ChevronRight style={{ color: colors.yellow[800] }} />}
            >
              login
            </Button>
          </form>
          <Button
            variant="text"
            style={{ position: "absolute", bottom: "-15%" }}
          >
            Forgot Password{" "}
          </Button>
        </Box>
    </div>
  );
};
