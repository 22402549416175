import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {
  Box,
  Button,
  colors,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Checkbox from "@material-ui/core/Checkbox";
import { AddCircle, Search } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { getItemDataAll, getItemDataByName } from "../../../redux";
import { useHistory } from "react-router-dom";
const rows = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "image", label: "Image" },
  { id: "name", label: "Item Name" },
  { id: "price", label: "Price" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  // { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)' },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        View Items
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  image: {
    width: "150px",
    height: "150px",
    margin: "auto",
    border: "1px solid black",
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
  },
  noProduct: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: theme.spacing(16),
    backgroundColor: "#f5f5f5",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem",
  },
}));

export default function AddFetureProduct({
  feture,
  addItem,
  dialogClose,
  removeItem,
}) {
  const history = useHistory();
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  // const [itemList, setitemList] = React.useState([])
  const [items, setitems] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [addOrRemove, setaddOrRemove] = React.useState(false);
  const [showAddItems, setShowAddItems] = React.useState(true);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    state?.feture?.status === "success" &&
      dialogClose(false) &&
      setSelected([]) &&
      setitems([]);
  }, [state?.feture?.status]);

  useEffect(() => {
    console.log(feture);
    setitems(feture?.featured_item_item_id);
    feture?.featured_item_item_id.map((res) => {
      selected.push(res._id);
      setSelected(selected);
    });
    feture?.featured_item_item_id?.length === 0 && setaddOrRemove(false);
  }, [feture?.featured_item_item_id]);

  const addIteminthisFeture = () => {
    setaddOrRemove(true);
    setSelected([]);
    feture.featured_item_applied_to_id !== "12345"
      ? dispatch(
          getItemDataAll(
            `item_list_by_category_any_cat_id/${feture.featured_item_applied_to_id}`
          )
        )
      : dispatch(getItemDataAll(`get_all_item?page=1&limit=10`));
    setShowAddItems(false);
  };

  const searchByWord = (value) => {
    try {
      dispatch(getItemDataByName(value));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const addItem = state?.item?.data;
    feture?.featured_item_item_id?.map((res) =>
      state?.item?.data?.find(
        (item, index) => item._id === res._id && addItem.splice(index, 1)
      )
    );
    addOrRemove && setitems(addItem);
  }, [state?.item?.data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <DialogTitle
        style={{ backgroundColor: colors.blue[50] }}
        id="max-width-dialog-title"
      >
        Create Featured products
      </DialogTitle>

      <DialogContent>
        <Paper className={classes.paper}>
          <Box className={classes.header}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              View Items
            </Typography>
            {showAddItems ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => addIteminthisFeture()}
              >
                Add Items
              </Button>
            ) : (
              <TextField
                size="small"
                variant="outlined"
                label="Search..."
                onChange={(e) => {
                  if (e.target.value !== "") {
                    searchByWord(e.target.value);
                  } else {
                    feture.featured_item_applied_to_id !== "12345"
                      ? dispatch(
                          getItemDataAll(
                            `item_list_by_category_any_cat_id/${feture.featured_item_applied_to_id}`
                          )
                        )
                      : dispatch(
                          getItemDataAll(`get_all_item?page=1&limit=10`)
                        );
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={state?.item?.data.length}
              />
              {/* <button onClick= {() => addIteminthisFeture()}>button</button> */}
              {state?.item?.loading ? (
                <CircularProgress></CircularProgress>
              ) : items?.length > 0 ? (
                <TableBody>
                  {stableSort(items, getComparator(order, orderBy))
                    .map((row, index) => {
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row._id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row._id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            alignItems="flex-start"
                            style={{ display: "flex" }}
                          >
                            <Box
                              style={{
                                width: "150px",
                                height: "150px",
                                border: "1px solid black",
                                padding: "5px",
                                margin: "10px",
                              }}
                            >
                              <img
                                src={row.item_cover_image}
                                height="100%"
                                width="100%"
                                alt="product"
                              ></img>
                            </Box>
                          </TableCell>
                          <TableCell>{row.item_name}</TableCell>
                          <TableCell>{row.item_price}</TableCell>
                          <TableCell>
                            {row.item_available_start_date &&
                              `${String(
                                new Date(
                                  row.item_available_start_date
                                ).getDate()
                              ).padStart(2, "0")}/
                                                                    ${String(
                                                                      new Date(
                                                                        row.item_available_start_date
                                                                      ).getMonth() +
                                                                        1
                                                                    ).padStart(
                                                                      2,
                                                                      "0"
                                                                    )}/
                                                                    ${new Date(
                                                                      row.item_available_start_date
                                                                    ).getFullYear()}`}
                          </TableCell>
                          <TableCell>
                            {row.item_available_end_date &&
                              `${String(
                                new Date(row.item_available_end_date).getDate()
                              ).padStart(2, "0")}/
                                                                ${String(
                                                                  new Date(
                                                                    row.item_available_end_date
                                                                  ).getMonth() +
                                                                    1
                                                                ).padStart(
                                                                  2,
                                                                  "0"
                                                                )}/
                                                                ${new Date(
                                                                  row.item_available_end_date
                                                                ).getFullYear()}`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box className={classes.noProduct}>
                        <Typography
                          variant="button"
                          color="textSecondary"
                          className={classes.margin}
                        >
                          {state?.item?.data?.length > 0
                            ? "You have no Product In This Feture"
                            : "You have no Product Left In This Catagory"}
                        </Typography>
                        {state?.item?.data?.length > 0 ? (
                          <Button
                            startIcon={<AddCircle />}
                            variant="contained"
                            color="primary"
                            className="button"
                            onClick={() => addIteminthisFeture()}
                          >
                            Add product in Feture
                          </Button>
                        ) : (
                          <Button
                            startIcon={<AddCircle />}
                            variant="contained"
                            color="primary"
                            className="button"
                            onClick={() =>
                              history.push({
                                pathname: `/${state?.login?.data?.adminstrator_branch_id}/menu-setup/create-item`,
                                state: {
                                  main_category_id:
                                    feture.featured_item_applied_to_id,
                                },
                              })
                            }
                          >
                            Add product in this Catagory
                          </Button>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            {addOrRemove && state?.item?.totalPage > 1 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                margin="5% 0 3% 0"
              >
                <Pagination
                  count={state?.item?.totalPage}
                  page={state?.item?.currentPage}
                  color="primary"
                  onChange={(e, value) =>
                    dispatch(
                      getItemDataAll(
                        `get_all_item?page=${value.toString()}&limit=10`
                      )
                    )
                  }
                />
              </Box>
            )}
          </TableContainer>
          {/* <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                /> */}
        </Paper>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            dialogClose(false) && setSelected([]) && setitems([]);
          }}
        >
          Back
        </Button>
        {selected.length > 0 && (
          <>
            {addOrRemove ? (
              <Button
                type="submit"
                color="primary"
                autoFocus
                onClick={() => addItem(feture._id, selected)}
              >
                Add
              </Button>
            ) : (
              <Button
                type="submit"
                color="primary"
                autoFocus
                onClick={() => removeItem(feture._id, selected)}
              >
                Remove
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </div>
  );
}
