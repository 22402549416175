import { Box, Breadcrumbs, Button, Divider, Link, Paper, TextField, Typography, Snackbar, colors, Container, makeStyles, ListItem, List, SwipeableDrawer, IconButton } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Check, ChevronLeft, ChevronRight, RadioButtonUnchecked } from '@material-ui/icons'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import MuiAlert from '@material-ui/lab/Alert';
import { postAdministrator, updateAdministrator,getAllBranch } from "../../redux"
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { SwipeDrawer } from '../RestaurantBesicPage/swipedrawer/swipeDrawer'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const CreateAdministrator = ({ location, match }) => {
  const history = useHistory()
  const classes = useStyles()
  const [drawerOpen, setdrawerOpen] = useState(true);
  const [selectState, setselectState] = useState("administrator");
  const id = match.params.id
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const [branchList, _branchList] = useState([])
  const [formData, _formData] = useState({
    adminstrator_branch_id: "", branch: {}, administrator_role_id: "", administrator_role: {},
    administrator_first_name: "", administrator_last_name: "", administrator_email: "",
    administrator_user_name: "", administrator_password: "", administrator_confirm_password: ""
  })

  const [roleList, _roleList] = useState([
    { role_name: 'Main Admin', _id: 'main_admin' },
    { role_name: 'Administrator', _id: 'administrator' },
    { role_name: 'Branch manager', _id: 'branch_manager' },
    { role_name: 'new role', _id: 'new_role' }
  ])
  useEffect(() => {
    dispatch(getAllBranch())
  }, [dispatch])

  useEffect(() => {
    state?.administrator?.status && location?.state && _formData(location?.state)
  }, [location?.state])

  useEffect(() => {
    state?.administrator?.status === 'success' &&
      _formData({
        adminstrator_branch_id: "", branch: {}, administrator_role_id: "", administrator_role: {},
        administrator_first_name: "", administrator_last_name: "", administrator_email: "",
        administrator_user_name: "", administrator_password: "", administrator_confirm_password: ""
      })

    state?.administrator?.status === 'success' && setTimeout(() => {
      location?.state && history.push({
        pathname: `/${state?.login?.data?.adminstrator_branch_id}/restaurant-basic/administrator`,
        // state: 'administrator'
      })
    }, 1000);

  }, [state?.administrator?.status])

  useEffect(() => {
    location?.state && _formData({...location?.state, 
      branch: state?.branch?.data?.find(res => res._id === location?.state?.adminstrator_branch_id),
      administrator_role: roleList.find(res =>res._id === location?.state?.administrator_role_id)})
  }, [location?.state])

  const onchange = (e) => {
    _formData({ ...formData, [e.target.name]: e.target.value })
  }

  const branchChange = (e, newValue) => {
    _formData({ ...formData, branch: newValue, adminstrator_branch_id: newValue?._id ? newValue?._id : '' })
  }

  const roleChange = (e, newValue) => {
    _formData({ ...formData, administrator_role: newValue, administrator_role_id: newValue?._id ? newValue?._id : '' })
  }

  return (
    <>
      {
        !state?.branch?.loader &&
        <div style={{
          paddingTop: "10px",
          width: "100%",
          height: "100%",
          background: colors.grey[200],
          position: "relative",
          display: 'flex'
        }} className={classes.root}>
          <div
            style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            open={drawerOpen}
            onClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Access Control
          </Typography>
            </div>
            <SwipeDrawer />
          </SwipeableDrawer>
          <div style={{ paddingLeft: 20, paddingRight: 20 }} className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen,
          })} >
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
              <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                Home
                </Link>
              <Link color="inherit" href={`/${id}/access-control/administrator`} style={{ fontSize: 15 }}>
                Administrator
                </Link>
              <Typography
                color="textPrimary"
                style={{ fontSize: 15, color: "var(--orange)" }}
              >
                Create
                </Typography>
            </Breadcrumbs>
            <Container maxWidth="sm">
              <Paper style={{ padding: "2rem", margin: "15px auto 5rem auto", }}>
                <Typography variant="h6" >Create</Typography>
                <Divider style={{ margin: '10px 0' }} />
                <form noValidate onSubmit={e => {
                  e.preventDefault()
                  location?.state ? dispatch(updateAdministrator(formData)) : dispatch(postAdministrator(formData))
                }}>
                  <Autocomplete
                    options={roleList} size="small"
                    value={formData.administrator_role}
                    getOptionLabel={(option) => option.role_name}
                    onChange={(e, newValue) => roleChange(e, newValue)}
                    style={{ marginBottom: '1.5rem' }}
                    renderInput={(params) => <TextField {...params} required label="Role Name" variant="outlined" />}
                  />
                  <Autocomplete size="small"
                    name="branch"
                    options={branchList} value={formData.branch}
                    getOptionLabel={(option) => option.branch_name}
                    onChange={(e, newValue) => branchChange(e, newValue)}
                    style={{ marginBottom: '1.5rem' }}
                    renderInput={(params) => <TextField size='small' required {...params} label="Branch Name" variant="outlined" />}
                  />
                  <TextField variant="outlined" style={{ marginBottom: '1.5rem' }} fullWidth size="small" label='First Name' required
                    value={formData.administrator_first_name} name="administrator_first_name" onChange={(e) => onchange(e)} />
                  <TextField variant="outlined" style={{ marginBottom: '1.5rem' }} fullWidth size="small" label='Last Name' required
                    value={formData.administrator_last_name} name="administrator_last_name" onChange={e => onchange(e)} />
                  <TextField variant="outlined" style={{ marginBottom: '1.5rem' }} fullWidth size="small" label='Email' required
                    value={formData.administrator_email} name="administrator_email" onChange={(e) => onchange(e)} />
                  <TextField variant="outlined" style={{ marginBottom: '1.5rem' }} fullWidth size="small" label='AdminUsername' required
                    value={formData.administrator_user_name} name="administrator_user_name" onChange={(e) => onchange(e)} />
                  {
                    !location?.state && <>
                      <TextField variant="outlined" style={{ marginBottom: '1.5rem' }} fullWidth size="small" label='Password' required
                        value={formData.administrator_password} name="administrator_password" onChange={(e) => onchange(e)} />
                      <TextField variant="outlined" style={{ marginBottom: '1.5rem' }} fullWidth size="small" label='Confirm Password' required
                        value={formData.administrator_confirm_password} name="administrator_confirm_password" onChange={(e) => onchange(e)} />
                    </>
                  }
                  <Box display='flex' alignItems='center' justifyContent='center' marginTop='2rem' >
                    <Button className="button" startIcon={<ChevronLeft />} style={{ marginRight: '2rem' }}
                      onClick={() => history.push({
                        pathname: `/${state?.login?.data?.adminstrator_branch_id}/restaurant-basic/administrator`,
                        // state: 'administrator'
                      })}
                    >Back</Button>
                    <Button className="button" type="submit" startIcon={<Check />} >
                      {
                        location?.state ? "Update" : "Save"
                      }
                    </Button>
                  </Box>
                </form>
              </Paper>
            </Container>
            <Snackbar open={state?.administrator?.status}
              onClose={state?.administrator?.status}>
              <Alert onClose={!state?.administrator?.status} severity={state?.administrator?.status}>
                {state?.administrator?.message}
              </Alert>
            </Snackbar>
          </div>
        </div>
      }</>)
}