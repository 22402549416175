import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

import {
  Breadcrumbs,
  Typography,
  Link,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  FormGroup,
  colors,
  Grid,
  Box,
  Button,
  IconButton,
  Snackbar,
  SwipeableDrawer,
  Checkbox,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MuiAlert from '@material-ui/lab/Alert';
import { ChevronRight, Check, ChevronLeft } from "@material-ui/icons";
import { useHistory } from 'react-router-dom'
import { SwipeDrawer } from "./swipedrawer/swipeDrawer";
import { getmenutime, updatemenutime } from "../../redux/menutime/menutimeActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const MenuTime = ({ match }) => {
  const history = useHistory()
  const classes = useStyles()
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const [drawerOpen, setdrawerOpen] = useState(true);

  useEffect(() => {
    state?.login?.data?.adminstrator_branch_id && dispatch(getmenutime(state?.login?.data?.adminstrator_branch_id))
  }, [state?.login?.data])

  const [menuTime, setmenuTime] = React.useState(null)

  const handleChange = (event) => {
    setmenuTime({ ...menuTime, [event.target.name]: { ...event.target.name, from: null, to: null, status: event.target.checked } })
  };

  useEffect(() => {
    state?.menutime?.data && setmenuTime(state?.menutime?.data)
  }, [state?.menutime?.data])

  const submitmenutime = (e) => {
    console.log(menuTime)
    dispatch(updatemenutime(menuTime))
    e.preventDefault()
  }

  return (
    <>
      {
        !state?.branch?.loader &&
        <div style={{
          width: "100%",
          height: "100vh",
          background: colors.grey[200],
          position: "relative",
        }} className={classes.root} >
          <div
            style={{ position: "fixed", top: "0%", right: "63%", zIndex: 1100 }}
            className={classes.drawerHeader}
          >
            <IconButton>
              {drawerOpen ? (
                <ChevronLeft onClick={() => setdrawerOpen(false)} />
              ) : (
                <ChevronRight onClick={() => setdrawerOpen(true)} />
              )}
            </IconButton>
          </div>
          <SwipeableDrawer
            className={classes.drawer}
            anchor="left"
            variant="persistent"
            style={{ marginTop: "10px" }} open={drawerOpen}
            o style={{ marginTop: "10px" }} nClose={() => setdrawerOpen(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
              <Typography align="center" variant="h6">
                Restaurant Basics
              </Typography>
            </div>
            <SwipeDrawer />
          </SwipeableDrawer>
          {
            menuTime ?
              <div className={clsx(classes.content, {
                [classes.contentShift]: drawerOpen,
              })} style={{ paddingLeft: 20, paddingRight: 20 }} >
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<ChevronRight style={{ fontSize: 15 }} />}
                >
                  <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                    Home
                  </Link>
                  <Typography
                    color="textPrimary"
                    style={{ fontSize: 15, color: "var(--orange)" }}
                  >
                    Menu Time
                  </Typography>
                </Breadcrumbs>
                <Paper style={{ marginTop: '15px', padding: "20px 30px" }}>
                  <form onSubmit={submitmenutime}>
                    <Box display="flex" padding="10px">
                      <div style={{width: "400px"}}>
                        Preferable Time
                      </div>
                      <div item>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={menuTime?.breakfast?.status}
                                onChange={e => handleChange(e)}
                                name="breakfast"
                                color="primary"
                              />
                            }
                            label="BreakFast"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={menuTime?.brunch?.status}
                                onChange={e => handleChange(e)}
                                name="brunch"
                                color="primary"
                              />
                            }
                            label="Brunch"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={menuTime?.lunch?.status}
                                onChange={e => handleChange(e)}
                                name="lunch"
                                color="primary"
                              />
                            }
                            label="Lunch"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={menuTime?.supper?.status}
                                onChange={e => handleChange(e)}
                                name="supper"
                                color="primary"
                              />
                            }
                            label="Supper"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={menuTime?.dinner?.status}
                                onChange={e => handleChange(e)}
                                name="dinner"
                                color="primary"
                              />
                            }
                            label="Dinner"
                          />
                        </FormGroup>
                      </div>
                    </Box>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell rowSpan={2} width="400px">
                            <Typography variant="h5">Item Avaliable Time</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Grid container spacing={3}>
                              <Grid item xs={6}>Start Time</Grid>
                              <Grid item xs={6}>End Time</Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                              <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>BreakFast</Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Grid container spacing={3}>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.breakfast?.status}
                                    margin="normal" disabled={!menuTime?.breakfast?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.breakfast?.from}
                                    onChange={(date) => {
                                      // menuTime?.breakfast?.from = date
                                      setmenuTime({
                                        ...menuTime,
                                        breakfast: {
                                          ...menuTime?.breakfast,
                                          from: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.breakfast?.status}
                                    margin="normal" disabled={!menuTime?.breakfast?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.breakfast?.to}
                                    onChange={(date) => {
                                      setmenuTime({
                                        ...menuTime,
                                        breakfast: {
                                          ...menuTime?.breakfast,
                                          to: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                              <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Brunch</Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Grid container spacing={3}>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.brunch?.status}
                                    margin="normal" disabled={!menuTime?.brunch?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.brunch?.from}
                                    onChange={(date) => {
                                      // menuTime?.brunch?.from = date
                                      setmenuTime({
                                        ...menuTime,
                                        brunch: {
                                          ...menuTime?.brunch,
                                          from: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.brunch?.status}
                                    margin="normal" disabled={!menuTime?.brunch?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.brunch?.to}
                                    onChange={(date) => {
                                      setmenuTime({
                                        ...menuTime,
                                        brunch: {
                                          ...menuTime?.brunch,
                                          to: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                              <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Lunch</Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Grid container spacing={3}>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.lunch?.status}
                                    margin="normal" disabled={!menuTime?.lunch?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.lunch?.from}
                                    onChange={(date) => {
                                      // menuTime?.lunch?.from = date
                                      setmenuTime({
                                        ...menuTime,
                                        lunch: {
                                          ...menuTime?.lunch,
                                          from: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.lunch?.status}
                                    margin="normal" disabled={!menuTime?.lunch?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.lunch?.to}
                                    onChange={(date) => {
                                      setmenuTime({
                                        ...menuTime,
                                        lunch: {
                                          ...menuTime?.lunch,
                                          to: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                              <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Super</Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Grid container spacing={3}>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.supper?.status}
                                    margin="normal" disabled={!menuTime?.supper?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.supper?.from}
                                    onChange={(date) => {
                                      // menuTime?.supper?.from = date
                                      setmenuTime({
                                        ...menuTime,
                                        supper: {
                                          ...menuTime?.supper,
                                          from: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.supper?.status}
                                    margin="normal" disabled={!menuTime?.supper?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.supper?.to}
                                    onChange={(date) => {
                                      setmenuTime({
                                        ...menuTime,
                                        supper: {
                                          ...menuTime?.supper,
                                          to: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
                              <Typography style={{ marginTop: "9px", marginRight: "15px", width: "200px" }}>Dinner</Typography>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Grid container spacing={3}>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.dinner?.status}
                                    margin="normal" disabled={!menuTime?.dinner?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.dinner?.from}
                                    onChange={(date) => {
                                      // menuTime?.dinner?.from = date
                                      setmenuTime({
                                        ...menuTime,
                                        dinner: {
                                          ...menuTime?.dinner,
                                          from: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              <Grid item xs>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker style={{ marginTop: "0px" }} required={menuTime?.dinner?.status}
                                    margin="normal" disabled={!menuTime?.dinner?.status}
                                    id="time-picker"
                                    label="HH:MM"
                                    value={menuTime?.dinner?.to}
                                    onChange={(date) => {
                                      setmenuTime({
                                        ...menuTime,
                                        dinner: {
                                          ...menuTime?.dinner,
                                          to: date
                                        }
                                      })
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change time",
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <div style={{ padding: "15px", display: "flex", justifyContent: "center" }}>
                      <Button variant="contained" style={{ "color": "#fff", margin: "0 10px" }} color="primary" startIcon={<Check />} type="submit">
                        Save
                      </Button>
                      <Button variant="contained" style={{ "color": "#fff", margin: "0 10px" }} color="primary" type="button"
                        onClick={() => dispatch(getmenutime())}>
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Paper>
                <Snackbar open={state.menutime.status}
                  onClose={state.menutime.status}>
                  <Alert onClose={!state.menutime.status} severity={state.menutime.status}>
                    {state.menutime.message}
                  </Alert>
                </Snackbar>
              </div> : <div className="loaderBg">
                <CircularProgress color="secondary" /></div>

          }
        </div >
      }
    </>);
};
