import {
    HANDLE_BRANCH_REQUEST, HANDLE_BRANCH_SUCCESS, HANDLE_BRANCH_FALIOUR, HANDLE_BRANCH_FORM_DATA, GET_BRANCH_DATA_BY_ID,
    GET_BRANCH_DATA_FOR_ID, GET_ADDRESS, GET_BRANCH_TIMEZONE
} from "./branchTypes";
import { fetchData, postData, updateData } from "../../Api/api";
import axios from "axios";
import { getAllstate } from "../countryCurrency/countryActions";

export const fetchBranchRequest = () => {
    return {
        type: HANDLE_BRANCH_REQUEST
    }
}

export const fetchBranchSucess = (data) => {
    return {
        type: HANDLE_BRANCH_SUCCESS,
        payload: data
    }
}

export const fetchBranchSucessForId = (data) => {
    return {
        type: GET_BRANCH_DATA_FOR_ID,
        payload: data
    }
}

export const fetchBranchSucessById = (data) => {
    return {
        type: GET_BRANCH_DATA_BY_ID,
        payload: data
    }
}

export const fetchBranchFaliour = (data) => {
    return {
        type: HANDLE_BRANCH_FALIOUR,
        payload: data
    }
}

export const handleBranchData = (data) => {
    return {
        type: HANDLE_BRANCH_FORM_DATA,
        payload: data
    }
}

export const fetchAddress = (data) => {
    return {
        type: GET_ADDRESS,
        payload: data
    }
}

export const fetchTimeZone = (data) => {
    return {
        type: GET_BRANCH_TIMEZONE,
        payload: data
    }
}

export const getAllBranch = (endpoint = `get_all_branch`) => {
    return (dispatch) => {
        dispatch(fetchBranchRequest())
        fetchData(endpoint).then(
            response => dispatch(fetchBranchSucess(response.data.response))
        ).catch(
            error => dispatch(fetchBranchFaliour(error.message))
        )
    }
}

export const getAddress = (lat, lng) => {
    return (dispatch) => {
        dispatch(fetchBranchRequest())
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAM7yjSDq3X-pzq2A0mNEcbYcqMMRHLlIQ`).then(
            response => {
                // console.log(response.data.results[0].address_components)
                let address = {
                    country: "",
                    branch_state: "",
                    branch_city: "",
                    branch_address: ""
                }
                address = {...address, branch_address: response?.data?.results[0]?.formatted_address, branch_city: response?.data?.results[0]?.formatted_address?.split(",")[1]}
                response.data.results[0]?.address_components?.map(
                    res => {
                        switch (res.types[0]) {
                            case "country":
                                address = {...address, country: res.long_name}
                                break;
                            case "administrative_area_level_1":
                                address = {...address, branch_state: res.long_name}
                                break;
                            default:
                                break;
                        }
                    }
                )
                console.log(address)
                dispatch(getAllstate(address.country))
                dispatch(fetchAddress(address))
            }
        ).catch(
            error => dispatch(fetchBranchFaliour(error.message))
        )
    }
}

export const getTimeZone = (lat, lng) => {
    return (dispatch) => {
        dispatch(fetchBranchRequest())
        axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1331161200&key=AIzaSyAM7yjSDq3X-pzq2A0mNEcbYcqMMRHLlIQ`).then(
            response => {
                dispatch(fetchTimeZone(response?.data?.status=== "OK" ? response?.data?.timeZoneId : ""))
            }
        ).catch(
            error => dispatch(fetchBranchFaliour(error.message))
        )
    }
}


export const getBranchById = (id) => {
    return (dispatch) => {
        dispatch(fetchBranchSucessForId())
        fetchData(`get_branch/${id}`).then(
            response => dispatch(fetchBranchSucessById(response.data.response))
        ).catch(
            error => dispatch(fetchBranchFaliour(error.message))
        )
    }
}

export const postBranch = (body) => {
    return (dispatch) => {
        dispatch(fetchBranchRequest())
        postData(`add_branch`, body).then(
            response => {
                dispatch(handleBranchData(response.data))
                response.data.status === 'success' && dispatch(getAllBranch(`get_all_branch`))
            }
        ).catch(
            error => dispatch(fetchBranchFaliour(error.message))
        )
    }
}

export const updateBranch = (body) => {
    return (dispatch) => {
        dispatch(fetchBranchRequest())
        updateData(`update_branch`, body).then(
            response => {
                dispatch(handleBranchData(response.data))
                response.data.status === 'success' && dispatch(getAllBranch(`get_all_branch`))
            }
        ).catch(
            error => dispatch(fetchBranchFaliour(error.message))
        )
    }
}

export const updateActiveBranch = (body) => {
    return (dispatch) => {
        dispatch(fetchBranchRequest())
        updateData(`update_branch`, body).then(
            response => {
                dispatch(handleBranchData(response.data))
                response.data.status === 'success' && dispatch(getBranchById(response.data.response._id))
            }
        ).catch(
            error => dispatch(fetchBranchFaliour(error.message))
        )
    }
}