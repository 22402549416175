import { FETCH_CATAGORY_ACCESS,HANDLE_CATAGORY_DATA,FETCH_CATAGORY_SUCCESS,REQUEST_FALIOUR,HANDLE_IMAGE_UPLOAD } from "./catagoryActionTypes";

const initailState = {
    loader: false,
    data: null,
    status: '',
    message: '',
    imageLink : '',
}

export const catagoryReducers = (state = initailState, action) => {
    switch (action.type) {
        case FETCH_CATAGORY_ACCESS:
            return {
                ...state, loader: true
            }
        case FETCH_CATAGORY_SUCCESS:
            return {
                ...state, 
                loader: false,
                data : action.payload.response,
                status : '',
                message : '',
                imageLink : ''
            }
        case HANDLE_CATAGORY_DATA:
            return {
                ...state, 
                loader: false,
                status : action.payload.status === 'success' ? action.payload.status : 'error',
                message : action.payload.message,
                imageLink : ''
            }
        case REQUEST_FALIOUR:
            return {
                ...state, 
                loader: false,
                data : [],
                status : 'error',
                message : action.payload,
                imageLink : ''
            }
        case HANDLE_IMAGE_UPLOAD : 
            return {
                ...state,
                loader: false,
                imageLink: action.payload.image_url
            }
        default:
            return state
    }
}