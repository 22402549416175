import { Box, Grid, Divider, Typography, makeStyles, StepConnector, withStyles, Stepper, Step, StepLabel, Button } from '@material-ui/core'
import { Check, GetApp, Motorcycle, ThumbUp } from "@material-ui/icons";
import React, { useEffect } from 'react'
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    containerPadding: {
        padding: "15px"
    }
}))

const useQontoStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center"
    },
    active: {
        color: "#784af4"
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor"
    },
    completed: {
        color: "#784af4",
        zIndex: 1,
        fontSize: 18
    }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    active: {
        "& $line": {
            backgroundColor: "#ff9800"
        }
    },
    completed: {
        "& $line": {
            backgroundColor: "#ff9800"
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center"
    },
    active: {
        backgroundColor: "#ff9800",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
    },
    completed: {
        backgroundColor: "#ff9800",
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <GetApp />,
        2: <Check />,
        3: <Motorcycle />,
        4: <ThumbUp />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

function getStepContent(step) {
    switch (step) {
        case 0:
            return "Select campaign settings...";
        case 1:
            return "What is an ad group anyways?";
        case 2:
            return "This is the bit I really care about!";
        default:
            return "Unknown step";
    }
}


export const TrackOrder = ({ order }) => {
    const classes = useStyles()
    const steps = [
        { value: 'pending', time: order.order_status_pending && order.order_status_pending.order_status_date_time, status: "Pending" },
        { value: 'accepted', time: order.order_status_accepted && order.order_status_accepted.order_status_date_time, status: "Accepted" },
        { value: 'on_the_way', time: order.order_status_on_the_way && order.order_status_on_the_way.order_status_date_time, status: "On the Way" },
        { value: 'delivered', time: order.order_status_delivered && order.order_status_delivered.order_status_date_time, status: order?.order_booking_type
    },
    ];
    const [activeStep, setActiveStep] = React.useState(0);

    useEffect(() => {
        setActiveStep(steps.findIndex(res => 
        order.order_status_delivered && order.order_status_delivered.status ? res.value === 'delivered' : 
        order.order_status_on_the_way && order.order_status_on_the_way.status ? res.value === 'on_the_way' : 
        order.order_status_accepted && order.order_status_accepted.status ? res.value === 'accepted' :
        order.order_status_rejected && order.order_status_rejected.status ? res.value === 'rejected' :
        order.order_status_pending && order.order_status_pending.status && res.value === 'pending'))
    }, [order])


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Order Information:
                        </Typography>
            <Divider />
            <Grid container spacing={1} className={classes.containerPadding}>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                        Order Number:
                        </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        {order.order_id}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                        Order Total:
                        </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        0000085
                        </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                        Order Date:
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        {moment(order.createdAt).format('L')}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                        Order Time:
                        </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        {moment(order.createdAt).tz("America/New_York").format('LT')}
                    </Typography>
                </Grid>
            </Grid>
            <Typography variant="h5" gutterBottom>
                Order Tracking
            </Typography>
            <Divider />
            <div className={classes.root}>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<ColorlibConnector />}
                >
                    {steps.map((label) => (
                        <Step key={label.value}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                {label.status}
                                <Typography variant="subtitle2" gutterBottom>
                                    {label.time ? `${moment(label.time).tz("America/New_York").format('L')} ${moment(label.time).tz("America/New_York").format('LT')}` : ''}
                                </Typography>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {/* <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                All steps completed - you&apos;re finished
            </Typography>
                            <Button onClick={handleReset} className={classes.button}>
                                Reset
            </Button>
                        </div>
                    ) : (
                        <div>
                            <Typography className={classes.instructions}>
                                {getStepContent(activeStep)}
                            </Typography>
                            <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    className={classes.button}
                                >
                                    Back
              </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                </Button>
                            </div>
                        </div>
                    )}
                </div> */}
            </div>
        </div>
    )
}
