import { Box, Breadcrumbs, Button, Divider, Link, Paper, Typography, makeStyles, ListItem, List, SwipeableDrawer, IconButton,colors } from '@material-ui/core'
import { Check, ChevronLeft, ChevronRight, Mail, RadioButtonUnchecked } from '@material-ui/icons'
import { AiOutlineGoogle, FaFacebookF } from 'react-icons/all'
import React,{ useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from "clsx";
import { useHistory } from 'react-router-dom'

import { Input } from '../../components/Input/Input.component'

import { getAnalytics, updateAnaliticsDetails } from '../../redux/AdvanceSetting/AdvanceSetting.action'

const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: 100,
  },
  drawerPaper: {
    width: drawerWidth,
    marginLeft: 100,
    background: "var(--light-black)",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export const AnalyticsSetting = ({ match }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const history = useHistory()
    const [drawerOpen, setdrawerOpen] = useState(true);
    const [selectState, setselectState] = useState("Analytics Setting");
    const id = match.params.id
    const [value, setValue] = useState({
        google_analytics:'',
        facebook_pixel_code_licence:'',
        mail_chimp_licence_number:'',
        google_tag_manager_number:''
    })
    const analytics = useSelector(state => state?.advanceSetting?.analytics?.response)


    useEffect(() => {
        dispatch(getAnalytics())
    },[dispatch])
    useEffect(() => {
        analytics?.map(item => setValue(item))
    },[analytics])

    const handleSubmit = (e) => {
        e.preventDefault()
        try {
            dispatch(updateAnaliticsDetails(value, analytics[0]?._id))
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{
            paddingTop: "10px",
            width: "100%",
            height: "100%",
            background: colors.grey[200],
            position: "relative",
          }}
          className={classes.root}>
            <div
        style={{ position: "fixed", top: "0", right: "63%", zIndex: 1500 }}
        className={classes.drawerHeader}
      >
        <IconButton>
          {drawerOpen ? (
            <ChevronLeft onClick={() => setdrawerOpen(false)} />
          ) : (
            <ChevronRight onClick={() => setdrawerOpen(true)} />
          )}
        </IconButton>
      </div>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="left"
        variant="persistent"
        open={drawerOpen}
        onClose={() => setdrawerOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div style={{ marginTop: 20, color: "#ffffff", marginBottom: 20 }}>
          <Typography align="center" variant="h6">
            Advance Settings
          </Typography>
        </div>
        <List>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "payment-gateways" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Payment Gateways</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "configureSms" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/configure-sms`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Configure SMS</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color:
                  selectState === "Congifure Email" ? "var(--orange)" : "#ffffff",
                  cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/configure-email`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Congifure Email</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Google Map Api Key" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/google-map-api-key`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Google Map Api Key</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Social Media Login" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/social-media-login`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Social Media Login</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Promotional Page Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/promotional`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Promotional Page Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Analytics Setting" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/analytics-settings`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Analytics Setting</Typography>
            </div>
          </ListItem>
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "GDPR" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/gdpr`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>GDPR</Typography>
            </div>
          </ListItem>
          {/* <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Homepage Banner" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => setselectState("Homepage Banner")}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Home Page Popup Banner</Typography>
            </div>
          </ListItem> */}
          <ListItem>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: selectState === "Pre Order Delivery" ? "var(--orange)" : "#ffffff",
                cursor: 'pointer'
              }}
              onClick={() => history.push(`/${id}/advance-settings/pre-order-delivary`)}
            >
              <RadioButtonUnchecked
                style={{ fontSize: "1rem", marginRight: 20 }}
              />
              <Typography>Pre Order Delivery Days</Typography>
            </div>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <div
      style={{ paddingLeft: 20, paddingRight: 20 }}
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
             <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight style={{ fontSize: 15 }} />}
            >
                <Link color="inherit" href="/" style={{ fontSize: 15 }}>
                    Home
                </Link>
                <Typography
                    color="textPrimary"
                    style={{ fontSize: 15, color: "var(--orange)" }}
                >
                    Analytics settings
                </Typography>
            </Breadcrumbs>
            <Paper style={{ padding: "2rem", margin: "15px auto 5rem auto", width: "70%" }}>
            <Typography variant="h6" >Analytics settings</Typography>
                <Divider style={{ margin: '10px 0' }} />
                <form onSubmit={handleSubmit} >
                <Input style={{ width: '100%', marginTop: '2rem' }} value={value.google_analytics} onChange={(e) => setValue({ ...value, google_analytics: e.target.value })} label='Google Analytics - Licence number' icon={<AiOutlineGoogle style={{ color: 'grey' }} />} />
                <Input style={{ width: '100%', marginTop: '2rem' }} value={value.facebook_pixel_code_licence} onChange={(e) => setValue({ ...value, facebook_pixel_code_licence: e.target.value })} label='Facebook Pixel Code - Licence number' icon={<FaFacebookF style={{ color: 'grey' }} />} />
                <Input style={{ width: '100%', marginTop: '2rem' }} value={value.mail_chimp_licence_number} onChange={(e) => setValue({ ...value, mail_chimp_licence_number: e.target.value })} label='Mail Chimp - Licence number' icon={<Mail />} />
                <Input style={{ width: '100%', marginTop: '2rem' }} value={value.google_tag_manager_number} onChange={(e) => setValue({ ...value, google_tag_manager_number: e.target.value })} label='Google Tag Manager Number' icon={<AiOutlineGoogle style={{ color: 'grey' }} />} />
                <Box display='flex' alignItems='center' justifyContent='center' marginTop='2rem' >
                    <Button className="button" startIcon={<Check />} type="submit" >save</Button>
                </Box>
                </form>
            </Paper>
      </div>
        </div>
    )
}
